import React, { useEffect } from 'react';
import { useState } from 'react';
import "./Banner.css";
import image1 from "../../Landing Page/media/image01.png";
import image2 from "../../Landing Page/media/image02.png";
import image3 from "../../Landing Page/media/image03.png";
import image4 from "../../Landing Page/media/banner-image.png";
import { useHistory } from "react-router-dom";

export default function Banner({ onDemoButtonClick }) {
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>

      <div style={{ width: '100%', background: '#f9f8ff', position: 'relative', top: '80px' }}>
        <div className='baNNER_Sec' >
          <div className='baNNER_Sec_Left'>

            <div className='baNNER_Sec_Heading_Main' style={{ color: '#4e4e4e' }}>
              {windowWidth <= 475 ? 'Regulatory Compliance is more effective with' : windowWidth <= 600 ? 'Regulatory Compliance is more effective' :
                windowWidth <= 630 ? 'Regulatory Compliance is more effective with' : windowWidth <= 1100 ? 'Regulatory Compliance is more effective' :
                  windowWidth <= 1272 ? 'Regulatory Compliance is more effective with' : windowWidth >= 1273 ? 'Regulatory Compliance is more effective' : ''}

              <div style={{ display: 'flex', gap: '10px', justifyContent: windowWidth <= 600 ? '' : windowWidth <= 963 ? 'center' : '' }}>
                <span style={{ display: windowWidth <= 475 ? 'none' : windowWidth <= 600 ? 'flex' : windowWidth <= 630 ? 'none' : windowWidth <= 1100 ? 'flex' : windowWidth <= 1272 ? 'none' : '' }}> with</span>
                <span style={{ color: '#124A99' }}>
                  flawless data and technology.
                </span>
              </div>
            </div>

            <div className='baNNER_Sec_Heading_Sub' style={{ width: windowWidth <= 963 ? '100%' : '' }}>
              Acaia delivers a suite of KYC & AML solutions in a single user-friendly platform, with improved data quality for more accurate results. Contact us to see the difference.
            </div>

            <div className='baNNER_Sec_BTN'>
              <button className="button-get" onClick={() => history.push('/signup')} >Get Started</button>
              <button className='button-demo' onClick={() => history.push('/contactUs')} >Watch a Demo</button>
            </div>

            <div className="image-container">
              <div>
                <img src={image1} alt="Image3" className="image-lap bam_IMg" />
                <img src={image2} alt="Image2" className="image-overlap bam_IMg" />
                <img src={image3} alt="Image1" className="image bam_IMg" />
              </div>
              <div className="banner-100k">
                <p style={{ margin: 'unset' }}>
                  100k+
                  <br />
                  Active User
                </p>
              </div>
            </div>

          </div>

          <div className='baNNER_Sec_Right'>
            <img className="" src={image4} alt="Image4" />
          </div>
        </div>
      </div>
    </>

  );
}
