import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from "axios";
import { Toast } from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
// import { Divider } from "primereact/divider";
// import { BiSolidCloudUpload } from 'react-icons/bi';
import { Triangle } from 'react-loader-spinner';

function SansationCorporateUpdate(props) {

    const toast = useRef(null);
    const [corporatename, setcorporateName] = useState('');
    let corporatenameRef = useRef('');
    const [idCardType, setidCardType] = useState('');
    let idCardTypeRef = useRef('');
    const [idno, setidNo] = useState('');
    let idnoRef = useRef('');
    const [idExpDate, setidExpDate] = useState(null);
    const [dateOfIncorporation, setdateOfIncorporation] = useState(null);
    const [address, setaddress] = useState('');
    let addressRef = useRef('');
    const [city, setidcity] = useState('');
    let cityRef = useRef('');
    const [country, setcountry] = useState('');
    let countryRef = useRef('');
    const [postalCode, setpostalCode] = useState('');
    let postalCodeRef = useRef('');
    const [listedOn, setlistedOn] = useState(null);
    const [description, setdescription] = useState('');
    let descriptionRef = useRef('');
    const [cnumber, setcnumber] = useState('');
    let cnumberRef = useRef('');
    const [aliasname, setAliasName] = useState('');
    let aliasNameRef = useRef('');
    const [comments, setcomments] = useState('')
    let commentsRef = useRef('');
    const [corporateNameError, setCorporateNameError] = useState('');
    const [corporateNumberError, setCorporateNumberError] = useState('');
    const [loading, setLoading] = useState(false);
    const [, setCompanyIdError] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US');
    };
    const resetfun = () => {
        setcorporateName('');
        setidCardType('');
        setidNo('');
        setidExpDate(null);
        setdateOfIncorporation(null);
        setaddress('');
        setidcity('');
        setcountry('');
        setpostalCode('');
        setlistedOn(null);
        setdescription('');
        setcnumber('');
        setAliasName('');
        setcomments('');
    };

    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };
    // const email = props?.data?.emailId

    const handleSubmit = (event) => {
        event.preventDefault();
        let isValid = true;
        setLoading(true);
        if (!corporatename) {
            setCorporateNameError('Corporate Name is required.');
            isValid = false;
        } else {
            setCorporateNameError('');
        }
        if (!cnumber) {
            setCorporateNumberError('Corporate Number is required.');
            isValid = false;
        } else {
            setCorporateNumberError('');
        }

        if (isValid) {
            const data = {
                corporateName: corporatename,
                idCardType: idCardType,
                idNo: idno,
                idExpDate: idExpDate ? formatDate(idExpDate) : null,
                dateOfIncorporation: dateOfIncorporation ? formatDate(dateOfIncorporation) : null,
                address: address,
                city: city,
                country: country,
                postalCode: postalCode,
                listedOn: listedOn ? formatDate(listedOn) : null,
                description: description,
                cnumber: cnumber,
                aliasName: aliasname,
                comments: comments
            }

            axios.post(process.env.REACT_APP_BACKEND_HOST + '/sanctions_corporate/upload', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props?.data?.accessToken
                }
            })
                .then(postdata => {
                    event.preventDefault();
                    setLoading(false);
                    if (postdata.status === 200) {
                        toast.current.show({ severity: 'success', summary: 'Registeration Successfully' });
                        setcorporateName('');
                        setidCardType('');
                        setidNo('');
                        setidExpDate(null);
                        setdateOfIncorporation(null);
                        setaddress('');
                        setidcity('');
                        setcountry('');
                        setpostalCode('');
                        setlistedOn(null);
                        setdescription('');
                        setcnumber('');
                        setAliasName('');
                        setcomments('');
                        setCorporateNameError('');
                        setCorporateNumberError('');
                        setCompanyIdError('');
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    } else if (error?.response?.status === 401) {
                        toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                    } else if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                });
        } else {
            setLoading(false);
        }
    }

    // const [selectedFile, setSelectedFile] = useState(null);
    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];
    //     setSelectedFile(file);
    // };

    // const handleUpdate = async (event) => {
    //     event.preventDefault();
    //     if (selectedFile) {
    //         try {
    //             const formData = new FormData();
    //             formData.append('file', selectedFile);

    //             const response = await axios.post(
    //                 process.env.REACT_APP_BACKEND_HOST + '/sanctionCorporate/fileupload',
    //                 formData,
    //                 {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                         Authorization: 'Bearer ' + props?.data?.accessToken
    //                     },
    //                 }
    //             );
    //             if (response.status === 200) {
    //                 toast.current.show({ severity: 'success', summary: 'File Upload Successfully' });
    //             } else {
    //                 toast.current.show({ severity: 'error', summary: 'Error' });
    //             }
    //         } catch (error) {
    //             if (error?.response && error?.response?.data && error?.response?.data?.message) {
    //                 const errorMessage = error?.response?.data?.message;
    //                 displayErrorToast(errorMessage);
    //             } else if (error?.response?.status === 401) {
    //                 toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
    //             } else if (error?.response && error?.response?.status === 403) {
    //                 window.location.assign(process.env.REACT_APP_AUTH_URL);
    //             }
    //             return;
    //         }
    //     }
    // };

    return (
        <>
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />

            {loading ?
                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
                : null}

            <div className="card overflow-auto" style={{ top: windowWidth <= 500 ? '-20px' : '' }}>

                <div className="feild c cor_U" >

                    <div className="heading"> Corporate Details</div>

                    <div className="flex">
                        <div className="input">
                            <div className="label">Corporate Name<span>*</span></div>
                            <InputText type='text' className="w-full" defaultValue={corporatenameRef.current} onChange={(event) => setcorporateName(event.target.value)} />
                            {corporateNameError && <div className="error-message mt-2">{corporateNameError}</div>}
                        </div>
                        <div className="input">
                            <div className="label">Alias Name</div>
                            <InputText className="w-full" defaultValue={aliasNameRef.current} onChange={(event) => setAliasName(event.target.value)} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="input">
                            <div className="label">Id Card Type</div>
                            <InputText className="w-full" defaultValue={idCardTypeRef.current} onChange={(event) => setidCardType(event.target.value)} />
                        </div>
                        <div className="input">
                            <div className="label">Id No<span>*</span></div>
                            <InputText className="w-full" defaultValue={idnoRef.current} onChange={(event) => setidNo(event.target.value)} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="input">
                            <div className="label">Id Expire Date</div>
                            <Calendar className="w-full unique-drops calander"
                                showIcon
                                value={idExpDate}
                                minDate={new Date()}
                                onChange={(e) => setidExpDate(e.value)}
                                id="p-calender" />
                        </div>
                        <div className="input">
                            <div className="label">Date Of In Corporation</div>

                            <Calendar className="w-full unique-drops calander"
                                showIcon
                                value={dateOfIncorporation}
                                onChange={(e) => setdateOfIncorporation(e.value)}
                                id="p-calender" />
                        </div>
                    </div>

                    <div className="heading">Address</div>

                    <div className="flex">
                        <div className="input">
                            <div className="label">Address</div>
                            <InputText className="w-full" defaultValue={addressRef.current} onChange={(event) => setaddress(event.target.value)} />
                        </div>

                        <div className="input">
                            <div className="label">City</div>
                            <InputText className="w-full" defaultValue={cityRef.current} onChange={(event) => setidcity(event.target.value)} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="input">
                            <div className="label">Country</div>
                            <InputText className="w-full" defaultValue={countryRef.current} onChange={(event) => setcountry(event.target.value)} />
                        </div>


                        <div className="input">
                            <div className="label">Postal Code</div>
                            <InputText className="w-full" defaultValue={postalCodeRef.current} onChange={(event) => setpostalCode(event.target.value)} />
                        </div>
                    </div>

                    <div className="heading">Sanction Details</div>

                    <div className="flex">
                        <div className="input">
                            <div className="label">Listed On</div>
                            <Calendar className="calander w-full unique-drops" value={listedOn} showIcon onChange={(e) => setlistedOn(e.value)} id="p-calender" />
                        </div>

                        <div className="input">
                            <div className="label">Corporate Number<span>*</span></div>
                            <InputText type='number' className="w-full" defaultValue={cnumberRef.current} onChange={(event) => setcnumber(event.target.value)} />
                            {corporateNumberError && <div className="error-message mt-2">{corporateNumberError}</div>}
                        </div>
                        <div className="input">
                            <div className="label">Description</div>
                            <InputText className="w-full" defaultValue={descriptionRef.current} onChange={(event) => setdescription(event.target.value)} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="input">
                            <div className="label">Comments</div>
                            <InputTextarea className="inputTextarea w-full" defaultValue={commentsRef.current} rows={1} cols={30} onChange={(event) => setcomments(event.target.value)} />
                        </div>
                    </div>

                    <div className="footer_sec_btn">
                        <Button style={{ background: '#124A99' }} onClick={resetfun} className="resetBtn " icon="pi pi-refresh" />
                        <Button style={{ background: '#124A99' }} onClick={handleSubmit} disabled={!cnumber || !corporatename || !idno} >Submit</Button>
                    </div>
                </div>
            </div >

        </>
    )
}

export default SansationCorporateUpdate
