import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { BreadCrumb } from 'primereact/breadcrumb';
// import { CirclesWithBar } from 'react-loader-spinner';
import SansactionUpdates from "./SansactionUpdates";
import SansationCorporateUpdate from "./SansationCorporateUpdate";
import './Sansationupdate.css';
// import { MdOutlineArrowDropDown } from 'react-icons/md';
// import { Checkbox } from "primereact/checkbox";
import IndividualForm from "./IndividualFile";
import CorporateForm from "./CorporateFile";
import { Triangle } from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router-dom';
import { RadioButton } from "primereact/radiobutton";

function SansactionMainpage() {

    const [activeSection, setActiveSection] = useState(' ');
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const history = useHistory();
    const [ingredient, ] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setIsChecked1(false);
    };
    const handleCheckboxChange1 = () => {
        setIsChecked1(!isChecked1);
        setIsChecked(false);
    };

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    // const handleNavigate = (url) => {
    //     history.push(url);
    // };
    const items = [
        { label: 'Risk Check' },
        { label: 'Sanction Updates' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = {
        icon: 'pi pi-angle-double-left', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }

    const option = [
        { label: 'Sanction Individual Update', value: 'Individual', },
        { label: 'Sanction Corporate Update', value: 'corporate' },
    ];

    return (
        <>
            <div className="mainSec">
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />

                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>

                        <div className="feild c feild-Sec">
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Sanction Updates</div>
                                    <Dropdown className='dropdown' style={{ width: '100%' }} options={option} onChange={(selectedOption) => handleSectionClick(selectedOption.value)} value={activeSection} />
                                </div>
                            </div>
                        </div>

                        <div className="feild c feild-Sec">
                            <div className="flex" style={{ justifyContent: 'center' }}>
                                <div className="chs-file-txt">
                                    Choose the format to be submitted:
                                </div>
                            </div>
                        </div>

                        <div className="feild c feild-Sec">
                            <div className="flex mobV">
                                <div>{ingredient}</div>
                                <div className="input a1" style={{ display: 'flex', justifyContent: 'end', gap: '5px' }}>
                                    <RadioButton value={isChecked} className="radioBtn" onChange={handleCheckboxChange} checked={isChecked} />
                                    <label style={{ fontSize: windowWidth <= 500 ? '12px' : '16px' }}>File</label>
                                </div>
                                <div className="input a1" style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px' }}>
                                    <RadioButton value={isChecked1} className="radioBtn" onChange={handleCheckboxChange1} checked={isChecked1} />
                                    <label style={{ fontSize: windowWidth <= 500 ? '12px' : '16px' }}>Form</label>
                                </div>
                            </div>
                        </div>

                        <div className='condent_sec height-max-vh' style={{ height: (windowWidth <= 500 ? '60vh' : '') }}>
                            {activeSection === 'Individual' && isChecked && <IndividualForm data={location?.state} />}
                            {activeSection === 'corporate' && isChecked && <CorporateForm data={location?.state} />}
                            {activeSection === 'Individual' && isChecked1 && <SansactionUpdates data={location?.state} />}
                            {activeSection === 'corporate' && isChecked1 && <SansationCorporateUpdate data={location?.state} />}
                        </div>

                    </>
                )}
            </div>
        </>
    )
}

export default SansactionMainpage
