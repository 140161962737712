import React, { useRef, useEffect, useState } from 'react';
import Header from "../Landing Page/Header/Header";
import Banner from "../Landing Page/Banner/Banner";
import Thirdsection from "../Landing Page/Thirdsection/Third";
import "../App.css";
import Footer from "../Landing Page/Footer/Footer";
import { Button } from 'primereact/button';
import ChooseUs from '../Landing Page/ChooseUs/ChooseUs';
import Contentful from '../Landing Page/Blog/Contentful';
import About from '../Landing Page/About/About';
import { useLocation } from 'react-router-dom';
import { initGA, logPageView } from '../Analytics'

function LandingPage() {

    const location = useLocation();
    initGA('G-L60HLR8K9R');
    logPageView();

    React.useEffect(() => {
        logPageView();
    }, [location]);

    const contactRef = useRef(null);
    const SubscriptionRef = useRef(null);
    const solutionsRef = useRef(null);
    const bannerRef = useRef(null);

    const scrollToContact = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToSubscription = () => {
        if (SubscriptionRef.current) {
            SubscriptionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollTobanner = () => {
        if (bannerRef.current) {
            bannerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollTosolutions = () => {
        if (solutionsRef.current) {
            solutionsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        sessionStorage.clear();
        const handleScroll = () => {
            const bottom = document.documentElement.scrollHeight - document.documentElement.scrollTop === document.documentElement.clientHeight;
            if (bottom || document.documentElement.clientHeight > 50) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <div>
                <Header onSubscriptionButtonClick={scrollToSubscription} onsolutionsButtonClick={scrollTosolutions} onbannerButtonClick={scrollTobanner} onDemoButtonClick={scrollToContact} />
                <div ref={bannerRef}>
                    <Banner onDemoButtonClick={scrollToContact} />
                </div>
                <Thirdsection />
                <div ref={solutionsRef}>
                    <ChooseUs />
                </div>
                <Contentful />
                <About />
                <Footer />
                {isVisible && (
                    <Button onClick={scrollToTop} className='scrollToTopButton' icon="pi pi-angle-double-up" />
                )}
            </div>
        </div>
    );
}

export default LandingPage;
