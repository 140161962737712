import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { Button } from 'primereact/button';
import { useHistory, useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'primereact/toast';


function CorporateAdvanceTable(props) {

    const [individualdata, setIndividualdata] = useState([]);
    // const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [loadings, setLoadings] = useState(false);
    const toast = React.useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    // const storageData = location.state;
    const corporateSearchData = location.state;
    // const [corporateSearchData, setCorporateSearchData] = useState(storageData);
    const accessToken = location?.state?.accessToken;
    const jsonObject = JSON.parse(sessionStorage?.individualnameDetails);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setTimeout(() => {
        }, 500);
    }, []);

    useEffect(() => {
        setLoadings(true);


        const payload = {
            fullName: location?.state?.fullname || jsonObject?.fullname,
            arabicName: location?.state?.arabicname || jsonObject?.arabicname,
            idNumber: location?.state?.idnumber || jsonObject?.idnumber,
            address: location?.state?.address || jsonObject?.address,
            city: location?.state?.city || jsonObject?.city,
            country: location?.state?.country || jsonObject?.country,
            postalCode: location?.state?.postalCode || jsonObject?.postalCode,
            exact: location?.state?.exact || 'true',
            partial: location?.state?.partial || 'true',
            wrinklerDistance: location?.state?.wrinklerDistance || 'false',
        };

        axios.post(process.env.REACT_APP_BACKEND_HOST + '/corporate/sanctions', payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                if (response.data.individuals.length === 0) {
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Warning',
                        detail: 'No data found.',
                    });
                }
                setIndividualdata(response.data.individuals);
                setLoadings(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
                }
                setLoadings(false);
            });
    }, []);

    const actionbody = (rowData) => {
        return (
            <Button
                style={{ color: '#124A99', fontWeight: 'bold', width: 'fit-content' }}
                className="p-button p-button-text p-button-info pi pi-info-circle"
                onClick={() => handleMoreInfo(rowData.id)}
            />
        );
    };

    const handleMoreInfo = (id) => {
        sessionStorage.setItem('pageCount', id);

        history.push({
            pathname: '/admin/adhoc/corporateadvancemoreinfo',
            state: {
                pageCount: id,
                corporateSearchData: corporateSearchData,
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                refreshToken: location?.state?.refreshToken,
            },
        });
    };

    const items = [
        {
            label: 'Risk Check', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                        refreshToken: location?.state?.refreshToken,
                    },
                })
        },
        {
            label: 'Adhoc Search', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                        refreshToken: location?.state?.refreshToken,
                    },
                })
        },
        { label: 'Corporate Search Data' },
    ];
    const home = location?.state?.getUserData?.premiumPlan === "FreeTrial" ? null : {
        icon: 'pi pi-home',
        command: () => history.push({
            pathname: '/admin/dashboard',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                refreshToken: location?.state?.refreshToken,
            },
        })
    };
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    const highlightMatchType = (exactName) => {
        return exactName.map((name, index) => (
            <span key={index} style={{ backgroundColor: name.matchType === 'bg-orange-500' ? '#fef2c2' : 'transparent' }}>
                {name.name}{' '}
            </span>
        ));
    };

    return (
        <>
            <div className="mainSec">
                <Toast ref={toast} />

                {loadings ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="card" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                        {/* <ToastContainer /> */}
                        <DataTable
                            value={individualdata} stripedRows
                            paginator
                            rows={10}
                            scrollable
                            scrollHeight="63vh"
                            rowsPerPageOptions={[15, 30, 45, 60]}
                            style={{ whiteSpace: 'nowrap' }}
                            className='dataTable adh'
                        >
                            <Column field="id" header="Id" />
                            <Column field="exactName.name" bodyClassName="" header="Full Name" body={(rowData) => highlightMatchType(rowData.exactName)}></Column>
                            <Column field="matchPoints" header="Match Point" />
                            <Column field="number" header="Number" />
                            <Column field="action" header="Action" />
                            <Column header="More Info" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} body={actionbody} />
                        </DataTable>
                    </div>
                )}
            </div>
        </>
    )
}

export default CorporateAdvanceTable
