import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { useHistory } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
// import { TbEdit } from "react-icons/tb";
import { Triangle } from 'react-loader-spinner';
// import { BiSolidCloudUpload } from 'react-icons/bi';
import { Card } from 'primereact/card';
import { useLocation } from 'react-router-dom';

function IndividualRiskreason(props) {

  const [accountdetials, setAccountDetials] = useState('');
  const [statusoption, setSelectedOption] = useState('');
  const [comments, setComments] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const toast = React.useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const accessToken = location?.state?.accessToken;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCheckboxChange = (e) => {
    setSelectedItems(e.value);
  };

  useEffect(() => {
    const isDisabled = !statusoption || !comments || selectedItems.length === 0;
    setIsButtonDisabled(isDisabled);
  }, [statusoption, comments, selectedItems]);

  useEffect(() => {
    const client_id = location?.state?.clientId || sessionStorage?.clientId;
    if (client_id) {
      axios
        .get(
          process.env.REACT_APP_BACKEND_HOST + `/accounts/pending?acc_client_id=` + client_id,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + accessToken
            }
          }
        )
        .then(response => {
          if (response && response.data) {
            setAccountDetials(response.data);
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          if (error?.response && error?.response?.data && error?.response?.data?.message) {
            const errorMessage = error?.response?.data?.message;
            toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
          } else if (error?.response && error?.response?.status === 403) {
            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
          } else if (error?.response?.status === 401) {
            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
          }
        });
    }
  }, [accessToken, location?.state?.clientId]);

  const beneficiarytable = accountdetials?.sanctions || [];
  // let beneficiarytables = accountdetials.accounts;

  const actionbody = (rowData) => {
    const { sanctionRelationshipId, account } = rowData;
    sessionStorage.setItem('sanctionRelationshipId2', sanctionRelationshipId);
    sessionStorage.setItem('account2', account);

    const nextpageWithSessionStorage = () => {
      history?.push({
        pathname: '/admin/account/individualaccountprofilingmoreinfo',
        state: {
          name: beneficiarytable,
          sanctionRelationshipId: sanctionRelationshipId,
          account: account,
          clientId: location?.state?.clientId,
          accountprofilingid: location?.state?.accountprofilingid,
          accessToken: accessToken,
          emailId: location?.state?.emailId,
          getUserData: location?.state?.getUserData,
        },
      });
    };

    return (
      <React.Fragment>
        <Button icon="pi pi-info-circle" className="p-button-rounded p-button-secondary p-mr-2 " style={{ backgroundColor: 'transparent', border: 'none' }} onClick={nextpageWithSessionStorage} id='MI' />
      </React.Fragment>
    );
  };

  const [visible, setVisible] = useState(false);

  const hideDialog = () => {
    setVisible(false);
  };

  const statusoptions = [
    { name: 'On-going', value: 'On-going' },
    { name: 'Cleared', value: 'Cleared' },
    { name: 'Blocked', value: 'Blocked' }

  ];

  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

  };

  const profileTemplate = (rowData) => {
    const status = rowData.screeningStatus;
    let color = '';

    if (status === 'Pending') {
      color = '#f7af00';
    } else if (status === 'Blocked') {
      color = '#f42616';
    } else if (status === 'Cleared') {
      color = 'rgb(69 160 75)';
    } else if (status === 'On-going') {
      color = '#3746c6';
    }

    return (
      <div style={{
        color: color,
        fontWeight: '500',
      }}>
        {status}
      </div>
    );
  };

  const updateaccdet = () => {
    // Check if at least one checkbox is selected
    if (selectedItems.length === 0) {
      toast.current.show({ severity: "warn", summary: "No Checkbox Selected", detail: "Please select similarities screened is at leaste one item.", life: 3000 });
      // setShowToast(true);
    } else {
      setVisible(true);
    }
  };

  const update = (event) => {

    event.preventDefault()
    setSubmitted(true);

    if (
      !statusoption
    ) {
      return;
    }
    let updateid = accountdetials.clientId;
    let bodyvalue = {
      status: statusoption,
      comment: comments,
      sanctionIds: selectedItems.reduce((acc, item) => {
        acc[item.sanctionRelationshipId] = true;
        return acc;
      }, {}),
    }

    axios.put(process.env.REACT_APP_BACKEND_HOST + '/accounts/pending/sanction?client_id=' + updateid, bodyvalue, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      }
    })
      .then(response => {
        if (response.status === 200) {
          hideDialog();
          history.push({
            pathname: '/admin/account/profilings',
            state: {
              accountprofilingid: location?.state?.accountprofilingid,
              accountId: location?.state?.accountprofilingid,
              accessToken: accessToken,
              emailId: location?.state?.emailId,
              getUserData: location?.state?.getUserData,

            },
          });
        }
      })
      .catch(error => {
        setLoading(false);
        if (error?.response && error?.response?.data && error?.response?.data?.message) {
          const errorMessage = error?.response?.data.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
        } else if (error?.response && error?.response?.status === 403) {
          window.location.assign(process.env.REACT_APP_AUTH_URL);
        } else if (error?.response?.status === 401) {
          toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
        }
        else if (error?.response?.status === 400) {
          history.push({
            pathname: '/admin/account/profilings',
            state: {
              accountprofilingid: location?.state?.accountprofilingid,
              accountId: location?.state?.accountprofilingid,
              accessToken: accessToken,
              emailId: location?.state?.emailId,
              getUserData: location?.state?.getUserData,
            },
          });
        }
      });
  }

  const items = [
    {
      label: 'Account Search', command: () =>
        history.push({
          pathname: '/admin/account/search',
          state: {
            accessToken: accessToken,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
          },
        })
    },
    {
      label: 'Individual Profiling', command: () =>
        history.push({
          pathname: '/admin/account/profilings',
          state: {
            accountprofilingid: location?.state?.accountprofilingid,
            accountId: location?.state?.accountprofilingid,
            accessToken: accessToken,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
          },
        })
    },
    { label: 'Account Details' },
  ];
  const home = {
    icon: 'pi pi-home', command: () =>
      history.push({
        pathname: '/admin/dashboard',
        state: {
          accessToken: location?.state?.accessToken,
          emailId: location?.state?.emailId,
          getUserData: location?.state?.getUserData,
        },
      })
  }
  const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
  // const handleNavigate = (url) => {
  //   history.push(url);
  // };

  const highlightMatchType = (exactName) => {
    return exactName.map((name, index) => (
      <span key={index} style={{ backgroundColor: name.matchType === 'bg-orange-500' ? '#fef2c2' : 'transparent' }}>
        {name.name}{' '}
      </span>
    ));
  };

  return (
    <>
      <div className="mainSec" >
        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
        <Toast ref={toast} style={{ marginTop: '3%' }} />
        {loading ? (
          <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
            <Triangle
              height="80"
              width="80"
              color="#124A99"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <Card className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
              <div style={{ display: 'flex', height: '50px', justifyContent: 'end', alignItems: 'center' }}>
                {props.roleName === "ProAdmin" &&
                  <Button className='back_BT_N' label='Edit' onClick={updateaccdet} />
                }
              </div>

              <div className='h_ing'>
                Account Details
              </div>

              {accountdetials?.fullName &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Full Name <span>:</span></div>
                  <div className='value' style={{ backgroundColor: accountdetials?.fullName ? '#fef2c2' : 'transparent' }}>{accountdetials?.fullName || 'N/A'}</div>
                </div>
              }

              {accountdetials?.arabicName &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Arabic Name <span>:</span></div>
                  <div className='value'>{accountdetials?.arabicName || 'N/A'}</div>
                </div>
              }

              {accountdetials?.gender &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Gender <span>:</span></div>
                  <div className='value'>{accountdetials?.gender || 'N/A'}</div>
                </div>
              }

              {accountdetials?.idNumber &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Id Number <span>:</span></div>
                  <div className='value'>{accountdetials?.idNumber || 'N/A'}</div>
                </div>
              }

              {accountdetials?.city &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>City <span>:</span></div>
                  <div className='value'>{accountdetials?.city || 'N/A'}</div>
                </div>
              }

              {accountdetials?.country &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Country <span>:</span></div>
                  <div className='value'>{accountdetials?.country || 'N/A'}</div>
                </div>
              }

              {accountdetials?.dob &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Date Of Birth <span>:</span></div>
                  <div className='value'>{accountdetials?.dob || 'N/A'}</div>
                </div>
              }

              {accountdetials?.nationality &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Nationality <span>:</span></div>
                  <div className='value'>{accountdetials?.nationality || 'N/A'}</div>
                </div>
              }

              {accountdetials?.postalCode &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Postal Code <span>:</span></div>
                  <div className='value'>{accountdetials?.postalCode || 'N/A'}</div>
                </div>
              }

              {accountdetials?.type &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Id Card Type <span>:</span></div>
                  <div className='value'>{accountdetials?.type || 'N/A'}</div>
                </div>
              }

              {accountdetials?.address &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: (windowWidth <= 400 ? '110px' : '130px'), display: 'flex', justifyContent: 'space-between' }}>Address<span>:</span></div>
                  <div className='value'>{accountdetials?.address || 'N/A'}</div>
                </div>
              }
              <br />

              <div className='h_ing'>
                Similarities Screened
              </div>

              <div style={{ padding: '20px 0' }}>
                {beneficiarytable.length >= 1 ? (
                  <DataTable
                    value={beneficiarytable}
                    stripedRows
                    showGridlines
                    paginator
                    selectionMode="multiple" selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {props.roleName === "ProAdmin" && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}
                    <Column field="sanctionIndividual.fullName" bodyClassName="" header="Full Name" body={(rowData) => highlightMatchType(rowData.sanctionIndividual.exactName)}></Column>
                    <Column field="category" bodyClassName="" header="Category"></Column>
                    <Column field="comments" bodyClassName="" header="Comments"></Column>
                    <Column field="screeningStatus" bodyClassName="" header="Status" body={profileTemplate} ></Column>
                    <Column field="moreinfo" bodyClassName="" header="More Info" body={actionbody}></Column>
                  </DataTable>
                ) : (
                  <DataTable
                    value={accountdetials?.accounts}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 20]}
                    responsive
                    selectionMode="multiple"
                    selection={selectedItems}
                    onSelectionChange={handleCheckboxChange}
                    className="datatable-responsive"
                    loading={loading}
                  >
                    {props.roleName === "ProAdmin" && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}
                    <Column field="id" header="Id" sortable></Column>
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="date" header="Date" sortable></Column>
                    <Column field="amount" header="Amount" sortable></Column>
                    <Column field="status" header="Status" body={profileTemplate}></Column>
                    <Column body={actionbody} headerStyle={{ width: '5rem' }}></Column>
                  </DataTable>
                )}
              </div>

            </Card>

            <Dialog visible={visible} header="Update Account Pending" modal style={{ width: '40vw' }} className="sing_up_main_Dialog" onHide={hideDialog}>
              <form onSubmit={update}>
                <div className=" overflow-hidden" id="advanced-ahoc-rect" style={{ overflow: 'auto' }}>
                  <div className="feild c pt-3 pb-3">
                    <div className="flex" style={{ flexDirection: 'column' }}>
                      <div className="input">
                        <div className="label">Status<span>*</span></div>
                        <Dropdown onChange={handleStatusChange} options={statusoptions} value={statusoption} className={classNames({ 'p-invalid': submitted && !statusoptions }, 'w-full p-0 unique-drops')} optionLabel="name" />
                        {submitted && !statusoption && <small className="p-error">Status is required.</small>}
                      </div>
                      <div className="input">
                        <div className="label">Comments<span>*</span></div>
                        <InputTextarea rows={1} cols={2} className="w-full pl-0 unique-drops" onChange={(event) => setComments(event.target.value)} />
                      </div>
                    </div>
                    {/* <div className="flex">
                      <div className="input">
                        <div className="label" style={{justifyContent:'center'}}>
                          <div className="text-center text-7xl" id="file-upload-icon" style={{ cursor: 'pointer' }}><BiSolidCloudUpload /></div>
                          <em className="text-xs">{file ? file.name : ''}</em>
                        </div>
                        <input type="file" id="fileInput" onChange={handleFileChange} />
                      </div>
                    </div> */}
                    <div className="footer_sec_btn_dialuge">
                      <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" disabled={isButtonDisabled} type='submit'>Update</Button>
                    </div>
                  </div>
                </div>
              </form>
            </Dialog>
          </>
        )}
        {showToast && (
          <Toast ref={toast} position="top-right" severity="warn" summary="No Checkbox Selected" detail="Please select at least one item." life={3000} onClose={() => setShowToast(false)} />
        )}
      </div >
    </>
  )
}

export default IndividualRiskreason;