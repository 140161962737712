import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Blog from "./Blog";

export default function Contentful(id) {

  const history = useHistory()
  const [posts, setPosts] = useState([]);

  const client = require("contentful").createClient({
    space: "kja4jw28hgk4",
    accessToken: "cwBtJjoC7EoI8nocV4hQNKazXIm0lEyAZdEpr7c567g"
  });

  useEffect(() => {
    const getBlogPosts = async () => {
      return client.getEntries().then(response => {
        return response?.items ? response.items : [];
      });
    };

    const promise = getBlogPosts();

    if (promise) {
      promise.then(blogPosts => {
        setPosts(blogPosts);
        history.push({ state: { blogData: blogPosts, }, })
      });

    }
  }, []);

  return (
    <>

      <Blog data={posts} />

    </>
  )

}