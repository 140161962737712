import React, { Component, createRef } from "react";
import { Dropdown } from 'primereact/dropdown';
import "../../../views/Loading.css";
import "./Transaction.css";
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import AccountDropdown from '../MonitoringThresholds/AccountId';
import jsPDF from "jspdf";
import { Menu } from 'primereact/menu';
import 'jspdf-autotable';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import { AiOutlineSwap } from 'react-icons/ai';
import { SelectButton } from 'primereact/selectbutton';

class TransactionSearchListTable extends Component {
    constructor(props) {
        super(props);
        this.menu = null;
        this.plan = props?.location?.state?.getUserData?.premiumPlan;

        this.itemsFB = [
            {
                label: 'PDF',
                icon: () => <FaFilePdf />,
                command: () => {
                    this.setVisible(false);
                    this.handleExportToPDF();
                }
            }
        ];

        this.items = [
            {
                label: 'XLS',
                icon: () => <FaFileExcel />,
                command: () => {
                    this.setVisible(false);
                    this.exportToXLS();
                }
            },
            {
                label: 'XLSX',
                icon: () => <FaFileExcel />,
                command: () => {
                    this.setVisible(false);
                    this.exportToExcel();
                }
            },
            {
                label: 'CSV',
                icon: () => <FaFileCsv />,
                command: () => {
                    this.setVisible(false);
                    this.handleExportToCSV();
                }
            },
            {
                label: 'PDF',
                icon: () => <FaFilePdf />,
                command: () => {
                    this.setVisible(false);
                    this.handleExportToPDF();
                }
            }
        ];
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let prevMonth = month === 0 ? 11 : month - 1;
        let prevYear = prevMonth === 11 ? year - 1 : year;
        let nextMonth = month === 11 ? 0 : month + 1;
        let nextYear = nextMonth === 0 ? year + 1 : year;
        this.state = {
            transactionStatus: [],
            currency: '',
            status: '',
            accountId: '',
            accountId1: '',
            transactionType: "",
            isLoading: false,
            dates2: null,
            minAmount: '',
            maxAmount: '',
            currencies: [],
            visible: false,
            productDialog1: false,
            productDialog2: false,
            correspondentId: '',
            amount: null,
            currencyCode: '',
            modeOfPayment: '',
            purpose: '',
            type: '',
            description1: '',
            description2: '',
            // selectedAccountId: null,
            isSubmitDisabled: true,
            selectedAccount: null,
            selectedAccountId: '',
            selectedBeneficiary: null,
            beneficiaries: null,
            transactionTypeErrorMessage: '',
            accountIdErrorMessage: '',
            beneficiaryIdErrorMessage: '',
            correspondentIdErrorMessage: '',
            amountErrorMessage: '',
            currencyCodeErrorMessage: '',
            modeOfPaymentErrorMessage: '',
            purposeErrorMessage: '',
            typeErrorMessage: '',
            description1ErrorMessage: '',
            value: 'Credit',
            dialogVisible: false,
        };
        this.minDate = new Date();
        this.minDate.setMonth(prevMonth);
        this.minDate.setFullYear(prevYear);
        this.maxDate = new Date();
        this.maxDate.setMonth(nextMonth);
        this.maxDate.setFullYear(nextYear);
        this.invalidDates = [today];
        this.dateTemplate = this.dateTemplate.bind(this);
        this.baseState = this.state;
        this.handlecurrencyChange = this.handlecurrencyChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleAccountIdChange = this.handleAccountIdChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleaccountIdChange = this.handleACcountIdChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.minAmountRef = createRef();
        this.maxAmountRef = createRef();
        this.purposeRef = React.createRef();
        this.description1Ref = React.createRef();
        this.accountidRef = React.createRef();
        this.amountRef = React.createRef();
        this.correspondentIdRef = React.createRef();
        this.dialogStyle = {
            width: '30rem',
            overflow: 'hidden',
            textAlign: 'center',
        };
    }

    dateTemplate(date) {
        if (date.day > 10 && date.day < 15) {
            return (
                <strong style={{ textDecoration: "line-through" }}>{date.day}</strong>
            );
        }
        return date.day;
    }
    exportToXLS() {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(this.state.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Transaction.xls");
    }
    exportToExcel = (format) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(this.state.data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Transaction.xlsx");
    };
    handleExportToPDF = () => {
        const doc = new jsPDF();
        doc.autoTable(this.exportColumns, this.state.data);
        doc.save("Transaction.pdf");
    };
    handleExportToCSV = () => {
        const csvContent = this.convertToCSV(this.state.data);
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.setAttribute("href", URL.createObjectURL(blob));
        link.setAttribute("download", "Transaction.csv");
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(","));
            for (const row of data) {
                const values = Object.values(row).join(",");
                csvRows.push(values);
            }
        }
        return csvRows.join("\n");
    };
    toggleMenu = (event) => {
        event.preventDefault();
        this.setVisible(!this.state.visible);
        this.menu.toggle(event);
    };
    setVisible = (visible) => {
        this.setState({ visible });
    };

    handleTransactionChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            this.setState({
                transactionType: selectedOption.value,
                transactionTypeErrorMessage: '',
            });
        } else {
            this.setState({
                transactionTypeErrorMessage: 'Transaction type is required.',
            });
        }
    };

    handleBeneficiaryChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            this.setState({
                selectedBeneficiary: selectedOption.value,
                beneficiaryIdErrorMessage: '',
            });
        } else {
            this.setState({
                beneficiaryIdErrorMessage: 'Beneficiary Id is required.',
            });
        }
    };

    handleAmountChange = (e) => {
        const amountValue = e.value;
        if (amountValue !== null) {
            this.setState({
                amount: amountValue,
                amountErrorMessage: '',
            });
        } else {
            this.setState({
                amount: null,
                amountErrorMessage: 'Amount is required.',
            });
        }
    };

    handleCurrencyCodeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            this.setState({
                currencyCode: selectedOption.value,
                currencyCodeErrorMessage: '',
            });
        } else {
            this.setState({
                currencyCodeErrorMessage: 'Currency is required.',
            });
        }
    };

    handleAccountChange(selectedAccount) {
        const accountId = selectedAccount ? selectedAccount.accountId : '';
        this.setState({
            selectedAccountId: accountId,
        });
    }

    handleAccountIdChanges = (e) => {
        const inputValue = e.target.value;
        const onlyValidDigits = /^\d*$/;
        const isEmpty = inputValue.trim() === '';
        this.setState({
            accountId1: inputValue,
            selectedBeneficiary: null,
            beneficiaries: [],
            accountIdErrorMessage: isEmpty ? 'Account Id is required.' : onlyValidDigits ? null : 'Invalid Account Id format.',
        });
        if (inputValue && onlyValidDigits.test(inputValue)) {
            this.fetchBeneficiariesByAccountId(inputValue);
        }
    };

    handleTypeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            this.setState({
                type: selectedOption.value,
                typeErrorMessage: '',
            });
        } else {
            this.setState({
                typeErrorMessage: 'Type is required.',
            });
        }
    };


    handleCorrespondentIdChange = (e) => {
        const value = e.target.value;
        const onlyValidDigits = /^\d*$/;
        const isEmpty = value.trim() === '';
        const containsNonDigit = /\D/.test(value);
        if (containsNonDigit) {
            return;
        }
        this.setState({
            correspondentId: value,
            correspondentIdErrorMessage: isEmpty ? 'Correspondent Id is required.' : onlyValidDigits ? null : 'Invalid Correspondent Id format.',
        });
    };

    handleModeOfPaymentChange = (e) => {
        const selectedModeOfPayment = e.value;
        const isEmpty = !selectedModeOfPayment;

        this.setState({
            modeOfPayment: selectedModeOfPayment,
            modeOfPaymentErrorMessage: isEmpty ? 'Mode of Payment is required.' : null,
        });
    };

    handlePurposeChange = (e) => {
        const inputValue = e.target.value;
        const onlyLetters = /^[A-Za-z\s]*$/;
        const containsNumbers = /\d/.test(inputValue);
        const isEmpty = inputValue.trim() === '';
        if (containsNumbers) {
            return;
        }
        this.setState({
            purpose: inputValue,
            purposeErrorMessage: isEmpty ? 'Purpose is required.' : onlyLetters ? null : 'Invalid characters in purpose.',
        });

    };

    handleAccountIdChange = (selectedAccountId) => {
        this.setState({
            accountId: selectedAccountId,
        });
    };

    handleDescriptionChange = (e) => {
        const value = e.target.value;
        const onlyLetters = /^[A-Za-z\s]*$/;
        const containsNumbers = /\d/.test(value);
        if (containsNumbers) {
            return;
        }
        this.setState({
            description1: value,
            description1ErrorMessage: !onlyLetters.test(value) ? 'Description only allows letters.' : null,
        });
    };

    handleKeyDown(e) {
        const validKeyCodes = [8, 46];
        if (validKeyCodes.indexOf(e.keyCode) === -1 && !/^\d$/.test(e.key)) {
            e.preventDefault();
        }
    }
    openNew = (event) => {
        event.preventDefault();
        this.setState({
            productDialog1: true,
            transactionTypeErrorMessage: '',
            accountIdErrorMessage: '',
            beneficiaryIdErrorMessage: '',
            correspondentIdErrorMessage: '',
            amountErrorMessage: '',
            currencyCodeErrorMessage: '',
            modeOfPaymentErrorMessage: '',
            purposeErrorMessage: '',
            typeErrorMessage: '',
            description1ErrorMessage: '',
            transactionType: '',
            accountId1: '',
            selectedBeneficiary: null,
            correspondentId: '',
            amount: null,
            currencyCode: '',
            modeOfPayment: '',
            purpose: '',
            type: '',
            description1: '',
        });
    };
    hideDialog = () => {
        this.setState({
            productDialog1: false,
            transactionTypeErrorMessage: '',
            accountIdErrorMessage: '',
            beneficiaryIdErrorMessage: '',
            correspondentIdErrorMessage: '',
            amountErrorMessage: '',
            currencyCodeErrorMessage: '',
            modeOfPaymentErrorMessage: '',
            purposeErrorMessage: '',
            typeErrorMessage: '',
            description1ErrorMessage: '',
            transactionType: '',
            accountId1: '',
            selectedBeneficiary: null,
            correspondentId: '',
            amount: null,
            currencyCode: '',
            modeOfPayment: '',
            purpose: '',
            type: '',
            description1: '',
        });
    };
    hideDialog2 = () => {
        this.setState({ productDialog2: false });
    };

    handleNavigate = (url) => {
        this.props.history.push(url);
    };
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };
    async componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        try {
            const products = await this.getProducts();
            this.setState({ data: products });
            const response = await axios.get('https://openexchangerates.org/api/currencies.json');
            const currencyData = response.data;
            const currencyArray = Object.entries(currencyData).map(([code]) => ({ code }));
            this.setState({ currencies: currencyArray });
        } catch (error) {
            if (error?.response) {
                if (error?.response?.status === 401) {
                    toast.error({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 406) {
                    toast.error({ severity: 'warn', summary: 'Transaction Not Allowed', detail: 'Transactions are not allowed for this country.', life: 8000 });
                }
            }
        }
    }
    fetchBeneficiariesByAccountId(accountId) {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken,
            }
        };
        axios
            .get(`${process.env.REACT_APP_BACKEND_HOST}/byAccountId/${accountId}?type=${this.state.transactionType}`, requestOptions)
            .then(response => {
                const beneficiaries = response.data;
                this.setState({ beneficiaries });
            })
            .catch(error => {
                if (error?.response) {
                    if (error?.response?.status === 401) {
                        toast.error({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', autoClose: 3000 });
                    } else if (error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    } else if (error?.response?.status === 406) {
                        toast.error({ severity: 'warn', summary: 'Transaction Not Allowed', detail: 'Transactions are not allowed for this country.', autoClose: 8000 });
                    }
                }
            });
    }
    displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };
    async getProducts() {
        try {
            this.setState({ isLoading: true });

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
                }
            };

            const uri = '/transaction';

            const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions);
            const productsData = response.data;
            this.setState({ isLoading: false });
            return productsData;
        } catch (error) {
            this.setState({ isLoading: false });
            if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
            }
            else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                this.displayErrorToast(errorMessage);
            }
            this.setState({ isLoading: false });
            return [];
        }
    }
    resetForm = async () => {
        sessionStorage.removeItem("account_search");
        this.setState({
            dates2: null,
            currency: null,
            status: null,
            accountId: null,
            minAmount: "",
            maxAmount: "",
        });
        try {
            this.setState({ isLoading: true });
            const products = await this.getProducts();
            this.setState({ data: products });
        } catch (error) {
            if (error?.response && error?.response?.status === 403) {
                window.location.assign(process.env.REACT_APP_AUTH_URL);
            }
            else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                this.displayErrorToast(errorMessage);
            }
            this.setState({ isLoading: false });
            return [];
        }
    }
    handlecurrencyChange(event) {
        this.setState({
            currency: event.target.value
        });
    }
    handleStatusChange(event) {
        this.setState({
            status: event.target.value
        });
    }
    handleACcountIdChange(event) {
        this.setState({
            accountId: event.target.value
        });
    }

    isFormValid = () => {
        const {
            transactionType,
            selectedBeneficiary,
            correspondentId,
            amount,
            currencyCode,
            modeOfPayment,
            purpose,
            type
        } = this.state;

        return (
            transactionType &&
            selectedBeneficiary &&
            correspondentId &&
            amount &&
            currencyCode &&
            modeOfPayment &&
            purpose &&
            type
        );
    };
    handleSave = async () => {
        const {
            accountId1,
            selectedBeneficiary,
            correspondentId,
            amount,
            currencyCode,
            modeOfPayment,
            purpose,
            type,
            transactionType,
            description1,
            value,
            beneficiaryId
        } = this.state;

        // if (!transactionType || !accountId1 || !selectedBeneficiary || !correspondentId || !amount || !currencyCode || !modeOfPayment || !type) {
        //     this.setState({ submitted: true });
        //     return;
        // }
        this.setState({ isLoading: true });
        const requestData = value === 'Credit' ? {
            accountId: accountId1,
            amount,
            modeOfPayment,
            currencyCode,
            type: value,
            transactionType,
        } : {
            accountId: accountId1,
            beneficiaryId: selectedBeneficiary ? selectedBeneficiary.beneficiaryId : null,
            correspondentId,
            amount,
            currencyCode,
            modeOfPayment,
            description1,
            // description2,
            purpose,
            type: value,
            transactionType,
            beneficiaryId: this.state.selectedBeneficiary
        };

        try {

            const response = await axios.post(
                process.env.REACT_APP_BACKEND_HOST + '/transactions',
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
                    },
                }
            );
            if (response.status === 200) {
                const newTransactionStatus = {
                    accountId: response?.data?.accountId,
                    accountName: response?.data?.accountName,
                    type: response?.data?.type,
                    status: response?.data?.status,
                    amount: response?.data?.amount,
                };

                toast.success('Saved Successfully.', {
                    autoClose: 5000,
                });
                this.setState({ transactionStatus: newTransactionStatus, productDialog2: true, productDialog1: false });
            }
            this.setState({ isLoading: false });
        } catch (error) {
            this.setState({ productDialog1: false, isLoading: false });
            if (error?.response && error?.response?.status === 403) {
                window.location.assign(process.env.REACT_APP_AUTH_URL);
            } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                if (errorMessage.includes('Weekly transaction count limit exceeded')) {
                    toast.error('Weekly transaction limit exceeded. Please try again next week.', {
                        autoClose: 3000,
                    });
                } else {
                    toast.error(errorMessage, {
                        autoClose: 3000,
                    });
                }
            } else {
                toast.error('An error occurred. Please try again later.', {
                    autoClose: 3000,
                });
            }
        }
    };

    handleChange = (key, value) => {
        this.setState({ [key]: value }, () => {
            const isSubmitDisabled = !this.state?.value || !this.state?.transactionType || !this.state?.amount || !this.state?.modeOfPayment
            this.setState({ isSubmitDisabled });
        });
    };

    handleChangeoptions = (e) => {
        const selectedValue = e.value;
        if (selectedValue === 'Credit' || selectedValue === 'Debit') {
            this.setState({ value: selectedValue });
        }
    };


    render() {
        const { currencies, purpose } = this.state;
        const { isSubmitDisabled } = this.state;
        const isInvalidAccountId = !/^\d*$/.test(this.state.accountId1);
        const isInvalidPurpose = purpose && !/^[A-Za-z\s]*$/.test(purpose);
        const cols = [
            { field: 'transactionId', header: 'Transaction Id' },
            { field: 'accountId', header: 'Account Id' },
            { field: 'amount', header: 'Amount' },
            { field: 'currency.code', header: 'Currency' },
            { field: 'transactionType', header: 'Transaction Type' },
            { field: 'status', header: 'Status' },
            { field: 'reason', header: 'Reason' },
        ];
        this.exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
        const status = [
            { value: "Pending" },
            { value: "Cleared" },
            { value: "Blocked" },
            { value: "On-going" }
        ]
        const modeOfPayment = [
            'Cash', 'Checks', 'Mobile payments', 'Electronic bank transfers'];
        const transactionTypeValue = ["Individual", "Corporate"];
        const productDialogFooter = (
            <React.Fragment>
                <div className="footer_sec_btn">
                    <Button onClick={this.handleSave} style={{ width: '80px' }}
                        // disabled={!this.state.type || !this.state.transactionType || !this.state.accountId1 || !this.state.selectedBeneficiary || !this.state.correspondentId || !this.state.amount || !this.state.currencyCode}
                        disabled={isSubmitDisabled}
                    >Submit</Button>
                </div>
            </React.Fragment>
        );
        if (this.state.isLoading) {
            return (
                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            );
        }
        const TypeTemplate = (rowData) => {
            const status = rowData.status;
            let color = '';
            if (status === 'Pending') {
                color = '#f7af00';
            } else if (status === 'Blocked') {
                color = '#b7b7b7';
            } else if (status === 'Cleared') {
                color = 'rgb(69 160 75)';
            } else if (status === 'On-going') {
                color = '#3746c6';
            }
            return (
                <div
                    style={{
                        color: color,
                        fontWeight: '500'
                    }}
                >
                    {status}
                </div>
            );
        };
        const items = [{ label: 'Transaction' }];
        const home = {
            icon: 'pi pi-home', command: () =>
                this.props.history.push({
                    pathname: '/admin/dashboard',
                    state: {
                        accessToken: this.props?.location?.state?.accessToken,
                        emailId: this.props?.location?.state?.emailId,
                        getUserData: this.props?.location?.state?.getUserData,
                    },
                })
        }

        // const storedAccountString = this.props?.location?.state?.selectedAccount || sessionStorage.getItem('selectedAccount');
        // const storedAccount = storedAccountString ? JSON.parse(storedAccountString) : null;

        // const home1 = { icon: 'pi pi-angle-double-left', command: () => this.handleNavigate('/admin/dashboard') }
        return (
            <>
                <div className="mainSec tran" >
                    <BreadCrumb model={items} home={home} style={{ border: 'none', overflowX: 'visible' }} />
                    <div className="feild c">
                        <div className="flex">
                            <div className="input">
                                <div className="label">From - To (Date)</div>
                                <Calendar
                                    value={this.state.dates2}
                                    onChange={(e) => this.setState({ dates2: e.value })}
                                    selectionMode="range"
                                    readOnlyInput
                                    className="calendar"
                                    showIcon
                                    // hideOnRangeSelection
                                    id="p-calender"
                                />
                            </div>
                            <div className="input">
                                <div className="label">Currency</div>
                                <Dropdown
                                    value={this.state.currency}
                                    onChange={this.handlecurrencyChange}
                                    options={currencies}
                                    optionLabel="code"
                                    filter filterBy="code"
                                    style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                    className="calendar"
                                />
                            </div>
                            <div className="input">
                                <div className="label">Status</div>
                                <Dropdown
                                    value={this.state.status}
                                    onChange={this.handleStatusChange}
                                    options={status}
                                    optionLabel="value"
                                    style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                    className="calendar"
                                />
                            </div>
                        </div>
                        <div className="flex mob-view-hides">
                            <div className="input">
                                <div className="label">Account Id</div>
                                <AccountDropdown onChange={this.handleAccountChange}
                                    data={this.props?.location?.state}
                                    selectedAccountId={this.state.selectedAccountId} className="calendar" />
                            </div>
                            <div className="input">
                                <div className="label">From ( Amount )</div>
                                <InputText
                                    type="number"
                                    defaultValue={this.minAmountRef.current}
                                    ref={this.minAmountRef}
                                    onChange={(e) => this.setState({ minAmount: e.target.value })}
                                    className="calendar"
                                />
                            </div>
                            <div className="flex justify-content-center align-items-center pt-3" style={{ width: '20px' }}>
                                <AiOutlineSwap />
                            </div>
                            <div className="input">
                                <div className="label">To ( Amount )</div>
                                <InputText
                                    type="number"
                                    defaultValue={this.maxAmountRef.current}
                                    ref={this.maxAmountRef}
                                    onChange={(e) => this.setState({ maxAmount: e.target.value })}
                                    className="calendar"
                                />
                            </div>
                        </div>
                        {window.innerWidth <= 575 && (
                            <>
                                <div className="unique-mob-amount flex">
                                    <div className="input">
                                        <div className="label">Account Id</div>
                                        <AccountDropdown onChange={this.handleAccountChange}
                                            data={this.props?.location?.state}
                                            selectedAccountId={this.state.selectedAccountId} className="calendar" />
                                    </div>
                                    <div className="input">
                                        <div className="label">From ( Amount )</div>
                                        <InputText
                                            type="number"
                                            defaultValue={this.minAmountRef.current}
                                            ref={this.minAmountRef}
                                            onChange={(e) => this.setState({ minAmount: e.target.value })}
                                            className="calendar"
                                        />
                                    </div>
                                    <span className="flex justify-content-center align-items-center unique-swap">
                                        <AiOutlineSwap />
                                    </span>
                                    <div className="input">
                                        <div className="label">To ( Amount )</div>
                                        <InputText
                                            type="number"
                                            defaultValue={this.maxAmountRef.current}
                                            ref={this.maxAmountRef}
                                            onChange={(e) => this.setState({ maxAmount: e.target.value })}
                                            className="calendar"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className='mob-view-hides mb-3'>
                            <div className='flex align-items-center'>
                                <small className="flex" style={{ color: 'rgba(200, 200, 200, 1)', fontWeight: '600' }}>Note: Fill in one or more fields for preferred results.</small>
                                <div className='flex align-items-center justify-content-end'>
                                    <Button
                                        icon='pi pi-refresh'
                                        className='reset-btn'
                                        style={{ background: '#124A99', border: '1px solid #124A99' }}
                                        onClick={this.resetForm}
                                    />
                                    <Button
                                        label='Search'
                                        style={{ background: '#124A99', border: '1px solid #124A99' }}
                                        onClick={this.handleSubmit}
                                        disabled={!this.state.minAmount && !this.state.maxAmount && !this.state.dates2 && !this.state.currency && !this.state.status && !this.state.selectedAccountId}
                                    />
                                </div>
                            </div>
                        </div>
                        {window.innerWidth <= 575 && (
                            <>
                                <div>
                                    <div className='unique-pi-iconss mb-3'>
                                        <small className="flex py-3" style={{ color: 'rgba(200, 200, 200, 1)', fontWeight: '600' }}>Note: Fill in one or more fields for preferred results.</small>
                                        <div className=' grid justify-content-end gap-3 m-0 pr-2'>
                                            <Button
                                                label='Search'
                                                style={{ background: '#124A99', border: '1px solid #124A99' }}
                                                onClick={this.handleSubmit}
                                                disabled={!this.state.minAmount && !this.state.maxAmount && !this.state.dates2 && !this.state.currency && !this.state.status && !this.state.selectedAccountId}
                                            />
                                            <Button
                                                className='reset-btn'
                                                icon='pi pi-refresh'
                                                iconPos='center'
                                                style={{ background: '#124A99', border: '1px solid #124A99', fontWeight: 'normal' }}
                                                onClick={this.resetForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <ToastContainer position="top-right" />
                    <div className="unique-con">
                        <div className=" bg-gray-50 unique-pi-icons" style={{ border: 'rgba(209, 209, 209, 1) 1px solid' }}>
                            <div className="flex gap-3 m-2">
                                <Button icon="pi pi-upload" disabled={this.state.data} style={{ background: '#124A99', gap: '7px', border: '1px solid #124A99' }} onClick={this.toggleMenu}>Export</Button>
                                <Menu className='unique-menu-exp'
                                    model={this.plan === 'FreeTrial' || this.plan === 'BasicAdmin' ? this.itemsFB : this.items}
                                    popup ref={(el) => (this.menu = el)} onHide={() => this.setVisible(false)} appendTo={document.body} />
                                <Button icon="pi pi-plus" style={{ background: '#124A99', border: '1px solid #124A99' }} className="reset-btn" onClick={this.openNew} />
                            </div>
                        </div>
                        <div>
                            <DataTable dataKey="id" value={this.state.data} paginator rows={5} rowsPerPageOptions={[5, 10, 20, 30]}
                                scrollable
                                stripedRows
                                resizableColumns className="unique-datatable-mobs"
                            >
                                <Column field="transactionId" header="Transaction Id" sortable />
                                <Column field="accountName" header="Account Name" sortable />
                                <Column field="accountId" header="Account Id" sortable />
                                <Column field="beneficiaryName" header="Beneficiary Name" sortable />
                                <Column field="amount" header="Amount" sortable />
                                <Column field="type" header="Type" sortable />
                                <Column field="status" header="Status" body={TypeTemplate} sortable />
                                <Column field="currencyCode.code" header="Currency" sortable />
                                <Column field="transactionType" header="Transaction Type" sortable />
                                <Column field="createdDate" header="Date" sortable />
                                <Column field="purpose" header="Purpose" sortable />
                            </DataTable>
                        </div>
                    </div>
                    <Dialog header="Add Transaction Details" modal draggable={false} className="sing_up_main_Dialog" visible={this.state.productDialog1} footer={productDialogFooter} onHide={this.hideDialog}>
                        <div className="feild c unique-mobile-fie">
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
                                <SelectButton
                                    value={this.state.value}
                                    onChange={this.handleChangeoptions}
                                    options={['Credit', 'Debit']}
                                    className="selectButton"
                                />
                            </div>

                            <div className="flex">

                                <div className="input">
                                    <div className="label">Transaction Type <span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                    <Dropdown
                                        options={transactionTypeValue}
                                        value={this.state.transactionType}
                                        onChange={(e) => this.handleChange('transactionType', e.value)}
                                        style={{ width: '100%', border: '1px solid #ced4da', padding: 'unset' }}
                                        className={`calendar ${this.state.transactionTypeErrorMessage ? 'invalid-input' : ''}`}
                                    />
                                    {this.state.transactionTypeErrorMessage && <small className="p-error">{this.state.transactionTypeErrorMessage}</small>}
                                </div>

                            </div>
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Account Id <span>*</span></div>
                                    <InputText
                                        // value={this.state.accountId1}
                                        // defaultValue={this.accountidRef.current}
                                        ref={this.accountidRef}
                                        onChange={this.handleAccountIdChanges}
                                        onKeyDown={this.handleKeyDown}
                                        className={`${isInvalidAccountId || this.state.accountIdErrorMessage ? 'invalid-input' : ''}`}
                                    />
                                    {this.state.accountIdErrorMessage && <small className="p-error">{this.state.accountIdErrorMessage}</small>}
                                </div>

                                {this.state.value === 'Debit' &&
                                    <div className="input">
                                        <div className="label">Beneficiary Id <span>*</span></div>
                                        <Dropdown
                                            value={this.state.selectedBeneficiary}
                                            options={this.state.beneficiaries}
                                            optionLabel="beneficiaryId"
                                            optionValue="beneficiaryId"
                                            onChange={(e) => this.handleChange('selectedBeneficiary', e.value)}
                                            disabled={!this.accountidRef.current}
                                            className={`calendar ${this.state.beneficiaryIdErrorMessage ? 'p-invalid' : ''}`}
                                            style={{ width: '100%', border: '1px solid #ced4da', padding: 'unset' }}
                                            filter
                                        />
                                        {this.state.beneficiaryIdErrorMessage && <small className="p-error">{this.state.beneficiaryIdErrorMessage}</small>}
                                    </div>
                                }
                            </div>
                            <div className="flex">

                                {this.state.value === 'Debit' &&
                                    <div className="input">
                                        <div className="label">Correspondent Id <span>*</span></div>
                                        <InputNumber
                                            // defaultValue={this.correspondentIdRef.current}
                                            ref={this.correspondentIdRef}
                                            onChange={(e) => this.handleChange('correspondentId', e.value)}
                                            style={{ width: '100%', fontWeight: '500', fontSize: '14px' }}
                                            className={`${this.state.correspondentIdErrorMessage ? 'p-invalid' : ''}`}
                                        />
                                        {this.state.correspondentIdErrorMessage && <small className="p-error">{this.state.correspondentIdErrorMessage}</small>}
                                    </div>
                                }
                                <div className="input">
                                    <div className="label">Amount <span>*</span></div>
                                    <InputNumber
                                        // defaultValue={this.amountRef.current}
                                        ref={this.amountRef}
                                        style={{ width: '100%', fontWeight: '500', fontSize: '14px' }}
                                        onChange={(e) => this.handleChange('amount', e.value)}
                                        className={`${this.state.amountErrorMessage ? 'p-invalid' : ''}`}
                                    />
                                    {this.state.amountErrorMessage && <small className="p-error">{this.state.amountErrorMessage}</small>}
                                </div>

                            </div>
                            <div className="flex">

                                <div className="input">
                                    <div className="label">Currency <span>*</span></div>
                                    <Dropdown
                                        options={currencies}
                                        optionLabel="code"
                                        filter filterBy="code"
                                        value={this.state.currencyCode}
                                        style={{ width: '100%', border: '1px solid #ced4da', padding: 'unset' }}
                                        onChange={(e) => this.handleChange('currencyCode', e.value)}
                                        className={`calendar ${this.state.currencyCodeErrorMessage ? 'p-invalid' : ''}`}
                                    />
                                    {this.state.currencyCodeErrorMessage && <small className="p-error">{this.state.currencyCodeErrorMessage}</small>}
                                </div>
                                <div className="input">
                                    <div className="label">Mode of Payment <span>*</span></div>
                                    <Dropdown
                                        value={this.state.modeOfPayment}
                                        options={modeOfPayment}
                                        onChange={(e) => this.handleChange('modeOfPayment', e.value)}
                                        style={{ width: '100%', border: '1px solid #ced4da', padding: 'unset' }}
                                        className={`calendar ${this.state.modeOfPaymentErrorMessage ? 'invalid-input' : ''}`}
                                    />
                                    {this.state.modeOfPaymentErrorMessage && <small className="p-error">{this.state.modeOfPaymentErrorMessage}</small>}
                                </div>

                            </div>

                            {this.state.value === 'Debit' &&
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Purpose <span>*</span></div>
                                        <InputText
                                            // value={purpose}
                                            // defaultValue={this.purposeRef.current}
                                            ref={this.purposeRef}
                                            onChange={(e) => this.handleChange('purpose', e.target.value)}

                                        />
                                        {this.state.purposeErrorMessage && <small className="p-error">{this.state.purposeErrorMessage}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Description</div>
                                        <InputText
                                            // value={this.state.description1}
                                            // defaultValue={this.description1Ref.current}
                                            ref={this.description1Ref}
                                            onChange={(e) => this.handleChange('description', e.target.value)}
                                            className={`${this.state.description1ErrorMessage ? 'invalid-input' : ''}`}
                                        />
                                        {this.state.description1ErrorMessage && <small className="p-error">{this.state.description1ErrorMessage}</small>}
                                    </div>
                                </div>
                            }
                        </div>
                    </Dialog>


                    <Dialog header="Transaction Status" modal draggable={false} className="sing_up_main_Dialog"
                        visible={this.state.productDialog2} style={this.dialogStyle}
                        onHide={this.hideDialog2}  >
                        <div className=' risk-clfn-dialog '>
                            <div className="w-full px-4 py-3">
                                <p className="flex align-items-center justify-content-center"><b className="w-6" style={{ textAlign: 'justify' }} >Account Id </b><span className="w-2">:</span><span className="w-6" style={{ textAlign: 'justify' }} >{this.state.transactionStatus.accountId}</span></p>
                                <p className="flex align-items-center justify-content-center"><b className="w-6" style={{ textAlign: 'justify' }}>Account Name</b>     <span className="w-2">:</span><span className="w-6" style={{ textAlign: 'justify' }} >{this.state.transactionStatus.accountName}</span></p>
                                <p className="flex align-items-center justify-content-center"><b className="w-6" style={{ textAlign: 'justify' }}>Type</b><span className="w-2">:</span><span className="w-6" style={{ textAlign: 'justify' }} >{this.state.transactionStatus.type}</span></p>
                                <p className="flex align-items-center justify-content-center"><b className="w-6" style={{ textAlign: 'justify' }}>Status</b>
                                    <span className="w-2">:</span><span className="w-6" style={{ fontWeight: 'bold', color: this.state.transactionStatus.status === 'Blocked' ? '#f42616' : this.state.transactionStatus.status === 'Pending' ? '#f7af00' : this.state.transactionStatus.status === 'Cleared' ? 'rgb(69 160 75)' : '', textAlign: 'justify' }}>{this.state.transactionStatus.status}</span></p>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </>
        );
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        const { currency, status, selectedAccountId, dates2, minAmount, maxAmount } = this.state;
        if (!status && !(dates2 && dates2.length === 2) && !currency && !status && !selectedAccountId && !minAmount && !maxAmount) {
            toast.warn('Please choose at least one option before searching.', {
                autoClose: 5000,
            });
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 10);
            return;
        } else if ((minAmount && !maxAmount) || (!minAmount && maxAmount)) {
            toast.warn('Please enter values for both minAmount and maxAmount');
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 10);
            return;
        }
        const startDate = dates2 && dates2.length === 2 ? dates2[0] : null;
        const endDate = dates2 && dates2.length === 2 ? dates2[1] : null;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props?.location?.state?.accessToken
            }
        };
        // const storedAccountString = this.props?.location?.state?.selectedAccount || sessionStorage.getItem('selectedAccount');
        // const storedAccount = storedAccountString ? JSON.parse(storedAccountString) : null;
        const queryParams = {
            status: status ? status : '',
            // dates2: dates2 ? dates2 : '',
            accountId: selectedAccountId,
            minAmount,
            maxAmount,
        };
        if (currency) {
            queryParams.currency = currency.code;
        }
        const queryString = new URLSearchParams(queryParams).toString();
        let url = `${process.env.REACT_APP_BACKEND_HOST}/transactions`;
        if (startDate && endDate) {
            const formattedStartDate = startDate.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            const formattedEndDate = endDate.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            url += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&${queryString}`;
        }
        else if (queryString) {
            url += `?${queryString}`;
        }

        fetch(url, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error fetching transaction data: ' + response.status);
                }
            })
            .then(data => {
                const formattedData = data.map(item => ({
                    ...item,
                    startDate: new Date(item.startDate).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    }),
                    endDate: new Date(item.endDate).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    })
                }));
                sessionStorage.setItem('account_search', JSON.stringify(data));
                sessionStorage.removeItem('selectedAccount')
                this.setState({ data: formattedData, isLoading: false });
            })
            .catch(error => {
                if (error.message.includes('Access denied')) {
                    toast.error('Access denied. Please check your permissions.');
                }
                this.setState({ isLoading: false });
            });
    }
}

export default TransactionSearchListTable;