import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
// import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import axios from "axios";
import { Toast } from "primereact/toast";
// import moment from "moment";
import { Card } from 'primereact/card';
import { Triangle } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import '../IndividualRegisteration/IndividualRegisteration.css';
import PhoneInput from 'react-phone-input-2';

function CorporateRegisteration(props) {

    const [date, setDate] = useState(null);
    const [corporateName, setCorporateName] = useState('');
    let cnameRef = useRef('');
    const [arabicName, setArabicName] = useState('');
    let anameRef = useRef('');
    const [clientId, setclientId] = useState('');
    let clientIdRef = useRef('');
    const [mobile, setmobile] = useState('');
    const [email, setemail] = useState('');
    let emailRef = useRef('');
    const [idcardtype, setIdCardType] = useState('');
    const [idNumber, setidNumber] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    let idNumberRef = useRef('');
    const [address, setAddress] = useState('');
    let addressRef = useRef('');
    const [city, setCity] = useState('');
    let cityRef = useRef('');
    const location = useLocation();
    const [countrydata, setCountrydata] = useState([]);
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    let postalCodeRef = useRef('');
    const [emailId, setemailId] = useState('');
    let emailIdRef = useRef('');
    const [phone, setphone] = useState('');
    const [avgAnnualIncome, setavgAnnualIncome] = useState('');
    let avgAnnualIncomeRef = useRef('');
    const toast = useRef(null);
    const [response, setResponse] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [notes, setnotes] = useState('');
    let notesRef = useRef('');
    const [dateofcop, setDateofcop] = useState(null);
    const [branch, setBranchname] = useState('');
    const [branchdata, setBranchdata] = useState([]);
    const [ownershipFields, setOwnershipFields] = useState([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
    const [managementFields, setManagementFields] = useState([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
    const [Authorization, setAuthorization] = useState([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
    const [loading, setLoading] = useState(false);

    const [corporateNameError, setCorporateNameError] = useState('');
    // const [cmpyarabicNameError, setCmpyArabicNameError] = useState('');
    const [, setMobileNoError] = useState('');
    const [, setemailError] = useState('');
    // const [emailIdError, setemailIdError] = useState('');
    const [idTypeError, setidTypeError] = useState('');
    const [registrationNumberErr, setRegistrationNumberErr] = useState('');
    const [addressError, setAddressError] = useState('');
    const [countryError, setcountryError] = useState('');
    const [, setphNoError] = useState('');
    // const [postError, setpostError] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mailEr, setMailEr] = useState(false);
    const [mailEr_T, setMailEr_T] = useState(false);
    const [emailEr_T, setEmailEr_T] = useState(false);


    const [countryofIncorporation, setCountryofIncorporation] = useState('');
    const [stateProvince, setStateProvince] = useState('');

    const [purposeofAccount, setPurposeofAccount] = useState('');
    const [eMT, setEMT] = useState('');

    const [accountType, setAccountType] = useState('Corporate' || []);

    const [authorizedSignatories, setAuthorizedSignatories] = useState([{ fullName: '', designation: '', email: '', phone: '' }]);
    const [isVisible, setIsVisible] = useState(false);
    const [beneficialOwnersOpc, setBeneficialOwnersOpc] = useState([{ fullName: '', ownershipPercentage: '', dateofBirth: '', address: '', idType: '', idNumber: '', expiryDate: '' }]);
    const [isVisibleS, setIsVisibleS] = useState(false);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatDate = (date) => {
        return date?.toLocaleDateString('en-US');
    };

    const handleIdcardtype = (event) => {
        setIdCardType(event.value);
    }

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };
    const today1 = new Date();
    const minDate1 = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate() + 1);

    const dialogStyle = {
        width: '30rem',
        overflow: 'hidden',
        textalign: 'center'
    };

    const showDialog = () => {
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogVisible(false);
        setDate(new Date());
        setCorporateName('');
        setArabicName('');
        setclientId('');
        setmobile('');
        setemail('');
        setIdCardType('');
        setRegistrationNumber('');
        setAddress('');
        setCity('');
        setOwnershipFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setManagementFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setAuthorization([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setCountry('');
        setPostalCode('');
        setemailId('');
        setphone('');
        setavgAnnualIncome('');
        setnotes('');
        setDateofcop(new Date());
        setBranchname('');
        refreshHandler();
    };

    useEffect(() => {
        getAllbranches();
        getAllcountry();
    }, []);

    const getAllcountry = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/country', {
            headers: {
                'Authorization': 'Bearer ' + location?.state?.accessToken
            }
        })
            .then(response => {
                setCountrydata(response.data);
            })
            .catch(error => {
            });
    }

    const getAllbranches = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/branches', {
            headers: {
                'Authorization': 'Bearer ' + location?.state?.accessToken
            }
        })
            .then(response => {
                const branches = response.data;
                setBranchdata(branches);
            })
            .catch(error => {
            });

    }

    const handleCorporateSubmit = event => {
        setLoading(true);
        event.preventDefault();

        if (corporateName || corporateName.length < 3 || registrationNumber || date || countryofIncorporation || dateofcop || address || city || stateProvince || mobile || postalCode || emailId || accountType || purposeofAccount || branch.name || eMT) {

            const data = {
                corporateName: corporateName,
                arabicName: arabicName,
                // clientId: clientId,
                mobile: mobile,
                // email: email,
                // idType: idcardtype,
                registrationNumber: registrationNumber,
                address: address,
                city: city,
                country: {
                    id: country.id,
                    country: {
                        laCountryName: country?.country?.laCountryName,
                    }
                },
                postalCode: postalCode,
                emailId: emailId,
                // phone: phone,
                avgAnnualIncome: eMT || avgAnnualIncome,
                dateOfIncorporation: formatDate(dateofcop || null),
                idExpDate: formatDate(date || null),
                // partner: ownershipFields,
                // management: managementFields,
                // authorized: Authorization,
                authorizedSignatories: authorizedSignatories,
                beneficialOwners: beneficialOwnersOpc,
                branch: {
                    id: branch.id,
                    name: branch.name,
                    emailId: branch.emailId
                },
                accountType: accountType
                // notes: notes,

            };
            axios.post(process.env.REACT_APP_BACKEND_HOST + '/create', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            })
                .then(postdata => {
                    event.preventDefault();
                    setResponse(postdata.data)
                    setLoading(false);
                    setmobile('');
                    setPostalCode('');
                    if (postdata.status === 200) {
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Registeration Successfully' });
                        setTimeout(() => {
                            showDialog();
                        }, 800);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: postdata?.data?.message || 'Enter the valid detials' });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    refreshHandler();
                    if (error?.response && error?.response?.status === 403) {
                        (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
                        setLoading(false);
                    }
                    else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                        setLoading(false);
                    }
                    else {
                        displayErrorToast('An error occurred. Please try again later.');
                        setLoading(false);
                    }
                })
        } else {
            setLoading(false);
        }

    }

    const idcardtypes = [
        { name: "Pan Card", code: "Pan Card" },
        { name: "Registration Certificate", code: "Registration Certificate" },
        { name: "Certificate Of Incorporation", code: "Certificate Of Incorporation" }
    ];

    const idcardtypess = [
        { name: "Passport", code: "Passport" },
        { name: "Driver's License", code: "Driver's License" },
        { name: "National ID", code: "National ID" },
    ];

    const clientIdstatus = response.clientId;
    const fullNamestatus = response.corporateName;
    const id = response.id;
    const status = response.status;
    const profileType = response.profileType;

    const refreshHandler = () => {
        setDate(null);
        setCorporateName('');
        setArabicName('');
        setclientId('');
        setmobile('');
        setemail('');
        setIdCardType('');
        setRegistrationNumber('');
        setOwnershipFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setManagementFields([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setAuthorization([{ clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        setAddress('');
        setCity('');
        setCountry('');
        setPostalCode('');
        setemailId('');
        setphone('');
        setavgAnnualIncome('');
        setnotes('');
        setDateofcop(null);
        setBranchname('');
        setPurposeofAccount('');
        setEMT('');
        setCountryofIncorporation('');
        setStateProvince('');
        setBeneficialOwnersOpc([{ fullName: '', ownershipPercentage: '', dateofBirth: '', address: '', idType: '', idNumber: '', expiryDate: '' }]);
    };


    const addFieldSet = (fieldSetType, event) => {
        event.preventDefault();
        if (fieldSetType === 'ownership') {
            setOwnershipFields([...ownershipFields, { clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        } else if (fieldSetType === 'management') {
            setManagementFields([...managementFields, { clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        } else if (fieldSetType === 'authorization') {
            setAuthorization([...Authorization, { clientId: '', name: '', idCardType: '', idNumber: '', mobile: '', email: '' }]);
        }
    };

    // const removeFieldSet = (fieldSetType, index, event) => {
    //     event.preventDefault();
    //     if (fieldSetType === 'ownership') {
    //         const updatedFields = [...ownershipFields];
    //         updatedFields.splice(index, 1);
    //         setOwnershipFields(updatedFields);
    //     } else if (fieldSetType === 'management') {
    //         const updatedFields = [...managementFields];
    //         updatedFields.splice(index, 1);
    //         setManagementFields(updatedFields);
    //     } else if (fieldSetType === 'authorization') {
    //         const updatedFields = [...Authorization];
    //         updatedFields.splice(index, 1);
    //         setAuthorization(updatedFields);
    //     }
    // };

    const handleFieldChange = (fieldSetType, index, field, value) => {
        if (fieldSetType === 'ownership') {
            const updatedFields = [...ownershipFields];
            updatedFields[index][field] = value;
            setOwnershipFields(updatedFields);

        } else if (fieldSetType === 'management') {
            const updatedFields = [...managementFields];
            updatedFields[index][field] = value;
            setManagementFields(updatedFields);
        } else if (fieldSetType === 'authorization') {
            const updatedFields = [...Authorization];
            updatedFields[index][field] = value;
            setAuthorization(updatedFields);
        }
    };

    const today = new Date();
    const minDate = new Date(today);
    minDate.setFullYear(today.getFullYear() - 90);

    const handleSearch = async (fieldSetType, setIndex, event) => {
        event.preventDefault();
        try {
            const ownershipId = ownershipFields[setIndex].clientId;
            const baseUrl = process.env.REACT_APP_BACKEND_HOST;
            const url = `${baseUrl}/accounts?clientId=${ownershipId}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            });
            const updatedFields = [...ownershipFields];
            updatedFields[setIndex] = {
                ...updatedFields[setIndex],
                name: response.data.fullName,
                idCardType: response.data.idType,
                idNumber: response.data.idNumber,
                mobile: response.data.phoneNumber,
                email: response.data.emailId,
            };
            setOwnershipFields(updatedFields);

        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
            }
        }
    };

    const manageSearch = async (fieldSetType, setIndex, event) => {
        event.preventDefault();
        try {
            const managementId = managementFields[setIndex].clientId;
            const baseUrl = process.env.REACT_APP_BACKEND_HOST;
            const url = `${baseUrl}/accounts?clientId=${managementId}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            });

            const updatedFields = [...managementFields]; // Create a copy of ownershipFields
            updatedFields[setIndex] = {
                ...updatedFields[setIndex],
                name: response.data.fullName,
                idCardType: response.data.idType,
                idNumber: response.data.idNumber,
                mobile: response.data.phoneNumber,
                email: response.data.emailId,
            };

            setManagementFields(updatedFields);
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
            }
        }
    };





    const authorization = async (fieldSetType, setIndex, event) => {
        event.preventDefault();
        try {
            const managementId = Authorization[setIndex].clientId;
            const baseUrl = process.env.REACT_APP_BACKEND_HOST;
            const url = `${baseUrl}/accounts?clientId=${managementId}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            });

            const updatedFields = [...Authorization]; // Create a copy of ownershipFields
            updatedFields[setIndex] = {
                ...updatedFields[setIndex],
                name: response.data.fullName,
                idCardType: response.data.idType,
                idNumber: response.data.idNumber,
                mobile: response.data.phoneNumber,
                email: response.data.emailId,
            };

            setAuthorization(updatedFields);
        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else if (error?.response && error?.response?.status === 403) {
                window?.location.assign(process.env.REACT_APP_AUTH_URL);
            }
        }
    };


    const branchchange = (event) => {
        const selectedBranch = branchdata?.find((item) =>
            item?.name === event?.target?.value
        );
        setBranchname(selectedBranch);
    };

    const [selectedCountryFlag, setSelectedCountryFlag] = useState('');

    const countrychange = (event) => {
        const selectedCountry = countrydata?.find((item) => item?.country?.laCountryName === event.target.value);
        setCountry(selectedCountry);
        setCountryofIncorporation(selectedCountry);
        setSelectedCountryFlag(selectedCountry?.country);
    };

    const handleEmailChange = (event, i) => {
        const emailValue = event.target.value.toLowerCase();
        setemail(emailValue);
        if (!emailValue.trim()) {
            setMailEr('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailEr('Invalid email format or domain, length.');
        } else {
            setMailEr('');
        }
    };

    const handleEmailChangeAdd = (event, i) => {
        const emailValue = event.target.value.toLowerCase();
        setemailId(emailValue);
        if (!emailValue.trim()) {
            setMailEr_T('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailEr_T('Invalid email format or domain, length.');
        } else {
            setMailEr_T('');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        // const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const words = email.endsWith('gmail.com');
        if (!words) {
            if (!emailRegex.test(email)) {
                return false;
            }
        }
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com' || '@gmail.com');
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 20;
        return hasDotCom && isDomainLengthValid && domain;
    };


    const handlePhoneNumberChange = (value) => {
        const rawInput = value;
        setmobile(rawInput);
    };

    const handlePhoneNumberChangeT = (value) => {
        const rawInput = value;
        setphone(rawInput);
    };


    // const formatPhoneNumber = (phonenumber) => {
    //     const matches = phonenumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    //     if (matches) {
    //         return `${matches[1]}-${matches[2]}-${matches[3]}`;
    //     }
    //     return phonenumber;
    // };

    const handleChange = (feild, index, value) => {
        const valid = [...authorizedSignatories]
        valid[index][feild] = value;
        setAuthorizedSignatories(valid);

        if (feild === 'email') {
            const emailValue = value.toLowerCase();
            if (!emailValue.trim()) {
                setEmailEr_T('Email is required.');
            } else if (!isValidEmail(emailValue)) {
                setEmailEr_T('Invalid email format or domain, length.');
            } else {
                setEmailEr_T('');
            }
        } else {
        }
    }

    const handleChange2 = (feild, index, value) => {
        const valid = [...beneficialOwnersOpc]
        valid[index][feild] = value;
        setBeneficialOwnersOpc(valid);

        if (feild === 'idType') {
            valid[index]['idNumber'] = '';
            setBeneficialOwnersOpc(valid);
        }
    }
    return (
        <>
            <div className="mb-4">
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="feild c">

                            <div className="heading">Business Information</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Registered Name<span>*</span></div>
                                    <InputText type="text" value={corporateName} onChange={(e) => setCorporateName(e.target.value)} />
                                    {corporateNameError && <div className="error-message">{corporateNameError}</div>}
                                    {corporateName && corporateName.length < 3 && <div className="error-message">minimum 3 characters</div>}
                                </div>
                                <div className="input">
                                    <div className="label">Registered Name In Arabic</div>
                                    <InputText dir="rtl" value={arabicName} onChange={(e) => setArabicName(e.target.value)} />
                                </div>

                                <div className="input">
                                    <div className="label">Registration No<span>*</span></div>
                                    <InputText value={registrationNumber} onChange={(e) => setRegistrationNumber(e.target.value)} />
                                    {registrationNumberErr && <div className="error-message">{registrationNumberErr}</div>}
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Id Expiration Date<span>*</span></div>
                                    <Calendar style={{ padding: 'unset' }} showIcon value={date} onChange={(e) => setDate(e.value)} required minDate={minDate1} id="p-calender" className="w-full calander" />
                                </div>

                                <div className="input">
                                    <div className="label">Date Of Incorporation<span>*</span></div>
                                    <Calendar style={{ padding: 'unset' }} onChange={(e) => setDateofcop(e.value)} value={dateofcop} showIcon minDate={minDate} maxDate={today} id="p-calender" className="w-full calander" required />
                                </div>

                                <div className="input">
                                    <div className="label">Country of Incorporation<span>*</span></div>
                                    <Dropdown value={country?.country?.laCountryName || null} options={countrydata?.map((item) => ({ label: item?.country?.laCountryName, value: item?.country?.laCountryName }))} optionLabel="label" required onChange={countrychange} filter className="w-full p-0 unique-drops dropdown" />
                                    {countryError && <div className="error-message">{countryError}</div>}
                                    {/* <Calendar style={{ padding: 'unset' }} onChange={(e) => setCountryofIncorporation(e.value)} value={countryofIncorporation} showIcon minDate={minDate} maxDate={today} id="p-calender" className="w-full calander" required /> */}
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Registered Address<span>*</span></div>
                                    <InputText value={address} onChange={(e) => setAddress(e.target.value)} />
                                    {addressError && <div className="error-message">{addressError}</div>}
                                </div>
                                <div className="input">
                                    <div className="label">City<span>*</span></div>
                                    <InputText value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>

                                <div className="input">
                                    <div className="label">State/Province<span>*</span></div>
                                    <InputText value={stateProvince} onChange={(e) => setStateProvince(e.target.value)} />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Postal Code<span>*</span></div>
                                    <InputText maxLength={6} value={postalCode} keyfilter="int" onChange={(e) => setPostalCode(e.target.value)} />
                                </div>
                                <div className="input">
                                    <div className="label">Phone Number<span>*</span></div>
                                    <PhoneInput
                                        country={selectedCountryFlag ? selectedCountryFlag?.alphabitSymbole1.toLowerCase() : 'us'}
                                        value={mobile}
                                        onChange={handlePhoneNumberChange}
                                        countryCodeEditable={false}
                                        className='w-full unique-phone'
                                    />
                                </div>
                                <div className="input">
                                    <div className="label">Business Email<span>*</span></div>
                                    <InputText value={emailId} onChange={handleEmailChangeAdd} />
                                    {mailEr_T && <div className="error-message">{mailEr_T}</div>}
                                </div>
                            </div>

                            <div className="heading">Account Details</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Account Type<span>*</span></div>
                                    <Dropdown value={accountType}
                                        options={
                                            [
                                                { name: "Corporate", code: "Corporate" },
                                            ]
                                        }
                                        onChange={(e) => setAccountType(e.target.value)}
                                        required
                                        className="w-full unique-drops dropdown"
                                        optionLabel="name" optionValue="code" />
                                </div>
                                <div className="input">
                                    <div className="label">Purpose of Account<span>*</span></div>
                                    <InputText value={purposeofAccount} onChange={(e) => setPurposeofAccount(e.target.value)} />
                                </div>

                                <div className="input">
                                    <div className="label">Branch<span>*</span></div>
                                    <Dropdown value={branch ? branch.name : null}
                                        options={branchdata?.map((item) =>
                                            ({ label: item?.name, value: item?.name }))
                                        } onChange={branchchange} required className="w-full unique-drops dropdown" optionLabel="label" />
                                </div>
                            </div>

                            <div className="flex">

                                <div className="input">
                                    <div className="label">Expected Monthly Transaction Volume<span>*</span></div>
                                    <InputText value={eMT} keyfilter={'int'} onChange={(e) => setEMT(e.target.value)} />
                                </div>
                            </div>

                            <div className="heading">Ownership Information</div>

                            <div className="gap" >

                                <div className="card_Sec" style={{ padding: 'unset', width: '-webkit-fill-available' }}>
                                    <div className="flex">
                                        <div className="sub_heading">Authorized Signatories</div>
                                        <Button
                                            icon={isVisible ? 'pi pi-times' : 'pi pi-plus'}
                                            onClick={(e) => setIsVisible(!isVisible)}
                                            style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                        // onClick={(event) => addFieldSet('ownership', event)}
                                        />
                                    </div>
                                    <Card className="card_Sec2 full_View" style={{ boxShadow: 'unset' }}>
                                        {authorizedSignatories.map((fieldSet, setIndex) => (
                                            <div key={setIndex} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {isVisible ? <>
                                                    <div className="flex">
                                                        <div className="input">
                                                            <div className="label">Full Name</div>
                                                            <InputText value={fieldSet.name} onChange={(e) => handleChange('fullName', setIndex, e.target.value)} />
                                                        </div>
                                                        <div className="input">
                                                            <div className="label">Designation</div>
                                                            <InputText value={fieldSet.name} onChange={(e) => handleChange('designation', setIndex, e.target.value)} />
                                                        </div>

                                                    </div>

                                                    <div className="flex">
                                                        <div className="input">
                                                            <div className="label">Email</div>
                                                            <InputText value={fieldSet.name} onChange={(e) => handleChange('email', setIndex, e.target.value)} />
                                                            {emailEr_T && <div className="error-message">{emailEr_T}</div>}
                                                        </div>
                                                        <div className="input">
                                                            <div className="label">Phone</div>
                                                            <InputText value={fieldSet.name} maxLength={10} keyfilter={'int'} onChange={(e) => handleChange('phone', setIndex, e.target.value)} />
                                                        </div>
                                                    </div>

                                                    {/* <div className="flex">
                                                        <div className="input">
                                                            <div className="label">Signature</div>
                                                            <InputText value={fieldSet.name} onChange={(e) => handleChange('signature', setIndex, e.target.value)} />
                                                        </div>
                                                    </div> */}
                                                </> : null}
                                            </div>
                                        ))}
                                    </Card>
                                </div>

                                <div className="card_Sec" style={{ padding: 'unset', width: '-webkit-fill-available' }}>
                                    <div className="flex">
                                        <div className="sub_heading">Beneficial Owners</div>
                                        <Button
                                            icon={isVisibleS ? 'pi pi-times' : 'pi pi-plus'}
                                            onClick={(e) => setIsVisibleS(!isVisibleS)}
                                            style={{ height: windowWidth < 500 ? '35px' : '40px', padding: '0 10px' }}
                                        // onClick={(event) => addFieldSet('ownership', event)}
                                        />
                                    </div>
                                    <Card className="card_Sec2 full_View" style={{ boxShadow: 'unset' }}>
                                        {beneficialOwnersOpc.map((fieldSet, setIndex) => (
                                            <div key={setIndex} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {isVisibleS ? <>
                                                    <div className="">
                                                        <div className="flex">
                                                            <div className="input">
                                                                <div className="label">Full Name</div>
                                                                <InputText value={fieldSet.fullName} onChange={(e) => handleChange2('fullName', setIndex, e.target.value)} />
                                                            </div>
                                                            <div className="input">
                                                                <div className="label">Ownership Percentage</div>
                                                                <InputText value={fieldSet.ownershipPercentage} onChange={(e) => handleChange2('ownershipPercentage', setIndex, e.target.value)} />
                                                            </div>

                                                        </div>

                                                        <div className="flex">
                                                            <div className="input">
                                                                <div className="label">Date of Birth</div>
                                                                <Calendar style={{ padding: 'unset' }} onChange={(e) => handleChange2('dateofBirth', setIndex, e.target.value)} value={fieldSet.dateofBirth} showIcon maxDate={eighteenYearsAgo} id="p-calender" className="w-full calander" required />
                                                            </div>

                                                            <div className="input">
                                                                <div className="label">Address</div>
                                                                <InputText value={fieldSet.address} onChange={(e) => handleChange2('address', setIndex, e.target.value)} />
                                                            </div>

                                                        </div>

                                                        <div className="flex">
                                                            <div className="input">
                                                                <div className="label">Id Type</div>
                                                                <Dropdown optionLabel="name" optionValue="code" options={idcardtypess} value={fieldSet.idType} onChange={(e) => handleChange2('idType', setIndex, e.target.value)} className="w-full unique-drops dropdown" required />
                                                            </div>
                                                            <div className="input">
                                                                <div className="label">Id Number</div>
                                                                <InputText
                                                                    maxLength={fieldSet.idType === `Driver's License` ? 16 : fieldSet.idType === 'National ID' ? 12 : 9}
                                                                    value={fieldSet.idNumber} keyfilter={"int"} onChange={(e) => handleChange2('idNumber', setIndex, e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="input">
                                                                <div className="label">ExpiryDate</div>
                                                                <Calendar style={{ padding: 'unset' }} showIcon value={fieldSet.expiryDate} onChange={(e) => handleChange2('expiryDate', setIndex, e.target.value)} required minDate={minDate1} id="p-calender" className="w-full calander" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null}
                                            </div>
                                        ))}
                                    </Card>
                                </div>


                            </div>




                            <div className="footer_sec_btn input" style={{ padding: 'unset' }}>
                                <Button style={{ background: '#124A99' }} onClick={refreshHandler} className="resetBtn" icon="pi pi-refresh" />
                                <Button
                                    label="Submit"
                                    style={{ background: '#124A99' }} onClick={handleCorporateSubmit}
                                    disabled={!corporateName || corporateName.length < 3 || !registrationNumber || !date || !countryofIncorporation || !dateofcop || !address || !city || !stateProvince ||
                                        emailEr_T ? emailEr_T : '' || !mobile || !postalCode || !emailId || !accountType || !purposeofAccount || !branch.name || !eMT}
                                />
                            </div>
                        </div>

                        <Dialog header="Registration Status" visible={dialogVisible} onHide={hideDialog} className="sing_up_main_Dialog" position="top" style={dialogStyle}>
                            <div className='risk-clfn-dialog '>
                                <div className="w-full px-4 py-3">
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Id</b>     <span className="w-2">:</span><span className="w-6">{id}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Client Id</b>     <span className="w-2">:</span><span className="w-6">{clientIdstatus}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Corporate Name</b><span className="w-2">:</span><span className="w-6">{fullNamestatus}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Status</b>        <span className="w-2">:</span><span className="w-6" style={{ fontWeight: 'bold', color: status === 'Blocked' ? '#f42616' : status === 'Pending' ? '#f7af00' : status === 'Cleared' ? 'rgb(69 160 75)' : '' }}>{status}</span></p>
                                </div>
                            </div>
                        </Dialog>
                    </>
                )}
            </div >

        </>
    )
}
export default CorporateRegisteration;








