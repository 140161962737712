import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useState } from 'react';
import './BendingDataPage.css';
import { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function BeneficiaryPending(props) {

    const [tableData, setTableData] = useState();
    const history = useHistory();
    // const [bencount, setbencount] = useState(props?.data)
    const toast = React.useRef(null);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;

    // const displayErrorToast = (message) => {
    //     if (toast.current) {
    //         toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    //     }
    // };

    // const displayWarningToast = (message) => {
    //     if (toast.current) {
    //         toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
    //     }
    // };
    // const emails = location?.state?.emailId;

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/beneficiary/getall/pending', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                if (response.data.length === 0) {
                    // displayWarningToast('No pending beneficiaries found.');
                } else {
                    setTableData(response.data);
                }
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast?.current?.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast?.current?.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else {
                    toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'An unexpected error occurred.', life: 5000 });
                }
            });
    }, [accessToken]);

    const actionbody = (rowData) => {
        const { clientId } = rowData;
        if (clientId) {
            sessionStorage.setItem('clientId', clientId);
        }

        const handleButtonClick = () => {
            history.push({
                pathname: '/admin/account/benificary',
                state: {
                    clientId: clientId,
                    emailId: location?.state?.emailId,
                    accPemding: location?.state?.accPemding,
                    benPending: location?.state?.benPending,
                    traPending: location?.state?.traPending,
                    accessToken: accessToken,
                    getUserData: location?.state?.getUserData
                },
            })
        };

        return (
            <React.Fragment>
                <Button className="p-button p-button-text p-button-info pi pi-info-circle" onClick={handleButtonClick} />
            </React.Fragment>
        );
    };

    const profileTemplate = (rowData) => {
        const status = rowData.screeningStatus;

        let color = '';

        if (status === 'Pending') {
            color = '#f7af00';
        } else if (status === 'Blocked') {
            color = '#f42616';
        } else if (status === 'Cleared') {
            color = 'rgb(69 160 75)';
        } else if (status === 'On-going') {
            color = '#f7af00';
        }
        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };

    let bencounts = props?.data?.benPending;

    return (
        <>
            <Toast ref={toast} className='transaction-toast' />
            <div className="feild" style={{ padding: 'unset' }}>
                <div className="input">
                    <div className='head head_T'>Beneficiary Pending   :<span className='pend-count-styl'>{bencounts || props?.beneficiaryPending || '0'}</span></div>
                    <DataTable
                        value={tableData}
                        scrollable
                        stripedRows
                        showGridlines
                        paginator
                        rows={5}
                        scrollHeight="80vh"
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        <Column field="clientId" header="Beneficiary Id" />
                        <Column field="name" header="Full Name" />
                        <Column field="beneficiaryType" header="Beneficiary Type" />
                        <Column field="screeningStatus" header="Status" body={profileTemplate} />
                        <Column field="moreinfo" style={{ width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }} header="More Info" body={actionbody} />
                    </DataTable>
                </div>
            </div>
        </>
    )
}

export default BeneficiaryPending
