import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import axios from "axios";
import { Toast } from "primereact/toast";
import { Dialog } from 'primereact/dialog';
import { Triangle } from "react-loader-spinner";
import { useLocation } from 'react-router-dom';
import './IndividualRegisteration.css';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';

function IndividualRegisteration(props) {

    const [fullName, SetFullname] = useState('');
    const [, setArabicName] = useState('');
    let fnameRef = useRef('');
    let anameRef = useRef('');
    const [date, setDate] = useState("");
    const [gender, setGender] = useState('');
    const location = useLocation();
    const [nationality, setNationality] = useState('');
    const [idcardtype, setIdCardType] = useState('');
    const [idnumber, setIdnumber] = useState('');
    let idnumberRef = useRef('');
    const [birthCountry, setCountryofBirth] = useState('');
    let addressRef = useRef('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState({});
    const [city, setCity] = useState('');
    let cityRef = useRef('');
    const [postalcode, setPostalCode] = useState('');
    let postalCodeRef = useRef('');
    const [mail, setEmail] = useState('');
    let emailRef = useRef('');
    const [phonenumber, setPhonenumber] = useState('');
    const [employeetype, setEmployeetype] = useState('');
    const [employeename, setEmployeename] = useState('');
    let enameRef = useRef('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [annualsalary, setAnnualsalary] = useState('')
    let annualSalaryRef = useRef('');
    const [notes, setNotes] = useState('');
    let notesRef = useRef('');
    const toast = useRef(null);
    const [response, setResponse] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [branch, setBranchname] = useState('');
    const [loading, setLoading] = useState(false);
    const [fullNameError, setFullNameError] = useState('');
    const [genderNameError, setgenderError] = useState('');
    const [nationalityError, setnationalityError] = useState('');
    const [idNoError, setidNoError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [emailIdError, setemailIdError] = useState('');
    const [phNoError, setphNoError] = useState('');
    const [emptypeError, setemptypeError] = useState('');
    const [designationError, setdesignationError] = useState('');
    const [empyTypeError, setempyTypeError] = useState('');
    const [avgError, setavgError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [postalcodeError, setPostalcodeError] = useState('');
    const [cityError, setCityError] = useState('');
    const [mailEr, setMailEr] = useState(false);
    const [branchdata, setBranchdata] = useState([]);
    const [nationalitydata, setNationalitydata] = useState([]);
    const [designationData, setDesignationData] = useState([]);
    const [accountType, setAccountType] = useState([]);
    const currentDate = new Date();
    const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    const [stateProvince, setStateProvince] = useState('');
    const [expirationDate, setExpirationDate] = useState('');

    useEffect(() => {
        getAllbranches();
        getAllcountry();
        getAlldesignation();
    }, []);

    const getAllcountry = () => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/country', {
            headers: {
                'Authorization': 'Bearer ' + location?.state?.accessToken
            }
        })
            .then(response => {
                setNationalitydata(response.data);
            })
            .catch(error => {
            });
    }

    const getAllbranches = () => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/branches', {
            headers: {
                'Authorization': 'Bearer ' + location?.state?.accessToken
            }
        })
            .then(response => {
                const branches = response.data;
                setBranchdata(branches);
            })
            .catch(error => {
            });
    }

    const getAlldesignation = () => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/designation?status=Active', {
            headers: {
                'Authorization': 'Bearer ' + location?.state?.accessToken
            }
        })
            .then(response => {
                setDesignationData(response.data);
            })
            .catch(error => {
            });
    }

    const handleDateChange = (selectedDate) => {
        const formattedSelectedDate = moment(selectedDate).format('MM/DD/YYYY');
        setDate(formattedSelectedDate);
    };

    // const formatDate = (inputDate) => {
    //     const selectedDate = new Date(inputDate);
    //     if (selectedDate.toISOString().substr(0, 10) === new Date().toISOString().substr(0, 10)) {
    //         return '';
    //     }
    //     const formattedDate = `${selectedDate.getMonth()}/${selectedDate.getDate() + 1}/${selectedDate.getFullYear()}`;
    //     return formattedDate;
    // };    

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    const handleGenderChange = (event) => {
        setGender(event.value);
    }

    const handleIdcardtype = (event) => {
        if (idnumber) {
            setIdnumber('')
        }
        setIdCardType(event.value);
    }

    const hideDialog = () => {
        setDialogVisible(false);
        setDate(new Date());
        SetFullname('');
        setGender('');
        setNationality();
        setIdCardType('');
        setIdnumber('');
        setCountryofBirth('');
        setAddress('');
        setCountry('');
        setCity('');
        setPostalCode('');
        setEmail('');
        setPhonenumber('');
        setEmployeetype('');
        setAnnualsalary('');
        setNotes('');
        setSelectedValue('');
        setBranchname('');
        resetFunc();
    };

    const resetFunc = () => {
        setDate('');
        SetFullname('');
        setGender('');
        setNationality('');
        setIdCardType('');
        setIdnumber('');
        setCountryofBirth('');
        setAddress('');
        setCountry('');
        setCity('');
        setPostalCode('');
        setEmail('');
        setPhonenumber('');
        setEmployeetype('');
        setAnnualsalary('');
        setNotes('');
        setSelectedValue('');
        setBranchname('');
        setEmployeename('');
        setStateProvince('');
        setExpirationDate('');
        setAccountType('');
        setSelectedCountryFlag('');
    };

    const category = response.category;
    const clientIdstatus = response.clientId;
    const fullNamestatus = response.fullName;
    const id = response.id;
    const status = response.status;

    const dialogStyle = {
        width: '30rem',
        overflow: 'hidden',
        textalign: 'center'
    };

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);

        if (fullName.length < 3 || fullName || date || gender || nationality || birthCountry || mail || phonenumber || address || city || stateProvince || postalcode || country ||
            idcardtype || idnumber || expirationDate || accountType || annualsalary || branch.name || employeetype || employeename || selectedValue?.designation) {
            const data = {
                fullName: fullName,
                dob: moment(date).format('MM/DD/YYYY'),
                gender: gender,
                nationality: nationality?.country?.laCountryName,
                countryOfBirth: {
                    id: nationality?.id,
                },

                emailId: mail,
                phoneNumber: phonenumber,
                address: address,
                city: city,
                stateProvince: stateProvince,
                postalCode: postalcode,
                country: {
                    id: nationality?.id,
                },

                idType: idcardtype,
                idNumber: idnumber,
                expirationDate: expirationDate,

                accountType: accountType,
                branch: {
                    id: branch.id,
                    name: branch.name,
                    emailId: branch.emailId
                },


                employmentType: employeetype,
                employerName: employeename,
                designation: {
                    id: selectedValue.id,
                    designation: selectedValue.designation,
                },
                annualSalary: annualsalary,
            };

            axios.post(process.env.REACT_APP_BACKEND_HOST + '/account/create', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + location?.state?.accessToken
                }
            })
                .then(response => {
                    event.preventDefault();
                    setResponse(response.data)
                    setDialogVisible(true);
                    if (response.status === 200) {
                        toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Registeration Successfully' });
                        resetFunc();
                    } else if (response.status === 400) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enter the valid detials' });
                        return;
                    }
                    resetFunc();
                    setLoading(false);
                })
                .catch((error) => {
                    resetFunc();
                    setLoading(false);
                    if (error?.response && error?.response?.status === 403) {
                        (window?.location?.assign(process.env.REACT_APP_AUTH_URL));
                    }
                    else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    } else {
                        displayErrorToast('An error occurred. Please try again later.');
                    }
                });
        } else {
            setLoading(false);
        }
    }

    const genders = [
        { name: "Male", code: "Male" },
        { name: "Female", code: "Female" },
        { name: "Other", code: "Other" }
    ];

    // const handlePhoneNumberChange = (e) => {
    //     const rawInput = e.target.value;
    //     const formattedInput = rawInput.replace(/[^0-9]/g, '');
    //     const formattedPhoneNumber = formatPhoneNumber(formattedInput);

    //     setPhonenumber(formattedPhoneNumber);
    // };

    // const formatPhoneNumber = (phonenumber) => {
    //     const matches = phonenumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    //     if (matches) {
    //         return `${matches[1]}-${matches[2]}-${matches[3]}`;
    //     }
    //     return phonenumber;
    // };

    const handlePhoneNumberChanges = (value) => {
        setPhonenumber(value);
    };

    const idcardtypes = [
        { name: "Passport", code: "Passport" },
        { name: "Driver's License", code: "Driver's License" },
        { name: "National ID", code: "National ID" },
    ];

    const employeetypes = [
        { name: "Employed", code: "Employed" },
        { name: "Self-Employed", code: "Self-Employed" },
        { name: "Unemployed", code: "Unemployed" },
        { name: "Student", code: "Student" },
        { name: "Retired", code: "Retired" }
    ];

    const today = new Date();
    const minDate = new Date(today);
    minDate.setFullYear(today.getFullYear() - 58);

    const handleSelectChange = (event) => {
        const selectedDesignation = designationData?.find((item) => item.designation === event.target.value);
        setSelectedValue(selectedDesignation);
    };

    const branchChange = (event) => {
        const selectedBranch = branchdata?.find((item) => item?.name === event.target.value);
        setBranchname(selectedBranch);
    };

    const birthCountrychange = (event) => {
        const selectedbirth = nationalitydata?.find((item) => item?.country?.laCountryName === event?.target?.value);
        setCountryofBirth(selectedbirth);
    };
    const [selectedCountryFlag, setSelectedCountryFlag] = useState('');

    const handleNationalityChange = (event) => {
        const selectedNationality = nationalitydata?.find((item) => item?.country?.laCountryName === event?.target?.value);
        setNationality(selectedNationality);
        setSelectedCountryFlag(selectedNationality?.country)
    };


    const countrychange = (event) => {
        const selectedCountry = nationalitydata?.find((item) => item?.country?.laCountryName === event?.target?.value);
        setCountry(selectedCountry);
    };

    // const [value, setValue] = useState('');
    const handleEmailChange = (event, i) => {
        const emailValue = event.target.value.toLowerCase();
        setEmail(emailValue);
        if (!emailValue.trim()) {
            setMailEr('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailEr('Invalid email format or domain, length.');
        } else {
            setMailEr('');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        // const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const words = email.endsWith('gmail.com');
        if (!words) {
            if (!emailRegex.test(email)) {
                return false;
            }
        }
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com');
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 20;
        return hasDotCom && isDomainLengthValid && domain;
    };

    return (
        <>
            <div>
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="feild c">

                            <div className="heading">Personal Information</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Full Name<span>*</span></div>
                                    <InputText defaultValue={fnameRef.current} onChange={(e) => SetFullname(e.target.value)} />
                                    {fullNameError && <small className="p-error">{fullNameError}</small>}
                                    {fullName && fullName.length < 3 && <div className="error-message">minimum 3 characters</div>}
                                </div>
                                <div className="input">
                                    <div className="label">Date Of Birth<span>*</span></div>
                                    <Calendar style={{ padding: 'unset' }} onChange={(e) => setDate(e?.target?.value)} value={date} showIcon maxDate={eighteenYearsAgo} id="p-calender" className="w-full calander" required />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Gender<span>*</span></div>
                                    <Dropdown optionLabel="name" optionValue="code" options={genders} value={gender} onChange={handleGenderChange} className="w-full unique-drops dropdown" required />
                                    {genderNameError && <small className="p-error">{genderNameError}</small>}
                                </div>
                                <div className="input">
                                    <div className="label">Nationality<span>*</span></div>
                                    <Dropdown value={nationality ? nationality?.country?.laCountryName : null} options={nationalitydata?.map((item) => ({ label: item?.country?.laCountryName, value: item?.country?.laCountryName }))} onChange={handleNationalityChange} filter required className="w-full unique-drops dropdown" optionLabel="label" />
                                    {nationalityError && <small className="p-error">{nationalityError}</small>}
                                </div>

                                <div className="input">
                                    <div className="label">Country of Birth<span>*</span></div>
                                    <Dropdown value={birthCountry ? birthCountry?.country?.laCountryName : null} options={nationalitydata?.map((item) => ({ label: item?.country?.laCountryName, value: item?.country?.laCountryName }))} onChange={birthCountrychange} filter required optionLabel="label" className="w-full unique-drops dropdown" />{nationalityError && <small className="p-error p-d-block pl-2">{nationalityError}</small>}
                                </div>
                            </div>

                            <div className="heading">Contact Information</div>

                            <div className="flex">

                                <div className="input">
                                    <div className="input">
                                        <div className="label">Email Address<span>*</span></div>
                                        <InputText value={mail} onChange={handleEmailChange} />
                                        {emailIdError && <small className="p-error">{emailIdError}</small>}
                                        {mailEr && <div className="error-message">{mailEr}</div>}
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="label">Phone Number<span>*</span></div>
                                    <PhoneInput
                                        country={selectedCountryFlag ? selectedCountryFlag?.alphabitSymbole1.toLowerCase() : 'us'}
                                        value={phonenumber}
                                        onChange={handlePhoneNumberChanges}
                                        countryCodeEditable={false}
                                        className='w-full unique-phone'
                                    />
                                    {phNoError && <div className="error-message">{phNoError}</div>}
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Address<span>*</span></div>
                                    <InputText defaultValue={addressRef.current} onChange={(e) => setAddress(e.target.value)} />
                                    {addressError && <small className="p-error">{addressError}</small>}
                                </div>
                                <div className="input">
                                    <div className="label">City<span>*</span></div>
                                    <InputText defaultValue={cityRef.current} onChange={(e) => setCity(e.target.value)} />
                                    {cityError && <small className="p-error">{cityError}</small>}
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">State/Province<span>*</span></div>
                                    <InputText value={stateProvince} onChange={(e) => setStateProvince(e.target.value)} />
                                </div>

                                <div className="input">
                                    <div className="label">Postal Code<span>*</span></div>
                                    <InputText maxLength={6} defaultValue={postalCodeRef.current} keyfilter="int" onChange={(e) => setPostalCode(e.target.value)} />
                                    {postalcodeError && <small className="p-error">{postalcodeError}</small>}
                                </div>

                                <div className="input">
                                    <div className="label">Country<span>*</span></div>
                                    <Dropdown value={country ? country?.country?.laCountryName : null} options={nationalitydata?.map((item) => ({ label: item?.country?.laCountryName, value: item?.country?.laCountryName }))} optionLabel="label" required onChange={countrychange} filter className="w-full unique-drops dropdown" />
                                    {countryError && <small className="p-error">{countryError}</small>}
                                </div>
                            </div>

                            <div className="heading">Identification Verification</div>

                            <div className="flex">

                                <div className="input">
                                    <div className="label">Identification Type <span>*</span></div>
                                    <Dropdown optionLabel="name" optionValue="code" options={idcardtypes} value={idcardtype} onChange={handleIdcardtype} className="w-full unique-drops dropdown" required />
                                </div>

                                <div className="input">
                                    <div className="label">Identification Number<span>*</span></div>
                                    <InputText value={idnumber} maxLength={idcardtype === 'Passport' ? 9 : idcardtype === 'National ID' ? 12 : idcardtype === 'Driver License' ? 16 : ''} onChange={(e) => setIdnumber(e.target.value)}
                                        style={{ border: idnumber && idnumber.length > 16 ? '1px solid red' : '1px solid #ced4da' }} />
                                    {idNoError || idnumber && idnumber.length > 16 && <small className="p-error">{idNoError || 'Input must be at a correct letters long.'}</small>}
                                </div>

                                <div className="input">
                                    <div className="label">Expiration Date<span>*</span></div>
                                    <Calendar style={{ padding: 'unset' }} onChange={(e) => setExpirationDate(e?.target?.value)} value={expirationDate} showIcon id="p-calender" className="w-full calander" required />
                                </div>
                            </div>

                            <div className="heading">Account Type</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Account Type<span>*</span></div>
                                    <Dropdown value={accountType}
                                        options={
                                            [
                                                { name: "Savings", code: "Savings" },
                                                { name: "Current", code: "Current" },
                                                { name: "Joint", code: "Joint" },
                                            ]
                                        }
                                        onChange={(e) => setAccountType(e.target.value)}
                                        required className="w-full unique-drops dropdown"
                                        optionLabel="name" optionValue="code" />
                                </div>
                                <div className="input">
                                    <div className="label">Branches<span>*</span></div>
                                    <Dropdown value={branch ? branch?.name : null} options={branchdata.map((item) => ({ label: item.name, value: item.name }))} onChange={branchChange} className="w-full unique-drops dropdown" optionLabel="label" required />
                                </div>
                            </div>

                            <div className="heading">Employment Information</div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Employment Status<span>*</span></div>
                                    <Dropdown optionLabel="name" optionValue="code" options={employeetypes} value={employeetype} onChange={(event) => setEmployeetype(event.target.value)} required className="w-full unique-drops dropdown" />
                                    {emptypeError && <small className="p-error">{emptypeError}</small>}
                                </div>
                                <div className="input">
                                    <div className="label">Employee Name<span>*</span></div>
                                    <InputText defaultValue={enameRef.current} onChange={(e) => setEmployeename(e.target.value)} ></InputText>
                                    {empyTypeError && <small className="p-error">{empyTypeError}</small>}
                                </div>

                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Designation<span>*</span></div>
                                    <Dropdown value={selectedValue?.designation} options={designationData?.map((item) => ({ label: item?.designation, value: item?.designation }))} onChange={handleSelectChange} required className="w-full unique-drops dropdown" optionLabel="label" />
                                    {designationError && <small className="p-error">{designationError}</small>}
                                </div>
                                <div className="input">
                                    <div className="label">AVG.Annual Salary<span>*</span></div>
                                    <InputText defaultValue={annualSalaryRef.current} keyfilter="int" onChange={(e) => setAnnualsalary(e.target.value)} />
                                    {avgError && <small className="p-error">{avgError}</small>}
                                </div>
                            </div>



                            <div className="footer_sec_btn" style={{ padding: 'unset' }}>
                                <Button style={{ background: '#124A99' }} className="resetBtn" onClick={resetFunc} icon="pi pi-refresh" />
                                <Button
                                    onClick={handleSubmit}
                                    disabled={
                                        fullName.length < 3 || !fullName || !date || !gender || !nationality || !birthCountry || !mail || !phonenumber || !address || !city || !stateProvince || !postalcode || !country ||
                                        !idcardtype || !idnumber || !expirationDate || !accountType || !annualsalary || !branch.name || !employeetype || !employeename || !selectedValue?.designation}
                                    label="Submit" />
                            </div>
                            <br />
                            <br />
                        </div>

                        <Dialog header="Registration Status" className="sing_up_main_Dialog" visible={dialogVisible} onHide={hideDialog} position="top" style={dialogStyle}>
                            <div className=' risk-clfn-dialog '>
                                <div className="w-full px-4 py-3">
                                    <p className="flex align-items-center justify-content-center"><b className="w-6" >Id </b><span className="w-2">:</span><span className="w-6">{id}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Client Id</b>     <span className="w-2">:</span><span className="w-6">{clientIdstatus}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Full Name</b><span className="w-2">:</span><span className="w-6">{fullNamestatus}</span></p>
                                    <p className="flex align-items-center justify-content-center"><b className="w-6">Status</b>        <span className="w-2">:</span><span className="w-6" style={{ fontWeight: 'bold', color: status === 'Blocked' ? '#f42616' : status === 'Pending' ? '#f7af00' : status === 'Cleared' ? 'rgb(69 160 75)' : '' }}>{status}</span></p>
                                </div>
                            </div>
                        </Dialog>

                    </>
                )}
            </div >
        </>
    )
}
export default IndividualRegisteration;







