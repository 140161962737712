import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Calendar } from 'primereact/calendar';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import 'jspdf-autotable';
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import './History.css'
import { BreadCrumb } from 'primereact/breadcrumb';
import { Triangle } from 'react-loader-spinner';
import { TbReportSearch } from "react-icons/tb";
import { Dialog } from 'primereact/dialog';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

function HistoryAudit() {
    const [data, setData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [dates2, setDates2] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [branchOptions, setBranchOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const history = useHistory();
    const toast = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mail, setEmail] = useState('');
    const [mailid, setEmailId] = useState('');
    const [mailEr, setMailEr] = useState(false);
    const [mailErs, setMailErs] = useState(false);
    const [showAccountData, setShowAccountData] = useState(false);
    const [showCorporateData, setShowCorporateData] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    // const companyid = location?.state?.companyid;
    // const email = location?.state?.emailId
    let inputRef = useRef('');
    const plan = location?.state?.getUserData?.premiumPlan;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        setLoading(true);
        const headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let uri = "/branches";

        axios.get(process.env.REACT_APP_BACKEND_HOST + uri, { headers })
            .then(response => {
                setLoading(false);
                setBranchOptions(response?.data);
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while the threshold.',
                    });
                }
            });
    }, [accessToken]);

    const handleReset = () => {
        // setBranch(null);
        setEmail('');

        setSelectedBranch(null);
        setDates2(null);
        fetchAuditHistoryData();
    };

    const handleSubmit = async () => {
        setLoading(true);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken
            },
        };

        let url = `${process.env.REACT_APP_BACKEND_HOST}/history/filter`;
        const params = new URLSearchParams();

        if (mail.trim() !== '') {
            params.append('username', mail.trim());
        }

        if (selectedBranch && selectedBranch.name) {
            params.append('branch', selectedBranch.name);
        }

        const formattedStartDate = dates2 && dates2?.length >= 2 ? dates2[0]?.toLocaleDateString('en-US') : '';
        const formattedEndDate = dates2 && dates2?.length >= 2 ? dates2[1]?.toLocaleDateString('en-US') : '';

        if (dates2) {
            params.append('startDate', formattedStartDate);
            params.append('endDate', formattedEndDate);

            // url += '?startDate=' + `${formattedStartDate}` + '&endDate=' + `${formattedEndDate}`;
        }

        url += '?' + params.toString();

        fetch(url, requestOptions)
        fetch(url, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error fetching transaction data: ' + response.status);
                }
            })
            .then(data => {
                setData(data);
                setLoading(false);
                setDates2('');
                setSelectedBranch('');
            })
            .catch(error => {
                setLoading(false);
                if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                }
                else {
                    console.error('An error occurred:', error.message);
                }
            });
    };

    useEffect(() => {
        function fetchAuditHistoryData() {
            setLoading(true);
            axios
                .get(process.env.REACT_APP_BACKEND_HOST + '/history', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken
                    },
                })
                .then(response => {
                    setData(response?.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                });
        }
        fetchAuditHistoryData();
    }, [accessToken]);

    function fetchAuditHistoryData() {
        setLoading(true);
        axios
            .get(process.env.REACT_APP_BACKEND_HOST + '/history', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken
                },
            })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                }
            });
    }

    // const formatNullableDate = (dateString) => {
    //     const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    //     const formattedDate = dateString === 'null' || !dateString ? 'N/A' : new Date(dateString).toLocaleDateString('en-GB', options);
    //     return formattedDate;
    // };

    const rowExpansionTemplate = (rowData) => {
        const formattedLoginDate = rowData?.loginDate ? new Date(rowData?.loginDate)?.toLocaleDateString('en-US') : null;
        const formattedLoginOutDate = rowData?.loginOutDate ? new Date(rowData?.loginOutDate)?.toLocaleDateString('en-US') : null;
        const formattedcreatedDate = rowData?.createdDate ? new Date(rowData?.createdDate)?.toLocaleDateString('en-US') : null;
        const formattedupdatedDate = rowData?.updatedDate ? new Date(rowData?.updatedDate)?.toLocaleDateString('en-US') : null;

        const hasAccountIdData = rowData?.accountSanctionIndividualRelationship && rowData?.accountSanctionIndividualRelationship.some(
            (item) => item.accountId
        );
        const hasCorporateIdData = rowData?.accountSanctionIndividualRelationship && rowData?.accountSanctionIndividualRelationship.some(
            (item) => item.corporateId
        );
        const getStatusColor = (status) => {
            switch (status) {
                case 'On-going':
                    return '#3746c6';
                case 'Pending':
                    return '#f7af00';
                case 'Cleared':
                    return 'rgb(69 160 75)';
                case 'Blocked':
                    return '#f42616';
                default:
                    return 'inherit';
            }
        };

        return (
            <>
                <div className=''>
                    {/* <div className='unique-row-expansion text-justify' style={{ fontSize: '14px' }}> */}
                    {rowData?.action && (rowData?.action?.includes("user logged in") || rowData?.action?.includes("logged out")) ? (
                        <div className='unique-row-expansion text-justify' style={{ fontSize: '14px' }}>
                            <>
                                <div className='col-6 avdf'>
                                    <div className='flex justify-content-center align-items-center'>
                                        <label className='col-2 font-semibold'>Login Date</label>
                                        <label className='col-1'>:</label>
                                        <label className='col-3 w-12rem '>{formattedLoginDate ? formattedLoginDate : "-"}</label>
                                    </div>
                                    <div className='flex justify-content-center align-items-center'>
                                        <label className='col-2 font-semibold'>Login Out Date</label>
                                        <label className='col-1'>:</label>
                                        <label className='col-3 w-12rem '>{formattedLoginOutDate ? formattedLoginOutDate : "-"}</label>
                                    </div>
                                </div>
                                <div className='col-5 avdf'>
                                    <div className='flex justify-content-center align-items-center'>
                                        <label className='col-2 font-semibold'>EmailId</label>
                                        <label className='col-1'>:</label>
                                        <label className='col-3 w-12rem'>{rowData?.userName ? rowData?.userName : "-"}</label>
                                    </div>
                                    <div className='flex justify-content-center align-items-center'>
                                        <label className='col-2 font-semibold'>Client Id</label>
                                        <label className='col-1'>:</label>
                                        <label className='col-3 w-12rem '>{rowData?.id ? rowData?.id : "-"}</label>
                                    </div>
                                </div>
                            </>
                        </div>
                    ) : (
                        <div className='unique-row-expansion tyuyt text-justify' style={{ fontSize: '14px' }}>

                            <>
                                <div className='col-6 avdf'>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>EmailId</label><label className='col-1'>:</label><label className='col-3 w-12rem'>{rowData?.userName ? rowData?.userName : "-"}</label></div>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>Created Date</label><label className='col-1'>:</label><label className='col-3 w-12rem'>{formattedcreatedDate ? formattedcreatedDate : "-"}</label></div>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>Updated Date</label><label className='col-1'>:</label><label className='col-3 w-12rem'>{formattedupdatedDate ? formattedupdatedDate : "-"}</label></div>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>Created User</label> <label className='col-1'>:</label> <label className='col-3 w-12rem'>{rowData?.createdUser ? rowData?.createdUser : "-"}</label></div>
                                    <div className='flex justify-content-center align-items-center abcd-reason'><label className='col-2 font-semibold'>Reason</label> <label className='col-1'>:</label> <label className='col-3 w-12rem lkjhg '>{rowData?.reason ? rowData?.reason : "-"}</label></div>
                                </div>

                                <div className='col-6 avdf'>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>Client Id</label> <label className='col-1'>:</label> <label className='col-3 w-12rem '>{rowData?.id ? rowData?.id : "-"}</label></div>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>Status</label> <label className='col-1'>:</label> <label className='col-3 w-12rem '>{rowData?.status ? rowData?.status : "-"}</label></div>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>Updated User</label> <label className='col-1'>:</label> <label className='col-3 w-12rem'>{rowData?.updateUser ? rowData?.updateUser : "-"}</label></div>
                                    <div className='flex justify-content-center align-items-center'><label className='col-2 font-semibold'>Branch Name</label> <label className='col-1'>:</label> <label className='col-3 w-12rem '>{rowData?.branchName ? rowData?.branchName : "-"}</label></div>
                                </div>
                            </>
                        </div>
                    )}
                    {/* </div> */}
                </div>
                <div className='unique-flow-table' style={{ fontSize: '18px', fontWeight: 600 }}>
                    {rowData?.accountSanctionIndividualRelationship && rowData?.accountSanctionIndividualRelationship?.length > 0 && (
                        <>
                            {hasAccountIdData && (
                                <div>
                                    <div className='unique-see-less m-2 flex justify-content-start align-items-center'>
                                        <div className='unique-show'>
                                            <div onClick={() => setShowAccountData(!showAccountData)} className='unique-show flex justify-content-center align-items-center'>
                                                {showAccountData ? '' : 'View More'}
                                                <i className={`unique-up-down pi ${showAccountData ? '' : 'pi-angle-down'}`} ></i>
                                            </div>
                                        </div>
                                    </div>
                                    {showAccountData && (
                                        <>
                                            <div className='unique-screen m-2'>Similarities Screened</div>
                                            <DataTable value={rowData.accountSanctionIndividualRelationship} className="unique-datatablee pb-3 m-2" scrollable scrollHeight={new Set(rowData?.accountSanctionIndividualRelationship?.map(item => item?.accountId?.clientId)).size > 6 ? "150px" : "300px"}>
                                                <Column field="accountId.clientId" header="Client Id" />
                                                <Column field="accountId.fullName" header="Full Name" />
                                                <Column field="accountId.screeningStatus" header="Status" style={{ fontWeight: '500' }}
                                                    body={(rowData) => (
                                                        <span style={{ color: getStatusColor(rowData?.accountId?.screeningStatus) }}>
                                                            {rowData?.accountId?.screeningStatus}
                                                        </span>
                                                    )}
                                                />
                                                <Column field="accountId.comments" header="Comments" body={(rowData) => rowData?.accountId?.comments ? rowData?.accountId?.comments : 'No Comments'} />
                                            </DataTable>
                                            <div className='unique-see-less m-2 flex justify-content-start align-items-center'>
                                                <div className='unique-show unique-margin-set'>
                                                    <div onClick={() => setShowAccountData(!showAccountData)} className='unique-show flex justify-content-center align-items-center'>
                                                        {showAccountData ? 'View Less' : ''}
                                                        <i className={`unique-up-down pi ${showAccountData ? 'pi-angle-up' : ''}`}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            {hasCorporateIdData && (
                                <div>
                                    <div className='unique-see-less m-2 flex justify-content-start align-items-center'>
                                        <div className='unique-show'>
                                            <div onClick={() => setShowCorporateData(!showCorporateData)} className='unique-show flex justify-content-center align-items-center'>
                                                {showCorporateData ? '' : 'View More'}
                                                <i className={`unique-up-down pi ${showCorporateData ? '' : 'pi-angle-down'}`}></i>
                                            </div>
                                        </div>
                                    </div>
                                    {showCorporateData && (
                                        <>
                                            <div className='unique-screen m-2'>Similarities Screened</div>
                                            <DataTable value={rowData?.accountSanctionIndividualRelationship} className="unique-datatablee pb-3 m-2" scrollable scrollHeight={new Set(rowData?.accountSanctionIndividualRelationship?.map(item => item?.corporateId?.clientId)).size > 6 ? "300px" : "125px"}>
                                                <Column field="corporateId.clientId" header="Client Id" />
                                                <Column field="corporateId.corporateName" header="Full Name" />
                                                <Column field="corporateId.amlStatus" header="Status" style={{ fontWeight: '500' }}
                                                    body={(rowData) => (
                                                        <span style={{ color: getStatusColor(rowData?.corporateId?.amlStatus) }}>
                                                            {rowData?.corporateId?.amlStatus}
                                                        </span>
                                                    )} />
                                                <Column field="corporateId.comments" header="Comments" body={(rowData) => rowData?.corporateId?.comments ? rowData?.corporateId?.comments : 'No Comments'} />
                                            </DataTable>
                                            <div className='unique-see-less m-2 flex justify-content-start align-items-center'>
                                                <div className='unique-show unique-margin-set'>
                                                    <div onClick={() => setShowCorporateData(!showCorporateData)} className='unique-show flex justify-content-center align-items-center'>
                                                        {showCorporateData ? 'View Less' : ''}
                                                        <i className={`unique-up-down pi ${showCorporateData ? 'pi-angle-up' : ''}`}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };
    const handleAccountIdChange = (selectedValue) => {
        setSelectedBranch(selectedValue);
    };

    const cols = [
        { field: 'id', header: 'Id' },
        { field: 'action', header: 'Action' },
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const handleExportToPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, data);
                doc.save('History Datas.pdf');
            });
        });
    };

    const handleExportToCSV = () => {
        const csvContent = convertToCSV(data);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'History Datas.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(','));

            for (const row of data) {
                const values = Object.values(row).join(',');
                csvRows.push(values);
            }
        }
        return csvRows.join('\n');
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'History Datas.xlsx');
    };

    const exportToXLS = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'History Datas.xls');
    };

    const [visible, setVisible] = useState(false);
    let menu = null;
    const fileInputRef = useRef(null);
    const items = [
        {
            label: 'XLS',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToXLS();
            }
        },
        {
            label: 'XLSX',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToExcel();
            }
        },
        {
            label: 'CSV',
            icon: () => <FaFileCsv />,
            command: () => {
                setVisible(false);
                handleExportToCSV();
            }
        },
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const itemsFB = [
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];


    const handleApplyFilter = () => {
        fetchData();
        hideDialogHandler();
    };

    const fetchData = async () => {

        try {
            const formattedStartDate = startDate ? format(startDate, 'MM/dd/yyyy') : null;
            const formattedEndDate = endDate ? format(endDate, 'MM/dd/yyyy') : null;
            const params = {
                username: mailid,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            };

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_HOST}/history/get/userReport`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken
                    },
                    params: params,
                }
            );

            const userDetails1 = response.data;
            // Pass userDetails1 directly to history.push
            history.push({
                pathname: '/admin/user/history/activity',
                state: { userDetails: userDetails1, getUserData: location?.state?.getUserData, accessToken: location?.state?.accessToken, emailId: location?.state?.getUserData?.emailId },
            });

        } catch (error) {
            console.error('Error fetching branch details:', error);
        }
    };


    const hideDialogHandler = () => {
        setShowDialog(false);
        setStartDate(null);
        setEndDate(null);
    };

    const opdialouge = () => {
        setShowDialog(true);
    }

    const onEndDateChange = (event) => {
        setEndDate(event.value);
    };

    const onStartDateChange = (event) => {
        setStartDate(event.value);
    };

    const reportTemplate = () => {
        opdialouge();
    };

    const toggleMenu = (event) => {
        setVisible(!visible);
        menu.toggle(event);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'text/csv') {
            setSelectedFile(file);
            handleUpload();
        } else {
            console.error('Please select a CSV file');
        }
    };

    const handleUpload = () => {
        setLoading(true);
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);

            axios.post(process.env.REACT_APP_BACKEND_HOST + '/api/audit/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + accessToken
                },
            })
                .then(response => {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'File upload successful.',
                    });
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error uploading file', error);
                    setLoading(false);
                });
        } else {
            console.error('No file selected for upload');
            setLoading(false);
        }
    };

    const handleEmailChange = (event, i) => {
        const emailValue = event.target.value.toLowerCase();
        setEmail(emailValue);
        if (!emailValue.trim()) {
            setMailEr('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailEr('Invalid email format or domain, length.');
        } else {
            setMailEr('');
        }
    };
    const handleEmailIdChange = (event, i) => {
        const emailValue = event.target.value;
        setEmailId(emailValue);
        if (!emailValue.trim()) {
            setMailErs('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailErs('Invalid email format or domain, length.');
        } else {
            setMailErs('');
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        if (!emailRegex.test(email)) {
            return false;
        }
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com');
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 20;
        return hasDotCom && isDomainLengthValid && domain !== 'gmail.com';
    };
    const isAnyFieldFilled = dates2 || selectedBranch || mail.trim();

    const item = [{ label: 'History' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }

    return (
        <>
            <div className="mainSec unique-margin-removes">
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                <BreadCrumb model={item} home={home} style={{ border: 'none' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className='condent_sec'>
                            <div className="feild feild-history c abcd gap-5 unique-margin-remove">
                                <div className='unique-his-fields flex gap-3'>
                                    <div className="input">
                                        <div className="label unique-history-label">From - To (Date)</div>
                                        <Calendar style={{ padding: 'unset' }} id="p-calender" showIcon className="w-full" onChange={(e) => setDates2(e.value)} value={dates2 || ''} selectionMode="range" />
                                    </div>
                                    <div className="input">
                                        <div className="label unique-history-label">Branch</div>
                                        <Dropdown optionLabel="name" filter filterBy="name" options={branchOptions} value={selectedBranch || ''} className="w-full p-0 unique-drops" onChange={(e) => handleAccountIdChange(e.value)} />
                                    </div>
                                    <div className="input unique-input">
                                        <div className="label unique-history-label">Email Id</div>
                                        <InputText value={mail || ''} onChange={handleEmailChange} />
                                        {mailEr && <div className="error-message">{mailEr}</div>}
                                    </div>
                                </div>
                                <div className='mob-view-hide'>
                                    <div className='flex align-items-center'>
                                        <small className="flex" style={{ color: 'rgba(200, 200, 200, 1)', fontWeight: '600' }}>Note: Fill in one or more fields for preferred results.</small>
                                        <div className='unique-footer-sec-btn flex align-items-center justify-content-end'>
                                            <Button
                                                label='Search'
                                                onClick={handleSubmit}
                                                disabled={!isAnyFieldFilled || !mail || mailEr}
                                            />
                                            <Button
                                                icon='pi pi-refresh'
                                                className='reset-btns'
                                                onClick={handleReset}
                                                disabled={!isAnyFieldFilled}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {windowWidth <= 500 && (
                                    <>
                                        <div>
                                            <div className='mob-unique-his-fields flex'>
                                                <small className="flex py-3" style={{ color: 'rgba(200, 200, 200, 1)', fontWeight: '600' }}>Note: Fill in one or more fields for preferred results.</small>
                                                <div className='unique-footer-sec-btn mob-font grid gap-2 m-0 pr-2'>
                                                    <Button
                                                        label='Search'
                                                        onClick={handleSubmit}
                                                        disabled={!isAnyFieldFilled}
                                                    />
                                                    <Button
                                                        className='reset-btns'
                                                        icon='pi pi-refresh'
                                                        iconPos='center'
                                                        onClick={handleReset}
                                                        disabled={!isAnyFieldFilled}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className='h-full abcd'>
                                    <div className='unique-search-exp-imp flex align-items-end justify-content-end' style={{ border: '1px solid #D1D1D1', backgroundColor: '#F8F9FA' }}>
                                        <div className='flex align-items-center justify-content-end m-2'>
                                            <div className=''>
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={globalFilter || ''}
                                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                                    />
                                                </span>
                                            </div>
                                            <div className='unique-footer-sec-btn mob-label-hide inline-flex'>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                    accept=".csv"
                                                />
                                                <Button
                                                    className='flex align-items-center justify-content-center'
                                                    id="b-btns-im"
                                                    tooltip="Import"
                                                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                                    onClick={() => fileInputRef.current.click()}
                                                >
                                                    <i className='pi pi-file-import'></i>
                                                </Button>
                                                <Button className=' flex align-items-center justify-content-center'
                                                    id="b-btns-ex"
                                                    tooltip="Export"
                                                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                                    onClick={toggleMenu}
                                                    disabled={data?.length === 0}
                                                >
                                                    <i className='pi pi-upload pl-1'></i></Button>
                                                <Button className=' flex align-items-center justify-content-center'
                                                    id="b-btns-ex"
                                                    tooltip="User Report"
                                                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                                                    onClick={reportTemplate}
                                                >
                                                    <TbReportSearch /></Button>
                                                <Menu
                                                    model={plan === 'FreeTrial' || plan === 'BasicAdmin' ? itemsFB : items}
                                                    popup
                                                    ref={(el) => (menu = el)}
                                                    onHide={() => setVisible(false)}
                                                    appendTo={document.body}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='unique-datatable-mob' style={{ border: '1px solid #D1D1D1', borderTop: 'none' }}>
                                        <DataTable
                                            value={data}
                                            paginator
                                            rows={10}
                                            rowsPerPageOptions={[10, 20, 35, 50]}
                                            rowExpansionTemplate={rowExpansionTemplate}
                                            // expandedRows={expandedRows}
                                            onRowToggle={onRowToggle}
                                            globalFilter={globalFilter}
                                            selection={selectedCustomers}
                                            onSelectionChange={e => setSelectedCustomers(e.value)}
                                            stripedRows={false}
                                        >
                                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                            {/* <Column expander style={{ width: '3rem' }} /> */}
                                            <Column field="id" header="ID" />
                                            <Column field="action" header="Action" />
                                            <Column field="createdUser" header="Created User" />
                                            <Column field="createdDate" header="Created Date" />
                                        </DataTable>
                                    </div>
                                    <br />
                                    <Dialog
                                        visible={showDialog}
                                        onHide={hideDialogHandler}
                                        header="User history activity Filter"
                                        className="sing_up_main_Dialog"
                                        footer={
                                            // <div className="footer_sec_btn_dialuge">
                                            //     <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" onClick={handleApplyFilter}>Apply</Button>
                                            //     <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" onClick={hideDialogHandler}>Cancel</Button>
                                            // </div>
                                            <div className="footer_sec_btn unique-data-btn" style={{ height: '70px' }}>
                                                <Button onClick={handleApplyFilter} style={{ width: '80px' }}>Apply</Button>
                                                <Button onClick={hideDialogHandler} style={{ width: '80px' }}>Cancel</Button>
                                            </div>
                                        }
                                    >
                                        <div className="feild c pt-3 pb-3 unique-fie-mob">
                                            <div className="flex">
                                                <div className="input">
                                                    <div className="label">Start Date</div>
                                                    <Calendar
                                                        style={{ padding: 'unset' }}
                                                        value={startDate}
                                                        onChange={onStartDateChange}
                                                        id="p-calender" className="w-full "
                                                        showIcon
                                                    />
                                                </div>
                                                <div className="input">
                                                    <div className="label">End Date</div>
                                                    <Calendar
                                                        style={{ padding: 'unset' }}
                                                        value={endDate}
                                                        onChange={onEndDateChange}
                                                        id="p-calender" className="w-full "
                                                        showIcon
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="input">
                                                    <div className="label">Email Id</div>
                                                    <InputText defaultValue={inputRef.current} onChange={handleEmailIdChange} />
                                                    {mailErs && <div className="error-message">{mailErs}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default HistoryAudit;