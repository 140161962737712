import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import './ForgetPass.css';
import { Toast } from 'primereact/toast';
import Logo_mob from "../../../../assets/acaia_logo/acaia_white_logo.svg";
import Logo_web from "../../../../assets/acaia_logo/acaia_dark_logo.svg"
import Iconlogo from "../../../../assets/center-acaia.png";
import "../../Loginpage/Login.css"
import { useLocation } from 'react-router-dom';

function ForgetChangePass() {

    const [newpassword, setNewPassword] = useState('');
    const [newpasswordError, setnewpasswordError] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('');
    const [confirmpasswordError, setconfirmpasswordError] = useState('');
    const history = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleNewChangepass = (event) => {
        const passwordValue = event.target.value;
        setNewPassword(passwordValue);

        if (!passwordValue.trim()) {
            // isValid = false;
            setnewpasswordError('New Password is required.');
        } else {
            let errors = [];

            if (!/[A-Z]/.test(passwordValue)) {
                errors.push('Must have at least one uppercase.');
            }
            else if (!/[a-z]/.test(passwordValue)) {
                errors.push('Must have at least one lowercase.');
            }

            else if (!/\d/.test(passwordValue)) {
                errors.push('Must have at least one number.');
            }

            else if (!/[!@#$%^&*()?]/.test(passwordValue)) {
                errors.push('Must have at least one special character');
            }

            else if (passwordValue.length < 8 || passwordValue.length > 20) {
                errors.push('Must have at least 8 to 20 characters.');
            }

            setnewpasswordError(errors.join(' '));

            if (errors.length === 0) {
                setnewpasswordError('');
            }
        }
    };

    const handleChangepass = (event) => {
        const passwordValue = event.target.value;
        setconfirmpassword(passwordValue);
        if (!passwordValue.trim()) {
            setconfirmpasswordError('Confirm Password is required.');
        } else {
            let errors = [];
            if (newpassword !== passwordValue) {
                // isValid = false;
                // setnewpasswordError('Passwords do not match.');
                // setconfirmpasswordError('Passwords do not match.');
                errors.push('The new password and confirm password do not match.');
                // toast.current.show({ severity: 'warn', summary: 'Password Mismatch', detail: 'The new password and confirm password do not match.', life: 5000 });
            }
            setconfirmpasswordError(errors.join(' '));

            if (errors.length === 0) {
                setconfirmpasswordError('');
            }
        }
    };

    const changepassword = async (event) => {
        event.preventDefault();

        const data = {
            newPassword: newpassword,
            confirmPassword: confirmpassword
        }
        setLoading(true);

        try {
            const userId = location?.state?.userId;
            const response = await axios.post(process.env.REACT_APP_IDM_BACKEND_HOST + `/users/reset_password/${userId}`, data);

            if (response.status === 200) {
                setLoading(false);
                toast.current.show({ severity: 'success', summary: 'Password Change Successfully', life: 1000 });
                setTimeout(() => {
                    history.push('/login');
                }, 1000);
            }
        } catch (error) {
            if (error?.response) {
                if (error?.response?.status === 403) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'Password Change Failed' });
            }
            setLoading(false);
            setNewPassword('');
            setconfirmpassword('');
        }
    };

    const handleBack = () => {
        history.push('/forgetPaswordchange/idpage');
    };


    useEffect(() => {
        const handleBeforePopState = () => {
            const emailId = location?.state?.emailId;
            if (!emailId) {
                console.warn("You must provide an email ID to access this page.");
                history.push('/login');
            }
        };
        window.addEventListener('popstate', handleBeforePopState);
        return () => {
            window.removeEventListener('popstate', handleBeforePopState);
        };
    }, [history,location]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    return (
        <>

            <div className="sing_up_main">
                <Toast ref={toast} />
                <div style={{ width: '-webkit-fill-available', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <div className="sing_up_main_c">
                        <div className="l_side">
                            <div className='logo_f' style={{ padding: ' 0 10px', position: 'relative', top: '-125px' }}>
                                <img src={Logo_web} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                            </div>
                            <div style={{ width: '400px', display: 'flex', justifyContent: "center" }}>
                                <img className='unique-acaia-logo-login' src={Iconlogo} alt="signup-content" style={{ width: '20rem' }} />
                            </div>
                        </div>
                        <div className="r_side back" style={{ justifyContent: 'center' }}>
                            <div className='logo_f web_hide'>
                                <img src={Logo_mob} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content',cursor:'pointer' }} alt="Acaia-content" />
                            </div>
                            <div className="feild pos forgetMargin autoH back_BTn" style={{ width: (windowWidth < 1000 ? '100%' : '90%'), gap: '20px' }}>

                                <div className='aling_DD'>

                                    <div className="flex forget" style={{ flexDirection: 'column', gap: '20px' }}>
                                        <div className="main_heading">Change Password</div>
                                        <div className="sub_heading">Enter A New Password Below To Change Your Password.</div>
                                    </div>

                                    <div className="flex forget heigt">
                                        <div className="input in">
                                            <div className="label">New Password<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <div className={`password_input fail ${newpasswordError ? 'invalid-input' : ''}`}>
                                                <InputText
                                                    type={showPassword ? 'text' : 'password'}
                                                    className={`w-full ${newpasswordError ? 'invalid-input' : ''}`}
                                                    onChange={handleNewChangepass}
                                                />
                                                <div className="cursor-pointer" onClick={togglePasswordVisibility} >
                                                    {showPassword ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye" />}
                                                </div>
                                            </div>
                                            {newpasswordError && <small className="error-message text-red-500" id='er-msg'>{newpasswordError}*</small>}
                                        </div>
                                    </div>

                                    <div className="flex forget heigt">
                                        <div className="input in">
                                            <div className="label">Confirm Password<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <div className={`password_input fail ${confirmpasswordError ? 'invalid-input' : ''}`}>
                                                <InputText
                                                    type={showPassword1 ? 'text' : 'password'}
                                                    className={`w-full ${confirmpasswordError ? 'invalid-input' : ''}`}
                                                    onChange={handleChangepass}
                                                />
                                                <div className="cursor-pointer" onClick={togglePasswordVisibility1} >
                                                    {showPassword1 ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye" />}
                                                </div>
                                            </div>
                                            {confirmpasswordError && <small className="error-message text-red-500">{confirmpasswordError}*</small>}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex_footer_btn" style={{ width: '100%', justifyContent: 'end', gap: '20px', flexDirection: 'unset' }}>
                                    <Button className="footer_Sub_Btn" style={{ background: 'none', color: '#124A99' }} onClick={handleBack}>Back</Button>
                                    <Button disabled={!newpassword || !confirmpassword || newpasswordError !== '' || confirmpasswordError !== '' || newpassword !== confirmpassword} className="footer_Sub_Btn" onClick={changepassword}>Submit</Button>
                                </div>

                                {loading &&
                                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                        <Triangle
                                            height="80"
                                            width="80"
                                            color="#124A99"
                                            ariaLabel="triangle-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetChangePass