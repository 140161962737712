import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Checkbox } from 'primereact/checkbox';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from "primereact/toast";
import { InputText } from 'primereact/inputtext';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Triangle } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import './Suspicious.css'

function Suspicious(props) {
    const [transdata, setTransdata] = useState('');
    const [, setindividual] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [, setMiddleName] = useState('');
    const [, setMiddlename] = useState('');
    const [, setMiddle] = useState('');
    const [, setFirstName] = useState('');
    const [, setFirst] = useState('');
    const [, setFirstname] = useState('');
    const [Phone, setPhone] = useState('');
    const [, setothers] = useState('');
    const [, setjob] = useState('');
    const [, setSignature] = useState('');
    const [, setAddress1] = useState('');
    const [Dateprepared, setDateprepared] = useState('');
    const [, setfulllast] = useState('');
    const [, setLastname] = useState('');
    const [DateTo, setDateTo] = useState('');
    // const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [visible, setVisible] = useState(true);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [selectedFile, setSelectedFile] = useState(null);
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const accessToken = location?.state?.accessToken;
    const fullnameRef = useRef(transdata.fullName);
    const numberRef = useRef(transdata.number);
    const accountDetailsRef = useRef(transdata.accountDetails);
    const ammountRef = useRef(transdata.totalAmount);
    const transactionRef = useRef(transdata.transactionType);
    const occupationRef = useRef(transdata.statedOccupation);
    const phoneNumberRef = useRef(transdata.phoneNumber);
    const addressRef = useRef(transdata.address);
    let inputRef = useRef('');

    useEffect(() => {
        // setTimeout(() => {
        //     setVisible(true)
        // }, 5000);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleFileView = () => {
        if (selectedFile) {
            const fileUrl = URL.createObjectURL(selectedFile);
            window.open(fileUrl, '_blank');
            URL.revokeObjectURL(fileUrl);
        }
    };

    const handleindividualChange = (event) => {
        setindividual(event.target.value);
    };
    const handleNameChange = (event) => {
        fullnameRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleMiddleNameChange = (event) => {
        setMiddleName(event.target.value);
    };
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handleAddressChange = (event) => {
        addressRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleothersChange = (event) => {
        setothers(event.target.value);
    };
    const handlePhoneNumberChange = (event) => {
        phoneNumberRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleOccupationChange = (event) => {
        occupationRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleAddress1Change = (event) => {
        setAddress1(event.target.value);
    };
    const handleSignatureChange = (event) => {
        setSignature(event.target.value);
    };
    const handleFirstChange = (event) => {
        setFirst(event.target.value);
    };
    const handleFirstnameChange = (event) => {
        setFirstname(event.target.value);
    };
    const handleMiddleChange = (event) => {
        setMiddle(event.target.value);
    };
    const handleMiddlenameChange = (event) => {
        setMiddlename(event.target.value);
    };
    const handlefulllastChange = (event) => {
        setfulllast(event.target.value);
    };
    const handleLastnameChange = (event) => {
        setLastname(event.target.value);
    };
    const handletransactionChange = (event) => {
        transactionRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleammountChange = (event) => {
        ammountRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleaccountdetailsChange = (event) => {
        accountDetailsRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleNumberChange = (event) => {
        numberRef.current = event.target.value;
        setTransdata(event.target.value);
    };
    const handleDateChange = (event) => {
        setTransdata(event.target.value);
    };
    const handleDateFromChange = (event) => {
        setTransdata(event.target.value);
    };
    const handleDateToChange = (event) => {
        setDateTo(event.target.value);
    };
    const handleDateIssueChange = (event) => {
        setTransdata(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };
    const handleDatepreparedToChange = (event) => {
        setDateprepared(event.target.value);
    };
    const handlejobChange = (event) => {
        setjob(event.target.value);
    };
    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked)
            _ingredients.push(e.value);
        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
    }
    // const [pdfUrl, setPdfUrl] = useState('');


    const items = [
        {
            label: 'Risk Check', command: () =>
                history.push({
                    pathname: '/admin/account/pending',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })
        },
        {
            label: 'Pending List', command: () =>
                history.push({
                    pathname: '/admin/account/pending',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })
        },
        {
            label: 'Transaction Details', command: () =>
                history.push({
                    pathname: '/admin/account/transationdata',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        transactionId: location?.state?.transactionId,
                        accountId: location?.state?.accountId,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })

        },
        { label: 'STR Report' },
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };


    useEffect(() => {
        const transidfordata = location?.state?.transactionId;
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/str-report/details?transactionId=' + transidfordata, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                setTransdata(response.data); // Extract the data array from the response
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                }
            });

    }, [accessToken, location?.state?.transactionId]);// eslint-disable-line react-hooks/exhaustive-deps

    const createPDF = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const section1Data = await html2canvas(document.querySelector("#section1"), {
            scale: 2,
        });
        const section1Img = section1Data.toDataURL("image/png");

        const section2Data = await html2canvas(document.querySelector("#section2"), {
            scale: 2,
        });
        const section2Img = section2Data.toDataURL("image/png");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const scalingFactor = 0.9;
        const pdfHeight = (pdfWidth * scalingFactor * section1Data.height) / section1Data.width;
        const pdfHeight2 = (pdfWidth * scalingFactor * section2Data.height) / section2Data.width;

        pdf.addImage(section1Img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.addPage();

        pdf.addImage(section2Img, "PNG", 0, 0, pdfWidth, pdfHeight2);

        return pdf;
    };

    // const handleviewpdf = async () => {
    //     const pdf = await createPDF();

    //     const pdfBlob = new Blob([pdf.output("blob")], { type: "application/pdf" });
    //     const formData = new FormData();
    //     formData.append("strReportFile", pdfBlob, "Suspicious_transaction_report.pdf");

    //     const url = URL.createObjectURL(pdfBlob);
    //     setPdfUrl(url);
    //     setShowPdfViewer(true);
    // }

    const generateEmailBody = (recipientName) => {

        return `
    Dear ${recipientName},
    
    We would like to bring to your attention a suspicious transaction that has been identified:
    
    Please find the attached Suspicious Transaction Report as requested.
    
    Best regards,
    
    ACAIA 
    `;
    };
    // const displayErrorToast = (message) => {
    //     if (toast.current) {
    //         toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    //     }
    // };
    // const displaySuccessToast = (message) => {
    //     if (toast.current) {
    //         toast.current.show({ severity: 'success', summary: 'success', detail: message, life: 5000 });
    //     }
    // };

    const hideDialog = () => {
        setVisible(false);
    }
    const handleSendMail = async () => {
        hideDialog();
        try {
            const pdf = await createPDF();
            const pdfBlob = new Blob([pdf.output("blob")], { type: "application/pdf" });
            // setLoading(false);

            const formData = new FormData();
            formData.append("attachmentFiles", selectedFile);
            formData.append("email", "pavithran.r@althisolutions.com");
            formData.append("email", "edwin.edison@althisolutions.com");
            formData.append("email", "beula.raj@althisolutions.com");
            formData.append("subject", "Suspicious Transaction Report");

            const recipientName = "Sir/Mam";
            const emailBody = generateEmailBody(recipientName);
            formData.append("body", emailBody);
            formData.append("strReportFile", pdfBlob, "Suspicious_transaction_report.pdf");
            setLoading(true);

            // const email = location?.state?.emailId;

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + accessToken
                },
                data: formData,
            };

            axios.post(process.env.REACT_APP_BACKEND_HOST + "/sendSuspiciousTransactionReport", formData, requestOptions)
                .then((response) => {
                    history.goBack();
                })
        } catch (error) {
            if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
            }
        }
    };

    const footerContent = (
        <div className='feild'>

            {loading ? (
                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <div className="footer_sec_btn input">
                        <Button className='back_BT_N' onClick={hideDialog} label='Back' />
                        {props.roleName !== "StartupAdmin" &&
                            <Button className='back_BT_N' onClick={handleSendMail} label='Send' />}
                    </div>
                </>
            )}
        </div>
    );

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="mainSec" >
            <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none', position: 'fixed', zIndex: '99', width: '-webkit-fill-available' }} />
            <Toast ref={toast} style={{ marginTop: '50px' }} />
            {loading ? (
                <div className="spin-wrapper">
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <>

                    <div className="feild" style={{ position: 'relative', top: '50px' }}>
                        <div className="flex" style={{ width: '100%' }}>
                            <div className="input">
                                <div className="label">FIU Ref</div>
                                <InputText
                                    //  value={individual} 
                                    defaultValue={inputRef.current} onChange={handleindividualChange} />
                            </div>
                            <div className="input" style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                <Button style={{ background: '#124A99', border: '#124A99' }} tooltip="Print" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={handlePrint} >Print<i className='font-bold'></i></Button>
                            </div>
                        </div>

                        <div className="flex" style={{ width: '100%' }}>
                            <div className="input">
                                <div className='ak-heade' style={{ textAlign: 'center', fontSize: '26px', color: 'rgba(63, 81, 181, 1)', fontFamily: 'primeicons' }}>
                                    <p className='font-bold'>FINANCIAL INTELLIGENCE UNIT</p>
                                </div>
                                <div className='ak-heade1' style={{ textAlign: 'center', fontSize: '16px', fontWeight: '500', fontFamily: 'primeicons' }}>
                                    <p className='text-gray-500'>SUSPICIOUS TRANSACTION REPORT (STR)</p>
                                </div>
                                <div className='ak-heade2' style={{ textAlign: 'center', fontSize: '15px' }}>
                                    <p className='text-gray-500'>(In accordance with the Anti Money Laundering and Countering the Financing o Terrori!" Act 2009)</p>
                                </div>
                            </div>
                        </div>

                        <div className='w-full bg-gray-100 py-2 px-4 text-gray-500 font-semibold ak-heade-sub' style={{ fontSize: '15px' }}>PART I - SUBJECT INFORMATION</div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label label_HEIGTH" style={{ overflow: 'auto' }}>Full name of Entity or last name of individual</div>
                                <InputText value={transdata.fullName} onChange={handleNameChange} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH">Middle Name</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH">First Name</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                        </div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label label_HEIGTH">Address</div>
                                <InputText value={transdata.address} onChange={handleAddressChange} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH">Phone Number</div>
                                <InputText className="w-full" value={transdata.phoneNumber} onChange={handlePhoneNumberChange} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH" style={{ overflow: 'auto' }}>Stated Occupation/nature of business</div>
                                <InputText className="w-full" value={transdata.statedOccupation} onChange={handleOccupationChange} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="input">
                                <div className="label">Date of birth/ Incorporation/Registration</div>
                                <InputText value={transdata.dateOfBirth} />
                            </div>
                        </div>
                        <div className="flex">
                            <div className='text-gray-500' id='sus-in'>If an individual- tick method used to verify identity: (please attach copy)</div>
                        </div>

                        <div className="flex flex_D">
                            <div className="input _C">
                                <div className="label">National Identification Card</div>
                                <Checkbox name="pizza" value="Cheese" onChange={onIngredientsChange} checked={ingredients.includes('Cheese')} />
                            </div>
                            <div className="input _C">
                                <div className="label">Passport</div>
                                <Checkbox inputId="ingredient2" name="pizza" value="Mushroom" onChange={onIngredientsChange} checked={ingredients.includes('Mushroom')} />
                            </div>
                            <div className="input _C">
                                <div className="label">Other (please specify)</div>
                                <Checkbox name="pizza" value="Pepper" onChange={onIngredientsChange} checked={ingredients.includes('Pepper')} />
                            </div>
                        </div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label">Date of issue</div>
                                <InputText value={transdata.dateOfIssue} />
                            </div>
                            <div className="input">
                                <div className="label">Number</div>
                                <InputText className="w-full" value={transdata.number} onChange={handleNumberChange} />
                            </div>
                        </div>

                        <div className='w-full bg-gray-100 py-2 px-4 text-gray-500 font-semibold ak-heade-sub' style={{ fontSize: '15px' }}>PART II - SUSPICIOUS ACTIVITY INFORMATION</div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label label_HEIGTH">Suspicious Activity Date/Range</div>
                                <InputText value={transdata.dateOfSuspiciousActivity} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH">Suspicious Activity Date/Range To</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                        </div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label">Total amount in local currency for suspicious transaction:</div>
                                <InputText value={transdata.totalAmount} onChange={handleammountChange} />
                            </div>
                            <div className="input">
                                <div className="label">Account details (including all connected accounts)</div>
                                <InputText className="w-full" value={transdata.accountDetails} onChange={handleaccountdetailsChange} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="input">
                                <div className="label">Type of transaction(s)</div>
                                <InputText className="w-full" value={transdata.transactionType} onChange={handletransactionChange} />
                            </div>
                        </div>

                        <div className='w-full bg-gray-100 py-2 px-4 text-gray-500 font-semibold ak-heade-sub' style={{ fontSize: '15px' }}>PART III - REPORTING ENTITY INFORMATION</div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label label_HEIGTH">Suspicious Activity Date/Range From</div>
                                <InputText defaultValue={inputRef.current} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH">Middle Name</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                        </div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label">First Name</div>
                                <InputText defaultValue={inputRef.current} />
                            </div>
                            <div className="input">
                                <div className="label">Address</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                        </div>

                        <div className='w-full bg-gray-100 py-2 px-4 text-gray-500 font-semibold ak-heade-sub' style={{ fontSize: '15px' }}>PART IV - SUBJECT INFORMATION</div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label">Last Name</div>
                                <InputText defaultValue={inputRef.current} />
                            </div>
                            <div className="input">
                                <div className="label">Middle Name</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                            <div className="input">
                                <div className="label">First Name</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                        </div>

                        <div className="flex flex_D">
                            <div className="input">
                                <div className="label label_HEIGTH">job title</div>
                                <InputText defaultValue={inputRef.current} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH">Phone No</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                            <div className="input">
                                <div className="label label_HEIGTH">Date report was prepared</div>
                                <InputText className="w-full" defaultValue={inputRef.current} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="input">
                                <div className="label">Signature</div>
                                <InputText defaultValue={inputRef.current} />
                            </div>
                        </div>

                        <div className='w-full bg-gray-100 py-2 px-4 text-gray-500 font-semibold ak-heade-sub' style={{ fontSize: '15px' }}>PART V - DOCUMENT UPLOAD (optional)</div>

                        <div className="flex">
                            <div className="input" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="w-6 py-4 border-round-xl text-center ak-heade-file" style={{ border: 'dashed 2px rgba(147, 159, 191, 1)', background: 'transparent' }}>
                                    <div className="flex align-items-center justify-content-center" >
                                        <div className="text-center " style={{ cursor: 'pointer', color: 'rgba(147, 159, 191, 1)' }}><i className='pi pi-upload text-xl' /></div>
                                    </div>
                                    {selectedFile && (
                                        <div>
                                            <p
                                                className="ak-heade-file1"
                                                style={{ textAlign: 'center', color: 'rgba(147, 159, 191, 1)', cursor: 'pointer' }}
                                                onClick={handleFileView}
                                            >
                                                {selectedFile.name}
                                            </p>
                                        </div>
                                    )}
                                    <p className="ak-heade-file1" style={{ textAlign: 'center', color: 'rgba(147, 159, 191, 1)' }}>Drop a File to Upload Sanction</p>
                                    <div className="w-full flex align-items-center justify-content-center mt-3">

                                        <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor="fileInput">
                                            <div className="form-btns w-7rem flex align-items-center justify-content-center py-2" id='browse-trans-btn' style={{ cursor: 'pointer', width: '100px', color: 'rgba(147, 159, 191, 1)', border: 'rgba(147, 159, 191, 1) solid 1px', borderRadius: '10px' }}>
                                                Browse
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!loading ? (
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button style={{ background: '#124A99', border: '#124A99' }} onClick={() => setVisible(true)}>Preview</Button>
                            </div>
                        ) : null}

                        {loading ? (
                            <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                <Triangle
                                    height="80"
                                    width="80"
                                    color="#124A99"
                                    ariaLabel="triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : null}

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                    </div>

                    <Dialog header="Header" className='finance-pdng-akg R_es' visible={visible} style={{ width: '60vw' }} footer={footerContent} onHide={() => setVisible(false)}>
                        <div className='w-full'>
                            <form id="pdf" className='my-4 mx-3'>
                                <div id="section1">
                                    <div className='w-full pb-4' style={{ outline: '2px solid black', backgroundColor: '#fff' }}>
                                        <div className='acaia-acknowledgement' style={{ paddingLeft: '5%', paddingRight: '5%', fontFamily: 'primeicons' }}>
                                            <div className='pndg-ackg-form-text'>
                                                <div className='flex align-items-center justify-content-between'>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p><b>FIU Ref.</b></p>
                                                        <InputText
                                                            // value={individual} 
                                                            defaultValue={inputRef.current} onChange={handleindividualChange} style={{ marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none' }} />
                                                    </span>
                                                </div>
                                                <div style={{ textAlign: 'center', fontSize: '18px', fontFamily: 'primeicons' }}>
                                                    <h1>FINANCIAL INTELLIGENCE UNIT</h1>
                                                </div>
                                                <div style={{ textAlign: 'center', fontSize: '22px', fontFamily: 'primeicons' }}>
                                                    <p>SUSPICIOUS TRANSACTION REPORT (STR)</p>
                                                </div>
                                                <div style={{ textAlign: 'center', fontSize: '18px' }}>
                                                    <p>(In accordance with the Anti Money Laundering and Countering the Financing o Terrori!" Act 2009)</p>
                                                </div>
                                                <h4 className='p-2 text-left' style={{ backgroundColor: '#d9d9d9' }}>PART I - SUBJECT INFORMATION</h4>
                                                <span style={{ display: 'flex', alignItems: 'center', fontSize: '19px', width: '100%', fontFamily: 'primeicons', flexWrap: 'wrap' }}>
                                                    <p className='w-6'>Full name of Entity or last name of individual</p>
                                                    <InputText
                                                        value={transdata.fullName}
                                                        onChange={handleNameChange} style={{ width: '100%', marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }} />
                                                </span>
                                                <div className='flex justify-content-between w-full flex_D' style={{ flexWrap: 'wrap' }}>
                                                    <span className='' style={{ width: '48%', display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p style={{ flexWrap: '70px' }}>Middle Name</p>
                                                        <InputText className='w-full' defaultValue={inputRef.current}
                                                            // value={MiddleName} 
                                                            onChange={handleMiddleNameChange} style={{ marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }} />
                                                    </span>
                                                    <span className='' style={{ width: '48%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p style={{ width: '70px' }}>First Name</p>
                                                        <InputText className='w-full' defaultValue={inputRef.current}
                                                            //  value={FirstName}
                                                            onChange={handleFirstNameChange} style={{ width: '395px', marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }} />
                                                    </span>
                                                </div>
                                                <span style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                    <p className='w-1'>Address</p>
                                                    <InputText value={transdata.address} onChange={handleAddressChange} style={{ width: '100%', marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }} />
                                                </span>
                                                <div className='flex justify-content-between w-full flex_D'>
                                                    <span className='' style={{ width: '48%', display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='w-4'>Phone Number</p>
                                                        <InputText className='w-full' value={transdata.phoneNumber} onChange={handlePhoneNumberChange} style={{ width: '40%', marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }} />
                                                    </span>
                                                    <span className='' style={{ width: '48%', display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='w-8'>Stated Occupation/nature of business</p>
                                                        <InputText className='w-full'
                                                            value={transdata.statedOccupation} onChange={handleOccupationChange} style={{ width: '280px', marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }} />
                                                    </span>
                                                </div>
                                                <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                    <p className='mr-3'>Date of birth/ Incorporation/Registration</p>
                                                    <InputText
                                                        type="date" value={transdata.dateOfBirth} onChange={handleDateChange} style={{ width: '60%', marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }}
                                                    />
                                                </span>
                                                <p style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>If an individual- tick method used to verify identity: (please attach copy)</p>
                                                <div className='flex justify-content-between w-full ff-flex-wrap flex_D' style={{ flexWrap: 'wrap' }}>
                                                    <div className='checkbox1'>
                                                        <Checkbox name="pizza" value="Cheese" onChange={onIngredientsChange} checked={ingredients.includes('Cheese')} />
                                                        <label htmlFor="ingredient1" className="ml-2 ack-check" style={{ fontSize: '19px', fontFamily: 'primeicons' }}>National Identification Card</label>
                                                    </div>
                                                    <div className='checkbox2'>
                                                        <Checkbox inputId="ingredient2" name="pizza" value="Mushroom" onChange={onIngredientsChange} checked={ingredients.includes('Mushroom')} />
                                                        <label htmlFor="ingredient2" className="ml-2 ack-check" style={{ fontSize: '19px', fontFamily: 'primeicons' }}>Passport</label>
                                                    </div>
                                                    <div className='checkbox3'>
                                                        <Checkbox name="pizza" value="Pepper" onChange={onIngredientsChange} checked={ingredients.includes('Pepper')} />
                                                        <label htmlFor="ingredient1" className="ml-2 ack-check" style={{ fontSize: '19px', fontFamily: 'primeicons' }}>Other (please specify)
                                                            <InputText
                                                                //  value={others} 
                                                                defaultValue={inputRef.current} onChange={handleothersChange}
                                                                style={{ marginLeft: '0px', padding: '5px 10px', borderBottom: '2px solid black', borderTop: 'none', borderLeft: 'none', borderRight: 'none', }} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='flex justify-content-between mt-1 flex_D'>
                                                    <span className='' style={{ width: '48%', display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='w-3'>Date of issue</p>
                                                        <InputText
                                                            type='date'
                                                            value={transdata.dateOfIssue}
                                                            onChange={handleDateIssueChange}
                                                            style={{
                                                                width: '88%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                    <span className='' style={{ width: '48%', display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='w-3'>Number</p>
                                                        <InputText

                                                            value={transdata.number}
                                                            onChange={handleNumberChange}
                                                            style={{
                                                                width: '100%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <h4 className='p-2 text-left' style={{ backgroundColor: '#d9d9d9' }}>PART II - SUSPICIOUS ACTIVITY INFORMATION</h4>
                                                <p style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', fontSize: '19px', fontFamily: 'primeicons', marginTop: '17px' }}>Date or date range of suspicious activity</p>
                                                <div className='flex justify-content-between flex_D'>
                                                    <span style={{ width: '48%', display: 'flex', alignItems: 'center', fontSize: '17px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>From</p>
                                                        <InputText
                                                            type="date"
                                                            value={transdata.dateOfSuspiciousActivity}
                                                            onChange={handleDateFromChange}
                                                            style={{
                                                                width: '100%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                    <span style={{ width: '48%', display: 'flex', alignItems: 'center', fontSize: '17px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>To</p>
                                                        <InputText
                                                            type="date"
                                                            id="dateInput3"
                                                            value={DateTo}
                                                            onChange={handleDateToChange}
                                                            style={{
                                                                width: '100%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='w-full'>
                                                    <span style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p>Total amount involved in suspicious transaction (State amount in local currency): $</p>
                                                        <InputText

                                                            value={transdata.totalAmount}
                                                            onChange={handleammountChange}
                                                            style={{
                                                                width: '35%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <span className='w-full' style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', fontSize: '19px', fontFamily: 'primeicons', marginTop: '-12px' }}>
                                                    <p className='mr-3'>Account details (include details of all connected accounts)</p>
                                                    <InputText

                                                        value={transdata.accountDetails}
                                                        onChange={handleaccountdetailsChange}
                                                        style={{
                                                            width: '40%',
                                                            marginLeft: '0px',
                                                            padding: '5px 10px',
                                                            borderBottom: '2px solid black',
                                                            borderTop: 'none',
                                                            borderLeft: 'none',
                                                            borderRight: 'none',
                                                        }}
                                                    />
                                                </span>
                                                <span className='w-full' style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', fontSize: '19px', fontFamily: 'primeicons', marginTop: '-12px' }}>
                                                    <p className=''>Type of transaction(s)</p>
                                                    <InputText

                                                        value={transdata.transactionType}
                                                        onChange={handletransactionChange}
                                                        style={{
                                                            width: '75%',
                                                            marginLeft: '0px',
                                                            padding: '5px 10px',
                                                            borderBottom: '2px solid black',
                                                            borderTop: 'none',
                                                            borderLeft: 'none',
                                                            borderRight: 'none',
                                                        }}
                                                    />
                                                </span>
                                                <h4 className='p-2 text-left' style={{ backgroundColor: '#d9d9d9' }}>PART III - REPORTING ENTITY INFORMATION</h4>
                                                <h4 style={{ fontWeight: '600' }}>Name of Reporting Entity or individual :</h4>
                                                <span className='w-full' style={{ display: 'flex', alignItems: 'center', marginLeft: '0px', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                    <p className='mr-3'>Full name of Entity or last name of individual</p>
                                                    <InputText
                                                        defaultValue={inputRef.current}
                                                        // value={fulllast}
                                                        onChange={handlefulllastChange}
                                                        style={{
                                                            width: '65%',
                                                            marginLeft: '0px',
                                                            padding: '5px 10px',
                                                            borderBottom: '2px solid black',
                                                            borderTop: 'none',
                                                            borderLeft: 'none',
                                                            borderRight: 'none',
                                                        }}
                                                    />
                                                </span>
                                                <div className='flex justify-content-between w-12 flex_D'>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons', }}>
                                                        <p className='mr-3'>Middle Name</p>
                                                        <InputText
                                                            defaultValue={inputRef.current}
                                                            // value={Middle}
                                                            onChange={handleMiddleChange}
                                                            style={{
                                                                width: '75%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>First Name</p>
                                                        <InputText
                                                            defaultValue={inputRef.current}
                                                            // value={First}
                                                            onChange={handleFirstChange}
                                                            style={{
                                                                width: '75%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                    <p className='mr-3'>Address</p>
                                                    <InputText
                                                        defaultValue={inputRef.current}
                                                        // value={Address1}
                                                        onChange={handleAddress1Change}
                                                        style={{
                                                            width: '89%',
                                                            marginLeft: '0px',
                                                            padding: '5px 10px',
                                                            borderBottom: '2px solid black',
                                                            borderTop: 'none',
                                                            borderLeft: 'none',
                                                            borderRight: 'none',
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form id="pdf1" className='m-4'>
                                <div id="section2">
                                    <div className='w-full' style={{ outline: '2px solid black', backgroundColor: '#fff' }}>
                                        <div className='pt-4 acaia-acknowledgement' style={{ paddingLeft: '5%', paddingRight: '5%', fontFamily: 'primeicons' }}>
                                            <div className='pndg-ackg-form-text'>
                                                <h4 className='p-2 text-left' style={{ backgroundColor: '#d9d9d9' }}>PART IV - COMPLIANCE OFFICER/PREPARER OF REPORT INFORMATION</h4>
                                                <div className='flex justify-content-between w-full ff-flex-wrap' style={{ flexWrap: 'wrap' }}>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>Last&nbsp;Name</p>
                                                        <InputText
                                                            defaultValue={inputRef.current}
                                                            // value={Lastname}
                                                            onChange={handleLastnameChange}
                                                            style={{
                                                                width: '70%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>Middle&nbsp;name</p>
                                                        <InputText
                                                            defaultValue={inputRef.current}
                                                            // value={Middlename}
                                                            onChange={handleMiddlenameChange}
                                                            style={{
                                                                width: '70%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>First&nbsp;name</p>
                                                        <InputText
                                                            defaultValue={inputRef.current}
                                                            // value={Firstname}
                                                            onChange={handleFirstnameChange}
                                                            style={{
                                                                width: '70%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='flex justify-content-between w-8 ff-flex-wrap' style={{ flexWrap: 'wrap' }}>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>Job&nbsp;title</p>
                                                        <InputText
                                                            defaultValue={inputRef.current}
                                                            // value={job}
                                                            onChange={handlejobChange}
                                                            style={{
                                                                width: '70%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3'>Phone No</p>
                                                        <InputText
                                                            type="number"
                                                            value={Phone}
                                                            onChange={handlePhoneChange}
                                                            style={{
                                                                width: '70%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='flex justify-content-between w-9 ff-flex-wrap' style={{ flexWrap: 'wrap' }}>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '17px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3 a1f1'>Date report was prepared</p>
                                                        <InputText
                                                            type="date"
                                                            id="dateInput4"
                                                            value={Dateprepared}
                                                            onChange={handleDatepreparedToChange}
                                                            style={{
                                                                width: '50%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                    <span className='w-full' style={{ display: 'flex', alignItems: 'center', fontSize: '19px', fontFamily: 'primeicons' }}>
                                                        <p className='mr-3 a1f1'>Signature</p>
                                                        <InputText
                                                            defaultValue={inputRef.current}
                                                            // value={Signature}
                                                            onChange={handleSignatureChange}
                                                            style={{
                                                                width: '80%',
                                                                marginLeft: '0px',
                                                                padding: '5px 10px',
                                                                borderBottom: '2px solid black',
                                                                borderTop: 'none',
                                                                borderLeft: 'none',
                                                                borderRight: 'none',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <div>
                                                    <p style={{ textAlign: 'right', marginTop: '20px' }}>Stamp/Seal of Reporting Entity if any:</p>
                                                </div>

                                                <br />
                                                <h4 className='p-2 text-left' style={{ backgroundColor: '#d9d9d9' }}>PART V - SUSPICIOUS TRANSACTION(S) DESCRIPTION</h4>
                                                <div style={{ outline: '2px solid black' }}>
                                                    <br />
                                                    <p style={{ fontSize: '19px', marginLeft: '10px' }}><b>Description of suspicious transaction(s)</b> This section of the report is <b>essential</b>.   Provide a clear, complete and chronological description of the transaction(s). Including what is unu!ual, irregular, or suspicious about the transaction(s). using the checklist below <b>as a guide</b> as you prepare your report</p>
                                                    <h3 style={{ fontSize: '19px', marginLeft: '10px' }}>Describe</h3>
                                                    <p style={{ fontSize: '19px', marginLeft: '30px' }}>(i)     The conduct that raised suspicion; and</p>
                                                    <p style={{ fontSize: '19px', marginLeft: '30px' }}>(ii)    The supporting documentation.</p>
                                                    <h3 style={{ fontSize: '19px', marginLeft: '10px' }}>Explain</h3>
                                                    <p style={{ fontSize: '19px', marginLeft: '30px' }}>(i)     Whether the transaction(s) was completed or only attempted; and</p>
                                                    <p style={{ fontSize: '19px', marginLeft: '30px' }}>(ii)    Who benefited, financially or otherwise, from the transaction(s).</p>
                                                    <h3 style={{ fontSize: '19px', marginLeft: '10px' }}>Indicate</h3>
                                                    <p style={{ fontSize: '19px', marginLeft: '30px' }}>(i)     Whether any information has been excluded from this report and why;</p>
                                                    <p style={{ fontSize: '19px', marginLeft: '30px' }}>(ii)    Whether the suspicious transaction is an isolated incident or relates to another transactions; and</p>
                                                    <p style={{ fontSize: '19px', marginLeft: '30px' }}>(iii)  (If the reporting entity is a financial institution) any additional account number and any domestic or foreign bank account number which may be involved.</p>
                                                    <br /><br /><br />
                                                </div>
                                                <br />
                                                <p style={{ textAlign: 'center', fontSize: '22px', marginTop: '5px' }}>Send completed report and associated documents to:</p>
                                                <b><p style={{ textAlign: 'center', fontSize: '22px' }}>The Director </p>
                                                    <p style={{ textAlign: 'center', fontSize: '22px' }}>Financial Intelligence Unit</p>
                                                    <p style={{ textAlign: 'center', fontSize: '22px' }}>Ministry of Finance Compound</p>
                                                    <p style={{ textAlign: 'center', fontSize: '22px' }}>49 Main & Urquhart Streets</p>
                                                    <p style={{ textAlign: 'center', fontSize: '22px' }}>Georgetown, Guyana</p>
                                                    <p style={{ textAlign: 'center', fontSize: '24px' }}>OR</p>
                                                    <p className='pb-2' style={{ textAlign: 'center', fontSize: '24px' }}>fiuguyana@gmail.com</p>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form >
                        </div>
                    </Dialog>
                </>

            )}
        </div >
    )
}

export default Suspicious
