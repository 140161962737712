import React, { useState, useRef, useEffect } from 'react';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import idmaxios from '../../../../src/axios/idmaxios';
import Logo_web from "../../../assets/acaia_logo/acaia_dark_logo.svg"
import Logo_mob from "../../../assets/acaia_logo/acaia_white_logo.svg";
import Iconlogo from "../../../assets/center-acaia.png";
import '../../../views/Loading.css';
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
// import SubHeader from '../../../layouts/SubHeader/SubHeader';
import "../Loginpage/Login.css";

function Login() {
    let passRef = useRef('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // console.clear();
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSignOut = () => {
        // const email = sessionStorage.getItem("email");
        fetch(process.env.REACT_APP_IDM_BACKEND_HOST + "/reload/reload/" + email, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        // sessionStorage.removeItem('access_token');
    }

    async function handleLogin(event) {
        event.preventDefault();
        let isValid = true;
        setEmailError('');
        setPasswordError('');

        if (!email.trim()) {
            isValid = false;
            setEmailError('Email is required.');
        }
        else if (!isValidEmail(email)) {
            isValid = false;
            setEmailError('Invalid email format or domain.');
        }

        if (!password.trim()) {
            isValid = false;
            setPasswordError('Password is required.');
        }

        if (!isValid) {
            return;
        }

        setLoading(true);

        const encryptedEmail = encrypt(email);
        const encryptedPassword = encrypt(password);

        const data = {
            emailId: encryptedEmail,
            password: encryptedPassword,
        }

        axios.post(process.env.REACT_APP_IDM_BACKEND_HOST + '/sign_in', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(async response => {
                const accessToken = response.data.access_token;
                const refreshToken = response.data.refresh_token + '_50';
                sessionStorage.setItem('ref_Key', refreshToken);
                try {
                    const [profileResponse, validateResponse] = await Promise.all([
                        idmaxios.get('token_info/' + accessToken),
                        axios.get(process.env.REACT_APP_BACKEND_HOST + `/get`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${accessToken}`
                            }
                        })
                    ]);
                    const roleName = profileResponse?.data?.roles[0]?.roleName;

                    if (roleName === 'FreeTrial') {
                        if (profileResponse?.data?.phoneNumber) {
                            history.push({
                                pathname: '/admin/dashboard',
                                state: {
                                    accessToken: accessToken,
                                    refreshToken: refreshToken,
                                    emailId: validateResponse?.data?.emailId,
                                    roleName: roleName,
                                    profile_DP: validateResponse?.data?.data
                                },
                            });
                        } else {
                            history.push({
                                pathname: '/additionaldetails',
                                state: {
                                    captureMail: validateResponse?.data?.emailId,
                                    captureAccessToken: accessToken,
                                    captureRefreshToken: refreshToken,
                                    password: password,
                                    roleName: roleName,
                                    type: 'sign_In',
                                    plan: validateResponse?.data?.premiumPlan,
                                    profile_DP: validateResponse?.data?.data
                                },
                            });
                        }

                    } else {
                        toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Login Successfully' });
                        if (profileResponse?.data?.phoneNumber) {
                            setTimeout(() => {
                                history.push({
                                    pathname: '/admin/dashboard',
                                    state: {
                                        accessToken: accessToken,
                                        refreshToken: refreshToken,
                                        emailId: validateResponse?.data?.emailId,
                                        roleName: roleName,
                                        profile_DP: validateResponse?.data?.data,
                                        type: 'sign_In'
                                    },
                                });
                            }, 1000);
                        } else {
                            history.push({
                                pathname: '/additionaldetails',
                                state: {
                                    captureMail: validateResponse?.data?.emailId,
                                    captureAccessToken: accessToken,
                                    captureRefreshToken: refreshToken,
                                    password: password,
                                    roleName: roleName,
                                    profile_DP: validateResponse?.data?.data,
                                    type: 'sign_In'
                                },
                            });
                        }
                    }
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Invalid access token', detail: 'Please login again' });
                    setLoading(false);
                    if (error?.response?.data?.message === 'Payment not done...Please make a payment and login') {
                        history.push({
                            pathname: '/payment',
                            state: {
                                emailId: email,
                                password: password,
                                accessToken: accessToken,
                                refreshToken: refreshToken,
                                type: 'sign_In',
                                noD: null,
                            },
                        });
                    } else {
                        handleSignOut();
                    }
                }
            })

            .catch(error => {
                setLoading(false);
                if (error?.response && error?.response?.data && error?.response?.data?.message === 'Your subscription has expired.') {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: errorMessage, life: 3000 });
                    setTimeout(() => {
                        history.push({
                            pathname: '/payment',
                            state: {
                                emailId: email,
                                password: password,
                                type: 'sign_In',
                            },
                        });
                    }, 5000);
                    handleSignOut();
                } else if (error?.response && error?.response?.data && error?.response?.data?.message === 'Payment not done...Please make a payment and login') {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: errorMessage, life: 3000 });
                    setTimeout(() => {
                        history.push({
                            pathname: '/payment',
                            state: {
                                emailId: email,
                                password: password,
                            },
                        });
                    }, 5000);
                    handleSignOut();
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                    handleSignOut();
                }
                if (error.config.url.includes('/get')) {

                } else {
                    if (error?.message === 'Network Error') {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: error?.message, life: 5000 });
                    }
                    setLoading(false);
                }
            });
    }

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 2000 });
    };

    const encrypt = (data) => {
        const key = CryptoJS.enc.Utf8.parse('acaiaalthi0001uu'); // 16, 24, or 32 bytes key
        const iv = CryptoJS.enc.Utf8.parse('qwertyuioplkjhgf'); // 16 bytes IV
        const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
        return encrypted.toString();
    };

    const isValidEmail = (email) => {
        const emailFormatRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        // const emailFormatRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        // const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailFormatRegex.test(email)) {
            return false;
        }

        // Check if the domain is not "gmail.com"
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com');
        // return hasDotCom && domain !== 'gmail.com';
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 20;

        return hasDotCom && isDomainLengthValid && domain !== 'gmail.com';
    };

    const handleEmailChange = (event) => {
        const emailValue = event.target.value.toLowerCase();
        setEmail(emailValue);

        if (!emailValue.trim()) {
            setEmailError('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setEmailError('	Invalid email');
        } else {
            setEmailError('');
        }
    };

    const handlePasswordChange = (event) => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);

        if (!passwordValue.trim()) {
            setPasswordError('Password is required.');
        } else {
            let errors = [];

            if (!/[A-Z]/.test(passwordValue)) {
                errors.push('Must have at least one uppercase.');
            }
            else if (!/[a-z]/.test(passwordValue)) {
                errors.push('Must have at least one lowercase.');
            }

            else if (!/\d/.test(passwordValue)) {
                errors.push('Must have at least one number.');
            }

            else if (!/[!@#$%^&*()?]/.test(passwordValue)) {
                errors.push('Must have at least one special character');
            }

            else if (passwordValue.length < 8 || passwordValue.length > 20) {
                errors.push('Must have at least 8 characters');
            }

            setPasswordError(errors.join(' '));

            if (errors.length === 0) {
                setPasswordError('');
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const forgetpas = (event) => {
        event.preventDefault();
        history.push({
            pathname: '/forgetPaswordchange/idpage',
            state: { emailId: email },
        });
    }

    return (
        <>
            <div className="sing_up_main">
                <Toast ref={toast} />
                {/* <SubHeader /> */}
                <div style={{ width: '-webkit-fill-available', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <div className="sing_up_main_c">
                        <div className="l_side">
                            <div className='logo_f' style={{ padding: ' 0 10px', position: 'relative', top: '-125px' }}>
                                <img src={Logo_web} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                            </div>
                            <div style={{ width: '400px', display: 'flex', justifyContent: "center" }}>
                                <img className='unique-acaia-logo-login' src={Iconlogo} alt="signup-content" style={{ width: '20rem' }} />
                            </div>
                        </div>
                        <div className="r_side back" style={{ justifyContent: 'center', gap: windowWidth < 600 ? '15px' : '30px' }}>
                            <div className='logo_f web_hide'>
                                <img src={Logo_mob} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                            </div>
                            <div className="feild forgetMargin autoH pos" style={{ width: (windowWidth < 1000 ? '100%' : '90%') }}>
                                <div className="flex forget " style={{ flexDirection: 'column', height: '80px', gap: '20px' }}>
                                    <div className="main_heading">Welcome Back!</div>
                                    <div className="sub_heading" id='sub-heading'>Login To Continue</div>
                                </div>

                                <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: "column", gap: '10px' }}>
                                    <div className="flex forget autoH">
                                        <div className="input in">
                                            <div className="label">	Business Email<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <InputText
                                                type="text"
                                                className={` w-full ${emailError ? 'invalid-input' : ''}`}
                                                value={email}
                                                onChange={handleEmailChange}
                                            />
                                            {emailError && <small className="errors-messages text-red-500">{emailError}*</small>}

                                        </div>
                                    </div>

                                    <div className="flex forget autoH">
                                        <div className="input in">
                                            <div className="label">Password<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                            <div className={`password_input fail ${passwordError ? 'invalid-input' : ''}`}>
                                                <InputText
                                                    defaultValue={passRef.current}
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={handlePasswordChange}
                                                    fdprocessedid="2489e8"
                                                    // autocomplete="off"
                                                    data-val="true"
                                                    data-val-length="Must be between 5 and 20 characters"
                                                    data-val-length-max="20"
                                                    data-val-length-min="5"
                                                    data-val-required="Password is required !!"
                                                    name="_Password"
                                                />
                                                <div className="cursor-pointer" onClick={togglePasswordVisibility} >
                                                    {showPassword ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye" />}
                                                </div>
                                            </div>
                                            {passwordError && <small className="errors-messages text-red-500">{passwordError}*</small>}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex forget autoH" style={{ justifyContent: 'end' }}><span onClick={forgetpas} style={{ fontSize: '14px', color: 'rgb(69, 128, 194)', fontWeight: 'bold', cursor: 'pointer' }} className='cursor-pointer'>Forget Password..?</span></div>

                                <div className="flex_footer_btn" style={{ width: '100%', gap: windowWidth <= 500 ? '5px' : '' }}>
                                    <div className="flex_footer_btn_sub" style={{ alignItems: windowWidth <= 500 ? 'center' : '' }}>
                                        <div className="f_L_Btn">
                                            <div className="terms-condition" style={{ width: 'fit-content', cursor: 'pointer', gap: '5px', flexWrap: 'wrap' }} onClick={() => history.push('/signup')}> Create A New Account ?<span style={{ color: 'rgb(69, 128, 194)', fontWeight: 'bold', fontSize: '14px', width: '90px' }}>Signup</span></div>
                                        </div>
                                    </div>
                                    <Button className="footer_Sub_Btn" onClick={handleLogin}>Login</Button>
                                </div>

                                {loading &&
                                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                        <Triangle
                                            height="80"
                                            width="80"
                                            color="#124A99"
                                            ariaLabel="triangle-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;