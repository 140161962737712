import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { useHistory } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
// import { TbEdit } from "react-icons/tb";
import { Triangle } from 'react-loader-spinner';
import { BiSolidCloudUpload } from 'react-icons/bi';
import { Card } from 'primereact/card';
import { useLocation } from 'react-router-dom';

function CorpoRiskreason(props) {

  const [accountdetialss, setAccountDetialss] = useState('');
  const [statusoption, setSelectedOption] = useState('');
  const [comments, setComments] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const toast = React.useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const accessToken = location?.state?.accessToken;
  const client_id = location?.state?.corpoRiskReasonClientId || sessionStorage?.corpoRiskReasonClientId;
  const refreshToken = location?.state?.refreshToken;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST + `/accounts/pending?acc_client_id=` + client_id,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        }
      )
      .then(response => {
        if (response && response.data) {
          setAccountDetialss(response.data); // Extract the data array from the response
        }
        setLoading(false);
      })
      .catch(error => {
        if (error?.response && error?.response?.data && error?.response?.data?.message) {
          const errorMessage = error?.response?.data?.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
        } else if (error?.response && error?.response?.status === 403) {
          window.location.assign(process.env.REACT_APP_AUTH_URL);
        } else if (error?.response?.status === 401) {
          toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
        }
      });
  }, [accessToken, client_id]);

  // const beneficiarytable = accountdetialss?.sanctions || [];
  // const beneficiarytables = accountdetialss?.accounts;

  const actionbody = (rowData) => {
    const { sanctionRelationshipId, account } = rowData;
    sessionStorage.setItem('sanctionRelationshipId', sanctionRelationshipId);
    sessionStorage.setItem('account', account);

    const nextpageWithSessionStorage = () => {
      history?.push({
        pathname: '/admin/account/corporateaccountprofilingmoreinfo',
        state: {
          accessToken: accessToken,
          sanctionRelationshipId: sanctionRelationshipId,
          account: account,
          storedId: location?.state?.corporateid,
          corporateid: location?.state?.corporateid,
          getUserData: location?.state?.getUserData,
          userAllData: location?.state?.userAllData,
          corpoRiskReasonClientId: location?.state?.corpoRiskReasonClientId,
          refreshToken: refreshToken,
        },
      })

    };

    return (
      <React.Fragment>
        <Button icon="pi pi-info-circle" className="p-button-rounded p-button-secondary p-mr-2 " style={{ backgroundColor: 'transparent', border: 'none' }} onClick={nextpageWithSessionStorage} id='MI' />
      </React.Fragment>
    );
  };

  const [visible, setVisible] = useState(false);

  const hideDialog = () => {
    setVisible(false);
  };

  const statusoptions = [
    { name: 'On-going', value: 'On-going' },
    { name: 'Cleared', value: 'Cleared' },
    { name: 'Blocked', value: 'Blocked' }

  ];

  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

  };

  const profileTemplate = (rowData) => {
    const status = rowData.screeningStatus;
    let color = '';

    if (status === 'Pending') {
      color = '#f7af00';
    } else if (status === 'Blocked') {
      color = '#f42616';
    } else if (status === 'Cleared') {
      color = 'rgb(69 160 75)';
    } else if (status === 'On-going') {
      color = '#3746c6';
    }

    return (
      <div style={{
        color: color,
        fontWeight: '500',
      }}>
        {status}
      </div>
    );
  };

  const update = (event) => {

    event.preventDefault()
    setSubmitted(true);

    if (
      !statusoption
    ) {
      return;
    }
    let updateid = accountdetialss.clientId;
    let bodyvalue = {
      status: statusoption,
      comment: comments,
      sanctionIds: selectedItems.reduce((acc, item) => {
        acc[item.sanctionRelationshipId] = true;
        return acc;
      }, {}),
    }


    axios.put(process.env.REACT_APP_BACKEND_HOST + '/accounts/pending/sanction?client_id=' + updateid, bodyvalue, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      }
    })
      .then(response => {
        if (response.status === 200) {
          hideDialog();
          history.push({
            pathname: '/admin/account/corporateprofiling',
            state: {
              accessToken: accessToken,
              emailId: location?.state?.emailId,
              getUserData: location?.state?.getUserData,
              corporateid: location?.state?.corporateid,
              individualid: location?.state?.individualid,
              accountprofilingid: location?.state?.accountprofilingid,
              userAllData: location?.state?.userAllData,
              refreshToken: refreshToken,
            },
          });
        }
      })
      .catch(error => {
        if (error?.response && error?.response?.data && error?.response?.data?.message) {
          const errorMessage = error?.response?.data?.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
        } else if (error?.response && error?.response?.status === 403) {
          window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);

        } else if (error?.response?.status === 401) {
          toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
        }
        else if (error?.response?.status === 400) {
          history.push({
            pathname: '/admin/account/corporateprofiling',
            state: {
              accessToken: accessToken,
              emailId: location?.state?.emailId,
              getUserData: location?.state?.getUserData,
              corporateid: location?.state?.corporateid,
              individualid: location?.state?.individualid,
              accountprofilingid: location?.state?.accountprofilingid,
              userAllData: location?.state?.userAllData,
              refreshToken: refreshToken,
            },
          });
        }
      });

  }

  const [file, setFile] = useState(null);

  const handleFileChange = async (event) => {
    setFile(event.target.files[0]);
    await handleUpload();
  };

  const handleUpload = async () => {
    try {
      const corporateId = location?.state?.corporateid;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('corporateId', corporateId);

      await axios.post(
        process.env.REACT_APP_BACKEND_HOST + `/accounts/upload/file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + accessToken,
          },
        }
      );
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const updateaccdet = () => {
    if (selectedItems.length === 0) {
      toast.current.show({ severity: "warn", summary: "No Checkbox Selected", detail: "Please select similarities screened is at leaste one item.", life: 3000 });
    } else {
      setVisible(true);
    }
  }

  const items = [
    {
      label: 'Account Search', command: () =>
        history.push({
          pathname: '/admin/account/search',
          state: {
            accessToken: accessToken,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
            refreshToken: refreshToken,
          },
        })
    },
    {
      label: 'Corporate Profiling', command: () =>
        history.push({
          pathname: `/admin/account/corporateprofiling`,
          state: {
            accessToken: accessToken,
            storedId: location?.state?.corporateid,
            getUserData: location?.state?.getUserData,
            corporateid: location?.state?.corporateid,
            individualid: location?.state?.individualid,
            userAllData: location?.state?.userAllData,
            refreshToken: refreshToken,
          },
        }),
    },
    { label: 'Corporate Details' },
  ];

  const home = {
    icon: 'pi pi-home', command: () =>
      history.push({
        pathname: '/admin/dashboard',
        state: {
          accessToken: location?.state?.accessToken,
          emailId: location?.state?.emailId,
          getUserData: location?.state?.getUserData,
          refreshToken: refreshToken,
        },
      })
  }
  const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

  const highlightMatchType = (exactName) => {
    return exactName.map((name, index) => (
      <span key={index} style={{ backgroundColor: name.matchType === 'bg-orange-500' ? '#fef2c2' : 'transparent' }}>
        {name.name}{' '}
      </span>
    ));
  };

  return (
    <>

      <div className="mainSec" >
        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
        <Toast ref={toast} style={{ marginTop: '3%' }} />

        {loading ? (
          <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
            <Triangle
              height="80"
              width="80"
              color="#124A99"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <Card className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
              <div style={{ display: 'flex', height: '50px', justifyContent: 'end', alignItems: 'center' }}>
                <Button className='back_BT_N' label='Edit' onClick={updateaccdet} />
              </div>

              <div className='h_ing'>
                Account Details
              </div>
              <br />

              {accountdetialss?.fullName &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>Full Name <span>:</span></div>
                  <div className='value'>{accountdetialss?.fullName || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.arabicName &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>Arabic Name <span>:</span></div>
                  <div className='value'>{accountdetialss?.arabicName || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.idNumber &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>Registration <br /> Number <span>:</span></div>
                  <div className='value'>{accountdetialss?.idNumber || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.city &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>City <span>:</span></div>
                  <div className='value'>{accountdetialss?.city || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.country &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>Country <span>:</span></div>
                  <div className='value'>{accountdetialss?.country || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.dob &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>Date Of Birth <span>:</span></div>
                  <div className='value'>{accountdetialss?.dob || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.postalCode &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>Postal Code <span>:</span></div>
                  <div className='value'>{accountdetialss?.postalCode || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.address &&
                <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <div className='leable' style={{ minWidth: '180px', display: 'flex', justifyContent: 'space-between' }}>Address <span>:</span></div>
                  <div className='value' style={{ color: '#7f7878' }}>{accountdetialss?.address || 'N/A'}</div>
                </div>
              }

              {accountdetialss?.sanctions?.length >= 1 || accountdetialss?.accounts?.length >= 1 ?
                <>
                  <br />
                  <div className='h_ing'>
                    Similarities Screened
                  </div>
                  <br />
                </>
                : null}

              <div style={{ padding: accountdetialss?.sanctions?.length >= 1 ? '20px 0' : '' }}>
                {accountdetialss?.sanctions?.length >= 1 ? (

                  <DataTable
                    value={accountdetialss?.sanctions}
                    stripedRows
                    showGridlines
                    paginator
                    selectionMode="multiple" selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {props.roleName === "ProAdmin" && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}
                    <Column field="sanctionIndividual.fullName" bodyClassName="" header="Full Name" body={(rowData) => highlightMatchType(rowData.sanctionIndividual.exactName)}></Column>
                    <Column field="category" bodyClassName="" header="Category"></Column>
                    <Column field="comments" bodyClassName="" header="Comments"></Column>
                    <Column field="screeningStatus" bodyClassName="" header="Status" body={profileTemplate} ></Column>
                    <Column field="moreinfo" bodyClassName="" header="More Info" body={actionbody}></Column>
                  </DataTable>
                ) : (
                  (accountdetialss?.accounts || []).map(account => (
                    <DataTable
                      key={account.id}
                      value={account.sanctions || []}
                      stripedRows
                      showGridlines
                      paginator
                      selectionMode="multiple" selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                      rows={5}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {props.roleName === "ProAdmin" && <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}
                      <Column field="sanctionIndividual.fullName" bodyClassName="" header="Full Name" body={(rowData) => highlightMatchType(rowData.sanctionIndividual.exactName)}></Column>
                      <Column field="category" bodyClassName="" header="Category"></Column>
                      <Column field="comments" bodyClassName="" header="Comments"></Column>
                      <Column field="screeningStatus" bodyClassName="" header="Status" body={profileTemplate} ></Column>
                      <Column field="moreinfo" bodyClassName="" header="More Info" body={actionbody}></Column>
                    </DataTable>
                  ))
                )}
              </div>
            </Card>

            <Dialog visible={visible} header="Update Corporate Account Pending" modal style={{ width: '40vw' }} className="sing_up_main_Dialog" onHide={hideDialog}>
              <form onSubmit={update}>
                <div className=" overflow-hidden" id="advanced-ahoc-rect" style={{ overflow: 'auto' }}>
                  <div className="feild c pt-3 pb-3">
                    <div className="flex" style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="input">
                        <div className="label">Status<span>*</span></div>
                        <Dropdown onChange={handleStatusChange} options={statusoptions} value={statusoption} className={classNames({ 'p-invalid': submitted && !statusoptions }, 'w-full p-0 unique-drops')} optionLabel="name" />
                        {submitted && !statusoption && <small className="p-error">Status is required.</small>}
                      </div>
                      <div className="input">
                        <div className="label">Comments<span>*</span></div>
                        <InputTextarea rows={1} cols={2} className="w-full pl-0 unique-drops" onChange={(event) => setComments(event.target.value)} />
                      </div>
                    </div>
                    {/* <div className="flex">
                      <div className="input">
                        <div className="label">
                          <div className="text-center text-7xl" id="file-upload-icon" style={{ cursor: 'pointer' }}><BiSolidCloudUpload /></div>
                          <em className="text-xs">{file ? file.name : ''}</em>
                        </div>
                        <input type="file" id="fileInput" onChange={handleFileChange} />
                      </div>
                    </div> */}
                    <div className="footer_sec_btn_dialuge">
                      <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" type='submit'>Update</Button>
                    </div>
                  </div>
                </div>
              </form>
            </Dialog>
          </>
        )}
      </div >
    </>
  )
}
export default CorpoRiskreason;