import React, { useRef, useState, useEffect, useCallback } from "react";
import { Button } from 'primereact/button';
import axios from 'axios';
import { Toast } from "primereact/toast";
import "../../../views/Loading.css";
import { InputNumber } from 'primereact/inputnumber';
import './Criteria-alert.css';
import { Knob } from 'primereact/knob';
import DoughnutChartComponent from './DoughnutChartComponent';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { FaQuestion } from "react-icons/fa6";
import { Dropdown } from 'primereact/dropdown';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';

export const MonitoringThresholdsListTable = (props) => {

    const [chartKey, setChartKey] = useState(0);
    const [country, setCountry] = useState('');
    // const [showInputNumber, setShowInputNumber] = useState(false);
    const [countryDetails, setCountryDetails] = useState([]);
    const [countrydata, setCountrydata] = useState([]);
    const [limit, setLimit] = useState();
    const [limitDetails, setLimitDetails] = useState({});
    // const [apiData, setApiData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [designation, setDesignation] = useState();
    const [designationDetails, setDesignationDetails] = useState(null);
    const [designationOptions, setDesignationOptions] = useState([]);
    // const [selectedAccountId, setSelectedAccountId] = useState('');
    const [thresholds, setThresholds] = useState([]);
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const [btnloading, setBtnLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const accessToken = props?.data?.accessToken;
    const [ids, setIds] = useState(false);
    const location = useLocation();

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const load = () => {
        setBtnLoading(true);

        setTimeout(() => {
            setBtnLoading(false);
        }, 5000);
    };

    const toast = useRef(null);

    const fetchThresholds = useCallback(() => {
        setLoading(true);
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        axios.get(`${process.env.REACT_APP_BACKEND_HOST}/thresholds`, requestOptions)
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setThresholds(response.data);
                    setIds(response.data[0].id);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching scores:", error);
                setLoading(false);
                if (error?.response && error?.response?.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while fetching the threshold.',
                    });
                }
            });
    }, [accessToken, toast]);

    const handleUpdate = (id, updatedThreshold) => {
        setLoading(true);
        load();
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
        };

        axios.put(`${process.env.REACT_APP_BACKEND_HOST}/thresholds/${ids}`, updatedThreshold, requestOptions)
            .then((response) => {
                setThresholds(response.data);
                setLoading(false);
                fetchThresholds();
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Threshold with ID ${id} updated Successfully.`,
                });
            })
            .catch((error) => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while updating the threshold.',
                    });
                }
            });
    };

    useEffect(() => {
        fetchThresholds();
    }, [fetchThresholds]);

    useEffect(() => {
        setLoading(true);
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let uri = `/designations?designation=${designation}`;
        axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions)
            .then(response => {
                setDesignationDetails(response.data);
                setLoading(false);

            })
            .catch(error => {
                if (error?.response) {
                    if (error?.response?.status === 401) {
                        toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                    } else if (error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                }
                setLoading(false);
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while the threshold.',
                    });
                }
            });
    }, [designation, accessToken]);

    const handleLimitSelectChange = (event) => {
        const selectedLimit = event.target.value;

        if (selectedLimit) {
            setLimit(selectedLimit);
        }
    };

    useEffect(() => {
        if (limit) {
            setLoading(true);
            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            let uri = `/byIncomeRange?incomeRange=${limit}`;
            axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions)
                .then(response => {
                    setLimitDetails(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    if (error?.response) {
                        if (error?.response?.status === 401) {
                            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                        } else if (error?.response && error?.response?.status === 403) {
                            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                        }
                    }
                    setLoading(false);
                    if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        toast.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: errorMessage,
                        });
                    } else {
                        toast.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'An error occurred while the threshold.',
                        });
                    }
                });
        }
    }, [limit, accessToken]);

    useEffect(() => {
        setLoading(true);
        if (country) {
            const requestOptions2 = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            };
            // const selectedCountry = countrydata.find(item => item.country.laCountryName === country);

            let uri2 = `/country/get`;
            // let uri2 = `/all/countries/${selectedCountry.id}/${companyid}`;
            axios.get(process.env.REACT_APP_BACKEND_HOST + uri2, requestOptions2)
                .then(response => {
                    setCountryDetails(response.data);
                    setLoading(false);
                    // setShowInputNumber(true);
                })
                .catch(error => {
                    if (error?.response) {
                        if (error?.response?.status === 401) {
                            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                        } else if (error?.response?.status === 403) {
                            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                        }
                    }
                    if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        toast.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: errorMessage,
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            // setShowInputNumber(false);
            setLoading(false);
        }
    }, [country, accessToken]);

    useEffect(() => {
        setLoading(true);
        const headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let uri = '/country';

        axios.get(process.env.REACT_APP_BACKEND_HOST + uri, { headers })
            .then(response => {
                setLoading(false);
                setCountrydata(response.data);
            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while the threshold.',
                    });
                }
            });
    }, [accessToken]);

    useEffect(() => {
        setLoading(true);
        const headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let uri = "/designation";

        axios.get(process.env.REACT_APP_BACKEND_HOST + uri, { headers })
            .then(response => {
                setDesignationOptions(response.data);
                setLoading(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while the threshold.',
                    });
                }
            });
    }, [accessToken]);

    const handleSubmits = async (e) => {
        e.preventDefault();
        load();
        setLoading(true);

        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        const selectedCountry = countrydata.find(item => item.country.laCountryName === country);

        let uri = `/countries/${selectedCountry.id}`;
        const updatedCountryDetails = {
            sanctionScore: countryDetails.sanctionScore
        };

        try {
            const response = await axios.put(process.env.REACT_APP_BACKEND_HOST + uri, updatedCountryDetails, requestOptions);

            if (response.status === 403) {
                window.location.assign(process.env.REACT_APP_AUTH_URL);
            }

            setCountryDetails(response.data);
            setCountry('');
            setCountryDetails([]);
            setChartKey(prevKey => prevKey + 1);

        } catch (error) {
            setLoading(false);

            if (error?.response && error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
            } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorMessage,
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        load();
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let uri = `/designation/${designationDetails[0].id}`;
        const updatedDesignationDetails = {
            transactionLimitPerDay: designationDetails[0].transactionLimitPerDay,
            transactionLimitPerWeek: designationDetails[0].transactionLimitPerWeek,
            transactionLimitPerMonth: designationDetails[0].transactionLimitPerMonth,
            transactionAmountLimitPerDay: designationDetails[0].transactionAmountLimitPerDay,
            transactionAmountLimitPerWeek: designationDetails[0].transactionAmountLimitPerWeek,
            transactionAmountLimitPerMonth: designationDetails[0].transactionAmountLimitPerMonth
        };

        axios.put(process.env.REACT_APP_BACKEND_HOST + uri, updatedDesignationDetails, requestOptions)
            .then(response => {
                if (response.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                } else {
                    return response.data;
                }
            })
            .then(data => {
                setDesignationDetails([data]);
                setDesignationDetails([]);
                setDesignation('');
                setDesignationDetails();
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while updating the designation details.',
                    });
                }
            });
    };

    const handleCorporateSubmit = e => {
        e.preventDefault();
        load();

        setLoading(true);
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let uri = `/updateFields?incomeRange=${limit}`;
        const updatedLimitDetails = {
            dailyTransactionValue: limitDetails[0]?.dailyTransactionValue,
            weeklyTransactionValue: limitDetails[0]?.weeklyTransactionValue,
            monthlyTransactionValue: limitDetails[0]?.monthlyTransactionValue,
            dailyTransactionCount: limitDetails[0]?.dailyTransactionCount,
            weeklyTransactionCount: limitDetails[0]?.weeklyTransactionCount,
            monthlyTransactionCount: limitDetails[0]?.monthlyTransactionCount
        };

        axios.put(process.env.REACT_APP_BACKEND_HOST + uri, updatedLimitDetails, requestOptions)
            .then(response => {
                if (response.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                } else {
                    return response.data;
                }
            })
            .then(data => {
                setDesignationDetails([data]);
                setLimit(null);
                setLimitDetails({});
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errorMessage,
                    });
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while updating the income range details.',
                    });
                }
            });
    };

    const CountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleSelectChange = (event) => {
        setDesignation(event.target.value);
    };


    const alertSentences1 = [
        'Transactions involving countries with a sanction score above 8 are prohibited.These transactions are blocked due to <span className="highlight">high-risk sanctions</span>',
        'Transactions involving<span className="highlight"> different countries</span> are flagged.',
        'Transactions with<span className="highlight"> trust-related</span> beneficiaries are monitored.',
        'Transactions from <span className="highlight">individual accounts</span> to corporate entities prompt investigation.',
        'Transactions that exceed the predefined limits associated with specific<span className="highlight"> designations</span> trigger an alert.',
        'Such transactions are marked as pending and undergo further scrutiny.',
    ];

    const handleKnobChange = (id, field, value) => {
        const updatedThresholds = thresholds.map(threshold => {
            if (threshold.id === id) {
                return {
                    ...threshold,
                    [field]: value
                };
            }
            return threshold;
        });

        setThresholds(updatedThresholds);
    };

    const handleUpdateAllButtonClick = (id, highRiskScore, mediumRiskScore, trustTransactionScore, designationScore, countryScore, corporateTransactionScore) => {
        load();
        const updatedThreshold = {
            highRiskScore: highRiskScore,
            mediumRiskScore: mediumRiskScore,
            trustTransactionScore: trustTransactionScore,
            designationScore: designationScore,
            countryScore: countryScore,
            corporateTransactionScore: corporateTransactionScore,
        };

        handleUpdate(id, updatedThreshold);
    };


    // const options = countryOptions.map(option => (
    //     <option key={option.id} value={option.laCountryName}>{option.laCountryName}</option>
    // ));
    // const option = designationOptions.map(option => (
    //     <option key={option.id} value={option.designation}>{option.designation}</option>
    // ));

    const limitoption = [
        '100000-1000000', '1000000-5000000', '5000000-10000000', '10000000-99999999', '1000000000-9999999999'
    ]


    // const handleKnobChange1 = (id, key, value) => {
    //     setThresholds((prevThresholds) =>
    //         prevThresholds.map((threshold) =>
    //             threshold.id === id ? { ...threshold, [key]: value } : threshold
    //         )
    //     );
    // };
    const increaseValue = (id, key) => {
        const step = 1;
        setThresholds((prevThresholds) =>
            prevThresholds.map((threshold) =>
                threshold.id === id ? { ...threshold, [key]: threshold[key] + step } : threshold
            )
        );
    };

    const decreaseValue = (id, key) => {
        const step = 1;
        setThresholds((prevThresholds) =>
            prevThresholds.map((threshold) =>
                threshold.id === id ? { ...threshold, [key]: threshold[key] - step } : threshold
            )
        );
    };

    const [activeIndex, setActiveIndex] = useState(0);

    const handleTabChange = index => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const isTabOpen = index => activeIndex === index;

    const renderAccordionTabHeader = (headerText, isOpen) => (
        <div className={`${isOpen ? 'open-class-mt-drop-icon' : 'mt-drop-icon'}`}>
            <div className={` ${isOpen ? 'open-class-mt-drop-text' : 'mt-drop-text'}`}>
                {headerText}
            </div>
            <div>
                <i className={isOpen ? 'pi pi-chevron-down dropIconW dropIconB' : 'pi pi-chevron-right dropIcon dropIconB'} />
            </div>
        </div>
    );

    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    const items = [
        { label: 'Alert Criteria' }
    ];

    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = {
        icon: 'pi pi-angle-double-left', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    return (
        <>
            <div className="mainSec thresold">
                <Toast ref={toast} style={{ marginTop: '5%' }} />
                <BreadCrumb model={items} home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="feild aaa">
                        <div className="footer_sec_btn_dialuge thresold" style={{ padding: '0px' }}>
                            <Button id='b-btn thresold' className="w-6rem" onClick={() => setVisible(true)} >Help <FaQuestion /></Button>
                        </div>
                        <Accordion activeIndex={activeIndex} onTabChange={e => handleTabChange(e.index)} className="accordionTab">
                            <AccordionTab header={renderAccordionTabHeader('Transaction Criteria Alert Scores', isTabOpen(0))}>
                                {thresholds.length > 0 ? (
                                    thresholds.map((threshold, index) => (
                                        <>
                                            <div key={index} className="thresold thresold-box flex-wrap column-gap-8 row-gap-8">
                                                <div className="w-16rem   align-items-center justify-content-center">
                                                    <div className="box" style={{ borderBottom: '4px solid red' }}>
                                                        <div className="l_">
                                                            <div className="h_">High-Risk-Score</div>
                                                            <div className="p_" style={{ color: 'red' }}>{threshold.highRiskScore}%</div>
                                                        </div>
                                                        <div className="r_">
                                                            <Knob
                                                                style={{ textAlign: 'center' }}
                                                                value={threshold.highRiskScore}
                                                                valueColor="#ff0000"
                                                                onChange={(props.roleName === 'ProAdmin') ? (e) => handleKnobChange(threshold.id, 'highRiskScore', e.value) : undefined}
                                                            />
                                                            <div className="flex_footer_btns flex_footer_btn " style={{ justifyContent: 'center', gap: '16px' }}>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-plus' onClick={props.roleName === 'ProAdmin' ? () => increaseValue(threshold.id, 'highRiskScore') : undefined} ></Button>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-minus' onClick={props.roleName === 'ProAdmin' ? () => decreaseValue(threshold.id, 'highRiskScore') : undefined} ></Button>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div>
                                                <div className="w-16rem   align-items-center justify-content-center">
                                                    <div className="box" style={{ borderBottom: '4px solid green ' }}>
                                                        <div className="l_">
                                                            <div className="h_">Medium-Risk-Score</div>
                                                            <div className="p_" style={{ color: 'green' }}>{threshold.mediumRiskScore}%</div>
                                                        </div>
                                                        <div className="r_">
                                                            <Knob
                                                                style={{ textAlign: 'center' }}
                                                                value={threshold.mediumRiskScore}
                                                                valueColor="green"
                                                                onChange={(props.roleName === 'ProAdmin') ? (e) => handleKnobChange(threshold.id, 'mediumRiskScore', e.value) : undefined}
                                                            />
                                                            <div className="flex_footer_btns flex_footer_btn" style={{ justifyContent: 'center', gap: '16px' }}>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-plus' onClick={props.roleName === 'ProAdmin' ? () => increaseValue(threshold.id, 'mediumRiskScore') : undefined}></Button>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-minus' onClick={props.roleName === 'ProAdmin' ? () => decreaseValue(threshold.id, 'mediumRiskScore') : undefined}></Button>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div>
                                                <div className="w-16rem   align-items-center justify-content-center">
                                                    <div className="box" style={{ borderBottom: '4px solid #2196f3 ' }}>
                                                        <div className="l_">
                                                            <div className="h_">Trust-Transaction</div>
                                                            <div className="p_" style={{ color: '#2196f3 ' }}>{threshold.trustTransactionScore}%</div>
                                                        </div>
                                                        <div className="r_">
                                                            <Knob
                                                                style={{ textAlign: 'center' }}
                                                                value={threshold.trustTransactionScore}
                                                                onChange={(props.roleName === 'ProAdmin') ? (e) => handleKnobChange(threshold.id, 'trustTransactionScore', e.value) : undefined}
                                                            />

                                                            <div className="flex_footer_btns flex_footer_btn" style={{ justifyContent: 'center', gap: '10px' }}>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-plus' onClick={props.roleName === 'ProAdmin' ? () => increaseValue(threshold.id, 'trustTransactionScore') : undefined}></Button>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-minus' onClick={props.roleName === 'ProAdmin' ? () => decreaseValue(threshold.id, 'trustTransactionScore') : undefined}></Button>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div>
                                                {/* <div className="w-16rem   align-items-center justify-content-center">
                                                    <div className="box" style={{ borderBottom: '4px solid #84edc5 ' }}>
                                                        <div className="l_">
                                                            <div className="h_">Designation-Score</div>
                                                            <div className="p_" style={{ color: '#84edc5 ' }}>{threshold.designationScore}%</div>
                                                        </div>
                                                        <div className="r_">
                                                            <Knob
                                                                style={{ textAlign: 'center' }}
                                                                value={threshold.designationScore}
                                                                valueColor="#84edc5"
                                                                onChange={(props.roleName === 'ProAdmin') ? (e) => handleKnobChange(threshold.id, 'designationScore', e.value) : undefined}
                                                            />
                                                            <div className="flex_footer_btns flex_footer_btn" style={{ justifyContent: 'center', gap: '16px' }}>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-plus' onClick={props.roleName === 'ProAdmin' ? () => increaseValue(threshold.id, 'designationScore') : undefined}></Button>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-minus' onClick={props.roleName === 'ProAdmin' ? () => decreaseValue(threshold.id, 'designationScore') : undefined}></Button>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div> */}
                                                <div className="w-16rem   align-items-center justify-content-center">
                                                    <div className="box" style={{ borderBottom: '4px solid #f68175  ' }}>
                                                        <div className="l_">
                                                            <div className="h_">Country-Score</div>
                                                            <div className="p_" style={{ color: '#f68175 ' }}>{threshold.countryScore}%</div>
                                                        </div>
                                                        <div className="r_">
                                                            <Knob
                                                                value={threshold.countryScore}
                                                                style={{ textAlign: 'center' }}
                                                                valueColor="#f68175"
                                                                onChange={(props.roleName === 'ProAdmin') ? (e) => handleKnobChange(threshold.id, 'countryScore', e.value) : undefined}
                                                            />
                                                            <div className="flex_footer_btns flex_footer_btn" style={{ justifyContent: 'center', gap: '16px' }}>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-plus' onClick={props.roleName === 'ProAdmin' ? () => increaseValue(threshold.id, 'countryScore') : undefined}></Button>
                                                                <Button className="footer_Sub_Btn alertCriteria" style={{ height: '20px', padding: 'unset' }} icon='pi pi-minus' onClick={props.roleName === 'ProAdmin' ? () => decreaseValue(threshold.id, 'countryScore') : undefined}></Button>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div>
                                                {/* <div className="w-16rem   align-items-center justify-content-center">
                                                    <div className="box" style={{ borderBottom: '4px solid #fe9707  ' }}>
                                                        <div className="l_">
                                                            <div className="h_">Corporate-Transaction</div>
                                                            <div className="p_" style={{ color: '#fe9707  ' }}>{threshold.corporateTransactionScore}%</div>
                                                        </div>
                                                        <div className="r_">
                                                            <Knob
                                                                style={{ textAlign: 'center' }}
                                                                value={threshold.corporateTransactionScore}
                                                                valueColor="#fe9707"
                                                                onChange={(props.roleName === 'ProAdmin') ? (e) => handleKnobChange(threshold.id, 'corporateTransactionScore', e.value) : undefined}
                                                            />
                                                            <div className="flex_footer_btns flex_footer_btn" style={{ justifyContent: 'center', gap: '16px' }}>
                                                                <Button className="footer_Sub_Btn alertCriteria" icon='pi pi-plus' style={{ height: '20px', padding: 'unset' }} onClick={props.roleName === 'ProAdmin' ? () => increaseValue(threshold.id, 'corporateTransactionScore') : undefined}></Button>
                                                                <Button className="footer_Sub_Btn alertCriteria" icon='pi pi-minus' style={{ height: '20px', padding: 'unset' }} onClick={props.roleName === 'ProAdmin' ? () => decreaseValue(threshold.id, 'corporateTransactionScore') : undefined}></Button>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div> */}
                                            </div>
                                            <div className="know_flex_footer_btn" style={{ justifyContent: 'end' }}>
                                                <Button className="know_footer_Sub_Btn"
                                                    onClick={props.roleName === 'ProAdmin' ? () => { load(); handleUpdateAllButtonClick(threshold.id, threshold.highRiskScore, threshold.mediumRiskScore, threshold.trustTransactionScore, threshold.designationScore, threshold.countryScore, threshold.corporateTransactionScore); } : undefined}
                                                    loading={btnloading}
                                                    disabled={props.roleName === 'BasicAdmin'}>Update</Button>
                                            </div>
                                            {/* <br /> */}
                                        </>
                                    ))
                                ) : (
                                    <p>No thresholds data available.</p>
                                )}
                            </AccordionTab >
                            <AccordionTab header={renderAccordionTabHeader('Bar Chart-Sanction Scores By Country', isTabOpen(1))}>

                                <div className="feild-2-3">
                                    <div className="flex flex-thresolds" style={{ margin: 'unset' }}>
                                        <div className="input">
                                            <DoughnutChartComponent
                                                key={chartKey}
                                                style={{
                                                    width: windowWidth <= 575 ? '50%' : '100%',
                                                    height: '335px',
                                                }}
                                                data={props} />
                                        </div>
                                        <div className="input flex thresold-input-country" style={{ flexDirection: 'column' }}>
                                            <>
                                                <div className="feild-2 flex" style={{ flexDirection: 'column', display: "flex", margin: '0px', width: '100%' }}>
                                                    <div className="input ">
                                                        <div className="label">Country Score</div>
                                                        <Dropdown
                                                            value={country}
                                                            options={countrydata?.map((item) => ({ label: item.country.laCountryName, value: item.country.laCountryName }))}
                                                            optionLabel="label"
                                                            required
                                                            style={{ width: '100%', border: 'solid 1px #ccc', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol' }}
                                                            onChange={CountryChange}
                                                            filter
                                                        />
                                                    </div>
                                                    <div className="input ">
                                                        <div className="label">Score</div>
                                                        <InputNumber
                                                            className="w-full"
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="minmaxm-buttons"
                                                            value={countryDetails.sanctionScore || 0}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                setCountryDetails((prevDetails) => ({
                                                                    ...prevDetails,
                                                                    sanctionScore: newValue,
                                                                }));
                                                            }}
                                                            mode="decimal"
                                                            showButtons
                                                            min={0}
                                                            max={10}
                                                            disabled={!country}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            <div className="sanction_country flex_footer_btn" style={{ justifyContent: 'center', gap: '10px' }}>
                                                <Button className="upd-button text-SM font-semibold px-4" loading={btnloading} disabled={!country || props.roleName === 'BasicAdmin'} onClick={handleSubmits} style={{ backgroundColor: '#124A99', border: 'none', width: 'auto', gap: '8px', borderRadius: '6px' }} id="b-btn">Update</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>

                            </AccordionTab>
                            <AccordionTab header={renderAccordionTabHeader('Individual Details', isTabOpen(2))}>
                                {designationDetails && (
                                    <>
                                        <div className="feild-2-thresold">
                                            <div className="feild-2">
                                                <div className="flex" style={{ margin: 'unset', width: '100%' }}>
                                                    <div className="input" style={{ flexDirection: 'column', gap: '10px' }}>
                                                        <div className="label">Designation Limit</div>
                                                        <Dropdown
                                                            value={designation}
                                                            options={designationOptions.map((item) => ({ label: item.designation, value: item.designation }))}
                                                            optionLabel="label"
                                                            required
                                                            onChange={handleSelectChange}
                                                            style={{ width: '100%', border: 'solid 1px #ccc', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild-2">
                                                <div className="flex" style={{ margin: 'unset', width: "-webkit-fill-available" }}>
                                                    <div className="input">
                                                        <div className="label">Daily Limit:</div>
                                                        <InputNumber
                                                            inputId="stacked-buttons"
                                                            value={designationDetails[0]?.transactionLimitPerDay}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value; // Get the updated value
                                                                setDesignationDetails((prevDetails) => {
                                                                    const updatedDetails = [...prevDetails];
                                                                    updatedDetails[0] = {
                                                                        ...updatedDetails[0],
                                                                        transactionLimitPerDay: newValue,
                                                                    };
                                                                    return updatedDetails;
                                                                });
                                                            }}
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            mode="decimal"
                                                            showButtons
                                                            className="w-full"
                                                            min={0}
                                                            max={1000000}
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Weekly Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={designationDetails[0]?.transactionLimitPerWeek}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                setDesignationDetails((prevDetails) => {
                                                                    const updatedDetails = [...prevDetails];
                                                                    updatedDetails[0] = {
                                                                        ...updatedDetails[0],
                                                                        transactionLimitPerWeek: newValue,
                                                                    };
                                                                    return updatedDetails;
                                                                });
                                                            }}
                                                            mode="decimal"
                                                            showButtons
                                                            min={0}
                                                            className="w-full"
                                                            max={1000000}
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Monthly Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '0px', background: "none", height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={designationDetails[0]?.transactionLimitPerMonth}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value; // Get the updated value
                                                                // Update the designationDetails state with the new value
                                                                setDesignationDetails((prevDetails) => {
                                                                    const updatedDetails = [...prevDetails];
                                                                    updatedDetails[0] = {
                                                                        ...updatedDetails[0],
                                                                        transactionLimitPerMonth: newValue,
                                                                    };
                                                                    return updatedDetails;
                                                                });
                                                            }}
                                                            mode="decimal"
                                                            showButtons
                                                            className="w-full"
                                                            min={0}
                                                            max={1000000}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="feild-2">
                                                <div className="flex" style={{ margin: 'unset', width: "-webkit-fill-available" }}>

                                                    <div className="input">
                                                        <div className="label">Daily Transaction Amount Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={designationDetails[0]?.transactionAmountLimitPerDay}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                setDesignationDetails((prevDetails) => {
                                                                    const updatedDetails = [...prevDetails];
                                                                    updatedDetails[0] = {
                                                                        ...updatedDetails[0],
                                                                        transactionAmountLimitPerDay: newValue,
                                                                    };
                                                                    return updatedDetails;
                                                                });
                                                            }}
                                                            showButtons
                                                            className="w-full"
                                                            mode="currency" currency="USD"
                                                            step={100}
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Weekly Transaction Amount Limit:</div>
                                                        <InputNumber
                                                            inputId="stacked-buttons"
                                                            style={{ borderRadius: '5px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            value={designationDetails[0]?.transactionAmountLimitPerWeek}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value; // Get the updated value
                                                                setDesignationDetails((prevDetails) => {
                                                                    const updatedDetails = [...prevDetails];
                                                                    updatedDetails[0] = {
                                                                        ...updatedDetails[0],
                                                                        transactionAmountLimitPerWeek: newValue,
                                                                    };
                                                                    return updatedDetails;
                                                                });
                                                            }}
                                                            showButtons
                                                            step={100}
                                                            className="w-full"
                                                            mode="currency" currency="USD"
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Monthly Transaction Amount Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '5px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={designationDetails[0]?.transactionAmountLimitPerMonth}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value; // Get the updated value
                                                                setDesignationDetails((prevDetails) => {
                                                                    const updatedDetails = [...prevDetails];
                                                                    updatedDetails[0] = {
                                                                        ...updatedDetails[0],
                                                                        transactionAmountLimitPerMonth: newValue,
                                                                    };
                                                                    return updatedDetails;
                                                                });
                                                            }}
                                                            showButtons
                                                            step={100}
                                                            className="w-full"
                                                            mode="currency" currency="USD"
                                                        />
                                                    </div>

                                                </div>


                                                <div className="thresold_designation designation_flex_footer_btn" style={{ justifyContent: 'end', gap: '10px', width: '100%' }}>
                                                    <Button className="upd-button p-button-text-icon-right text-SM font-semibold px-4" loading={btnloading} disabled={!designation || props.roleName === 'BasicAdmin'} id="b-btn" style={{ gap: '10px', width: 'auto', border: 'none', backgroundColor: '#124A99', borderRadius: '6px' }} onClick={handleSubmit} >Update</Button>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )}
                            </AccordionTab>
                            <AccordionTab header={renderAccordionTabHeader('Corporate Details', isTabOpen(3))}>
                                {limitDetails && (
                                    <>
                                        <div className="feild-2-thresold">
                                            <div className="feild-2">
                                                <div className="flex" style={{ margin: 'unset', width: '100%' }}>
                                                    <div className="input ">
                                                        <div className="label">Annual income Limit</div>
                                                        <Dropdown
                                                            value={limit}
                                                            options={limitoption.map((option, index) => (
                                                                { label: <option key={index} value={option}>{option}</option>, value: option }
                                                            ))}
                                                            required
                                                            className="limit-thresold"
                                                            onChange={handleLimitSelectChange}
                                                            style={{ width: '100%', border: 'solid 1px #ccc', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild-2">
                                                <div className="flex" style={{ margin: 'unset', width: "-webkit-fill-available" }}>
                                                    <div className="input ">
                                                        <div className="label">Daily Limit:</div>
                                                        <InputNumber
                                                            inputId="stacked-buttons"
                                                            value={limitDetails[0]?.dailyTransactionCount}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                {
                                                                    newValue &&
                                                                        setLimitDetails((prevDetails) => {
                                                                            const updatedDetails = [...prevDetails];
                                                                            updatedDetails[0] = {
                                                                                ...updatedDetails[0],
                                                                                dailyTransactionCount: newValue,
                                                                            };
                                                                            return updatedDetails;
                                                                        });
                                                                }
                                                            }}
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            mode="decimal"
                                                            className="w-full"
                                                            showButtons
                                                            min={0}
                                                            max={100000000}
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Weekly Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={limitDetails[0]?.weeklyTransactionCount}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                {
                                                                    newValue &&
                                                                        setLimitDetails((prevDetails) => {
                                                                            const updatedDetails = [...prevDetails];
                                                                            updatedDetails[0] = {
                                                                                ...updatedDetails[0],
                                                                                weeklyTransactionCount: newValue,
                                                                            };
                                                                            return updatedDetails;
                                                                        });
                                                                }
                                                            }}
                                                            mode="decimal"
                                                            showButtons
                                                            className="w-full"
                                                            min={0}
                                                            max={100000000}
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Monthly Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '0px', background: "none", height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={limitDetails[0]?.monthlyTransactionCount}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                {
                                                                    newValue &&
                                                                        setLimitDetails((prevDetails) => {
                                                                            const updatedDetails = [...prevDetails];
                                                                            updatedDetails[0] = {
                                                                                ...updatedDetails[0],
                                                                                monthlyTransactionCount: newValue,
                                                                            };
                                                                            return updatedDetails;
                                                                        });
                                                                }
                                                            }}
                                                            mode="decimal"
                                                            showButtons
                                                            className="w-full"
                                                            min={0}
                                                            max={100000000}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="feild-2">
                                                <div className="flex" style={{ margin: 'unset', width: "-webkit-fill-available" }}>
                                                    <div className="input">
                                                        <div className="label">Daily Transaction Amount Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={limitDetails[0]?.dailyTransactionValue}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                {
                                                                    newValue &&
                                                                        setLimitDetails((prevDetails) => {
                                                                            const updatedDetails = [...prevDetails];
                                                                            updatedDetails[0] = {
                                                                                ...updatedDetails[0],
                                                                                dailyTransactionValue: newValue,
                                                                            };
                                                                            return updatedDetails;
                                                                        });
                                                                }
                                                            }}
                                                            showButtons
                                                            className="w-full"
                                                            mode="currency" currency="USD"
                                                            step={1000}
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Weekly Transaction Amount Limit:</div>
                                                        <InputNumber
                                                            inputId="stacked-buttons"
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            value={limitDetails[0]?.weeklyTransactionValue}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                {
                                                                    newValue &&
                                                                        setLimitDetails((prevDetails) => {
                                                                            const updatedDetails = [...prevDetails];
                                                                            updatedDetails[0] = {
                                                                                ...updatedDetails[0],
                                                                                weeklyTransactionValue: newValue,
                                                                            };
                                                                            return updatedDetails;
                                                                        });
                                                                }
                                                            }}
                                                            showButtons
                                                            step={1000}
                                                            className="w-full"
                                                            mode="currency" currency="USD"
                                                        />
                                                    </div>

                                                    <div className="input ">
                                                        <div className="label">Monthly Limit:</div>
                                                        <InputNumber
                                                            style={{ borderRadius: '0px', height: windowWidth <= 575 ? '35px' : '40px' }}
                                                            inputId="stacked-buttons"
                                                            value={limitDetails[0]?.monthlyTransactionValue}
                                                            onValueChange={(e) => {
                                                                const newValue = e.value;
                                                                {
                                                                    newValue &&
                                                                        setLimitDetails((prevDetails) => {
                                                                            const updatedDetails = [...prevDetails];
                                                                            updatedDetails[0] = {
                                                                                ...updatedDetails[0],
                                                                                monthlyTransactionValue: newValue,
                                                                            };
                                                                            return updatedDetails;
                                                                        });
                                                                }
                                                            }}
                                                            showButtons
                                                            step={1000}
                                                            className="w-full"
                                                            mode="currency" currency="USD"
                                                        />
                                                    </div>

                                                </div>

                                                <div className="thresold_designation designation_flex_footer_btn" style={{ justifyContent: 'end', gap: '10px', width: '100%' }}>
                                                    <Button className="upd-button p-button-text-icon-right text-SM font-semibold px-4" loading={btnloading} disabled={!limit || props.roleName === 'BasicAdmin'} id="b-btn" style={{ gap: '10px', width: 'auto', border: 'none', backgroundColor: '#124A99', borderRadius: '6px' }} onClick={handleCorporateSubmit} >Update</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </AccordionTab>
                        </Accordion>
                        <br />
                        <br />
                    </div>
                )}
                <br />
                <br />
                <Dialog header="Transaction Alerts" visible={visible} className="sing_up_main_Dialog transaction alerts" style={{ width: '30rem' }} onHide={() => setVisible(false)}>
                    <div className="m-0">
                        <div className="line-height-4" style={{ color: 'rgba(42, 55, 89, 1)', fontWeight: '500' }}>
                            <div className="mt-dialog-content" style={{ padding: '10px' }}>
                                <ul>
                                    {alertSentences1.map((sentence, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{ __html: sentence }} />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div >
            <br />
            <br />
            <br />
        </>
    );
}

export default MonitoringThresholdsListTable;


