import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
// import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import { Card } from 'primereact/card';

function CorporatePendingMoreInfo(props) {

  const location = useLocation();

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const toast = React.useRef(null);
  const [accountdetials, setAccountDetials] = useState('');
  const accessToken = location?.state?.accessToken;
  const individualdatavalue = location?.state?.sanctionRelationshipId || sessionStorage?.sanctionRelationshipId;
  const individualdatavalue1 = location?.state?.account || sessionStorage?.account;
  const refreshToken = location?.state?.refreshToken;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    axios.get(process.env.REACT_APP_BACKEND_HOST + '/get/sanction/moreInfo?sanctionId=' + individualdatavalue + '&account=' + individualdatavalue1, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      }
    })
      .then(response => {
        const accountDetailsData = response.data;
        setAccountDetials(accountDetailsData);
        setLoading(false);
      })
      .catch(error => {
        if (error?.response && error?.response?.data && error?.response?.data?.message) {
          const errorMessage = error?.response?.data?.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
        } else if (error?.response && error?.response?.status === 403) {
          window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);

        } else if (error?.response?.status === 401) {
          toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
        }
      });
  }, [accessToken, individualdatavalue, individualdatavalue1]);

  const items = [
    {
      label: 'Corporate Profiling', command: () =>
        history.push({
          pathname: `/admin/account/corporateprofiling`,
          state: {
            accessToken: accessToken,
            storedId: location?.state?.corporateid,
            getUserData: location?.state?.getUserData,
            corporateid: location?.state?.corporateid,
            individualid: location?.state?.individualid,
            userAllData: location?.state?.userAllData,
            refreshToken: refreshToken,
          },
        }),
    },
    {
      label: 'Corporate Details', command: () =>
        history.push({
          pathname: '/admin/account/corpoprofiling/riskreason',
          state: {
            accessToken: accessToken,
            storedId: location?.state?.corporateid,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
            corporateid: location?.state?.storedId,
            individualid: location?.state?.individualid,
            accountprofilingid: location?.state?.accountprofilingid,
            userAllData: location?.state?.userAllData,
            corpoRiskReasonClientId: location?.state?.corpoRiskReasonClientId,
            refreshToken: refreshToken,
          },
        })
    },
    { label: 'Corporate More Info' },
  ];
  const home = {
    icon: 'pi pi-home', command: () =>

      history.push({
        pathname: '/admin/dashboard',
        state: {
          accessToken: location?.state?.accessToken,
          emailId: location?.state?.emailId,
          getUserData: location?.state?.getUserData,
          refreshToken: refreshToken,
        },
      })
  }
  const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

  return (
    <>
      <div className="mainSec" >
        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
        <Toast ref={toast} style={{ marginTop: '3%' }} />

        {loading ? (
          <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
            <Triangle
              height="80"
              width="80"
              color="#124A99"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <Card className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
              <div className='h_ing'>
                Corporate Account More Info
              </div>
              <br />
              <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Full Name <span>:</span></div>
                <div className='big_name' style={{ height: 'auto', minHeight: '30px' }}>
                  {accountdetials?.sanctionIndividual?.exactName.map(name => (
                    <div className='value' style={{ backgroundColor: name.matchType === 'bg-orange-500' ? '#fef2c2' : 'transparent' }}>{name?.name || 'N/A'}</div>
                  ))}
                </div>
              </div>
              <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>ID <span>:</span></div>
                <div className='value'>{accountdetials?.sanctionIndividual?.id || 'N/A'}</div>
              </div>

              <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Screening Status <span>:</span></div>
                <div className='value' style={{
                  color: '#7f7878' ||
                    accountdetials.screeningStatus === 'Pending'
                    ? '#f7af00'
                    : accountdetials.screeningStatus === 'Cleared'
                      ? 'rgb(69 160 75)'
                      : accountdetials.screeningStatus === 'Blocked'
                        ? '#f42616'
                        : 'Black', fontWeight: 'bold'
                }}>{accountdetials?.screeningStatus || 'N/A'}</div>
              </div>

              <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Comments <span>:</span></div>
                <div className='value'>{accountdetials?.comments || 'N/A'}</div>
              </div>

              <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Match Points <span>:</span></div>
                <div className='value'>{accountdetials?.sanctionIndividual?.matchPoints || 'N/A'}</div>
              </div>

              <div className='info_D mob' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Relationship Id <span>:</span></div>
                <div className='value'>{accountdetials?.sanctionRelationshipId || 'N/A'}</div>
              </div>
            </Card>
          </>
        )}
      </div >
    </>

  )
}
export default CorporatePendingMoreInfo


