import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom/cjs/react-router-dom';
import '../src/App.css';
import LandingPage from './LandingPage/LandingPage';
import reportWebVitals from './reportWebVitals';
import Admin from './layouts/Admin';
import PrivateRoute from "./views/PrivateRoute";
import Login from "./views/auth/Loginpage/login.js";
import ForgetPas from './layouts/ForgetPas';
import Signup from './layouts/SignInForm/Signup.js';
import PaymentsPage from './layouts/PaymentPage/PaymentPage.js';
import AdditionalInfo from './layouts/Additional_Info/Additional_Info.js';
import BlogItem from './Landing Page/BlogItem/BlogItem.js';
import Contact from './Landing Page/Contact/Contact.js';
import Subscription from './Landing Page/Subscription/Subscription.js';


function App() {
  return (
    <>
      <React.StrictMode>
        <BrowserRouter>
          <Switch>
            <PrivateRoute path='/admin' component={Admin} />
            <Route path='/forgetPaswordchange' component={ForgetPas} />
            <Route path='/login' component={Login} />
            <Route path='/signup' component={Signup} />
            <Route path='/plan' component={Subscription} />
            <Route path='/blog' component={BlogItem} />
            <Route path='/contactUs' component={Contact} />
            <Route path='/additionaldetails' component={AdditionalInfo} />
            <Route path="/payment" component={PaymentsPage} />
            <Route path='/' component={LandingPage} />
            <Redirect from='*' to='/' />
          </Switch>
        </BrowserRouter>
      </React.StrictMode>,
    </>
  );
}

export default App;