import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Toast } from "primereact/toast";
import './AccountProfiling.css';
import { useLocation } from 'react-router-dom';

const Historyv = () => {
    const [historyData, setHistoryData] = useState([]);
    const toast = useRef(null);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;

    const displayWarningToast = (message) => {
        toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
    };
    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    const accountIValue = location?.state?.rowID || sessionStorage?.rowID;

    useEffect(() => {
        const fetchData = async () => {

            if (accountIValue) {
                try {
                    const requestOptions = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    };

                    const uri = `/accountHistory?accountId=${accountIValue}`;
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_HOST}${uri}`,
                        requestOptions
                    );

                    const data = response.data;
                    if (response?.data?.length === 0) {
                        displayWarningToast('No History found.');
                    } else {
                        setHistoryData(data);
                    }
                } catch (error) {
                    if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                    else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    }
                }
            }
        };

        fetchData();

    }, [accountIValue, accessToken]);

    return (
        <div style={{ padding: '20px 0' }} >
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
            <DataTable value={historyData} className="his_tory" style={{ whiteSpace: 'nowrap' }} stripedRows paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                scrollable='true'>
                <Column field="id" header="Id" />
                <Column field="action" header="Action" />
                <Column field="createdUser" header="Created User" />
                <Column field="createdDate" header="Created Date" />
            </DataTable>
        </div>
    );
};

export default Historyv;
