import React, { useState, useEffect, useRef } from 'react';
// import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from "primereact/toast";
import "../../../views/Loading.css";
import './GenaralInformation.css';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { BreadCrumb } from 'primereact/breadcrumb';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';

function GenaralInformation() {

    const toast = useRef(null);
    // const [dob, setDob] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();
    // const email = location?.state?.getUserData?.emailId;
    const [phoneNumber, setPhoneNumber] = useState(location?.state?.getUserData?.phoneNumber);
    const [address, setAddress] = useState(location?.state?.getUserData?.address);
    const [selectedCountry, setSelectedCountry] = useState('');
    const Country = location?.state?.getUserData?.country?.country?.laCountryName;
    const [Countrys, setCountrys] = useState(location?.state?.getUserData?.country?.country?.laCountryName);
    const [selectedCountryId, setSelectedCountryId] = useState(null);
    const [fetchedCountryData, setFetchedCountryData] = useState([]);
    const [state, setState] = useState(location?.state?.getUserData?.state);
    const [city, setCity] = useState(location?.state?.getUserData?.city);
    const accessToken = location?.state?.accessToken;

    useEffect(() => {
        async function fetchCountryData() {
            try {
                const headers = {
                    Authorization: 'Bearer ' + accessToken
                };
                let uri = '/country';

                const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, {
                    headers: headers
                });
                if (Array.isArray(response.data)) {
                    setFetchedCountryData(response.data);
                } else {
                    setFetchedCountryData([]);
                }
                setLoading(false);

            } catch (error) {
                setLoading(false);
                if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                }
            }
        }
        fetchCountryData();
    }, [accessToken]);

    const handleSaveAll = async () => {
        setLoading(true);
        const requestBody = {
            phoneNumber,
            address,
            country: {
                id: selectedCountryId,
            },
            state,
            city,
        };

        axios.put(process.env.REACT_APP_BACKEND_HOST + '/update', JSON.stringify(requestBody), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                axios.get(process.env.REACT_APP_BACKEND_HOST + '/get', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => {
                        setLoading(false);
                        history.push({
                            pathname: '/admin/userprofile',
                            state: { selectedMenuItemLabel: 'GENERAL INFORMATION', getUserData: response?.data, accessToken: accessToken, emailId: response?.data?.emailId },
                        });
                    })
                    .catch(error => {
                        setLoading(false);
                        toast.current.show({ severity: 'error', summary: 'Access Denied', detail: '', life: 3000 });
                    });
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: error?.response?.data?.message, detail: '', life: 3000 });
            });
    };


    const countryChange = (e, event) => {
        const selectedCountryName = e.value;
        setSelectedCountry(e.value);
        const selectedCountryObj = fetchedCountryData.find(item => item.country.laCountryName === selectedCountryName);
        if (selectedCountryObj) {
            const selectedCountryId = selectedCountryObj.id;
            setSelectedCountryId(selectedCountryId);
            setCountrys(selectedCountryId);
        }
    };



    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const items = [
        {
            label: 'User Profile', command: () =>
                history.push({
                    pathname: '/admin/userprofile',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, emailId: location?.state?.getUserData?.emailId },
                })
        },
        { label: 'Edit Information' }
    ];
    const home = {
        icon: 'pi pi-home', command: () =>

            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    return (
        <>
            <div className="mainSec inform" >
                <Toast ref={toast} position="top-right" style={{ top: '12%' }} />
                <BreadCrumb model={items} home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="card card-Adhoc w-full abcd bg-white" style={{ background: '#fff', position: 'relative', }} >
                        <div className='two-field-tab-responce'>
                            <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                                <div className="feild c pt-3 pb-3">
                                    <div className='feild-main-class' style={{ width: '100%' }}>
                                        <div className="flex" style={{ width: '100%' }}>
                                            <div className="input">
                                                <div className="label">Phone Number<span>*</span></div>
                                                <PhoneInput country={'us'} countryCodeEditable={false} className='phoneFeild' value={phoneNumber} onChange={(e) => setPhoneNumber(e)} required />
                                            </div>
                                            <div className="input">
                                                <div className="label">Address<span>*</span></div>
                                                <InputText onChange={(e) => setAddress(e.target.value)} value={address} className='address' />
                                            </div>
                                            <div className="input">
                                                <div className="label">Country<span>*</span></div>
                                                <Dropdown value={selectedCountry || Country || Countrys}
                                                    options={fetchedCountryData.map(item => ({ label: item.country.laCountryName, value: item.country.laCountryName }))}
                                                    optionLabel="label"
                                                    required
                                                    onChange={countryChange}
                                                    filter
                                                    className="w-full unique-drops" />
                                            </div>
                                        </div>
                                        <div className="flex" style={{ width: '100%' }}>
                                            <div className="input">
                                                <div className="label">State<span>*</span></div>
                                                <InputText onChange={(e) => setState(e.target.value)} value={state} className='fieldsa' />

                                            </div>
                                            <div className="input">
                                                <div className="label">City<span>*</span></div>
                                                <InputText onChange={(e) => setCity(e.target.value)} value={city} className='fieldsa' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer_sec_btn_dialuge user-reg" style={{ padding: '0px' }}>
                                        <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" onClick={handleSaveAll}>Update</Button>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                        </div>
                        {window.innerWidth <= 880 && (
                            <div >
                                <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                                    <div className="feild feild-d c pt-3 pb-3">
                                        <div className='feild-main-class' style={{ width: '100%' }}>
                                            <div className="flex" style={{ width: '100%' }}>
                                                <div className="input">
                                                    <div className="label">Phone Number<span>*</span></div>
                                                    <PhoneInput country={'us'} className='phoneFeild' value={phoneNumber} onChange={(e) => setPhoneNumber(e)} required />
                                                </div>
                                                <div className="input">
                                                    <div className="label">State<span>*</span></div>
                                                    <InputText onChange={(e) => setState(e.target.value)} value={state} className='fieldsa' />
                                                </div>
                                            </div>
                                            <div className="flex" style={{ width: '100%' }}>
                                                <div className="input">
                                                    <div className="label">Country<span>*</span></div>
                                                    <Dropdown value={selectedCountry || Country || Countrys}
                                                        options={fetchedCountryData.map(item => ({ label: item.country.laCountryName, value: item.country.laCountryName }))}
                                                        optionLabel="label"
                                                        required
                                                        onChange={countryChange}
                                                        filter
                                                        className="w-full unique-drops" />
                                                </div>
                                                <div className="input">
                                                    <div className="label">Address<span>*</span></div>
                                                    <InputText onChange={(e) => setAddress(e.target.value)} value={address} className='address' />
                                                </div>
                                            </div>
                                            <div className="flex" style={{ width: '100%' }}>
                                                <div className="input">
                                                    <div className="label">City<span>*</span></div>
                                                    <InputText onChange={(e) => setCity(e.target.value)} value={city} className='fieldsa' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer_sec_btn_dialuge user-reg" style={{ padding: '0px' }}>
                                            <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" onClick={handleSaveAll}>Update</Button>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                            </div>
                        )}
                    </div>
                )}
            </div >
        </>
    );
}

export default GenaralInformation;




