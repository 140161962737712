import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Stomp from 'stompjs';
import { FaBell } from 'react-icons/fa';

const WebSocketComponent = () => {
  const [message, setMessage] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);

  const createWebSocket = () => {

    socketRef.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_BACKEND_HOST + "/websocket");
    const stompClient = Stomp?.over(socketRef?.current);

    stompClient?.connect({}, (frame) => {

      stompClient?.subscribe('/topic/company-', (message) => {
        try {
          const messageData = JSON?.parse(message?.body);
          const newMessages = [...message, messageData?.message];
          sessionStorage.setItem('messages', JSON.stringify(newMessages));
          const newNotificationCount = newMessages?.length;
          sessionStorage.setItem('notificationCount', newNotificationCount);

          toast.success(
            <div>
              <span style={{ marginRight: '8px' }}>
                <FaBell />
              </span>
              {messageData.message}
            </div>
          );
        } catch (error) {
          console.error('Error parsing message:', error);
          toast?.error('Error parsing message: Invalid JSON format');
        }
      });
    });

    socketRef.current.onopen = () => {
      setIsConnected(true);
    };

    socketRef.current.onmessage = (event) => {
      setMessage(event.data);
    };

    socketRef.current.onclose = (event) => {
      setIsConnected(false);
    };

    socketRef.current.onerror = (error) => {
      setIsConnected(false);
    };
  };

  useEffect(() => {
    createWebSocket();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);


  return (
    <>
      <ToastContainer />
    </>
  );
};

export default WebSocketComponent;

