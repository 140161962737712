import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from "axios";
import { Toast } from "primereact/toast";
import { useLocation } from 'react-router-dom';

function ViewHistory() {

    const location = useLocation();
    const [historyData, setHistoryData] = useState([]);
    const toast = useRef(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const corporateId = location?.state?.corporateid || sessionStorage?.rowID;
    const accessToken = location?.state?.accessToken;

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 2000 });
    };
    const displayWarningToast = (message) => {
        toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 2000 });
    };

    useEffect(() => {

        if (corporateId) {
            axios.get(process.env.REACT_APP_BACKEND_HOST + `/accountHistory/${corporateId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
            })

                .then(response => {
                    if (response.data.length === 0) {
                        displayWarningToast('No History found.');
                    } else {
                        const jsonObject = JSON.parse(response?.data);
                        setHistoryData(jsonObject);
                    }
                })
                .catch(error => {
                    if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    }
                });
        }

    }, [corporateId, accessToken]);

    const statusTemplate = (rowData) => {
        const status = rowData.status;

        // let backgroundColor = '';
        let color = '';

        if (status === 'Pending') {
            // backgroundColor = '#f9d9bb';
            color = '#f7af00';
        } else if (status === 'Blocked') {
            // backgroundColor = '#ffcdd2';
            color = '#f42616';
        } else if (status === 'Cleared') {
            // backgroundColor = '#c8e6c9';
            color = 'rgb(69 160 75)';
        }
        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="w-full flex text-justify Acount-profile-expander-txt" style={{ fontSize: '14px', padding: '20px 40px' }}>
                <div className='f-Dd'>
                    <div className='w-full flex m-2'><div className='w-10rem font-semibold'>Corporate ID</div> <div className='w-3rem'>:</div> <div className='w-full'>{rowData?.corporate.id || 'N/A'}</div></div>
                    <div className='w-full flex m-2'><div className='w-10rem font-semibold'>Action</div> <div className='w-3rem'>:</div>       <div className='w-full'>{rowData?.action || 'N/A'}</div></div>
                    <div className='w-full flex m-2'><div className='w-10rem font-semibold'>Created User</div> <div className='w-3rem'>:</div> <div className='w-full'>{rowData?.createdUser || 'N/A'}</div></div>
                    <div className='w-full flex m-2'><div className='w-10rem font-semibold'>Status</div> <div className='w-3rem'>:</div>       <div className='w-full'>{rowData?.status || 'N/A'}</div></div>
                    <div className='w-full flex m-2'><div className='w-10rem font-semibold'>Created Date</div> <div className='w-3rem'>:</div> <div className='w-full'>{rowData?.createdDate || 'N/A'}</div></div>
                </div>
            </div>
        );
    };
    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    return (
        <div style={{ padding: '20px 0' }} >
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
            <DataTable value={historyData ? historyData : []} style={{ whiteSpace: 'nowrap' }} stripedRows paginator expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate} onRowToggle={onRowToggle} rows={5} rowsPerPageOptions={[5, 10, 15, 20]} scrollable='true'>
                <Column expander style={{ width: '3rem' }} />
                <Column field="id" header="Id"></Column>
                <Column field="action" header="Action"></Column>
                <Column field="status" header="Status" body={statusTemplate}></Column>
            </DataTable>
        </div>
    );
}
export default ViewHistory;