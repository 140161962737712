import React, { useState, useRef, useEffect } from 'react';
import "../ForgetPassword/ForgetPass.css";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import Logo_mob from "../../../../assets/acaia_logo/acaia_white_logo.svg";
import Logo_web from "../../../../assets/acaia_logo/acaia_dark_logo.svg"
import Iconlogo from "../../../../assets/center-acaia.png";
import "../../../../views/Loading.css";
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { useHistory, useLocation } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import OtpInput from 'react-otp-input';
import CryptoJS from 'crypto-js';

function ForgetPass() {
    const location = useLocation();
    const [email, setEmail] = useState(location?.state?.emailId  || '');
    const [emailError, setEmailError] = useState('');
    const history = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [openPop, setOpenPop] = useState(false);
    const [otp, setOtp] = useState('');
    // const [otpError, setOtpError] = useState('');
    const [countdown, setCountdown] = useState(600);
    const intervalRef = useRef();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const isValidEmail = (email) => {
        // const emailFormatRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        const emailFormatRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5a\x5c\x5d-\x7f]|\\[\x21\x23-\x5a\x5c\x5d-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x5c\x5d-\x7f]|\\[\x21-\x5a\x5c\x5d-\x7f])+)\])$/;

        // const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailFormatRegex.test(email)) {
            return false;
        }

        // Check if the domain is not "gmail.com"
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com');
        // return hasDotCom && domain !== 'gmail.com';
        const isDomainLengthValid = email.length >= 8;

        return hasDotCom && isDomainLengthValid && domain !== 'gmail.com';
    };

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const handleEmailChange = (event) => {
        const emailValue = event.target.value.toLowerCase();
        setEmail(emailValue);

        if (!emailValue.trim()) {
            setEmailError('email is required');
        } else if (!isValidEmail(emailValue)) {
            setEmailError('Invalid email');
        } else {
            setEmailError('');
        }
    };

    const handleBack = () => {
        history.push('/login');
    };

    const encrypt = (data) => {
        const key = CryptoJS.enc.Utf8.parse('acaiaalthi0001uu'); // 16, 24, or 32 bytes key
        const iv = CryptoJS.enc.Utf8.parse('qwertyuioplkjhgf'); // 16 bytes IV
        const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
        return encrypted.toString();
    };

    const sendotp = (event) => {
        event.preventDefault();

        if (!email) {
            setEmailError('email is required.');
            return;
        }
        if (!isValidEmail(email)) {
            setEmailError('invalid email format.');
            return;
        }
        setEmailError('');
        setLoading(true);

        const encryptedEmail = encrypt(email);

        axios.get(process.env.REACT_APP_IDM_BACKEND_HOST + `/users/send_mail?emailId=${encryptedEmail}&systemName=ACAIA`)
            .then(response => {
                const id = response.data.id;
                // const email = response.data.emailId;
                setLoading(false);
                setUserId(id);
                toast.current.show({ severity: 'success', summary: 'Mail Send Successfully', life: 5000 });
                setTimeout(() => {
                    setOpenPop(true);
                }, 2000);
            })
            .catch(error => {
                if (error?.response) {
                    if (error?.response?.status === 401) {
                        toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                    } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: 'Mail Send Failed' });
                }
                setLoading(false);
            });

    }

    const resendotp = async (event) => {
        setOtp('')
        event.preventDefault();
        setLoading(true);
        // setCountdown('')
        try {
            const emailId = email;
            await axios.get(process.env.REACT_APP_IDM_BACKEND_HOST + `/users/send_mail?emailId=${emailId}`);
            toast.current.show({ severity: 'success', summary: 'Mail Send Successfully', life: 5000 });
            resetCountdown();
        } catch (error) {
            if (error?.response) {
                if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response.data && error?.response.data.message) {
                    const errorMessage = error?.response?.data.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                }
            }

            setOtp(false);
        }
        setLoading(false);
    };

    const resetCountdown = () => {
        clearInterval(intervalRef.current);
        setCountdown(600);
        intervalRef.current = setInterval(() => {
            setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
        }, 1000);
    };

    const submitotp = async (event) => {
        event.preventDefault();
        // let k = otp.toString()

        // if (otp === '') {
        //     setOtpError('OTP is required');
        //     return;
        // }

        // if (otp.length !== 6) {
        //     setOtpError('OTP must be 6 digits');
        //     return;
        // }
        // if (!/^[0-9]+$/.test(otp)) {
        //     setOtpError('OTP must contain only numbers');
        //     return;
        // }
        const data = {
            otp: otp,
            systemName: 'ACAIA',
        }
        setLoading(true);

        try {
            await axios.post(process.env.REACT_APP_IDM_BACKEND_HOST + `/users/check_otp/${userId}`, data);
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'OTP Verification successful', life: 3000 });
            setTimeout(() => {
                history.push('/forgetPaswordchange/changepass', { userId });
                history.push({
                    // pathname: ('/forgetPaswordchange/changepass',  userId ),
                    state: { emailId: email, userId: userId },
                });

            }, 2000);
        } catch (error) {
            if (error?.response) {
                if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                }
            } else {
                toast.current.show({ severity: 'error', summary: 'OTP Verification Failed' });
            }
            setLoading(false);
            setOtp(false);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleClosePop = () => {
        setOtp('');
        setOpenPop(false);
    }

    return (
        <div className="sing_up_main">
            <Toast ref={toast} />
            <div style={{ width: '-webkit-fill-available', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                <div className="sing_up_main_c">
                    <div className="l_side">
                        <div className='logo_f' style={{ padding: ' 0 10px', position: 'relative', top: '-125px' }}>
                            <img src={Logo_web} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                        </div>
                        <div style={{ width: '400px', display: 'flex', justifyContent: "center" }}>
                            <img className='unique-acaia-logo-login' src={Iconlogo} onClick={() => history.push('/')} alt="signup-content" style={{ width: '20rem', cursor: 'pointer' }} />
                        </div>
                    </div>
                    <div className="r_side back" style={{ justifyContent: 'center' }}>
                        <div className='logo_f web_hide'>
                            <img src={Logo_mob} style={{ width: '130px', height: 'max-content' }} alt="Acaia-content" />
                        </div>
                        <div className="feild pos forgetMargin autoH back_BTn" style={{ width: (windowWidth < 1000 ? '100%' : '90%'), gap: '20px' }}>

                            {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}> */}

                            <div className="flex forget" style={{ flexDirection: 'column', gap: '20px' }}>
                                <div className="main_heading">Forget Password</div>
                                <div className="sub_heading" style={{ fontWeight: 'normal' }} >Please enter your registered email to get the OTP to reset your password.</div>
                            </div>

                            <div className="flex forget">
                                <div className="input relative in">
                                    <div className="label">	Business Email<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                    <InputText
                                        type="text"
                                        className={`p-inputtext-sm custom-input w-full ${emailError ? 'invalid-input' : ''}`}
                                        value={email}
                                        onChange={handleEmailChange}
                                    />

                                    {emailError && <small className="errors-messages text-red-500">{emailError}*</small>}
                                </div>
                            </div>
                            {/* </div> */}

                            <div className="flex_footer_btn" style={{ width: '100%', justifyContent: 'end', gap: '20px', flexDirection: 'unset' }}>
                                <Button className="footer_Sub_Btn" style={{ background: 'none', color: '#124A99' }} onClick={handleBack}>Back</Button>
                                <Button disabled={!email || !isValidEmail(email)} className="footer_Sub_Btn" onClick={sendotp}>Submit</Button>
                            </div>

                            {loading &&
                                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                    <Triangle
                                        height="80"
                                        width="80"
                                        color="#124A99"
                                        ariaLabel="triangle-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            }
                        </div>


                    </div>

                    <Dialog header="OTP Verification" visible={openPop} className="sing_up_main_Dialog" onHide={handleClosePop}>
                        <div style={{ height: 'auto', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                            <div className="feild" style={{ padding: 'unset' }}>

                                <div className="verificationOTP" style={{ fontSize: (windowWidth < 575 ? '16px' : '18px'), }}>Enter the OTP sent to your email.</div>
                                <div style={{ fontSize: (windowWidth < 575 ? '12px' : '14px'), textAlign: 'center', height: 'auto', color: '#bdbdbd' }}>OTP expires in <span style={{ color: '#0072b4' }}>{formatTime(countdown)}</span> minutes</div>
                                {/* <span style={{ fontSize: (windowWidth < 575 ? '12px' : '14px'), overflow: (windowWidth < 480 ? 'hidden' : ''), color: '#0072b4', textAlign: 'center' }}>{email}</span> */}

                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    isInputNum={true}
                                    separator={<span className="p-2"></span>}
                                    containerStyle="otp-container"
                                    inputStyle="otp-input"
                                    inputType='number'
                                    renderInput={(props, index) => (
                                        <input {...props} className="otp-input-field text-center no_select" key={index} />
                                    )}
                                />

                                <div className="flex_footer_btn otp_footer">
                                    <Button className="footer_Sub_Btn" style={{ background: 'none', border: 'none', color: '#124A99', fontWeight: 'bold', padding: 'unset' }} onClick={resendotp}><u>Resend OTP</u></Button>
                                    <Button disabled={!otp || otp.length !== 6} className="footer_Sub_Btn" onClick={submitotp}>Submit</Button>
                                </div>

                                {loading &&
                                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                        <Triangle
                                            height="80"
                                            width="80"
                                            color="#124A99"
                                            ariaLabel="triangle-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>

    );
}

export default ForgetPass;