import React, { useState, useRef, useEffect } from 'react';
import PaypalView from './PaypalView';
import './PaymentPage.css';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import Logo from '../../assets/acaia_logo/acaia _transparent_logo.svg';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import CryptoJS from 'crypto-js';
import idmaxios from '../../axios/idmaxios';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';

const encrypt = (data, type) => {
    const key = CryptoJS.enc.Utf8.parse('acaiaalthi0001uu');
    const iv = CryptoJS.enc.Utf8.parse('qwertyuioplkjhgf');
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });

    if (type === 'hex') {
        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    } else {
        return encrypted.toString();
    }
};

const PaymentsPage = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [monthsliderPosition, setMonthSliderPosition] = useState(0);
    const location = useLocation();
    const emailId = location.state?.emailId;
    const password = location.state?.password;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSliderChange = (newPosition) => {
        setSliderPosition(newPosition);
    };
    const handleSliderChanges = (newPosition) => {
        setMonthSliderPosition(newPosition);
    };

    const toast = useRef(null);

    const showToast = (severity, summary, detail) => {
        toast.current.show({ severity, summary, detail });
    };

    const handleSignOut = () => {
        fetch(process?.env?.REACT_APP_IDM_BACKEND_HOST + "/reload/reload/" + emailId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    // const [acc, setAcc] = useState();
    // const [ref, setRef] = useState();

    const handleLogin = async () => {
        setLoading(true);
        const encryptedEmail = encrypt(emailId, 'default');
        const encryptedPassword = encrypt(password, 'default');
        try {
            const response = await idmaxios.post(`/sign_in`, {
                emailId: encryptedEmail,
                password: encryptedPassword,
            });

            const accessToken = response.data.access_token;
            const refreshToken = response.data.refresh_token;
            // setAcc(accessToken);
            // setRef(refreshToken);

            const [profileResponse, validateResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BACKEND_HOST}/get`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }),
                idmaxios.get('token_info/' + accessToken),
            ]);

            if (profileResponse.status === 200 && validateResponse.status === 200) {
                const userProfile = profileResponse.data;

                if (userProfile.premiumPlan === "FreeTrial") {
                    setTimeout(() => {
                        history.push({
                            pathname: '/admin/dashboard',
                            state: {
                                emailId: emailId,
                                accessToken: accessToken,
                                refreshToken: refreshToken,
                                roleName: userProfile.premiumPlan
                            },
                        });
                    }, 1000);
                } else {
                    setTimeout(() => {
                        history.push({
                            pathname: '/admin/dashboard',
                            state: {
                                emailId: emailId,
                                accessToken: accessToken,
                                refreshToken: refreshToken
                            },
                        });
                    }, 1000);
                }
            } else {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while processing your request.', life: 3000 });
                setTimeout(() => {
                    history.push('/login');
                }, 1000);
                handleSignOut();
            }
        } catch (error) {
            setLoading(false);
            if (error?.response && error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 3000 });
            }
            setTimeout(() => {
                history.push('/login');
            }, 2000);
        }
    };

    const [a_PayShow, seta_PayShow] = useState(false);

    const tOKEN_Path = location?.state?.type

    const handleClick = async (amount) => {

        // if (amount === 'Free trial') {
        if (amount && tOKEN_Path) {
            if (amount === 'Free trial') {
                if (location?.state?.type === 'sign_In' && location?.state?.noD) {
                    history.push({
                        pathname: '/additionaldetails',
                        state: {
                            captureMail: emailId,
                            captureAccessToken: location?.state?.accessToken,
                            captureRefreshToken: location?.state?.refreshToken,
                            password: password,
                            type: location?.state?.type
                        },
                    });
                } else if (location?.state?.type === 'Remain_ing' || !location?.state?.noD) {
                    //remaing
                    setLoading(true);
                    try {
                        const response = await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/freeTrial`, {
                            email: emailId
                        });
                        const purchasePlanCount = response.data.user.purchasePlanCount;
                        const accessToken = response.data.signInResponse.access_token;
                        const refreshToken = response.data.signInResponse.refresh_token + '_50';
                        const premiumPlan = response.data.user.premiumPlan
                        sessionStorage.setItem('roleName', premiumPlan);
                        sessionStorage.setItem('ref_Key', refreshToken);

                        if (purchasePlanCount > 1) {
                            history.push({
                                pathname: '/admin/dashboard',
                                state: {
                                    accessToken: accessToken,
                                    refreshToken: refreshToken,
                                    emailId: emailId,
                                    roleName: premiumPlan,
                                },
                            });
                        } else {
                            history.push({
                                pathname: '/additionaldetails',
                                state: {
                                    captureMail: emailId,
                                    captureAccessToken: accessToken,
                                    captureRefreshToken: refreshToken,
                                    password: password,
                                    type: 'sign_Up',
                                    plan: premiumPlan
                                },
                            });
                        }
                    } catch (error) {
                        if (typeof showToast === 'function' && error.response && error.response.data) {
                            showToast('error', 'Error', error.response.data.message || 'An error occurred while calling the API. Please try again later.');
                        } else {
                            console.error('An error occurred while calling the API:', error);
                        }
                    } finally {
                        setLoading(false);
                    }
                } else {
                    // sign_Up
                    try {
                        const response = await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/freeTrial`, {
                            email: emailId
                        });
                        const purchasePlanCount = response.data.user.purchasePlanCount;
                        const accessToken = response.data.signInResponse.access_token;
                        const refreshToken = response.data.signInResponse.refresh_token + '_50';
                        const premiumPlan = response.data.user.premiumPlan;
                        sessionStorage.setItem('ref_Key', refreshToken);

                        if (purchasePlanCount > 1) {
                            setTimeout(() => {
                                handleLogin();
                            }, 3000);
                        } else {
                            setTimeout(() => {
                                history.push({
                                    pathname: '/additionaldetails',
                                    state: {
                                        captureMail: emailId,
                                        captureAccessToken: accessToken,
                                        captureRefreshToken: refreshToken,
                                        password: password,
                                        type: 'sign_Up',
                                        plan: premiumPlan
                                    },
                                });
                            }, 1000);
                        }
                    } catch (error) {
                        if (typeof showToast === 'function' && error.response && error.response.data) {
                            showToast('error', 'Error', error.response.data.message || 'An error occurred while calling the API. Please try again later.');
                        } else {
                            console.error('An error occurred while calling the API:', error);
                        }
                    } finally {
                        setLoading(false);
                    }
                }

            } else {
                setSelectedPlan(amount);
                seta_PayShow(true)
            }
        }
        // } else {
        //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Enjoy your free trial!', life: 3000 });
        // }
    };
    const options = ['Monthly', 'Yearly'];
    useEffect(() => {
        const intervalId = setInterval(() => {
            const nextIndex = (sliderPosition + 1) % 3;
            handleSliderChange(nextIndex);
        }, 4000);

        return () => clearInterval(intervalId);
    }, [sliderPosition]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const nextIndex = (monthsliderPosition + 1) % 3;
            handleSliderChanges(nextIndex);
        }, 4000);

        return () => clearInterval(intervalId);
    }, [monthsliderPosition]);
    const [value, setValue] = useState('Monthly');

    const items = [
        {
            id: 1,
            label: 'Free',
            month_amount: 'Free trial',
            items: [
                { keyNote: '250 Registers' },
                { keyNote: '5 Users' },
                { keyNote: 'Access Risk and Control' },
                { keyNote: 'Watchlist Screening' },
                { keyNote: 'Basic Reports' },
                { keyNote: 'Risk Suggestion' },
            ],
            footerlabel: 'Free Trail',
        },
        {
            id: 2,
            label: 'Basic',
            month_amount: 79,
            yearly_amount: 899,
            items: [
                { keyNote: '500 Registers' },
                { keyNote: '10 Users' },
                { keyNote: 'Manage Events' },
                { keyNote: 'Report Configuration' },
                { keyNote: 'All Format Data Import and Export' },
                { keyNote: 'Criteria Monitoring' },
                { keyNote: 'Account Profiling' },
                { keyNote: 'Custom Roles and Responsibilities' },
            ],
            footerlabel: 'Get Started',
        },
        {
            id: 3,
            label: 'Pro',
            month_amount: 129,
            yearly_amount: 1499,
            items: [
                { keyNote: '1500 Registers' },
                { keyNote: 'Unlimited Users' },
                { keyNote: 'Beneficiary Screening' },
                { keyNote: 'Advanced Reporting' },
                { keyNote: 'Sanction Update' },
                { keyNote: 'Control Criteria Settings' },
                { keyNote: 'Email and SMS Alert' },
            ],
            footerlabel: 'Get Started',
        },
    ];
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (count === items.length && value === 'Monthly') {
            setCount(0);
        } else {
            if (value === 'Monthly') {
                const interval = setInterval(() => {
                    setCount(prevCount => (prevCount < 2 ? prevCount + 1 : 0));
                }, 4000);
                return () => clearInterval(interval);
            } else {
                const interval = setInterval(() => {
                    setCount(prevCount => (prevCount < 2 ? prevCount + 1 : 1));
                }, 4000);
                return () => clearInterval(interval);
            }
        }

    }, [count]);

    const contactUsPath = () => {
        history.push({
            pathname: '/contactUs',
            state: {
                path: 'Contact Us'
            },
        });
    }
    return (
        <>
            {loading ? (
                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <div>
                    <Toast ref={toast} />

                    <div className="head_r">
                        <img className='img-header' onClick={() => history.push('/')} src={Logo} style={{ width: '150px', height: 'max-content' }} alt="Acaia-content" />
                    </div>

                    {!a_PayShow ?
                        <div className='a_Pay'>
                            <div className='a_Pay_Top_C'>
                                <h1 className='a_Pay_Head'>Plans and Pricing</h1>
                                <div className='a_Pay_Sub_H'>Subscribe in less than 30 seconds. Try out our risk free trial, upgrade at anytime, no questions, no hassle</div>
                                <div className="sel_BTN">
                                    <SelectButton value={value} onClick={(e) => setValue(e.target.innerText) + setCount(1)} options={options} />
                                </div>
                            </div>
                            <div className='a_Pay_Main'>

                                {windowWidth <= 1755 ? (
                                    <>
                                        <div key={items[count]?.id} className='a_Pay_Card' style={{ display: value === 'Yearly' && count === 0 ? 'none' : '' }}>
                                            <div className='a_Pay_Plan'>{items[count]?.label}</div>
                                            <div className='a_Pay_Amo'>
                                                <div style={{ display: 'flex', gap: '2px' }}>
                                                    <div className='a_Pay_D'>$</div>
                                                    <div className='a_Pay_Amo_C'>{value === 'Monthly' ? (items[count]?.month_amount === 'Free trial' ? 0 : items[count]?.month_amount) : items[count]?.yearly_amount}</div>
                                                </div>
                                                <div>
                                                    <div className='a_Pay_Pr'>{items[count]?.label === 'Free' ? '/ 14 days' : value === 'Monthly' ? '/ month' : '/ year'}</div>
                                                    <div>For up to Package</div>
                                                </div>
                                            </div>
                                            <div className='a_Pay_Opc_'>
                                                {items[count]?.items?.map((note, index) => (
                                                    <div className='a_Pay_Opc'>{note.keyNote}</div>
                                                ))}
                                            </div>
                                            <div className='a_Pay_Foo'>
                                                <Button label={items[count]?.footerlabel}
                                                    // disabled={items[count]?.footerlabel === 'Get Started'} 
                                                    className='a_Pay_Foo_B' onClick={() => handleClick(value === 'Monthly' ? items[count]?.month_amount : items[count]?.yearly_amount)} />
                                            </div>
                                        </div>

                                        <div className='doT_B' style={{ display: value === 'Yearly' && count === 0 ? 'none' : '' }} >
                                            {items?.map((note, index) => (
                                                <Button key={index} className='doT_BTN' style={{ background: count === index ? '#124A99' : 'transparent', display: value === 'Yearly' && index === 0 ? 'none' : '' }} onClick={() => setCount(index)} />
                                            ))}
                                        </div>

                                        <div className='a_Pay_Sub_H' style={{ textAlign: 'center', display: value === 'Yearly' && count === 0 ? 'none' : '' }}><a onClick={contactUsPath} className='l_INK'>Contact Us</a> for Enterprise or for teams large then -</div>
                                    </>
                                ) : (
                                    <>
                                        {items?.map((item, index) => (
                                            <div key={index} className='a_Pay_Card' style={{ display: value === 'Yearly' && index === 0 ? 'none' : '' }}>
                                                <div className='a_Pay_Plan'>{item?.label}</div>
                                                <div className='a_Pay_Amo'>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className='a_Pay_D'>$</div>
                                                        <div className='a_Pay_Amo_C'>{value === 'Monthly' ? (item?.month_amount === 'Free trial' ? 0 : item?.month_amount) : item?.yearly_amount}</div>
                                                    </div>
                                                    <div>
                                                        <div className='a_Pay_Pr'>{item?.label === 'Free' ? '/ 14 days' : value === 'Monthly' ? '/ month' : '/ year'}</div>
                                                        <div>For up to Package</div>
                                                    </div>
                                                </div>
                                                <div className='a_Pay_Opc_'>
                                                    {item?.items?.map((note, index) => (
                                                        <div className='a_Pay_Opc'>{note.keyNote}</div>
                                                    ))}
                                                </div>
                                                <div className='a_Pay_Foo'>
                                                    <Button label={item?.footerlabel}
                                                        // disabled={item?.footerlabel === 'Get Started'}
                                                        className='a_Pay_Foo_B' onClick={() => handleClick(value === 'Monthly' ? item?.month_amount : item?.yearly_amount)} />
                                                </div>
                                            </div>
                                        ))}

                                        <div className='a_Pay_Sub_H' style={{ textAlign: 'center', display: value === 'Yearly' && count === 0 ? 'none' : '' }}><a onClick={contactUsPath} className='l_INK'>Contact Us</a> for Enterprise or for teams large then -</div>

                                    </>
                                )}
                            </div>
                        </div>
                        :
                        <PaypalView amount={selectedPlan} />
                    }
                </div >
            )}
        </>
    );
};

export default PaymentsPage;