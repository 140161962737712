import React, { useState, useEffect, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './AdhocAdvancedsearch.css';
import { useHistory } from 'react-router-dom';
import '../../../../../views/Loading.css';
import { Checkbox } from "primereact/checkbox";
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
import { Triangle } from 'react-loader-spinner';

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import axios from "axios";
import './AdhocAdvancedsearch.css';
// import { RadioButton } from "primereact/radiobutton";
import { useLocation } from 'react-router-dom';

function AdhocAdvancedsearch() {
    const [individualname, setIndividualFullname] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showAdvancemain, setShowAdvancemain] = useState(false);
    const [exact, setexact] = useState([]);
    const [partial, setpartial] = useState([]);
    const [eighty, seteighty] = useState([]);
    // const [atLeastOneChecked, setAtLeastOneChecked] = useState(false);
    const toastRef = useRef(null);
    const [both, setBoth] = useState(true);
    const [showIndividual, setShowIndividual] = useState(false);
    const [showCorporate, setShowCorporate] = useState(false);
    const [date, setDate] = useState(null);
    const [fullname, setFullname] = useState('');
    const [arabicname, setArabicname] = useState('');
    const [gender, setGender] = useState('');
    const [idnumber, setIdnumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [countrydata, setCountrydata] = useState([]);
    const [nationalitydata, setNationalitydata] = useState([]);
    const [nationality, setNationality] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalnumber] = useState('');
    const [pep, setpep] = useState('');
    // const [fullnameError, setFullnameError] = useState('');
    const toast = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    // const roleName = location?.state?.roleName;
    // let individualnameRef = useRef(null);
    let fullnameRef = useRef(null);
    let ifullnameRef = useRef(null);
    let arabicnameRef = useRef(null);
    let idnumberRef = useRef(null);
    let addressRef = useRef(null);
    let cityRef = useRef(null);
    let postalCodeRef = useRef(null);
    let pepRef = useRef(null);

    useEffect(() => {
        sessionStorage.removeItem('individualnameDetails');
        sessionStorage.removeItem("pageCount");

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const selectgender = [
        { name: "Male", code: "Male" },
        { name: "Female", code: "Female" },
    ];

    const items = [
        { label: 'Risk Check' },
        { label: 'Adhoc Search' },
    ];

    const home = location?.state?.getUserData?.premiumPlan === "FreeTrial" ? null : {
        icon: 'pi pi-home',
        command: () => history.push({
            pathname: '/admin/dashboard',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                refreshToken: location?.state?.refreshToken,
            },
        })
    };

    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    const validateCheckboxes = () => {
        const isAtLeastOneChecked = exact.some(item => item.value) || partial.some(item => item.value) || eighty.some(item => item.value);
        return isAtLeastOneChecked;
    };

    const onexact = (e) => {
        const { value, checked } = e.target;
        let ingredients = [...exact];

        if (checked) {
            ingredients.push({ name: value, value: true });
        } else {
            const index = ingredients.findIndex((item) => item.name === value);
            if (index !== -1) {
                ingredients.splice(index, 1);
            }
        }

        setexact(ingredients);
        // validateCheckboxes();
    };

    const onpartial = (e) => {
        const { value, checked } = e.target;
        let ingredients = [...partial];

        if (checked) {
            ingredients.push({ name: value, value: true });
        } else {
            const index = ingredients.findIndex((item) => item.name === value);
            if (index !== -1) {
                ingredients.splice(index, 1);
            }
        }

        setpartial(ingredients);
        // validateCheckboxes();
    };

    const individualsearch = (event) => {
        event.preventDefault();
        if (!individualname.trim()) {
            toastRef.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Please enter the full name.',
                life: 80000,
            });
            return;
        }

        if (!validateCheckboxes) {
            toastRef.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Please check at least one checkbox.',
                life: 3000,
            });
            return;
        }

        const exactvalue = exact.some((item) => item.value === true);
        const partialvalue = partial.some((item) => item.value === true);
        const eightyvalue = eighty.some((item) => item.value === true);

        history.push({
            pathname: '/admin/adhoc/individualdata',
            state: {
                individualname: individualname,
                exactvalue: exactvalue,
                partialvalue: partialvalue,
                eightyvalue: eightyvalue,
                getUserData: location?.state?.getUserData,
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                refreshToken: location?.state?.refreshToken,
            },
        });
        sessionStorage.setItem('individualnameDetails', JSON.stringify(history?.location?.state));
    };



    useEffect(() => {
        const abc = () => document.getElementById('pr_id_1_header');
        abc()
    }, []);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + "/countries", {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + location?.state?.accessToken
            },
        })
            .then((response) => {
                setLoading(false);
                if (Array.isArray(response.data)) {
                    setLoading(false);
                    setCountrydata(response.data);
                    setCountrydata(response.data);
                    setNationalitydata(response.data);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                }
            })
    }, []);

    const SearchSubmitsssss = (event) => {
        event.preventDefault();
        let isValid = true;
        // setFullnameError('');
        if (!fullname.trim()) {
            isValid = false;
            // setFullnameError('Fullname is required.');
        }
        if (!isValid) {
            return;
        }
        setLoading(true);
        history.push({
            pathname: '/admin/adhoc/Individualadvancedatatable',
            state: {
                fullname: fullname,
                arabicname: arabicname,
                gender: gender,
                nationality: nationality?.laCountryName,
                idnumber: idnumber,
                address: address,
                city: city,
                country: country?.laCountryName,
                postalCode: postalCode,
                pep: pep,
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                refreshToken: location?.state?.refreshToken,
            },
        });

        sessionStorage.setItem('individualnameDetails', JSON.stringify(history?.location?.state));
    }
    const countrychange = (event) => {
        const selectedCountry = countrydata.find((item) => item.laCountryName === event.target.value);
        setCountry(selectedCountry);
    };

    const handleNationalityChange = (event) => {
        const selectedNationality = nationalitydata.find((item) => item.laCountryName === event.target.value);
        setNationality(selectedNationality);
    };

    const resetfun = () => {

        setFullname('');
        setArabicname('');
        setGender('');
        setNationality('');
        setIdnumber('');
        setAddress('');
        setCity('');
        setCountry('');
        setPostalnumber('');
        setDate(null);
        setpep('');
        if (fullnameRef.current) {
            fullnameRef.current.value = fullnameRef.current.defaultValue;
        }
        if (pepRef.current) {
            pepRef.current.value = pepRef.current.defaultValue;
        }
        if (postalCodeRef.current) {
            postalCodeRef.current.value = postalCodeRef.current.defaultValue;
        }
        if (cityRef.current) {
            cityRef.current.value = cityRef.current.defaultValue;
        }
        if (arabicnameRef.current) {
            arabicnameRef.current.value = arabicnameRef.current.defaultValue;
        }
        if (idnumberRef.current) {
            idnumberRef.current.value = idnumberRef.current.defaultValue;
        }
        if (addressRef.current) {
            addressRef.current.value = addressRef.current.defaultValue;
        }
    }

    const reset = (e) => {
        e.preventDefault();
        setIndividualFullname('');
        if (ifullnameRef.current) {
            ifullnameRef.current.value = ifullnameRef.current.defaultValue;
        }
        setexact([]);
        setpartial([]);
        seteighty([]);
    }

    const corporatesubmits = (event) => {
        event.preventDefault();
        let isValid = true;
        // setFullnameError('');

        if (!fullname.trim()) {
            isValid = false;
            // setFullnameError('Corporatename is required.');
        }

        if (!isValid) {
            return;
        }

        setLoading(true);
        history.push({
            pathname: '/admin/adhoc/corporateadvancetable',
            state: {
                fullname: fullname,
                arabicname: arabicname,
                idnumber: idnumber,
                address: address,
                city: city,
                country: country?.laCountryName,
                postalCode: postalCode,
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                refreshToken: location?.state?.refreshToken,
            },
        });

        sessionStorage.setItem('individualnameDetails', JSON.stringify(history?.location?.state));
    }

    const resetfun1 = () => {
        setFullname('');
        setAddress('');
        setArabicname('');
        setCity('');
        setCountry('');
        setIdnumber('');
        setPostalnumber('');
        if (fullnameRef.current) {
            fullnameRef.current.value = fullnameRef.current.defaultValue;
        }
        if (postalCodeRef.current) {
            postalCodeRef.current.value = postalCodeRef.current.defaultValue;
        }
        if (cityRef.current) {
            cityRef.current.value = cityRef.current.defaultValue;
        }
        if (arabicnameRef.current) {
            arabicnameRef.current.value = arabicnameRef.current.defaultValue;
        }
        if (idnumberRef.current) {
            idnumberRef.current.value = idnumberRef.current.defaultValue;
        }
        if (addressRef.current) {
            addressRef.current.value = addressRef.current.defaultValue;
        }
    }

    const handleBothChange = () => {
        setBoth(true);
        setShowCorporate(false);
        setShowIndividual(false);
        setShowAdvancemain(false);
    };

    const handleShowIndividualChange = () => {
        setBoth(false);
        setShowIndividual(true);
        setShowAdvancemain(false);
        setShowCorporate(false);
    };

    const handleShowCorporateChange = () => {
        setBoth(false);
        setShowAdvancemain(false);
        setShowIndividual(false);
        setShowCorporate(true);
    };

    return (
        <>
            <div className="mainSec max-height-adhoc a_AS">
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle height="80" width="80" color="#124A99" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                    </div>
                ) : (
                    <div className="feild">
                        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                        <Toast ref={toastRef} style={{ marginTop: '3%' }} />

                        <div className={`${both ? 'main_base both_main_base' : 'main_base'}`} style={{ width: (windowWidth <= 500 ? '100%' : '80%') }}>

                            <div className="btn_sec">
                                {location?.state?.getUserData?.premiumPlan !== "ProAdmin" &&
                                    <Button
                                        className="w-13rem p-2"
                                        onClick={handleBothChange}
                                        style={{ background: both ? '#124A99' : 'transparent', color: both ? 'white' : '#124A99', }}
                                    >
                                        Sanction List Search
                                    </Button>
                                }
                                {location?.state?.getUserData?.premiumPlan === "ProAdmin" &&
                                    <>
                                        <Button
                                            className="w-13rem p-2"
                                            onClick={handleBothChange}
                                            style={{ background: both ? '#124A99' : 'transparent', color: both ? 'white' : '#124A99', }}
                                        >
                                            Both
                                        </Button>
                                        <Button
                                            className="w-13rem"
                                            onClick={handleShowCorporateChange}
                                            style={{ background: showCorporate ? '#124A99' : 'transparent', color: showCorporate ? 'white' : '#124A99', }}
                                        >
                                            Corporate
                                        </Button>

                                        <Button
                                            className="w-13rem"
                                            onClick={handleShowIndividualChange}
                                            style={{ background: showIndividual ? '#124A99' : 'transparent', color: showIndividual ? 'white' : '#124A99', }}
                                        >
                                            Individual
                                        </Button>
                                    </>
                                }
                            </div>

                            {both && (
                                <>
                                    {showAdvancemain ? null : (<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

                                        <div className="flex">
                                            <div className="input" style={{ width: '80%', margin: 'auto' }}>
                                                <div className="label" style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>Full Name<span>*</span></div>
                                                <InputText className="input" ref={ifullnameRef} defaultValue={ifullnameRef.current ? ifullnameRef.current.defaultValue : ''} onChange={(e) => setIndividualFullname(e.target.value)} />
                                            </div>
                                        </div>


                                        <div className="flex">
                                            <div className="input a1 wra" style={{ display: 'flex', justifyContent: 'center', gap: '20px', padding: '0 30px' }}>
                                                <div className="c_position">
                                                    <Checkbox className="radioBtn" value="exact" onChange={onexact} checked={exact.some((item) => item.name === 'exact' && item.value)} />
                                                    <label style={{ width: '135px' }}>Exact Name Match </label>
                                                </div>

                                                <div className="c_position">
                                                    <Checkbox className="radioBtn" value="partial" onChange={onpartial} checked={partial.some((item) => item.name === 'partial' && item.value)} />
                                                    <label style={{ width: '135px' }}>Partial Name Match</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn_sec" style={{ height: '70px', gap: '25px', flexDirection: 'row' }}>
                                            <Button className="resetBtn" style={{ width: '40px' }} icon="pi pi-refresh" onClick={reset} />
                                            <Button disabled={!individualname} onClick={individualsearch} label=" Search" />
                                        </div>
                                    </div>
                                    )}
                                </>

                            )}

                            {showIndividual && (
                                <>
                                    {showAdvancemain ? null : (
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '90%', margin: 'auto' }}>
                                            <div className="feild c">
                                                <div className="flex">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Full Name<span>*</span></div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={fullnameRef} defaultValue={fullnameRef.current ? fullnameRef.current.defaultValue : ''} onChange={(e) => setFullname(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="flex">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Arabic Name</div>
                                                        <InputText dir="rtl" style={{ width: '-webkit-fill-available' }} ref={arabicnameRef} defaultValue={arabicnameRef.current ? arabicnameRef.current.defaultValue : ''} onChange={(e) => setArabicname(e.target.value)} />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Date Of Birth</div>
                                                        <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon className="w-full calander" id="p-calender" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="flex wra">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Gender</div>
                                                        <Dropdown optionLabel="name" optionValue="code" style={{ flexDirection: 'row' }} options={selectgender} value={gender} onChange={(event) => setGender(event.target.value)} className="w-full p-0 unique-drops dropdown" />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Nationality</div>
                                                        <Dropdown value={nationality ? nationality.laCountryName : null} style={{ flexDirection: 'row' }} options={nationalitydata.map((item) => ({ label: item.laCountryName, value: item.laCountryName }))} onChange={handleNationalityChange} filter required className="w-full p-0 unique-drops dropdown" optionLabel="label" />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Id Number</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={idnumberRef} defaultValue={idnumberRef.current ? idnumberRef.current.defaultValue : ''} onChange={(e) => setIdnumber(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="flex wra">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Address</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={addressRef} defaultValue={addressRef.current ? addressRef.current.defaultValue : ''} onChange={(e) => setAddress(e.target.value)} />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">City</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={cityRef} defaultValue={cityRef.current ? cityRef.current.defaultValue : ''} onChange={(e) => setCity(e.target.value)} />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Country</div>
                                                        <Dropdown value={country ? country.laCountryName : null} style={{ flexDirection: 'row' }} options={countrydata?.map((item) => ({ label: item.laCountryName, value: item.laCountryName }))} optionLabel="label" required onChange={countrychange} filter className="w-full p-0 unique-drops dropdown" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="feild c">
                                                <div className="flex wra">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Postal Code</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} maxLength={6} keyfilter={"num"} ref={postalCodeRef} defaultValue={postalCodeRef.current ? postalCodeRef.current.defaultValue : ''} onChange={e => setPostalnumber(e.target.value)} />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>                                                    <div className="label">Pep</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={pepRef} defaultValue={pepRef.current ? pepRef.current.defaultValue : ''} onChange={e => setpep(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="btn_sec" style={{ height: '100px', gap: '25px', flexDirection: 'row' }}>
                                                    <Button className="resetBtn" style={{ width: '40px' }} onClick={resetfun} icon="pi pi-refresh" />
                                                    <Button disabled={!fullname} onClick={SearchSubmitsssss} label="Search" />
                                                </div>
                                            </div>

                                            {loading ? (
                                                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                                    <Triangle height="80" width="80" color="#124A99" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </>
                            )}

                            {showCorporate && (
                                <>
                                    {showAdvancemain ? null : (
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '90%', margin: 'auto' }}>
                                            <div className="feild c">
                                                <div className="flex">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Full Name<span>*</span></div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={fullnameRef} defaultValue={fullnameRef.current ? fullnameRef.current.defaultValue : ''} onChange={(e) => setFullname(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="flex">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Arabic Name</div>
                                                        <InputText dir="rtl" style={{ width: '-webkit-fill-available' }} ref={arabicnameRef} defaultValue={arabicnameRef.current ? arabicnameRef.current.defaultValue : ''} onChange={(e) => setArabicname(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="flex wra">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Id Number</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={idnumberRef} defaultValue={idnumberRef.current ? idnumberRef.current.defaultValue : ''} onChange={(e) => setIdnumber(e.target.value)} />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Address</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={addressRef} defaultValue={addressRef.current ? addressRef.current.defaultValue : ''} onChange={(e) => setAddress(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="flex wra">
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">City</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} ref={cityRef} defaultValue={cityRef.current ? cityRef.current.defaultValue : ''} onChange={(e) => setCity(e.target.value)} />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Country</div>
                                                        <Dropdown value={country ? country.laCountryName : null} style={{ flexDirection: 'row' }} options={countrydata?.map((item) => ({ label: item.laCountryName, value: item.laCountryName }))} optionLabel="label" required onChange={countrychange} filter className="w-full p-0 unique-drops dropdown" />
                                                    </div>
                                                    <div className="input" style={{ margin: 'auto' }}>
                                                        <div className="label">Postal Code</div>
                                                        <InputText style={{ width: '-webkit-fill-available' }} maxLength={6} keyfilter={"num"} ref={postalCodeRef} defaultValue={postalCodeRef.current ? postalCodeRef.current.defaultValue : ''} onChange={e => setPostalnumber(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="feild c">
                                                <div className="btn_sec" style={{ height: '100px', gap: '25px' }}>
                                                    <Button style={{ width: '40px' }} className="resetBtn" icon="pi pi-refresh" onClick={resetfun1} />
                                                    <Button disabled={!fullname} onClick={corporatesubmits} label=" Search" />
                                                </div>
                                            </div>

                                            {loading ? (
                                                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                                    <Triangle height="80" width="80" color="#124A99" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </>
                            )}
                        </div >
                    </div>
                )}
            </div >
        </>
    )
}
export default AdhocAdvancedsearch;

