import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import './Roles.css';
import RoleService from './RoleService';
import "../../../views/Loading.css";
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from "primereact/toast";
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Triangle } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export default function Role() {

    let emptyProduct = {
        roleName: "",
        roleDescription: ""
    }
    const history = useHistory();

    const [selectedData, setSelectedData] = useState(null);
    const [scopes, setScopes] = useState(null);
    const [visible, setVisible] = useState(false);
    const [products, setProducts] = useState(null);
    const [selectedScopeObj, setSelectedScopeObj] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [roleDialog, setRoleDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const roleService = new RoleService();
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    const plan = location?.state?.getUserData?.premiumPlan;
    let inputRef = useRef('');

    const handleRequestError = (error) => {
        if (error?.response) {
            if (error?.response?.status === 403) {
                window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
            } else if (error?.response?.status === 401) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Access Denied',
                    detail: 'You do not have permission to access this resource.',
                    life: 3000
                });
            } else if (error?.response?.data && error?.response?.data?.message) {
                const errorMessage = error?.response?.data?.message;
                displayErrorToast(errorMessage);
            }
        } else if (axios.isAxiosError(error)) {
            displayErrorToast('An error occurred while processing the request.');
        } else {
            // console.error('Unexpected error:', error);
        }
    };

    useEffect(() => {
        roleService.getScopes(accessToken).then(data => setScopes(data));
        setLoading(true);
        roleService.getProducts(accessToken)
            .then((data) => {
                setProducts(data);
                setLoading(false);
            })
            .catch(error => {
                handleRequestError(error);
                setLoading(false);

            });
    }, []);

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setSelectedScopeObj(null);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setRoleDialog(false);
        setRoleDesMessage('');
        setRoleMessage('');
        setProduct({
            roleName: '',
            roleDescription: '',
        });
        setSelectedScopeObj([]);
    }

    const displayErrorToast = (message) => {
        if (toast.current) { // Check if the ref is not null
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    const saveProduct = (e) => {
        e.preventDefault();
        setSubmitted(true);
        setProductDialog(false);
        setLoading(true);
        const data = {
            roleDescription: roleDescription,
            roleName: roleName,
            scopes: selectedScopeObj
        }
        axios.post(process.env.REACT_APP_IDM_BACKEND_HOST + '/roles', data, {

            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }

        })
            .then(response => {

                if (response.status === 200) {
                    setProducts(products);
                    setProductDialog(false);
                    setRoleDialog(false);
                    setProduct(emptyProduct);
                    hideDialog();
                    getproduct();
                    toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Roles created successfully!' });
                    setRoleName('');
                    setSelectedScopeObj('');
                    setRoleDescription('');
                }
                setLoading(false);
            })
            .catch(error => {
                handleRequestError(error);
                setLoading(false);
            });

    }
    const getproduct = () => {
        roleService.getScopes(accessToken).then(data => setScopes(data));
        setLoading(true);
        roleService.getProducts(accessToken)
            .then((data) => {
                setProducts(data);
                setLoading(false); // Set loading state to false when data fetching is complete
            })
            .catch(error => {
                handleRequestError(error);
                setLoading(false);

            });
    }

    const saveProducts = (e) => {
        e.preventDefault();
        setSubmitted(true);
        hideDialog();
        setLoading(true);

        const data = {
            id: product.id,
            roleDescription: product.roleDescription,
            roleName: product.roleName,
            scopes: selectedScopeObj
        }

        axios.put(process.env.REACT_APP_IDM_BACKEND_HOST + `/roles`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
        })
            .then(response => {
                setProducts(products);
                setProductDialog(false);
                setRoleDialog(false);
                setProduct(emptyProduct);
                hideDialog();
                getproduct();
                setLoading(false);
                toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Roles Updated successfully!' });
            })
            .catch(error => {
                handleRequestError(error);
                setLoading(false);
            });
    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setSelectedScopeObj([...product.scopes]);
        setRoleDialog(true);
    }

    const [roleMessage, setRoleMessage] = useState('');
    const [roleDesMessage, setRoleDesMessage] = useState('');
    const [roleScopeMessage, setRoleScopeMessage] = useState('');

    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        switch (name) {
            case 'roleName':
                if (/[^a-zA-Z\s]/.test(val)) {
                    return;
                }
                if (val.length === 0) {
                    setRoleMessage('Role Name is required.');
                } else {
                    setRoleMessage('');
                }
                break;
            case 'roleDescription':
                if (/[^a-zA-Z\s]/.test(val)) {
                    return;
                }
                if (val.length === 0) {
                    setRoleDesMessage('Role Description is required.');
                } else {
                    setRoleDesMessage('');
                }
                break;
            default:
                break;
        }
        _product[`${name}`] = val;
        setProduct(_product);
    }
    const productDialogFooter = (
        <React.Fragment>
            <div className="footer_sec_btn" style={{ height: '70px', paddingRight: '20px' }}>
                <Button onClick={saveProduct} label='Submit' style={{ width: '80px' }} disabled={!roleName || !roleDescription || !selectedScopeObj} />
            </div>
        </React.Fragment >
    );
    const productDialogFooter1 = (
        <React.Fragment>
            <div className="footer_sec_btn">
                <Button onClick={saveProducts} style={{ width: '80px' }} label='Update' />
            </div>
        </React.Fragment>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" disabled={(plan === 'FreeTrial' ? true : false)} style={{ color: '#124A99' }} onClick={() => editProduct(rowData)} />
            </React.Fragment>
        );
    }

    const onSelectionChange = (e) => {
        setSelectedData(e.value);
    };

    const cols = [
        { field: 'id', header: 'Role ID' },
        { field: 'roleName', header: 'Role Name' },
        { field: 'roleDescription', header: 'Role Description' },
        { field: 'createdById', header: 'Created User ID' },
        { field: 'createdOn', header: 'Created On' },
        { field: 'updatedById', header: 'Updated User ID' },
        { field: 'updatedOn', header: 'Updated On' },
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const handleExportToPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('Role Datas.pdf');
            });
        });
    };

    const handleExportToCSV = () => {
        const csvContent = convertToCSV(products);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'Role Datas.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(','));

            for (const row of data) {
                const values = Object.values(row).join(',');
                csvRows.push(values);
            }
        }
        return csvRows.join('\n');
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Role Datas.xlsx');
    };

    const exportToXLS = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Role Datas.xls');
    };

    let menu = null;

    const items = [
        {
            label: 'XLS',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToXLS();
            }
        },
        {
            label: 'XLSX',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToExcel();
            }
        },
        {
            label: 'CSV',
            icon: () => <FaFileCsv />,
            command: () => {
                setVisible(false);
                handleExportToCSV();
            }
        },
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const itemsFB = [
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const toggleMenu = (event) => {
        setVisible(!visible);
        menu.toggle(event);
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Role Datas List</title></head><body>');
        printWindow.document.write('<style>table { border-collapse: collapse; width: 100%; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
        printWindow.document.write('<h1>Role Datas List</h1>');

        printWindow.document.write('<table>');
        printWindow.document.write('<thead>');
        printWindow.document.write('<tr>');
        printWindow.document.write('<th>Role Id</th>');
        printWindow.document.write('<th>Role Name</th>');
        printWindow.document.write('<th>Role Description</th>');
        printWindow.document.write('<th>Created By UserId</th>');
        printWindow.document.write('<th>Created On</th>');
        printWindow.document.write('<th>Updated By UserId</th>');
        printWindow.document.write('<th>Updated On</th>');
        printWindow.document.write('</tr>');
        printWindow.document.write('</thead>');
        printWindow.document.write('<tbody>');

        products.forEach(row => {
            printWindow.document.write('<tr>');
            printWindow.document.write(`<td>${row.id || '-'}</td>`);
            printWindow.document.write(`<td>${row.roleName || '-'}</td>`);
            printWindow.document.write(`<td>${row.roleDescription || '-'}</td>`);
            printWindow.document.write(`<td>${row.createdById || '-'}</td>`);
            printWindow.document.write(`<td>${row.createdOn ? formatDate(row.createdOn) : '-'}</td>`);
            printWindow.document.write(`<td>${row.updatedById || '-'}</td>`);
            printWindow.document.write(`<td>${row.updatedOn ? formatDate(row.updatedOn) : '-'}</td>`);
            printWindow.document.write('</tr>');
        });

        printWindow.document.write('</tbody></table></body></html>');
        printWindow.document.close();
        printWindow.print();
    };



    const item = [
        { label: 'Roles' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    const formatDate = (date) => {
        if (!date || isNaN(date)) {
            return 'Invalid Date';
        }

        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: '2-digit',
            hour12: false,
        }).format(new Date(Number(date)));
        return formattedDate;
    };

    const handleScopeChange = (e) => {
        setSelectedScopeObj(e.value);
        setSubmitted(false);
        if (e.value.length === 0) {
            setRoleScopeMessage('Scope is required.');
        } else {
            setRoleScopeMessage('');
        }
    };

    return (
        <>
            {/* <div className='mainSec'>
                <Toast ref={toast} style={{ marginTop: '150px' }} />
                <div className='container-user h-full w-full'>
                    <BreadCrumb model={item} home={home} style={{ border: 'none' }} /> */}
            <div className='mainSec cou'>
                <Toast ref={toast} style={{ marginTop: '70px' }} />
                <div className='container-user h-full w-full'>
                    <BreadCrumb model={item} home={home} style={{ border: 'none' }} />
                    {loading ?
                        <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                            <Triangle
                                height="80"
                                width="80"
                                color="#124A99"
                                ariaLabel="triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>

                        : null}

                    <div className="card-Adhoc w-full bg-white unique-data-responses" style={{ marginBottom: '8%', background: '#fff', position: 'relative', border: '1px solid #e8e9eb' }} >
                        <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                            <div className="flex flex-wrap align-items-center justify-content-end" style={{ borderBottom: 'rgba(209, 209, 209, 1) 1px solid' }}>

                                <div className='country-conatent flex justify-content-end  pt-2 pb-2 px-3 bg-gray-50'>
                                    <div className="tab-head-btn flex justify-content-end flex-wrap w-full" >
                                        <div className=" ">
                                            <span className="p-input-icon-left ">
                                                <i className="pi pi-search" />
                                                <InputText placeholder="Search..." style={{ border: '2px solid #ccc' }} className='py-2 w-full' onInput={(e) => setGlobalFilter(e.target.value)} />
                                            </span>
                                        </div>
                                        <div className="flex align-items-center justify-content-center" style={{ zIndex: '1' }}>
                                            <Button className='mr-2 flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={openNew} tooltip="Create Role" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-plus '></i></Button>
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <Button className='mr-2  flex align-items-center justify-content-center' disabled={products?.length === 0} id="b-btn" style={{ border: 'none', backgroundColor: 'transparent' }} tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleMenu} ><i className='pi pi-upload '></i></Button>
                                            <Menu
                                                className='unique-menu-exp'
                                                model={plan === 'FreeTrial' || plan === 'BasicAdmin' ? itemsFB : items}
                                                popup
                                                ref={(el) => (menu = el)}
                                                onHide={() => setVisible(false)}
                                                appendTo={document.body}
                                            />
                                        </div>
                                        <div className="flex align-items-center justify-content-center">
                                            <Button className='mr-2 flex align-items-center justify-content-center' disabled={products?.length === 0} id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={handlePrint} tooltip="Print" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-print '></i></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='border-btm-datatble' >

                                <DataTable value={products}
                                    showGridlines id='role-edit-btn'
                                    paginator rows={10} rowsPerPageOptions={[10, 20, 30, 40]}
                                    selectionMode="checkbox" selection={selectedData} onSelectionChange={onSelectionChange}
                                    stripedRows
                                    globalFilter={globalFilter}
                                >
                                    <Column field="id" header="Role Id" style={{ whiteSpace: 'nowrap' }} headerClassName='align-center' sortable></Column>
                                    <Column field="roleName" header="Role Name" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                    <Column field="roleDescription" header="Role Description" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                    <Column field="createdById" header="Created By UserId" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                    <Column field="createdOn" header="Created On" style={{ whiteSpace: 'nowrap' }} body={(rowData) => formatDate(rowData.createdOn)} sortable></Column>
                                    <Column field="updatedById" header="Updated By UserId" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                    <Column field="updatedOn" header="Updated On" style={{ whiteSpace: 'nowrap' }} body={(rowData) => formatDate(rowData.updatedOn)} sortable></Column>
                                    <Column header="Edit" body={actionBodyTemplate}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <Dialog visible={productDialog} draggable={false} header="Add Role Details" modal className="breanch-dialog sing_up_main_Dialog p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className=" overflow-hidden   overflow-auto pb-2 pt-4" id="advanced-ahoc-rect">
                            <div className="feild">
                                <div className="flex">
                                    <div className="input">
                                        <div className='label'>Role Name<span>*</span></div>
                                        <InputText id="Role Name" value={roleName} onChange={(e) => setRoleName(e.target.value)} className={roleMessage ? "invalid-input" : ""} style={{ width: '100%' }} />
                                        {roleMessage && (<small className="p-error">{roleMessage}</small>)}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className='label'>Role Description<span>*</span></div>
                                        <InputText id="Role Description" value={roleDescription} onChange={(e) => setRoleDescription(e.target.value)} style={{ width: '100%' }} className={roleDesMessage ? "invalid-input" : ""} />
                                        {roleDesMessage && (<small className="p-error">{roleDesMessage}</small>)}
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className="input">
                                        <div className='label'> Scope Name<span>*</span></div>
                                        <MultiSelect
                                            inputId="multiselect"
                                            value={selectedScopeObj}
                                            options={scopes}
                                            onChange={handleScopeChange}
                                            optionLabel="scopeName"
                                            filter
                                            showSelectAll={false}
                                            className={`w-full pt-1 rOLE${roleScopeMessage ? "p-invalid" : ""}`}
                                        />
                                        {submitted && roleScopeMessage && <small className="error-message">{roleScopeMessage}</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={roleDialog} header="Update Scope Details" modal draggable={false} className="sing_up_main_Dialog p-fluid" footer={productDialogFooter1} onHide={hideDialog}>
                        {/* <div className="p-field"> */}
                        <div className='overflow-hidden pt-4 pb-2' id='advanced-ahoc-react'>
                            <div className="feild">
                                <div className="flex">
                                    <div className="input">
                                        <div className='label'> Scope Name</div>
                                        <MultiSelect
                                            inputId="multiselect"
                                            value={selectedScopeObj}
                                            options={scopes}
                                            onChange={(e) => setSelectedScopeObj(e.value)}
                                            optionLabel="scopeName"
                                            maxSelectedLabels={3}
                                            minSelectedLabels={1}
                                            filter
                                            showSelectAll={false}
                                            className='w-full pt-1'
                                        />
                                        {submitted && selectedScopeObj && <small className="error-message">Scope is required.</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    );
}