import React, { useState, useEffect } from "react";
import "./Blog.css";
import { Button } from 'primereact/button';
import { useHistory, useLocation } from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export default function Blog(props) {

    const location = useLocation();
    const history = useHistory();
    // const [posts, setPosts] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // useEffect(() => {
    //     // setLoading(true)
    //     if (!location?.state?.blogData) {
    //         return () => {
    //             setPosts(location?.state?.blogData)
    //         };
    //     }
    // }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: windowWidth <= 575 ? 1 : windowWidth <= 850 ? 2 : 3,
        slidesToScroll: 1,
    };

    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <br />

            <p className="contact-heading" style={{
                position: 'relative', top: '80px', color: '#093EA4'
            }}>
                Find the best insights in the AML industry.
            </p >

            <div className="bLOG_H">
                <div className="bLOG"><div></div></div>
                <br />
                <br />

                <div className="bLOG_H_Su">
                    Are you searching for an AML solution provider that can surpass your expectations and deliver invaluable insights? We've compiled a list of articles to assist you in finding the perfect match.
                </div>
            </div >

            < div className="slider_S" >
                <Slider {...settings} className="blog_Items" >
                    {location?.state?.blogData?.map((item, index) => (
                        <div key={index} className={'slider_S_blog'} >
                            <>
                                <img src={item?.fields?.blogImage[0]?.fields?.file?.url} alt={'blog'} className={'slider_S_Tblog'} />

                                <div className={'foo_V_blog'}>{item?.fields?.blogAuthor} </div>

                                <div key={index} className={'foo_blog'}>
                                    <span className={'foo_C_blog'}>{item?.fields?.blogTitle}</span>
                                    <Button key={index} icon="pi pi-arrow-right"
                                        onClick={() => history.push({
                                            pathname: '/blog',
                                            state: { blogItemS: item?.fields },
                                        })}
                                        className={'blog_BTN'}
                                    />
                                </div>
                            </>
                        </div>
                    ))}
                </Slider>
            </div >
            <br />
            <br />
        </>
    );
}
