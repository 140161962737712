import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Triangle } from 'react-loader-spinner';
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BreadCrumb } from 'primereact/breadcrumb';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './RiskClarification.css';
import "../../views/Loading.css";

export default function RiskClarification() {

    const [loading, setLoading] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const toast = useRef(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const history = useHistory()
    const [products, setProducts] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = (props) => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };
    const displayWarningToast = (message) => {
        toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + location?.state?.accessToken
                    }
                };

                const uri = `/accounts/highRisk`;
                const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions);
                const data = response.data;

                if (data.length === 0) {
                    displayWarningToast('High risk data not found.');
                } else {
                    setProducts(data);
                    setLoading(false);
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error?.response && error?.response?.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                }
            }
        };

        fetchData();
    }, []);

    const TypeTemplate = (rowData) => {
        const status = rowData.profileType || rowData.profile;

        let color = '';

        if (status === 'Medium Risk') {
            color = '#f7af00';
        } else if (status === 'High Risk') {
            color = '#f42616';
        } else if (status === 'Low Risk') {
            color = 'rgb(69 160 75)';
        }
        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',

                }}
            >
                {status}
            </div>
        );
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="w-full flex text-justify Acount-profile-expander-txt" style={{ fontSize: '14px', padding: '20px 40px', gap: '25%' }}>
                <div className='f-Dd'>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Email</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.emailId ? rowData.emailId : rowData?.email || '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Branch Code</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.branch.code ? rowData.branch.code : '-'}</div></div>
                    {!products?.highRiskCorporates &&
                        <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Gender</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.gender ? rowData.gender : '-'}</div></div>
                    }
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>{rowData.gender ? 'Nationality' : 'Country'}</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.gender ? rowData.nationality : rowData.country?.country?.laCountryName || '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Postal Code</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.postalCode ? rowData.postalCode : rowData?.pincodeNo || '-'}</div></div>
                    {!rowData.gender &&
                        <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Registration Number</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.registrationNumber ? rowData.registrationNumber : rowData?.registrationNumber || '-'}</div></div>
                    }
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Address</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.address ? rowData.address : '-'}</div></div>
                </div>
                <div className='f-Dd' style={{ width: '100%' }}>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>City</div> <div className='w-3rem'>:</div> <div className='ws-15rem' style={{ width: '100%' }}>{rowData.city ? rowData.city : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>{rowData.gender ? 'Phone No.' : 'Mobile'}  </div> <div className='w-3rem'>:</div> <div className='ws-15rem' style={{ width: '100%' }}>{rowData.phoneNumber ? rowData.phoneNumber : rowData?.mobile || '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Balance</div> <div className='w-3rem'>:</div> <div className='ws-21rem' style={{ width: '100%' }}>{rowData.balance ? rowData.balance : '0.0'}</div></div>
                    {rowData.riskClassification &&
                        <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Risk Classification</div> <div className='w-3rem'>:</div> <div className='ws-15rem' style={{ width: '100%' }}>{rowData.riskClassification ? rowData.riskClassification : '-'}</div></div>
                    }
                    {rowData.comments &&
                        <div className='w-full flex m-2'><div className='w-8rem font-semibold'>{rowData.gender ? 'Risk Reason' : 'Comments'}</div> <div className='w-3rem'>:</div> <div className='ws-15rem' style={{ width: '100%' }}>{rowData.comments ? rowData.comments : '-'}</div></div>
                    }
                </div>
            </div>
        );
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    const item = [
        { label: 'Risk Classification' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = {
        icon: 'pi pi-angle-double-left', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }

    const statusTemplate = (rowData) => {
        const status = rowData?.amlStatus || rowData?.screeningStatus;

        let color = '';

        if (status === 'Pending') {
            color = '#f7af00';
        } else if (status === 'Blocked') {
            color = ':#f42616';
        } else if (status === 'Cleared') {
            color = 'rgb(69 160 75)';
        } else if (status === 'On-going') {
            color = '#3746c6';
        }

        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };
    return (
        <>
            <div className="mainSec" >
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                <div className='container-user h-full w-full'>
                    <BreadCrumb model={item} home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                    {loading ? (
                        <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                            <Triangle
                                height="80"
                                width="80"
                                color="#124A99"
                                ariaLabel="triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="card-Adhoc w-full bg-white" style={{ marginBottom: '8%', position: 'relative', background: '#fff', border: '1px solid #e8e9eb' }} >
                                <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>

                                    <br />
                                    <div className="heading" style={{ padding: '0 20px' }}>Individual Accounts</div><br />

                                    <div className='border-btm-datatble1'>
                                        <DataTable
                                            value={products.highRiskAccounts}
                                            selection={selectedProducts}
                                            stripedRows
                                            className='p-datatable-wrapper risk'
                                            expandedRows={expandedRows}
                                            onChange={(e) => setSelectedProducts(e.value)}
                                            id='edit-btn' paginator rows={10}
                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                            rowExpansionTemplate={rowExpansionTemplate}
                                            style={{ height: 'auto' }}
                                            onRowToggle={onRowToggle}
                                        >
                                            <Column expander style={{ width: '3rem' }} />
                                            <Column field="accountId" header="Account Id" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                            <Column field="fullName" header="Name" sortable></Column>
                                            <Column field="dob" header="DOB" sortable></Column>
                                            <Column field="branch.name" header="Branch" sortable></Column>
                                            <Column field="country.country.laCountryName" header="Country" sortable></Column>
                                            <Column field="profile" header="Profile Type" body={TypeTemplate} sortable></Column>
                                            <Column field="amlStatus" header="Status" body={statusTemplate} sortable></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <br />

                                <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>

                                    <br />
                                    <div className="heading" style={{ padding: '0 20px' }}>Corporates Accounts</div><br />

                                    <div className='border-btm-datatble1'>
                                        <DataTable
                                            value={products.highRiskCorporates}
                                            selection={selectedProducts}
                                            stripedRows
                                            className='p-datatable-wrapper risk'
                                            expandedRows={expandedRows}
                                            onChange={(e) => setSelectedProducts(e.value)}
                                            id='edit-btn' paginator rows={10}
                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                            rowExpansionTemplate={rowExpansionTemplate}
                                            style={{ height: 'auto' }}
                                            onRowToggle={onRowToggle}
                                        >
                                            <Column expander style={{ width: '3rem' }} />
                                            <Column field="id" header="Account Id" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                            <Column field="corporateName" header="Name" sortable></Column>
                                            <Column field="dateOfIncorporation" header="Date Of Incorporation" sortable></Column>
                                            <Column field="branch.name" header="Branch" sortable></Column>
                                            <Column field="country.country.laCountryName" header="Country" sortable></Column>
                                            <Column field="profileType" header="Profile Type" body={TypeTemplate} sortable></Column>
                                            <Column field="screeningStatus" header="Status" body={statusTemplate} sortable></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div >
        </>
    );
}