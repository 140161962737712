import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
// import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
// import { Checkbox } from 'primereact/checkbox';
// import { CascadeSelect } from 'primereact/cascadeselect';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
// import './Transaction.css';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import DesignationService from './DesignationService';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import "../../../views/loading.css";
import '../../../views/Loading.css';
// import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
// import { Menubar } from 'primereact/menubar';
// import { MenuItem } from 'primereact/api';
import { Menu } from 'primereact/menu';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './Designation.css';
// import { InputNumber } from 'primereact/inputnumber';
import { useLocation } from 'react-router-dom';

export default function Designation(props) {


    let emptyProduct = {
        id: "",
        designation: '',
        description: '',
        status: '',
        transactionLimitPerDay: '',
        transactionLimitPerWeek: '',
        transactionLimitPerMonth: '',
        transactionAmountLimitPerDay: '',
        transactionAmountLimitPerWeek: '',
        transactionAmountLimitPerMonth: '',
    };
    const history = useHistory();

    const [selectedData, setSelectedData] = useState(null);
    const [visible, setVisible] = useState(false);
    const [products, setProducts] = useState(null);
    const [productDialog1, setProductDialog1] = useState(false);
    const [productDialog2, setProductDialog2] = useState(false);
    // const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    // const productService = new DesignationService();
    const [productid, setProductid] = useState('')
    const location = useLocation();
    // const email = location?.state?.emailId;
    const accessToken = location?.state?.accessToken;
    const plan = location?.state?.getUserData?.premiumPlan;
    let inputRef = useRef('');

    const [, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const productService = useMemo(() => new DesignationService(), []);

    useEffect(() => {
        setLoading(true);
        productService.getProducts(accessToken)
            .then((data) => {
                setProducts(data);
                setProduct(data);
                setLoading(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            });
    }, [accessToken, productService]);

    const getproduct = () => {
        productService.getProducts(accessToken)
            .then((data) => {
                setProducts(data);
                setProduct(data);
                setLoading(false); // Set loading state to false when data fetching is complete
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            });
    }

    const statusOptions = [
        { name: 'Active', value: 'Active' },
        { name: 'Inactive', value: 'Inactive' }
    ];

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog1(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog1(false);
        setProductDialog2(false);
    }
    const areErrorMessagesEmpty = () => {
        return (
            designationMessage === '' &&
            descriptionMessage === '' &&
            tlpdMessage === '' &&
            tlpwMessage === '' &&
            tlpmMessage === '' &&
            talpdMessage === '' &&
            talpwMessage === '' &&
            talpmMessage === '' &&
            statusMessage === ''
        );
    };

    const [designation, setDesignation] = useState('')
    const [description, setDescription] = useState('')

    const handleCreate = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (areErrorMessagesEmpty()) {
            hideDialog();
            setSubmitted(true);

            const _product = {
                designation: designation,
                description: description,
                status: product.status,
                transactionLimitPerDay: product.transactionLimitPerDay,
                transactionLimitPerWeek: product.transactionLimitPerWeek,
                transactionLimitPerMonth: product.transactionLimitPerMonth,
                transactionAmountLimitPerDay: product.transactionAmountLimitPerDay,
                transactionAmountLimitPerWeek: product.transactionAmountLimitPerWeek,
                transactionAmountLimitPerMonth: product.transactionAmountLimitPerMonth,
            }


            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + location?.state?.accessToken
            };

            let uri = `/designation`;
            try {
                await axios.post(process.env.REACT_APP_BACKEND_HOST + uri, _product, {
                    headers: headers
                });
                getproduct();
                hideDialog();
                setLoading(false);
                setDesignation('');
                setDescription('');
                toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Designation created successfully!' });
            } catch (error) {
                setLoading(false);
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            }
        } else {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Empty fields Occured.', life: 5000 });
        }
    }

    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    const upd = (e) => {
        e.preventDefault();
        hideDialog();
        setSubmitted(true);
        setLoading(true);
        if (
            !product.designation ||
            !product.status
        ) {
            return;
        }
        const data = {
            designation: product.designation,
            status: product.status,
        }

        axios.put(process.env.REACT_APP_BACKEND_HOST + `/designation/${productid}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + location?.state?.accessToken
            },
        })
            .then(response => {
                hideDialog();
                getproduct();
                setLoading(false);
                toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Designation updated successfully!' });
            })
            .catch(error => {
                setLoading(false);
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            });
    }

    const editProduct = (product) => {
        setProductid(product.id)
        setProduct({ ...product });
        setProductDialog2(true);
    }

    const infoTemplat = (rowData) => {
        return (
            <React.Fragment>
                <Button className="pi pi-pencil" onClick={() => editProduct(rowData)} />
            </React.Fragment>
        );
    };

    const [statusMessage, setStatusMessage] = useState('');
    const handleStatusChanges = (e) => {
        const selectedStatus = e.value;
        setProduct({ ...product, status: selectedStatus });

        if (!selectedStatus) {
            setStatusMessage('Status field is required.');
        } else {
            setStatusMessage('');
        }
    };

    const handleStatusChange = (e) => {
        setProduct({ ...product, status: e.value });

    };
    const [designationMessage, setDesignationMessage] = useState('');
    const [descriptionMessage, setDescriptionMessage] = useState('');
    const [tlpdMessage, setTlpdMessage] = useState('');
    const [tlpwMessage, setTlpwMessage] = useState('');
    const [tlpmMessage, setTlpmMessage] = useState('');
    const [talpdMessage, setTalpdMessage] = useState('');
    const [talpwMessage, setTalpwMessage] = useState('');
    const [talpmMessage, setTalpmMessage] = useState('');

    const onInputChange = (e, createdUser) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        switch (createdUser) {
            case 'designation':
                if (/[^a-zA-Z\s]/.test(val)) {
                    return;
                }
                if (val.trim() === '') {
                    setDesignationMessage('Designation is required.');
                } else {
                    setDesignationMessage('');
                }
                break;
            case 'description':
                if (/[^a-zA-Z\s]/.test(val)) {
                    return;
                }
                if (val.trim() === '') {
                    setDescriptionMessage('Description is required.');
                } else {
                    setDescriptionMessage('');
                }
                break;
            case 'transactionLimitPerDay':
                if (val.trim() === '') {
                    setTlpdMessage('Transaction Limit /day is required.');
                } else if (!/^\d+$/.test(val)) {
                    setTlpdMessage('Only contain Numbers.');
                } else {
                    setTlpdMessage('');
                }
                break;
            case 'transactionLimitPerWeek':
                if (val.trim() === '') {
                    setTlpwMessage('Transaction Limit /week is required.');
                } else if (!/^\d+$/.test(val)) {
                    setTlpwMessage('Only contain Numbers.');
                } else {
                    setTlpwMessage('');
                }
                break;
            case 'transactionLimitPerMonth':
                if (val.trim() === '') {
                    setTlpmMessage('Transaction Limit /month is required.');
                } else if (!/^\d+$/.test(val)) {
                    setTlpmMessage('Only contain Numbers.');
                } else {
                    setTlpmMessage('');
                }
                break;
            case 'transactionAmountLimitPerDay':
                if (val.trim() === '') {
                    setTalpdMessage('Transaction Amount Limit /day is required.');
                } else if (!/^\d+$/.test(val)) {
                    setTalpdMessage('Only contain Numbers.');
                } else {
                    setTalpdMessage('');
                }
                break;
            case 'transactionAmountLimitPerWeek':
                if (val.trim() === '') {
                    setTalpwMessage('Transaction Amount Limit /week is required.');
                } else if (!/^\d+$/.test(val)) {
                    setTalpwMessage('Only contain Numbers.');
                } else {
                    setTalpwMessage('');
                }
                break;
            case 'transactionAmountLimitPerMonth':
                if (val.trim() === '') {
                    setTalpmMessage('Transaction Amount Limit /month is required.');
                } else if (!/^\d+$/.test(val)) {
                    setTalpmMessage('Only contain Numbers.');
                } else {
                    setTalpmMessage('');
                }
                break;
            default:
                break;
        }
        _product[`${createdUser}`] = val;
        setProduct(_product);
    }

    const productDialogFooter = (
        <React.Fragment>
            <div className='footer_sec_btn'>
                <Button style={{ margin: 'unset', width: '80px' }} disabled={!product.status || !product.designation} onClick={upd} >Update</Button>
            </div>
        </React.Fragment>
    );
    const productDialogFooter2 = (
        <React.Fragment>
            <div className='footer_sec_btn'>
                <Button
                    style={{ margin: 'unset', width: '80px' }}
                    onClick={handleCreate}
                // disabled={
                //     !product.designation ||
                //     !product.description ||
                //     !product.status ||
                //     !product.transactionLimitPerDay ||
                //     !product.transactionLimitPerWeek ||
                //     !product.transactionLimitPerMonth ||
                //     !product.transactionAmountLimitPerDay ||
                //     !product.transactionAmountLimitPerWeek ||
                //     !product.transactionAmountLimitPerMonth ||
                //     designationMessage !== '' ||
                //     descriptionMessage !== '' ||
                //     statusMessage !== '' ||
                //     tlpdMessage !== '' ||
                //     tlpwMessage !== '' ||
                //     tlpmMessage !== '' ||
                //     talpdMessage !== '' ||
                //     talpwMessage !== '' ||
                //     talpmMessage !== ''
                // }
                >
                    Submit
                </Button>
            </div>
        </React.Fragment>
    );


    const onSelectionChange = (e) => {
        setSelectedData(e.value);
    };


    const cols = [
        { field: 'id', header: 'Designation Id' },
        { field: 'designation', header: 'Designation' },
        { field: 'createdUser', header: 'Created By' },
        { field: 'modifiedDate', header: 'Created Date' },
        { field: 'modifiedDate', header: 'Updated Date' },
        // { field: 'status', header: 'Status' },

    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const handleExportToPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('Designation Datas.pdf');
            });
        });
    };


    const handleExportToCSV = () => {
        const csvContent = convertToCSV(products);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'Designation Datas.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(','));

            for (const row of data) {
                const values = Object.values(row).join(',');
                csvRows.push(values);
            }
        }
        return csvRows.join('\n');
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(products);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        XLSX.writeFile(workbook, 'Designation Datas.xlsx');
    };

    const exportToXLS = () => {
        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(products);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        XLSX.writeFile(workbook, 'Designation Datas.xls');
    };

    let menu = null;

    const items = [
        {
            label: 'XLS',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToXLS();
            }
        },
        {
            label: 'XLSX',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToExcel();
            }
        },
        {
            label: 'CSV',
            icon: () => <FaFileCsv />,
            command: () => {
                setVisible(false);
                handleExportToCSV();
            }
        },
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const itemsFB = [
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const toggleMenu = (event) => {
        setVisible(!visible);
        menu.toggle(event);
    };
    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Designation Datas List</title></head><body>');
        printWindow.document.write('<style>table { border-collapse: collapse; width: 100%; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
        printWindow.document.write('<h1>Designation Datas List</h1>');

        printWindow.document.write('<table>');
        printWindow.document.write('<thead>');
        printWindow.document.write('<tr>');

        const columns = [
            { field: "designation", header: "Designation" },
            { field: "description", header: "Description" },
            { field: "transactionAmountLimitPerDay", header: "Transaction Amount Limit /day" },
            { field: "transactionAmountLimitPerMonth", header: "Transaction Amount Limit /month" },
            { field: "transactionAmountLimitPerWeek", header: "Transaction Amount Limit /week" },

        ];

        columns.forEach(col => {
            printWindow.document.write(`<th>${col.header}</th>`);
        });

        printWindow.document.write('<th>Status</th>');
        printWindow.document.write('</tr>');
        printWindow.document.write('</thead>');
        printWindow.document.write('<tbody>');

        if (products) {
            products.forEach(product => {
                printWindow.document.write('<tr>');

                columns.forEach(col => {
                    const fieldValue = product[col.field] !== undefined ? product[col.field] : '-';
                    printWindow.document.write(`<td>${fieldValue}</td>`);
                });

                printWindow.document.write(`<td>${product.status}</td>`);

                printWindow.document.write('</tr>');
            });
        }

        printWindow.document.write('</tbody></table></body></html>');
        printWindow.document.close();
        printWindow.print();
    };




    const item = [
        // { label: 'ADMIN' },
        { label: 'Designation' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }

    const TypeTemplate = (rowData) => {
        const status = rowData.status;

        let color = '';

        if (status.toLowerCase() === 'inactive') {
            color = '#f7af00';
        } else if (status.toLowerCase() === 'active') {
            color = 'rgb(69 160 75)';
        }

        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };

    return (
        <>
            <div className='mainSec cou'>
                <Toast ref={toast} style={{ marginTop: '70px' }} />
                <div className='container-user h-full w-full'>
                    <BreadCrumb model={item} home={home} style={{ border: 'none' }} />
                    {loading ? (
                        <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>

                            <Triangle
                                height="80"
                                width="80"
                                color="#124A99"
                                ariaLabel="triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        <div className="card-Adhoc bg-white w-full unique-data-response" style={{ marginBottom: '8%', background: '#fff', position: 'relative', border: '1px solid #e8e9eb' }} >
                            <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                                <div className="flex flex-wrap align-items-center justify-content-end" style={{ borderBottom: 'rgba(209, 209, 209, 1) 1px solid' }}>
                                    <div className='country-conatent flex justify-content-end  pt-2 pb-2 px-3 bg-gray-50'>
                                        <div className="tab-head-btn flex justify-content-end flex-wrap w-full" >

                                            <div className=" " >
                                                <span className="p-input-icon-left ">
                                                    <i className="pi pi-search" />
                                                    <InputText placeholder="Search..." style={{ border: '2px solid #ccc' }} className='py-2 w-full' onInput={(e) => setGlobalFilter(e.target.value)} />
                                                </span>
                                            </div>

                                            <div className="flex align-items-center justify-content-center" style={{ zIndex: '1' }}>
                                                <Button className=' mr-2 flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={openNew} tooltip={props?.roleName === 'FreeTrial' ? "Add $" : 'Create'} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-plus '></i></Button>
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <Button className=' mr-2  flex align-items-center justify-content-center' disabled={products.length === 0} id="b-btn" style={{ border: 'none', backgroundColor: 'transparent' }} tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleMenu} ><i className='pi pi-upload '></i></Button>
                                                <Menu
                                                    className='unique-menu-exp'
                                                    model={plan === 'FreeTrial' || plan === 'BasicAdmin' ? itemsFB : items}
                                                    popup
                                                    ref={(el) => (menu = el)}
                                                    onHide={() => setVisible(false)}
                                                    appendTo={document.body}
                                                />
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <Button className=' mr-2 flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={handlePrint} disabled={products.length === 0} tooltip="Print" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-print '></i></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='border-btm-datatble' >
                                    <DataTable
                                        value={products}
                                        showGridlines id='edit-btn'
                                        paginator rows={10} rowsPerPageOptions={[10, 20, 30, 40]}
                                        // selectionMode="checkbox"
                                        globalFilter={globalFilter}
                                        selection={selectedData}
                                        stripedRows
                                        onSelectionChange={onSelectionChange} >
                                        {cols.map((col, index) => (
                                            <Column key={index} field={col.field} header={col.header} />
                                        ))}
                                        <Column field="status" header="Status" body={TypeTemplate} ></Column>
                                        <Column header="Edit" body={infoTemplat} ></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    )}

                    <Dialog visible={productDialog1} header="Add Details" draggable={false} modal className="sing_up_main_Dialog" footer={productDialogFooter2} onHide={hideDialog}>
                        <div className=" overflow-hidden py-4 " id="advanced-ahoc-rect" style={{ overflow: 'auto' }}>

                            <div className="feild">
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Designation<span>*</span></div>
                                        <InputText id="designation" defaultValue={inputRef.current} onChange={(e) => onInputChange(e, 'designation')} className={classNames({ 'p-invalid': submitted && !product.designation })} style={{ width: '100%' }} />
                                        {submitted && !product.designation && <small className="error-message">Designation is required.</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Status</div>
                                        <Dropdown id="status"
                                            className='w-full'
                                            value={product.status}
                                            onChange={handleStatusChange}
                                            options={statusOptions}
                                            optionLabel="name"
                                            style={{ width: '100%', border: 'solid 1px rgb(211, 215, 225)' }} />
                                        {submitted && !product.status && <small className="error-message">status is required.</small>}
                                        {/* </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={productDialog1} draggable={false} modal header='Add Details' className="sing_up_main_Dialog" footer={productDialogFooter2} onHide={hideDialog}>
                        <div className="feild c pt-3 unique-fie-mob unique-fields-row">
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Designation<span>*</span></div>
                                    <InputText id="designation" type="text" value={designation} onChange={(e) => setDesignation(e.target.value)} className={`w-full ${designationMessage ? "p-invalid" : ""}`} />
                                    {designationMessage && (<small className="p-error">{designationMessage}</small>)}
                                </div>
                            </div>
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Description<span>*</span></div>
                                    <InputText id="description" value={description} onChange={(e) => setDescription(e.target.value)} className={`w-full ${descriptionMessage ? "p-invalid" : ""}`} />
                                    {descriptionMessage && (<small className="p-error">{descriptionMessage}</small>)}
                                </div>
                                <div className="input">
                                    <div className="label">Status<span>*</span></div>
                                    <Dropdown id="status"
                                        className={`w-full ${statusMessage ? "p-invalid" : ""}`}
                                        value={product.status}
                                        onChange={handleStatusChanges}
                                        options={statusOptions}
                                        optionLabel="name"
                                        style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                    />
                                    {statusMessage && (<small className="p-error">{statusMessage}</small>)}
                                </div>
                            </div>
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Transaction Limit /day<span>*</span></div>
                                    <InputText keyfilter='num' defaultValue={inputRef.current} onChange={(e) => onInputChange(e, 'transactionLimitPerDay')} className={`w-full ${tlpdMessage ? "p-invalid" : ""}`} />
                                    {tlpdMessage && (<small className="p-error">{tlpdMessage}</small>)}
                                </div>
                                <div className="input">
                                    <div className="label">Transaction Amount Limit /day<span>*</span></div>
                                    <InputText keyfilter='num' defaultValue={inputRef.current} onChange={(e) => onInputChange(e, 'transactionAmountLimitPerDay')} className={`w-full ${talpdMessage ? "p-invalid" : ""}`} />
                                    {talpdMessage && (<small className="p-error">{talpdMessage}</small>)}
                                </div>
                            </div>
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Transaction Limit /week<span>*</span></div>
                                    <InputText keyfilter='num' defaultValue={inputRef.current} onChange={(e) => onInputChange(e, 'transactionLimitPerWeek')} className={`w-full ${tlpwMessage ? "p-invalid" : ""}`} />
                                    {tlpwMessage && (<small className="p-error">{tlpwMessage}</small>)}
                                </div>
                                <div className="input">
                                    <div className="label">Transaction Amount Limit /week<span>*</span></div>
                                    <InputText keyfilter='num' defaultValue={inputRef.current} onChange={(e) => onInputChange(e, 'transactionAmountLimitPerWeek')} className={`w-full ${talpwMessage ? "p-invalid" : ""}`} />
                                    {talpwMessage && (<small className="p-error">{talpwMessage}</small>)}
                                </div>
                            </div>
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Transaction Limit /month<span>*</span></div>
                                    <InputText keyfilter='num' defaultValue={inputRef.current} onChange={(e) => onInputChange(e, 'transactionLimitPerMonth')} className={`w-full ${tlpmMessage ? "p-invalid" : ""}`} />
                                    {tlpmMessage && (<small className="p-error">{tlpmMessage}</small>)}
                                </div>
                                <div className="input">
                                    <div className="label">Transaction Amount Limit /month<span>*</span></div>
                                    <InputText keyfilter='num' defaultValue={inputRef.current} onChange={(e) => onInputChange(e, 'transactionAmountLimitPerMonth')} className={`w-full ${talpmMessage ? "p-invalid" : ""}`} />
                                    {talpmMessage && (<small className="p-error">{talpmMessage}</small>)}
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={productDialog2} header=" Update Details" modal className="sing_up_main_Dialog" footer={productDialogFooter} onHide={hideDialog}>

                        <div className="feild pb-2">
                            <br />
                            <div className="flex">
                                <div className="input">
                                    <div className="label">Designation</div>
                                    <InputText type="text" disabled value={product.designation} onChange={(e) => onInputChange(e, 'designation')} />
                                </div>
                            </div>

                            <div className="flex">
                                <div className="input">
                                    <div className="label">Status</div>
                                    <Dropdown id="status"
                                        className='w-full'
                                        value={product.status}
                                        onChange={handleStatusChange}
                                        options={statusOptions}
                                        optionLabel="name"
                                        style={{ border: '1px solid #ced4da', borderRadius: '6px', padding: 'unset' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    );
}