import React, { useEffect } from 'react';
import { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import IndividualRegisteration from '../IndividualRegisteration/IndividualRegisteration';
import CorporateRegisteration from '../CorporateRegisteration/CorporateRegisteration';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Triangle } from 'react-loader-spinner';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import './Registeration.css';

function Registeration() {

    const [activeSection, setActiveSection] = useState('Individual');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSectionClick = (section) => {
        setActiveSection(section);
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 600);
    }, []);

    const items = [
        { label: 'Risk Check' },
        { label: 'Account Registration' },
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    // const home1 = { icon: 'pi pi-angle-double-left', command: () => handleNavigate('/admin/dashboard') }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    const option = [
        { label: 'Individual Registration', value: 'Individual' },
        { label: 'Corporate Registration', value: 'corporate' },
    ];
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    return (
        <>
            <div className="mainSec main-scroll-height" >
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />

                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="feild-Sec">
                            <Dropdown className='dropdown' style={{ width: '100%' }} options={option} onChange={(selectedOption) => handleSectionClick(selectedOption.value)} value={activeSection} />
                        </div>

                        <div className='condent_sec' style={{ height: (windowWidth < 500 ? '65vh' : '70vh') }}>
                            {activeSection === 'Individual' && <IndividualRegisteration />}
                            {activeSection === 'corporate' && <CorporateRegisteration />}
                        </div>
                    </>

                )}
            </div>
        </>
    )
}

export default Registeration