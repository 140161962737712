import React, { useState, useEffect, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { useHistory } from 'react-router-dom';
import { Toast } from "primereact/toast";
import axios from "axios";
import './UserProfile.css';
import "../../../views/Loading.css";
import { BreadCrumb } from 'primereact/breadcrumb';
import { TbEdit } from "react-icons/tb";
import { Triangle } from 'react-loader-spinner';
import defaultUserLogo from '../../../assets/imagelogo.jpg';
// import Resizer from 'react-image-file-resizer';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Compressor from 'compressorjs';

function UserProfile(props) {
    const [visible, setVisible] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(true);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [showIcon, setShowIcon] = useState(false);
    const toast = useRef(null);
    const history = useHistory();
    const MAX_FILE_SIZE_MB = 5;
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let passRef = useRef('');
    // const email = location?.state?.getUserData?.emailId;
    const accessToken = location?.state?.accessToken;
    const refreshToken = location?.state?.refreshToken;
    const navigate = useHistory();
    const loginUserPlan = sessionStorage?.roleName || props?.roleName;
    const [planDetails, setPlanDetails] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (loginUserPlan === 'FreeTrial' || loginUserPlan === 'BasicAdmin' || loginUserPlan === 'StartupAdmin' || loginUserPlan === 'ProAdmin') {
            setPlanDetails(true);
        } else {
            setPlanDetails(false);
        }
        setLoading(true);
        const fetchProfileData = async () => {
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                };
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/get`, requestOptions);
                setProfileData(response.data);
            } catch (error) {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                }
                else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                }
            }
            setLoading(false);
        };
        fetchProfileData();
    }, [accessToken]);

    const showSuccessToast = (message) => {
        toast.current.show({ severity: 'success', summary: 'Successfully Changed', detail: message, life: 5000 });
    };

    const goUpdate = () => {
        history.push({
            pathname: '/admin/generalinformation',
            state: { selectedMenuItemLabel: 'GENERAL INFORMATION', getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: location?.state?.getUserData?.emailId },
        });
    }

    const encrypt = (data) => {
        const key = CryptoJS.enc.Utf8.parse('acaiaalthi0001uu'); // 16, 24, or 32 bytes key
        const iv = CryptoJS.enc.Utf8.parse('qwertyuioplkjhgf'); // 16 bytes IV
        const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
        return encrypted.toString();
    };

    const handleChangePassword = () => {
        setLoading(true);
        let isValid = true;
        if (!currentPassword) {
            setCurrentPasswordError('Current Password is required.');
            isValid = false;
        } else {
            setCurrentPasswordError('');
        }

        if (!newPassword) {
            setNewPasswordError('New Password is required.');
            isValid = false;
        } else if (newPassword.length < 8) {
            setNewPasswordError('New Password must be at least 8 characters.');
            isValid = false;
        } else {
            setNewPasswordError('');
        }

        if (!confirmPassword) {
            setConfirmPasswordError('Confirm Password is required.');
            isValid = false;
        } else if (confirmPassword.length < 8) {
            setConfirmPasswordError('Confirm Password must be at least 8 characters.');
            isValid = false;
        } else if (newPassword !== confirmPassword) {
            setConfirmPasswordError('New Password and Confirm Password do not match.');
            isValid = false;
        } else if (confirmPassword === newPassword) {
            setConfirmPasswordError('');
        } else {
            setConfirmPasswordError('');
        }


        // If form validation fails, stop execution and set loading state to false
        if (!isValid) {
            setLoading(false);
            return;
        }

        // If form validation passes, proceed with API request
        const data = {
            oldPassword: currentPassword,
            newPassword: encrypt(newPassword),
            confirmNewPassword: encrypt(confirmPassword),
        };
        axios.post(process.env.REACT_APP_IDM_BACKEND_HOST + '/users/change_password', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                setVisible(false);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'New Password updated successfully!' });
            })
            .catch(error => {
                setVisible(false);
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                }
            })
            .finally(() => {
                // Set loading state to false after the API request completes (whether successful or not)
                setLoading(false);
            });
        setConfirmPasswordError('');
    };


    useEffect(() => {
        if (location?.state?.getUserData?.data || sessionStorage?.hinT_D) {
            fetchProfileImage();
        }
    }, [accessToken]);

    const fetchProfileImage = () => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + `/get/image`, {
            headers: {
                'Authorization': 'Bearer ' + accessToken
            },
            responseType: 'arraybuffer'
        })
            .then(response => {
                const base64Image = btoa(String.fromCharCode(...new Uint8Array(response.data)));
                const dataUrl = `data:image/png;base64,${base64Image}`;
                setUploadedImageUrl(dataUrl);
                navigate.push({
                    state: {
                        getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: location?.state?.getUserData?.emailId
                    },
                });
                // window.location.reload();
            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                }
                else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                }
            });
    };

    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    const [base64String, setBase64String] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [compressedImage, setCompressedImage] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            toast.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'File size exceeds the limit.',
                life: 3000
            });
            return;
        }

        new Compressor(file, {
            quality: 0.6,
            maxWidth: 800,
            maxHeight: 800,
            success(result) {
                setCompressedImage(URL.createObjectURL(result));
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64 = reader.result.split(',')[1];
                    setBase64String(base64);
                    setImagePreview(reader.result);
                    handleImageUpload(result)
                };
                reader.readAsDataURL(file);
            },
            error(err) {
                console.error(err);
            },
        });
    };

    const handleImageUpload = async (data) => {
        setLoading(true);

        const selectedFile = data;

        const formData = new FormData();
        formData.append('file', selectedFile);

        axios.post(process.env.REACT_APP_BACKEND_HOST + '/upload/image', formData, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(response => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Image Uploaded Successfully',
                    life: 3000
                });
                setShowIcon(false);
                setLoading(false);
                sessionStorage.setItem('hinT_D', '-');
                window.location.reload();
            })
            .catch(error => {
                setLoading(false);
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                }
            });
    };

    const items = [
        { label: 'User Profile' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                    refreshToken: refreshToken,
                },
            })
    }
    const home1 = {
        icon: 'pi pi-angle-double-left', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                    refreshToken: refreshToken,
                },
            })
    }

    return (
        <>
            <div className="mainSec userprofile" >
                <Toast ref={toast} position="top-right" style={{ marginTop: '3%' }} />
                <BreadCrumb model={items} home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="card card-prof w-full bg-white" style={{ background: '#fff', position: 'relative', }} >
                        <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                            <br />
                            <div className="w-full flex align-items-center justify-content-end px-5" onClick={goUpdate}>
                                <div className=" flex align-items-center justify-content-center font-bold up-edit-bn" style={{ color: '#124A99', cursor: 'pointer' }}>
                                    Edit &nbsp;<TbEdit className=" font-bold user-icon-profile" style={{ color: '#124A99', fontSize: '20px', cursor: 'pointer' }} />
                                </div>
                            </div>
                            <br />
                            <div className="user-profile-content flex">
                                <div className=" text-center" id="user-info">
                                    <div className="user-avatar " onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)}>
                                        <Avatar
                                            image={uploadedImageUrl || defaultUserLogo}
                                            size="xlarge"
                                            shape="circle"
                                            className="up-avtr overflow-hidden "
                                        >
                                            {showIcon && (
                                                <div className="overlay mb-2" style={{ position: 'absolute', cursor: 'pointer' }}>
                                                    <label htmlFor="imageInput" className="px-2 py-1" style={{ fontSize: '10px', border: '1px solid white', borderRadius: '4px', color: '#000', background: 'white', fontWeight: 'lighter', cursor: 'pointer' }}>
                                                        Choose Image
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="imageInput"
                                                        accept="image/*"
                                                        style={{ display: 'none', cursor: 'pointer' }}
                                                        onChange={handleFileChange}
                                                    />
                                                </div>
                                            )}
                                        </Avatar>
                                    </div>

                                    {/* )} */}
                                    {profileData ? <h3 className="user-name  text-center mb-3" style={{ color: '#124A99' }}>{profileData.firstName + " " + profileData.lastName}</h3> : <p>-</p>}
                                    <div className="flex align-items-end justify-content-center " id="up-button">
                                        <Button className="user-profile-btn p-button-text-icon-right w-12rem bg-transparent font-bold px-2" style={{ color: '#124A99', border: '1px solid #124A99' }} onClick={() => setVisible(true)}>Change&nbsp;Password
                                            <i className="pi pi-unlock pl-3 font-bold" />
                                        </Button>
                                    </div>
                                </div>
                                <div className="formgrid grid pb-4" id="user-profile">
                                    <div className=" col-12 md:col-4 u1-a">
                                        <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>Full&nbsp;Name</p>
                                        {profileData ? <p className="text-gray-500 ui-b mmm-kk">{profileData.firstName + " " + profileData.lastName || 'N/A'}</p> : <p>-</p>}
                                    </div>
                                    <div className=" col-12 md:col-6 u1-a">
                                        <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>Email</p>
                                        {profileData ? <p className="text-gray-500 ui-b" id="e-mail-up" style={{ wordWrap: 'break-word' }}>{profileData.emailId || 'N/A'}</p> : <p>-</p>}
                                    </div>
                                    <div className="col-12 md:col-4 u1-a">
                                        <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>Branch&nbsp;Name</p>
                                        {profileData ? (<p className="text-gray-500 ui-b"> {profileData.branchName && profileData.branchName.name ? profileData.branchName.name : profileData.branchName || 'N/A'}</p>) : (
                                            <p>-</p>
                                        )}
                                    </div>

                                    {planDetails ?
                                        <div className=" col-12 md:col-4 u1-a">
                                            <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>Role</p>
                                            {profileData ? <p className="text-gray-500 ui-b">{loginUserPlan || 'N/A'}</p> : <p>-</p>}
                                        </div>
                                        : null}

                                    <div className=" col-12 md:col-4 u1-a" >
                                        <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>Phone&nbsp;No</p>
                                        {profileData ? <p className="text-gray-500 ui-b">{profileData.phoneNumber || 'N/A'}</p> : <p>-</p>}
                                    </div>
                                    <div className=" col-12 md:col-4 u1-a" >
                                        <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>Address</p>
                                        {profileData ? <p className="text-gray-500 ui-b">{profileData.address || 'N/A'}</p> : <p>-</p>}
                                    </div>

                                    {profileData?.country?.country?.laCountryName ?
                                        <div className=" col-12 md:col-4 u1-a" >
                                            <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>Country</p>
                                            <p className="text-gray-500 ui-b">{profileData.country.country.laCountryName || 'N/A'}</p>
                                        </div>
                                        : null}

                                    <div className=" col-12 md:col-4 u1-a" >
                                        <p style={{ color: '#124A99', fontWeight: 'bold', fontSize: '15px' }}>City</p>
                                        {profileData ? <p className="text-gray-500 ui-b">{profileData.city || 'N/A'}</p> : <p>-</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <Dialog header="Change Password" className="sing_up_main_Dialog change" style={{ width: '24rem' }} visible={visible} onHide={() => setVisible(false)}

                        >
                            <div className="feild feild-m c pt-3 ">
                                <div className="flex">
                                    <div className="input mnmnmn">
                                        <div className="label">Current Password<span>*</span></div>
                                        <div className="see_Pass">
                                            <InputText
                                                type={showCurrentPassword ? 'text' : 'password'}
                                                className="w-full"
                                                defaultValue={passRef.current}
                                                onChange={(e) => setCurrentPassword(e.target.value)}
                                                required
                                            />
                                            <i onClick={() => setShowCurrentPassword(!showCurrentPassword)} className={`pi ${showCurrentPassword ? 'pi-eye' : 'pi-eye-slash'}`}></i>
                                        </div>
                                        {(currentPassword.length < 8 && currentPasswordError) && <small className="errors-messages text-red-500">{currentPasswordError}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input mnmnmn">
                                        <div className="label">New Password<span>*</span></div>
                                        <div className="see_Pass">
                                            <InputText
                                                type={showNewPassword ? 'text' : 'password'}
                                                className="w-full"
                                                defaultValue={passRef.current}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                            <i onClick={() => setShowNewPassword(!showNewPassword)} className={`pi ${showNewPassword ? 'pi-eye' : 'pi-eye-slash'}`}></i>
                                        </div>
                                        {(newPassword.length < 8 && newPasswordError) && <small className="errors-messages text-red-500">{newPasswordError}</small>}
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="input mnmnmn">
                                        <div className="label">Confirm Password<span>*</span></div>
                                        <div className="see_Pass">
                                            <InputText
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                className="w-full"
                                                defaultValue={passRef.current}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                                style={{ border: (newPassword && newPassword === confirmPassword) ? '' : newPassword && confirmPassword ? '1px solid red' : '' }}
                                            />
                                            <i onClick={() => setShowConfirmPassword(!showConfirmPassword)} className={`pi ${showConfirmPassword ? 'pi-eye' : 'pi-eye-slash'}`}></i>
                                        </div>
                                        {(confirmPassword.length < 8 && confirmPasswordError) || (newPassword && confirmPassword && newPassword !== confirmPassword) && <small className="errors-messages text-red-500">{confirmPasswordError || "New Password mismatch."}</small>}
                                    </div>
                                </div>
                                <div className="flex btn btn32">
                                    <div className="change-btn">
                                        <Button onClick={handleChangePassword} style={{ width: '80px', backgroundColor: '#124A99', border: '#124A99' }}>Change</Button>
                                    </div>
                                </div>
                            </div >
                        </Dialog>
                    </div>
                )}


            </div >
        </>
    )
}
export default UserProfile;