import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import PhoneInput from 'react-phone-input-2';
// import classNames from "classnames";
import './Branches.css';
import BranchesService from './BranchesService.js';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import "../../../views/Loading.css";
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

export default function Branches(props) {

    let emptyProduct = {
        id: '',
        name: '',
        address: '',
        city: '',
        code: '',
        country: '',
        emailId: '',
        phoneNumber: '',
        postalCode: ''
    };
    const [selectedName, setSelectedName] = useState(null)
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [productDialog1, setProductDialog1] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [loading, setLoading] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [countrydata, setCountrydata] = useState([]);
    const [country, setCountry] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const toast = useRef(null);
    // const productService = new BranchesService();
    const [expandedRows, setExpandedRows] = useState([]);
    const history = useHistory()
    const [, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    const plan = location?.state?.getUserData?.premiumPlan;
    let inputRef = useRef('');
    const productService = useMemo(() => new BranchesService(), []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const showErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    useEffect(() => {
        setLoading(true);

        productService.getProducts(accessToken)
            .then((data) => {
                setProducts(data);
                setLoading(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    if (typeof process !== 'undefined' && (process.release || process.release.name === 'node')) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            });
    }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function fetchCountryData() {

            try {
                const headers = {
                    Authorization: 'Bearer ' + accessToken
                };
                let uri = "/country";

                const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, {
                    headers: headers
                });

                if (Array.isArray(response.data)) {
                    setCountrydata(response.data);
                } else {
                    console.error('Country data is not an array:', response.data);
                }
            } catch (error) {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    if (typeof process !== 'undefined' && (process.release || process.release.name === 'node')) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            }
        }
        fetchCountryData();
    }, [accessToken]);

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
        setPhoneNumber('');
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCountry(false);
        setProductDialog(false);
        setProductDialog1(false);
        setProduct({
            name: '',
            address: '',
            city: '',
            postalCode: '',
            code: '',
            emailId: '',
            phoneNumber: ''
        });
        setPhoneNumber('');
        setErNameMessage('');
        setErMailMessage('');
        setErCodeMessage('');
        setErCityMessage('');
        setErPostalMessage('');
        setPhoneNumber('');
        setCountry('');
        setErAddressMessage('');
        setErCountryMessage('');
        setErPhMessage('');
    };

    const countrychange = (event) => {
        if (!event.target.value) {
            setErCountryMessage('Country is required.');
        } else {
            setErCountryMessage('');
        }
        const selectedCountry = countrydata.find((item) => item.country.laCountryName === event.target.value);
        setCountry(selectedCountry);

    };

    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    const saveProduct = () => {
        setSubmitted(true);
        setLoading(true);

        if (product.name.trim()) {
            const countryPayload = {
                status: ''
            };
            const countryPayloads = {
                id: country.id,
            };
            let _products = [...products];
            let _product = { ...product, country: countryPayloads, phoneNumber: phoneNumber };

            if (product.id) {
                setLoading(true);
                const index = findIndexById(product.id);
                productService.updateProduct(_product, countryPayload, location?.state?.emailId, accessToken)
                    .then(() => {
                        _products[index] = _product;
                        setLoading(false);
                        setProducts(_products);
                        setProductDialog(false);
                        setCountry(false);
                        setProductDialog1(false);
                        setProduct(emptyProduct);
                    })
                    .catch((error) => {
                        if (error?.response && error?.response?.data && error?.response?.data?.message) {
                            const errorMessage = error?.response?.data?.message;
                            displayErrorToast(errorMessage);
                            setLoading(false);
                        } else if (error?.response && error?.response?.status === 403) {
                            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                            setLoading(false);
                        } else if (error?.response?.status === 401) {
                            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                            setLoading(false);
                        }
                        // showErrorToast('Branch Update Failed');
                        setLoading(false);
                    });

            } else {
                productService.createProduct(_product, accessToken)
                    .then(() => {
                        toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Branches created successfully!' });
                        setPhoneNumber('');
                        setLoading(false);
                        _product.id = createId();
                        _products.push(_product, accessToken);
                        setProducts(_products);
                        setProductDialog(false);
                        setCountry(false);
                        setProductDialog1(false);
                        setProduct(emptyProduct);
                        setTimeout(() => {
                            getproduct();
                        }, 1000);
                    })
                    .catch((error) => {
                        if (error?.response && error?.response?.data && error?.response?.data?.message) {
                            const errorMessage = error?.response?.data?.message;
                            displayErrorToast(errorMessage);
                            setLoading(false);
                            setPhoneNumber('');
                        } else if (error?.response && error?.response?.status === 403) {
                            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                            setLoading(false);
                        } else if (error?.response?.status === 401) {
                            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                            setLoading(false);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                        setProducts(_products);
                        // setProductDialog(false);
                        setCountry(false);
                        setProductDialog1(false);
                        setProduct(emptyProduct);
                        setPhoneNumber('');
                        setTimeout(() => {
                            getproduct();
                            setProductDialog(false)
                        }, 1000);
                    });
            }
        } else {
            setLoading(false);
        }
    };
    const updateProduct = async () => {
        setSubmitted(true);
        setLoading(true);
        setProductDialog1(false);

        if (product && product.name.trim() && product.status) {
            // const countryPayload = {
            //     status: ''
            // };
            const countryPayloads = {
                id: product?.country?.id,
                laCountryName: product?.country?.laCountryName,
            };
            let _products = [...products];
            let _product = { ...product, country: countryPayloads, phoneNumber: phoneNumber };

            if (product.id) {
                try {
                    const index = findIndexById(product.id);
                    await productService.updateProduct(_product, accessToken);
                    _products[index] = _product;
                    setLoading(false);
                    setProducts(_products);
                    setProductDialog(false);
                    setCountry(false);
                    setProductDialog1(false);
                    setProduct(emptyProduct);
                    toast.current.show({ severity: 'success', summary: 'Successfully', detail: 'Branches updated successfully!' });
                    getproduct();
                } catch (error) {
                    // Handle errors appropriately
                    console.error('Error updating product:', error);
                    setLoading(false);
                    showErrorToast('Branch Update Failed');
                }
            }
        } else {
            setLoading(false);
        }
    };

    const getproduct = () => {
        setLoading(true);
        productService.getProducts(accessToken)
            .then((data) => {
                setProducts(data);
                setLoading(false);
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
                setLoading(false);
            });
    }

    const editProduct = (product) => {
        setPhoneNumber(product.phoneNumber);
        setProduct({ ...product }); // Make sure to spread other properties as well
        setProductDialog1(true);
    };


    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }
    const [erNameMessage, setErNameMessage] = useState('');
    const [erAddressMessage, setErAddressMessage] = useState('');
    const [erMailMessage, setErMailMessage] = useState('');
    const [erCodeMessage, setErCodeMessage] = useState('');
    const [erCityMessage, setErCityMessage] = useState('');
    const [erPostalMessage, setErPostalMessage] = useState('');
    const [erCountryMessage, setErCountryMessage] = useState('');
    const [erPhMessage, setErPhMessage] = useState('');


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        switch (name) {
            case 'name':
                if (/[^a-zA-Z\s]/.test(val)) {
                    return;
                }
                if (val.length === 0) {
                    setErNameMessage('Branch name is required.');
                } else if (val.length < 3) {
                    setErNameMessage('Must have at least 3 characters.');
                } else if (!/^[a-zA-Z\s]+$/.test(val)) {
                    setErNameMessage('Only contain Letters and spaces.');
                } else {
                    setErNameMessage('');
                }
                break;
            case 'code':
                const digitValid = /\D/.test(val);
                if (digitValid || val.length > 5) {
                    return;
                }
                if (val.length === 0) {
                    setErCodeMessage('Code is required.');
                } else if (!/^\d+$/.test(val)) {
                    setErCodeMessage('Only contain Numbers.');
                } else if (val.length !== 5) {
                    setErCodeMessage('Must contain exactly 5 digits.');
                } else {
                    setErCodeMessage('');
                }
                break;
            case 'city':
                if (/[^a-zA-Z\s]/.test(val)) {
                    return;
                }
                if (val.length === 0) {
                    setErCityMessage('City is required.');
                } else {
                    setErCityMessage('');
                }
                break;
            case 'emailId':
                const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                // const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
                if (val.length === 0) {
                    setErMailMessage('EmailId is required.');
                } else if (!emailRegex.test(val.toLowerCase())) {
                    setErMailMessage('Enter a valid email address.');
                } else {
                    setErMailMessage('');
                }
                break;
            case 'postalCode':
                const digitValids = /\D/.test(val);
                if (digitValids) {
                    return;
                }
                if (val.length === 0) {
                    setErPostalMessage('Postal Code is required.');
                } else if (!/^\d+$/.test(val)) {
                    setErPostalMessage('Only contain Numbers.');
                } else {
                    setErPostalMessage('');
                }
                break;
            case 'address':
                if (val.length === 0) {
                    setErAddressMessage('Address is required.');
                } else {
                    setErAddressMessage('');
                }
                break;
            default:
                break;
        }



        if (name === 'emailId') {
            _product[`${name}`] = val.toLowerCase();
            setProduct(_product);
        } else {
            _product[`${name}`] = val;
            setProduct(_product);
        }


    }

    const handlePhChange = (newPhoneNumber) => {
        setPhoneNumber(newPhoneNumber);
        if (!newPhoneNumber) {
            setErPhMessage('Phone Number is required.');
        } else {
            setErPhMessage('');
        }
    };

    const handleStatusChange = (e) => {
        setProduct({ ...product, status: e.value });
    };

    const infoTemplate = (rowData) => {
        return (
            <Button icon="pi pi-pencil" disabled={(plan === 'FreeTrial' ? true : false)} style={{ color: '#124A99' }} onClick={() => editProduct(rowData)} />
        );
    };

    const opdialouge = () => {
        setShowDialog(true);
    }

    const reportTemplate = (rowData) => {
        const { name } = rowData;

        const handleReportClick = () => {
            setSelectedName(name);
            opdialouge();
        };

        return (
            <Button>
                <i className="pi pi-info-circle" style={{ color: '#124A99' }} size={20} onClick={handleReportClick} />
            </Button>
        );
    };

    const hideDialogHandler = () => {
        setShowDialog(false);
        setStartDate(null);
        setEndDate(null);
        // sessionStorage.removeItem('branch_name');
    };

    const handleApplyFilter = () => {
        fetchData();
        hideDialogHandler();
    };

    const fetchData = async () => {
        try {
            const formattedStartDate = startDate ? format(startDate, 'MM/dd/yyyy') : null;
            const formattedEndDate = endDate ? format(endDate, 'MM/dd/yyyy') : null;
            const params = {
                branchName: selectedName ? selectedName : null,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            };

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_HOST}/branches/details`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken
                    },
                    params: params,
                }
            );

            const firstBranchDetails = response.data;

            history.push({
                pathname: '/admin/branches/activity',
                state: {
                    branchDetails: firstBranchDetails,
                    getUserData: location?.state?.getUserData,
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    // getUserData: location?.state?.getUserData,
                },
            });

        } catch (error) {
            console.error('Error fetching branch details:', error);
        }
    };

    const onStartDateChange = (event) => {
        setStartDate(event.value);
    };

    const onEndDateChange = (event) => {
        setEndDate(event.value);
    };
    const isFormValid = () => {
        return (
            !erNameMessage &&
            !erCodeMessage &&
            !erCityMessage &&
            !erMailMessage &&
            !erPostalMessage &&
            !erAddressMessage &&
            !erCountryMessage &&
            !erPhMessage &&
            product.name.trim() !== '' &&
            product.code.trim() !== '' &&
            product.city.trim() !== '' &&
            product.emailId.trim() !== '' &&
            product.postalCode.trim() !== '' &&
            product.address.trim() !== '' &&
            phoneNumber !== '' &&
            country &&
            country.country &&
            country.country.laCountryName &&
            !erCountryMessage
        );
    };

    const productDialogFooter = (
        <React.Fragment>
            <div className="footer_sec_btn" style={{ height: '70px' }}>
                <Button onClick={saveProduct} style={{ width: '80px' }} label='Submit' />
            </div>
        </React.Fragment>
    );
    const productDialogFooter1 = (
        <React.Fragment>
            <div className="footer_sec_btn" style={{ height: '70px' }}>
                <Button onClick={updateProduct} style={{ width: '80px' }}>Update</Button>
            </div>
        </React.Fragment>
    );

    const status = [
        { name: 'Active', value: "Active" },
        { name: 'Inactive', value: "Inactive" }
    ];

    const cols = [
        { field: 'id', header: 'Branch Id' },
        { field: 'name', header: 'Name' },
        { field: 'address', header: 'Address' },
        { field: 'emailId', header: 'Email' },
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const handleExportToPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, products);
                doc.save('Branch Datas.pdf');
            });
        });
    };

    const handleExportToCSV = () => {
        const csvContent = convertToCSV(products);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'Branch Datas.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(','));
            for (const row of data) {
                const values = Object.values(row).join(',');
                csvRows.push(values);
            }
        }
        return csvRows.join('\n');
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Branch Datas.xlsx');
    };

    const exportToXLS = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(products);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Branch Datas.xls');
    };

    const [visible, setVisible] = useState(false);
    let menu = null;

    const items = [
        {
            label: 'XLS',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToXLS();
            }
        },
        {
            label: 'XLSX',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToExcel();
            }
        },
        {
            label: 'CSV',
            icon: () => <FaFileCsv />,
            command: () => {
                setVisible(false);
                handleExportToCSV();
            }
        },
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const itemsFB = [
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const toggleMenu = (event) => {
        setVisible(!visible);
        menu.toggle(event);
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Branch Datas List</title></head><body>');
        printWindow.document.write('<style>table { border-collapse: collapse; width: 100%; } th, td { border: 1px solid black; padding: 8px; text-align: left; }</style>');
        printWindow.document.write('<h1>Branch Datas List</h1>');

        printWindow.document.write('<table>');
        printWindow.document.write('<thead>');
        printWindow.document.write('<tr>');

        const columns = [
            { field: "id", header: "Branch ID" },
            { field: "name", header: "Name" },
            { field: "address", header: "Address" },
            { field: "emailId", header: "Email" },
            { field: "phoneNumber", header: "Phone Number" },
            { field: "status", header: "Status" },
        ];

        columns.forEach(col => {
            printWindow.document.write(`<th>${col.header}</th>`);
        });

        printWindow.document.write('</tr>');
        printWindow.document.write('</thead>');
        printWindow.document.write('<tbody>');

        products.forEach(row => {
            printWindow.document.write('<tr>');

            columns.forEach(col => {
                const fieldValue = row[col.field] ?? '-';
                printWindow.document.write(`<td>${fieldValue}</td>`);
            });

            printWindow.document.write('</tr>');
        });

        printWindow.document.write('</tbody></table></body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="branch-expand-details" style={{ fontSize: '14px', }}>
                <div className='branch-expand-details-a'>
                    <div className='branch-expand-details-a1'><div className=' font-semibold'>Country</div> <div className='branch-expand-details-a2' >:</div> <div className='branch-expand-details-a3'>{rowData?.newCountry?.laCountryName || 'N/A'}</div></div>
                    <div className='branch-expand-details-a1'><div className=' font-semibold'>Branch Code</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData?.code || 'N/A'} </div></div>
                    <div className='branch-expand-details-a1'><div className=' font-semibold'>Postal Code</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData?.postalCode || 'N/A'}</div></div>
                    <div className='branch-expand-details-a1'><div className=' font-semibold'>Branch Name</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData?.name || 'N/A'}</div></div>
                    <div className='branch-expand-details-a1'><div className=' font-semibold'>Email Id</div> <div className='branch-expand-details-a2'>:</div> <div className='branch-expand-details-a3'>{rowData?.emailId || 'N/A'}</div></div>
                    <div className='branch-expand-details-b1'><div className=' font-semibold'>Address</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData?.address || 'N/A'}</div></div>
                </div>
                <div className='branch-expand-details-b' style={{}}>
                    <div className='branch-expand-details-b1'><div className=' font-semibold'>City</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData?.city || 'N/A'}</div></div>
                    <div className='branch-expand-details-b1'><div className=' font-semibold'>Phone No.</div > <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData?.phoneNumber || 'N/A'}</div></div>
                    <div className='branch-expand-details-b1'><div className=' font-semibold'>Created User</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData?.createdUser || 'N/A'}</div></div>
                    <div className='branch-expand-details-b1'><div className=' font-semibold'>Modified Time</div> <div className='branch-expand-details-b2'>:</div> <div className='branch-expand-details-b3'>{rowData?.modifiedTime || 'N/A'}</div></div>
                    <div className='branch-expand-details-b1'><div className=' font-semibold'>Status</div> <div className='branch-expand-details-b2'>:</div > <div className='branch-expand-details-b3'>{rowData?.status || 'N/A'}</div></div>
                </div>
            </div>
        );
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    useEffect(() => {
        const defaultValue = location?.state?.companyprofiledropdown;
        if (defaultValue !== null) {
            setGlobalFilter(defaultValue);
        }
    }, [location?.state?.companyprofiledropdown]);

    const item = [
        { label: 'Branches' }];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    // const home1 = {
    //     icon: 'pi pi-angle-double-left', command: () =>
    //         history.push({
    //             pathname: '/admin/dashboard',
    //             state: {
    //                 accessToken: location?.state?.accessToken,
    //                 emailId: location?.state?.emailId,
    //                 getUserData: location?.state?.getUserData,
    //             },
    //         })
    // }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };
    const TypeTemplate = (rowData) => {
        const status = rowData.status;
        let color = '';

        if (status && status.toLowerCase) {
            if (status.toLowerCase() === 'inactive') {
                color = '#f7af00';
            } else if (status.toLowerCase() === 'active') {
                color = 'rgb(69 160 75)';
            }
        }
        //  else {
        //     console.error("Status is undefined or doesn't have toLowerCase method");
        // }
        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };

    return (
        <>
            <div className="mainSec branch-scroll" >
                <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
                <div className='container-user h-full w-full'>
                    <BreadCrumb model={item} home={home} style={{ border: 'none' }} />
                    {loading ? (
                        <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                            <Triangle
                                height="80"
                                width="80"
                                color="#124A99"
                                ariaLabel="triangle-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="card-Adhoc w-full bg-white unique-data-response" style={{ marginBottom: '8%', position: 'relative', background: '#fff', border: '1px solid #e8e9eb' }} >
                                <div className="individual-account-search" style={{ border: '1px solid rgba(209, 209, 209, 1)' }}>
                                    <div className="tab-head-btns flex flex-wrap align-items-center justify-content-end" style={{ borderBottom: 'rgba(209, 209, 209, 1) 1px solid' }}>
                                        <div className='country-conatent flex justify-content-end  pt-2 pb-2 px-3 bg-gray-50'>
                                            <div className="tab-head-btn flex justify-content-end flex-wrap w-full" >
                                                <div className="">
                                                    <span className="p-input-icon-left">
                                                        <i className="pi pi-search" />
                                                        <InputText
                                                            type="text"
                                                            placeholder="Search..."
                                                            style={{ border: '2px solid #ccc' }}
                                                            value={globalFilter || ''}
                                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                                            className='py-2 w-full'
                                                        />
                                                    </span>
                                                </div>
                                                <div className="flex align-items-center justify-content-center" style={{ zIndex: '1' }}>
                                                    <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} disabled={plan === 'FreeTrial'} onClick={openNew} tooltip="Create Branch" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-plus pl-1'></i></Button>
                                                </div>
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ border: 'none', backgroundColor: 'transparent' }} disabled={products.length === 0} tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleMenu} ><i className='pi pi-upload pl-1'></i></Button>
                                                    <Menu
                                                        className='unique-menu-exp'
                                                        model={plan === 'FreeTrial' || plan === 'BasicAdmin' ? itemsFB : items}
                                                        popup
                                                        ref={(el) => (menu = el)}
                                                        onHide={() => setVisible(false)}
                                                        appendTo={document.body}
                                                    />
                                                </div>
                                                <div className="flex align-items-center justify-content-center">
                                                    <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ zIndex: '1', border: 'none', backgroundColor: 'transparent' }} onClick={handlePrint} disabled={products.length === 0} tooltip="Print" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} ><i className='pi pi-print pl-1'></i></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border-btm-datatble1'>
                                        <DataTable
                                            value={products}
                                            selection={selectedProducts}
                                            stripedRows
                                            className='p-datatable-wrapper branch'
                                            expandedRows={expandedRows}
                                            onChange={(e) => setSelectedProducts(e.value)}
                                            id='edit-btn' paginator rows={10}
                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                            rowExpansionTemplate={rowExpansionTemplate}
                                            globalFilter={globalFilter}
                                            onRowToggle={onRowToggle}
                                        >
                                            <Column expander style={{ width: '3rem' }} />
                                            <Column field="id" header="Branch ID" style={{ whiteSpace: 'nowrap' }} sortable></Column>
                                            <Column field="name" header="Name" sortable></Column>
                                            <Column field="address" header="Address" sortable></Column>
                                            <Column field="emailId" header="Email" sortable></Column>
                                            <Column field="phoneNumber" header="Phone Number" sortable></Column>
                                            <Column field="status" header="Status" body={TypeTemplate} sortable></Column>
                                            <Column header="Edit" body={infoTemplate} ></Column>
                                            {props.roleName !== 'StartupAdmin' && <Column header="Report" body={reportTemplate} ></Column>}
                                        </DataTable>
                                    </div>
                                </div>
                            </div>

                            <Dialog visible={productDialog} draggable={false} modal header='Add Details' className="sing_up_main_Dialog" footer={productDialogFooter} onHide={hideDialog}>
                                <Toast ref={toast} style={{ marginTop: '70px', zIndex: '1' }} />
                                <div className="feild c pt-3 unique-fie-mob">
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">Branch Name<span>*</span></div>
                                            <InputText type="text" defaultValue={inputRef.current || ''} onChange={(e) => onInputChange(e, 'name')} className={erNameMessage ? "p-invalid" : ""} />
                                            {erNameMessage && (<small className="p-error">{erNameMessage}</small>)}
                                        </div>
                                        <div className="input">
                                            <div className="label">Address<span>*</span></div>
                                            <InputText defaultValue={inputRef.current || ''} onChange={(e) => onInputChange(e, 'address')} className={erAddressMessage ? "p-invalid" : ""} />
                                            {erAddressMessage && (<small className="p-error">{erAddressMessage}</small>)}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">Phone Number<span>*</span></div>
                                            <PhoneInput country={'us'} countryCodeEditable={false} value={phoneNumber || ''} onChange={(newPhoneNumber) => handlePhChange(newPhoneNumber)} className={`w-full unique-phone ${erPhMessage ? "error-border" : ""}`} />
                                            {erPhMessage && (<small className="p-error">{erPhMessage}</small>)}
                                        </div>
                                        <div className="input">
                                            <div className="label">Country<span>*</span></div>
                                            <Dropdown value={country && country.country && country.country.laCountryName || ''} options={countrydata?.map((item) => ({ label: item.country.laCountryName, value: item.country.laCountryName }))} optionLabel="label" onChange={countrychange} filter className={`w-full unique-drops  ${erCountryMessage ? "p-invalid" : ""}`} />
                                            {erCountryMessage && (<small className="p-error">{erCountryMessage}</small>)}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">Code<span>*</span></div>
                                            <InputText defaultValue={inputRef.current || ''} keyfilter="int" onChange={(e) => onInputChange(e, 'code')} className={erCodeMessage ? "p-invalid" : ""} />
                                            {erCodeMessage && (<small className="p-error">{erCodeMessage}</small>)}
                                        </div>
                                        <div className="input">
                                            <div className="label">Email<span>*</span></div>
                                            <InputText value={product.emailId || ''} onChange={(e) => onInputChange(e, 'emailId')} className={erMailMessage ? "p-invalid" : ""} />
                                            {erMailMessage && (<small className="p-error">{erMailMessage}</small>)}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">City<span>*</span></div>
                                            <InputText defaultValue={inputRef.current || ''} onChange={(e) => onInputChange(e, 'city')} className={erCityMessage ? "p-invalid" : ""} />
                                            {erCityMessage && (<small className="p-error">{erCityMessage}</small>)}
                                        </div>
                                        <div className="input">
                                            <div className="label">Postal Code<span>*</span></div>
                                            <InputText defaultValue={inputRef.current || ''} keyfilter="int" maxLength={6} onChange={(e) => onInputChange(e, 'postalCode')} className={erPostalMessage ? "p-invalid" : ""} />
                                            {erPostalMessage && (<small className="p-error">{erPostalMessage}</small>)}
                                        </div>
                                    </div>
                                </div>
                            </Dialog>

                            <Dialog visible={productDialog1} draggable={false} header="Update Details" modal className="sing_up_main_Dialog" footer={productDialogFooter1} onHide={hideDialog}>
                                <div className="feild c pt-3 pb-3 unique-fie-mob">
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">Phone Number</div>
                                            <PhoneInput country={'us'} countryCodeEditable={false} value={phoneNumber || ''} onChange={(newPhoneNumber) => handlePhChange(newPhoneNumber)} className={`w-full unique-phone ${submitted && !product.phoneNumber ? "error-border" : ""}`} />
                                            {submitted && !product.phoneNumber && <small className="p-error">Phone Number is required.</small>}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">Status</div>
                                            <Dropdown value={product.status || ''} onChange={handleStatusChange} options={status} optionLabel="name" className="w-full unique-drops" />
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog
                                visible={showDialog}
                                onHide={hideDialogHandler}
                                header="Branch Filter"
                                className="sing_up_main_Dialog"
                                footer={
                                    // <div className="footer_sec_btn_dialuge">
                                    //     <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" onClick={handleApplyFilter}>Apply</Button>
                                    //     <Button id='b-btn' style={{ background: '#124A99' }} className="w-6rem" onClick={hideDialogHandler}>Cancel</Button>
                                    // </div>
                                    <div className="footer_sec_btn unique-data-btn" style={{ height: '70px' }}>
                                        <Button onClick={hideDialogHandler} style={{ width: '80px' }} label='Cancel' />
                                        <Button onClick={handleApplyFilter} style={{ width: '80px' }} label='Apply' />
                                    </div>
                                }
                            >
                                <div className="feild c pt-3 pb-3 unique-fie-mob">
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">Start Date</div>
                                            <Calendar
                                                style={{ padding: 'unset' }}
                                                value={startDate || ''}
                                                onChange={onStartDateChange}
                                                id="p-calender" className="w-full "
                                                showIcon
                                            />
                                        </div>
                                        <div className="input">
                                            <div className="label">End Date</div>
                                            <Calendar
                                                style={{ padding: 'unset' }}
                                                value={endDate || ''}
                                                onChange={onEndDateChange}
                                                id="p-calender" className="w-full "
                                                showIcon
                                            />
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="input">
                                            <div className="label">Branch Name</div>
                                            <Dropdown
                                                value={selectedName || ''}
                                                options={[{ label: selectedName, value: selectedName }]}
                                                className="w-full unique-drops"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}