import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { Card } from 'primereact/card';
import './MonitoringThresholds.css';
const StackedBarChart = (props) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = props?.data?.data?.accessToken;

            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            };
            try {
                const response = await fetch(process.env.REACT_APP_BACKEND_HOST + '/country-sanction-scores', requestOptions);
                const data = await response.json();
                setChartData(data);
            } catch (error) {

            }
        };
        fetchData();
    }, [props?.data?.data?.accessToken]);

    const transformDataForChart = (data) => {
        const dataArray = Object.entries(data);
        dataArray?.sort((a, b) => b[1]?.sanctionScore - a[1]?.sanctionScore);
        const topEntries = dataArray.slice(0, 8);
        const labels = topEntries.map(([key, _]) => key);
        const sanctionScores = topEntries.map(([_, item]) => item?.sanctionScore);
        const backgroundColors = sanctionScores?.map(score => {
            return score >= 8 ? '#FFE0E6' : '#FF87A1';
        });

        const customLabels = sanctionScores?.map(score => {
            return score?.toString();
        });

        const datasets = [
            {
                label: 'Sanction Score',
                data: sanctionScores,
                backgroundColor: backgroundColors,
                borderColor: '#FF87A1', // Set the border color
                borderWidth: 2,
                stack: 'Stack 0',
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    display: true,
                    formatter: (value, context) => {
                        return customLabels[context.dataIndex];
                    }
                }
            }
        ];

        return { labels, datasets };
    };


    const chartOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                labels: {
                    color: "gray",
                    usePointStyle: true
                },
                position: 'bottom',
            }
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            }
        }
    };

    if (!chartData) {
        return <p>Loading...</p>;
    }

    const transformedChartData = transformDataForChart(chartData || []);

    return (
        <div className='chart-container' >
            <Card className='card-chart' style={{ margin: 'auto', width: '-webkit-fill-available', alignItems: 'center' }}>
                <Chart type="bar" data={transformedChartData} options={chartOptions} className="company-chart-pie1" style={{ height: '350px', width: '100%', padding: 'unset', borderBottom: 'none' }} />
            </Card>
        </div>
    );
};

export default StackedBarChart;
