import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import axios from "axios";
import { Toast } from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { Triangle } from 'react-loader-spinner';

function UserRegisteration() {

    const [, setid] = useState(null);
    const [, setexternalId] = useState('');
    const [firstName, setfirstName] = useState('');
    const [middleName, setmiddleName] = useState('');
    const [lastName, setlastName] = useState('');
    const [password, setpassword] = useState('');
    const [idCardType, setidCardType] = useState('');
    const [selectedRoles, setSelectedRoles] = useState(null);
    const [rolesdata, setRolesdata] = useState([]);
    const [idCardNo, setidCardNo] = useState('');
    const [maritalStatus, setmaritalStatus] = useState('');
    const [address, setaddress] = useState('');
    const [countrydata, setCountrydata] = useState();
    const [country, setCountry] = useState({ id: '', country: '' });
    const [emailId, setemailId] = useState('');
    const [phoneNumber, setphone] = useState('');
    const [branch, setBranchname] = useState({ id: '', branch: '' });
    const [branchdata, setBranchdata] = useState();
    const toast = useRef(null);
    const [, setResponse] = useState('');
    const [workType, setWorkType] = useState('');
    const [workTime, setWorkTime] = useState('');
    const history = useHistory();
    const [step, setStep] = useState(0);
    const totalSteps = 3;
    const currentDate = new Date();
    const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dob, setdob] = useState('');
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    let inputRef = useRef('');
    let passRef = useRef('');
    const [loading, setLoading] = useState(false);
    const refreshToken = location?.state?.refreshToken;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDay = day.toString().padStart(2, '0');
        const formattedMonth = month.toString().padStart(2, '0');

        return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const usersubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (!firstName || !lastName || !password || !idCardType || !idCardNo || !dob || !address || !selectedBranchCity.name || !selectedBranchState.name || !selectedBranchCountry || !emailId || !phoneNumber || !selectedRoles || !branch) {
            displayWarnToast("Please fill all the required fields.");
            setLoading(false);
            return;
        }
        // const email = location?.state?.emailId;

        const data = {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            password: password,
            idCardType: idCardType,
            idCardNo: idCardNo,
            dob: formatDate(dob),
            maritalStatus: maritalStatus,
            address: address,
            city: selectedBranchCity.name,
            state: selectedBranchState.name,
            country: {
                id: country.country.id,
            },
            emailId: emailId,
            phoneNumber: phoneNumber,
            roles: selectedRoles ? [{ id: selectedRoles }] : [],
            branch: {
                id: branch && branch.id,
                name: branch.name
            },
            workType: workType,
            workTime: workTime,
        };
        axios.post(process.env.REACT_APP_BACKEND_HOST + `/users`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                e.preventDefault();
                if (response.status === 200) {
                    showSuccessToast(response.data.message);
                    resetForm();
                } else if (response.status === 400) {
                    toast.current.show({ severity: 'error', summary: 'Enter the valid details' });
                }
                history.push({
                    pathname: '/admin/users',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                        rolesData: location?.state?.rolesData
                    },
                })
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                }
            });
    }

    const showSuccessToast = (message) => {
        toast.current.show({ severity: 'success', summary: 'Successfully Changed', detail: message, life: 5000 });
    };
    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_IDM_BACKEND_HOST}/roles/all`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                setRolesdata(response.data);
            })
            .catch((error) => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            });

    }, [accessToken]);

    useEffect(() => {
        const branchda = async () => {

            try {
                const headers = {
                    'Authorization': 'Bearer ' + accessToken
                };
                let uri = `/branches`;

                const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, {
                    headers: headers
                });
                setBranchdata(response.data);
            } catch (error) {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            }
        };
        branchda();
    }, [accessToken]);

    useEffect(() => {
        async function fetchCountryData() {

            try {
                const headers = {
                    Authorization: 'Bearer ' + accessToken
                };
                let uri = '/country';

                const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, {
                    headers: headers
                });

                if (Array.isArray(response.data)) {
                    setCountrydata(response.data);
                } else {
                    // console.error('Country data is not an array:', response.data);
                }
            } catch (error) {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            }
        }
        fetchCountryData();
    }, [accessToken]);

    const maritalOptions = [
        { name: "Single", code: "Single" },
        { name: "Married", code: "Married" },
        { name: "Widowed", code: "Widowed" },
        { name: "Separated", code: "Separated" },
        { name: "Divorced", code: "Divorced" },

    ]
    const idcardtypes = [
        { name: "Passport", code: "Passport" },
        { name: "National ID", code: "National ID" },
        { name: "Driver License", code: "Driver License" }
    ];

    const workingtypes = [
        { name: "Remote", code: "Remote" },
        { name: "Office", code: "office" },
    ]

    const workingtimeOptions = [
        { name: "DayShift", code: "DayShift" },
        { name: "NightShift", code: "NightShift" },
    ]

    const displayWarnToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
        }
    };

    const [erFirstNameMessage, setErFirstNameMessage] = useState('');
    const [erIdCardNoMessage, setErIdCardNoMessage] = useState('');
    const [dobMessage, setDobMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [branchMessage, setBranchMessage] = useState('');
    const [passMessage, setPassMessage] = useState('');
    const [rolesMessage, setRolesMessage] = useState('');
    const [lastNameMessage, setLastNameMessage] = useState('');
    const [idTypeMessage, setIdTypeMessage] = useState('');
    const [erAddressMessage, setErAddressMessage] = useState('');
    const [erCityMessage, setErCityMessage] = useState('');
    const [erStateMessage, setErStateMessage] = useState('');
    const [erCountryMessage, setErCountryMessage] = useState('');
    const [erPhoneMessage, setPhoneMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [middleNameError, setMiddleNameError] = useState('');

    const handleMiddleNameChange = (event) => {
        const inputValue = event.target.value;
        if (/[^a-zA-Z\s]/.test(inputValue)) {
            setMiddleNameError('Only contain alphabetic characters and spaces.');
        } else {
            setmiddleName(inputValue);
            setMiddleNameError('');
        }
    };


    const handleFirstnameChange = (event) => {
        const val = event.target.value;
        if (/[^a-zA-Z\s]/.test(val)) {
            return;
        }
        if (val.length === 0) {
            setErFirstNameMessage('First Name is required.');
        } else if (val.length < 3) {
            setErFirstNameMessage('Must have at least 3 characters.');
        } else {
            setErFirstNameMessage('');
        }
        setfirstName(val);
    };

    const handleLastnameChange = (event) => {
        const val = event.target.value;
        if (/[^a-zA-Z\s]/.test(val)) {
            return;
        }
        if (val.length === 0) {
            setLastNameMessage('Last Name is required.');
        } else {
            setLastNameMessage('');
        }
        setlastName(val);
    };

    const handleDobChange = (e) => {
        const val = e.value;
        if (val.length === 0) {
            setDobMessage('Date of birth is required.');
        } else {
            setDobMessage('');
        }
        setdob(val);
    };

    const handleIdcardtype = (e) => {
        const val = e.value;
        if (val.length === '') {
            setIdTypeMessage('IdCard Type is required.')
        } else {
            setIdTypeMessage('')
        }
        setidCardType(val);
        setidCardNo('')
    }

    const handleIdNoChange = (event) => {
        const val = event.target.value;
        if (val.length === 0) {
            setErIdCardNoMessage('ID Card Number is required.');
        } else {
            setErIdCardNoMessage('');
        }
        setidCardNo(val);
    };

    const handlemaritalchange = (event) => {
        setmaritalStatus(event.value);
    }

    const handleAddressChange = (event) => {
        const val = event.target.value;
        if (val.length === 0) {
            setErAddressMessage('Address is required.');
        } else {
            setErAddressMessage('');
        }
        setaddress(val);
    };

    // UserRegistration Panel country,state,city changed
    const [selectedBranchCountry, setSelectedBranchCountry] = useState('');
    const [selectedBranchState, setSelectedBranchState] = useState('');
    const [selectedBranchCity, setSelectedBranchCity] = useState('');
    const [countriess, setCountriess] = useState([]);
    const [statess, setStatess] = useState([]);
    const [citiess, setCitiess] = useState([]);
    const [selectedBranchCountries, setSelectedBranchCountries] = useState('');

    useEffect(() => {
        const fetchCountries = async () => {
            const allCountries = await Country.getAllCountries();
            setCountriess(allCountries);
        };
        fetchCountries();
    }, []);
    const [selectedCountryFlag, setSelectedCountryFlag] = useState(null);
    const handleBranchCountryChange = (e) => {
        const selectedCountryName = e.value;
        const selectedCountry = countrydata.find((item) => item.country.laCountryName === e.target.value);
        setCountry(selectedCountry);
        const selectedCountryData = countriess.find(country => country.name === selectedCountryName);
        setSelectedBranchCountry(selectedCountryName)
        setSelectedCountryFlag(selectedCountryData)
        if (selectedCountryData) {
            setSelectedBranchCountries(selectedCountryData);
            setSelectedBranchState('');
            setSelectedBranchCity('');
            const countryStates = State.getStatesOfCountry(selectedCountryData.isoCode);
            setStatess(countryStates);
        } else {
            console.error("Selected country not found");
        }
    };

    const handleStateBranchChange = (e) => {
        const stateValue = e.value;
        setSelectedBranchState(stateValue);
        setSelectedBranchCity('');
        const stateCities = City.getCitiesOfState(selectedBranchCountries.isoCode, stateValue.isoCode);
        setCitiess(stateCities);
    };


    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        if (!emailRegex.test(email)) {
            return false;
        }
        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com');
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 20;
        return hasDotCom && isDomainLengthValid && domain !== 'gmail.com';
    };

    const handleMailChange = (event) => {
        const val = event.target.value.toLowerCase();
        if (val.length === 0) {
            setEmailMessage('EmailId is required.');
        } else if (!isValidEmail(val)) {
            setEmailMessage('Enter a valid email address.');
        } else {
            setEmailMessage('');
        }
        setemailId(val);
    };

    const handlePhNumberChange = (e) => {
        const val = e;
        if (val.length === 0) {
            setPhoneMessage('Phone Number is required.');
        } else {
            setPhoneMessage('');
        }
        setphone(val);
    };

    const handleRolesChange = (event) => {
        setSelectedRoles(event.value);
        if (!event.value) {
            setRolesMessage('Role is required.')
        } else {
            setRolesMessage('')
        }
    }

    const branchchange = (event) => {
        const selectedBranch = branchdata.find((item) => item.name === event.target.value);
        if (!selectedBranch) {
            setBranchMessage('Branch field is required.')
        } else {
            setBranchMessage('')
        }
        setBranchname(selectedBranch);
    };

    const handlePasswordChange = (event) => {
        const val = event.target.value;
        if (val.length === 0) {
            setPassMessage('Password is required.');
        } else if (!/[A-Z]/.test(val)) {
            setPassMessage('Must have at least one uppercase.');
        } else if (!/[a-z]/.test(val)) {
            setPassMessage('Must have at least one lowercase.');
        } else if (!/\d/.test(val)) {
            setPassMessage('Must have at least one number.');
        } else if (!/[!@#$%^&*()?]/.test(val)) {
            setPassMessage('Must have at least one special character.');
        } else if (val.length < 8 || val.length > 20) {
            setPassMessage('Must have 8 to 20 characters.');
        } else {
            setPassMessage('');
        }
        setpassword(val);
    };

    const handleWorkTime = (event) => {
        setWorkTime(event.value);
    }

    const handleWorkType = (event) => {
        setWorkType(event.value);
    }

    const handleNext = () => {
        if (!dob || !idCardNo || !firstName) {
            displayWarnToast("Please fill the details..");
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const handleNext1 = () => {
        if (!emailId) {
            displayWarnToast("Please fill an EmailID.");
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrevious = (e) => {
        e.preventDefault();
        setStep((prevStep) => prevStep - 1);
    };

    const resetForm = () => {
        setSelectedBranchCountry('')
        setSelectedBranchState('')
        setSelectedBranchCity('')
        setid(null);
        setexternalId('');
        setfirstName('');
        setmiddleName('');
        setlastName('');
        setpassword('');
        setidCardType('');
        setSelectedRoles([]);
        setRolesdata([]);
        setidCardNo('');
        setdob(null);
        setmaritalStatus('');
        setaddress('');
        setCountrydata();
        setCountry({ id: '', country: '' });
        setemailId('');
        setphone('');
        setBranchname({ id: '', branch: '' });
        setBranchdata();
        setResponse('');
        setWorkType('');
        setWorkTime('');
        setStep(0);
        setErFirstNameMessage('');
        setErIdCardNoMessage('');
        setDobMessage('');
        setEmailMessage('');
        setBranchMessage('');
        setPassMessage('');
        setRolesMessage('');
        setLastNameMessage('');
        setIdTypeMessage('');
        setErAddressMessage('');
        setErCityMessage('');
        setErStateMessage('');
        setErCountryMessage('');
        setPhoneMessage('');
        setMiddleNameError('');
    };

    const renderStep1 = () => (
        <>
            <div className="feild c unique-h">
                <div className="heading unique-h">Personal Information</div>
                <div className="flex">
                    <div className="input">
                        <div className="label">First Name<span>*</span></div>
                        <InputText value={firstName} onChange={handleFirstnameChange} className={erFirstNameMessage ? "p-invalid" : ""} />
                        {erFirstNameMessage && (<small className="p-error">{erFirstNameMessage}</small>)}
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Middle Name</div>
                        <InputText value={middleName} onChange={handleMiddleNameChange} className={middleNameError ? "p-invalid" : ""} />
                        {middleNameError && <small className="p-error">{middleNameError}</small>}
                    </div>
                    <div className="input">
                        <div className="label">Last Name<span>*</span></div>
                        <InputText value={lastName} onChange={handleLastnameChange} className={lastNameMessage ? "p-invalid" : ""} />
                        {lastNameMessage && (<small className="p-error">{lastNameMessage}</small>)}
                    </div>
                    <div className="input">
                        <div className="label">Date Of Birth<span>*</span></div>
                        <Calendar value={dob} onChange={handleDobChange} showIcon maxDate={eighteenYearsAgo} id="p-calender" className={`w-full ${dobMessage ? "p-invalid" : ""}`} />
                        {dobMessage && (<small className="p-error">{dobMessage}</small>)}
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">ID Card Type<span>*</span></div>
                        <Dropdown optionLabel="name" optionValue="code" options={idcardtypes} value={idCardType} onChange={handleIdcardtype} className={`w-full unique-drops  ${idTypeMessage ? "p-invalid" : ""}`} />
                        {idTypeMessage && (<small className="p-error">{idTypeMessage}</small>)}
                    </div>
                    <div className="input">
                        <div className="label">ID Card Number<span>*</span></div>
                        <InputText value={idCardNo} maxLength={idCardType === 'Passport' ? 9 : idCardType === 'National ID' ? 12 : idCardType === 'Driver License' ? 16 : ''} onChange={handleIdNoChange} className={erIdCardNoMessage ? "p-invalid" : ""} style={{ height: '40px' }} />
                        {erIdCardNoMessage && (<small className="p-error">{erIdCardNoMessage}</small>)}
                    </div>
                    <div className="input">
                        <div className="label">Marital Status</div>
                        <Dropdown optionLabel="name" optionValue="code" options={maritalOptions} value={maritalStatus} onChange={handlemaritalchange} className="w-full unique-drops" style={{ width: '100%' }} />
                    </div>
                </div>
                <br />
            </div>
        </>
    );

    const renderStep2 = () => (
        <>
            <div className="feild c unique-h">
                <div className="heading unique-h"> Contact Information</div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Country<span>*</span></div>
                        <Dropdown
                            value={selectedBranchCountry}
                            options={countrydata ? countrydata?.map((item) => ({ label: item.country.laCountryName, value: item.country.laCountryName })) : []}
                            onChange={handleBranchCountryChange}
                            optionLabel="label"
                            filter
                            className="w-full p-0 unique-drops dropdown"
                        />
                    </div>
                    <div className="input">
                        <div className="label">State<span>*</span></div>
                        <Dropdown
                            value={selectedBranchState}
                            options={statess}
                            onChange={handleStateBranchChange}
                            optionLabel="name"
                            filter
                            disabled={!selectedBranchCountry}
                            className="w-full p-0 unique-drops dropdown"
                        />
                    </div>
                    <div className="input">
                        <div className="label">City<span>*</span></div>
                        <Dropdown
                            value={selectedBranchCity}
                            options={citiess}
                            onChange={(e) => setSelectedBranchCity(e.value)}
                            optionLabel="name"
                            filter
                            disabled={!selectedBranchState}
                            className="w-full p-0 unique-drops dropdown"
                        />
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Address<span>*</span></div>
                        <InputText value={address} onChange={handleAddressChange} className={erAddressMessage ? "p-invalid" : ""} />
                        {erAddressMessage && (<small className="p-error">{erAddressMessage}</small>)}
                    </div>
                    <div className="input">
                        <div className="label">Email Id<span>*</span></div>
                        <InputText value={emailId} onChange={handleMailChange} className={emailMessage ? "p-invalid" : ""} style={{ height: '40px' }} />
                        {emailMessage && (<small className="p-error">{emailMessage}</small>)}
                    </div>
                    <div className="input">
                        <div className="label">Phone Number<span>*</span></div>
                        <PhoneInput country={selectedCountryFlag ? selectedCountryFlag.isoCode.toLowerCase() : 'us'} countryCodeEditable={false} value={phoneNumber} onChange={handlePhNumberChange} className={`w-full unique-phone  ${erPhoneMessage ? "error-border" : ""}`} />
                        {erPhoneMessage && (<small className="p-error">{erPhoneMessage}</small>)}
                    </div>
                </div>
                <br />
            </div>
        </>
    );

    const renderStep3 = () => (
        <>
            <div className="feild c unique-h">
                <div className="heading unique-h"> Working Information</div>
                <div className="flex">
                    <div className="input unique-drop-fie">
                        <div className="label">Roles<span>*</span></div>
                        <Dropdown
                            value={selectedRoles}
                            onChange={handleRolesChange}
                            options={(rolesdata || []).map(item => ({ label: item.roleName, value: item.id }))}
                            optionLabel="label"
                            className={`w-full unique-drops ${rolesMessage ? "p-invalid" : ""}`}
                        />
                        {rolesMessage && (<small className="p-error">{rolesMessage}</small>)}
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Branch<span>*</span></div>
                        <Dropdown options={branchdata ? branchdata.map((item) => ({ label: item.name, value: item.name })) : []} value={branch ? branch.name : null} onChange={branchchange} filter optionLabel="label" className={`w-full unique-drops  ${branchMessage ? "p-invalid" : ""}`} />
                        {branchMessage && (<small className="p-error">{branchMessage}</small>)}
                    </div>
                    <div className="input">
                        <div className="label">Password<span>*</span></div>
                        <div className={`w-full see_Passs ${passMessage ? "unique_see_Pass" : ""}`} style={{ borderRadius: '3px' }}>
                            <InputText type={showPassword ? 'text' : 'password'} value={password} onChange={handlePasswordChange} />
                            <i onClick={(e) => setShowPassword(!showPassword)} className={`pi ${showPassword ? 'pi-eye' : 'pi-eye-slash'}`}></i>
                        </div>
                        {passMessage && (<small className="p-error">{passMessage}</small>)}
                    </div>
                </div>
                <div className="flex">
                    <div className="input">
                        <div className="label">Work Type</div>
                        <Dropdown optionLabel="name" optionValue="code" options={workingtypes} onChange={handleWorkType} value={workType} className="w-full unique-drops" />
                    </div>
                    <div className="input">
                        <div className="label">Work Time</div>
                        <Dropdown optionLabel="name" optionValue="code" options={workingtimeOptions} onChange={handleWorkTime} value={workTime} className="w-full unique-drops" />
                    </div>
                </div>
                <br />
            </div>
        </>
    );

    const handleStepClick = () => {
        // displayWarnToast("Please fill the details..");
    };

    const renderStepIndicator = () => {
        return (
            <div className="step-indicator p-3">
                <div className="step-item-container">
                    {Array.from({ length: totalSteps }, (_, index) => (
                        <div
                            key={index}
                            className={`step-item ${index === step ? 'active-on' : ''}`}
                            onClick={handleStepClick}
                        >
                            {index + 1}
                            {index !== totalSteps - 1 && (
                                <div className={`step-line ${index < step ? 'active-line' : 'inactive-line'}`} />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    // const handleNavigates = () => {
    //     history.goBack();
    // };

    const items = [
        {
            label: 'Users', command: () =>
                history.push({
                    pathname: '/admin/users',
                    state: {
                        emailId: location?.state?.emailId, accessToken: location?.state?.accessToken, rolesData: location?.state?.rolesData, refreshToken: refreshToken,
                    },
                })
        },
        { label: 'User Registration' },
    ];

    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                    refreshToken: refreshToken,
                },
            })
    }
    const home1 = {
        icon: 'pi pi-angle-double-left', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                    refreshToken: refreshToken,
                },
            })
    }
    const nextRequired1 = () => {
        return !erFirstNameMessage && !lastNameMessage && !dobMessage && !idTypeMessage && !erIdCardNoMessage &&
            firstName && lastName && dob && idCardType && idCardNo;
    };
    const nextRequired2 = () => {
        return !erAddressMessage && !erCityMessage && !erStateMessage && !erCountryMessage && !emailMessage && !erPhoneMessage &&
            address && selectedBranchCity.name && selectedBranchState.name && selectedBranchCountry && emailId && phoneNumber;
    };
    const submitDisabled = () => {
        return (
            rolesMessage ||
            branchMessage ||
            passMessage ||
            !selectedRoles ||
            !branch ||
            !password
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!submitDisabled()) {
            usersubmit(e);
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please fill all the required fields.', life: 3000 });
        }
    };
    return (
        <>
            <div className="mainSec" >
                <Toast ref={toast} />
                <BreadCrumb model={items} home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />

                {loading &&
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                }
                <div>
                    <div className="w-full bg-white unique-d" style={{ background: '#fff', padding: '10px', border: '2px solid #c1c1c1', borderRadius: '15px' }} >
                        <div className="" >
                            <div className="">
                                {renderStepIndicator()}
                                <form>
                                    <div className="">
                                        {step === 0 && renderStep1()}
                                        {step === 1 && renderStep2()}
                                        {step === 2 && renderStep3()}
                                        <div className="flex justify-content-end flex-wrap card-container  mob-unique-userbtn" style={{ width: '97.7%', gap: '10px' }}>
                                            {step > 0 && (
                                                <div className="footer_sec_btn_previous">
                                                    <Button style={{ background: '#124A99', gap: '5px', borderColor: '#124A99' }} onClick={handlePrevious}>Previous </Button>
                                                </div>
                                            )}
                                            {step === 0 && (
                                                <div className="footer_sec_btn_next_1">
                                                    <Button disabled={!nextRequired1()} style={{ background: '#124A99', gap: '5px', borderColor: '#124A99' }} onClick={handleNext}>Next </Button>
                                                </div>
                                            )}
                                            {step === 1 && (
                                                <div className="footer_sec_btn_next">
                                                    <Button disabled={!nextRequired2()} style={{ background: '#124A99', gap: '5px', borderColor: '#124A99' }} onClick={handleNext1}>Next </Button>
                                                </div>
                                            )}
                                            {step === 2 && (
                                                <div className="footer_sec_btn_submit">
                                                    <Button onClick={handleSubmit} disabled={submitDisabled()} type="submit" style={{ background: '#124A99', gap: '5px', borderColor: '#124A99' }}> Submit</Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserRegisteration;












