import "../Subscription/Subscription.css";
import React, { useState, useEffect } from 'react';
import { CiGlobe } from "react-icons/ci";
import { IoBagHandleOutline } from "react-icons/io5";
import { GoTrophy } from "react-icons/go";
import { IoDiamond } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { RadioButton } from 'primereact/radiobutton';
import { FaCheckCircle } from "react-icons/fa";

function Subscription({ onDemoButtonClick }) {
  const history = useHistory();

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count === planList.length) {
      setCount(0);
    } else {
      const interval = setInterval(() => {
        setCount(prevCount => (prevCount < 2 ? prevCount + 1 : 0));
      }, 5000);
      return () => clearInterval(interval);
    }

  }, [count]);

  const planList = [
    {
      id: 0,
      number: "01",
      plantype: "Free",
      icon: <CiGlobe />,
      subscriptionCost: "Free Trial",
      planDescription: "Free Plan Includes",
      checkSymbol: "",
      subscriptionDetails: [
        "250 Registers",
        "5 Users",
        "Access Risk and control",
        "Watchlist screening",
        "Basic Reports",
        "Risk suggestion",
      ],
      subscriptionButton: "Get Started",
    },
    {
      id: 1,
      number: "02",
      plantype: "Basic",
      icon: <IoBagHandleOutline />,
      subscriptionCost: "$79.00",
      planDescription: "Includes The Pro Plans,Plus",
      checkSymbol: "",
      subscriptionDetails: [
        "500 Registers",
        "10 users",
        "Manage events",
        "Report configuration",
        "Criteria monitoring",
        "Account profiling",
        "All format data import and export",
        "Custom roles and responsibilities",
      ],
      subscriptionButton: "Get Started",
    },
    {
      id: 2,
      number: "03",
      plantype: "Pro",
      icon: <GoTrophy />,
      subscriptionCost: "$129.00",
      planDescription: "Includes The Pro Plans,Plus",
      checkSymbol: "",
      subscriptionDetails: [
        "1500 Registers",
        "Unlimited users",
        "Beneficiary screening",
        "Advanced Reporting",
        "Email and SMS alert",
        "Sanction Update",
        "Control criteria settings",
      ],
      subscriptionButton: "Get Started",
    },
    {
      id: 3,
      number: "04",
      plantype: "Enterprise",
      icon: <IoDiamond />,
      subscriptionCost: "Let’s Talk",
      planDescription: "Includes The Pro Plans,Plus",
      checkSymbol: "",
      subscriptionDetails: [
        "Unlimited Registers",
        "White labelled solutions",
        "Integration",
        "Customisable reporting",
        "Regional Languages",
        "Two years of free tech support",
        "A customized product that fulfils all your needs.",
      ],
      subscriptionButton: "Contact Us",
    },
  ];

  const goToPage = (value) => {
    if (value === 'Contact Us') {
      history.push("/contactUs")
    } else {
      history.push("/signup")
    }
  }
  const [ingredient, setIngredient] = useState('');

  return (
    <>
      <Header />
      <div className="payment_Op">
        {/* {windowWidth <= 700 ?
          <>
            <div className="payment_Op_B_S">
              <div className="payment_Op_B">
                <div className="pay_B_T">
                  <div className="pay_In">{planList[count]?.number}</div>
                  <div className="pay_PlaN">{planList[count]?.plantype}</div>
                </div>
                <div className="pay_B_C">
                  <div className="p_B_C_T">
                    <div className="p_B_C_T_">{planList[count]?.icon}</div>
                  </div>
                  <div className="p_B_C">
                    <h2 className="p_B_C_He">{planList[count]?.subscriptionCost}</h2>
                    <div className="sub_H_Pay">
                      <div className="h_PayH">{planList[count]?.planDescription}</div>
                      {planList[count]?.subscriptionDetails?.map((item, index) => (
                        <div className="h_Pay_Li">
                          <FaCheck />
                          <div>{item} </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="pay_B_F">
                  <Button onClick={() => goToPage(planList[count]?.subscriptionButton)} label={planList[count]?.subscriptionButton} />
                </div>
              </div>
            </div>

            <div className="pay_Dot_Sec">
              {planList?.map((note, index) => (
                <Button className='doT_BTN' style={{ background: count === index ? '#124A99' : 'transparent' }} onClick={() => setCount(index)} />
              ))}
            </div>
          </>
          :
          <div className="payment_Op_B_S">
            {planList?.map((item, index) => (
              <div className="payment_Op_B">
                <div className="pay_B_T">
                  <div className="pay_In">{item?.number}</div>
                  <div className="pay_PlaN">{item?.plantype}</div>
                </div>
                <div className="pay_B_C">
                  <div className="p_B_C_T">
                    <div className="p_B_C_T_">{item?.icon}</div>
                  </div>
                  <div className="p_B_C">
                    <h2 className="p_B_C_He">{item?.subscriptionCost}</h2>
                    <div className="sub_H_Pay">
                      <div className="h_PayH">{item?.planDescription}</div>
                      {item?.subscriptionDetails?.map((item, index) => (
                        <div className="h_Pay_Li">
                          <FaCheck />
                          <div>{item} </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="pay_B_F">
                  <Button onClick={() => goToPage(item.subscriptionButton)} label={item.subscriptionButton} />
                </div>
              </div>
            ))}
          </div>
        } */}


        <div className="max_W a_Plan">
          <div className="subscription-container-heading pay_Main_Head" style={{ color: '#4E4E4E' }}>
            Subscribing p<span className="underliner-s">lan</span>
          </div>

          <div className="pLAN_Opc">
            {planList?.map((item, index) => (
              <div key={index} className="pLAN_Opc_Box">
                <div className="plaN_Flex">
                  <div className="pLAN_Opc_Box_L">
                    <RadioButton key={index} className="pLAN_Opc_Rad" inputId="ingredient1" name="pizza" value="Cheese" onChange={(e) => setIngredient(index)} checked={index === ingredient} />
                    <div className="gap_10_Px">
                      <div className="box_PlaN">{item?.plantype}</div>
                      <div className="box_NotE">{item?.planDescription}</div>
                    </div>
                  </div>

                  <div className="pLAN_Opc_Box_R box_PricE">{item?.subscriptionCost}</div>
                </div>
                <div className={index === ingredient ? 'plaN_Det ass_Plan' : 'plaN_Det'}>
                  {index === ingredient ?
                    <>
                      <div className="pLAN_Lrft">
                        {item?.subscriptionDetails?.map((item, index) => (
                          <div key={index} className="h_Pay_Li">
                            <FaCheckCircle />
                            <div className="plaN_Det_No">{item} </div>
                          </div>
                        ))}
                      </div>

                      <Button className="pLAN_Right" onClick={() => goToPage(item?.subscriptionButton)} label={item?.subscriptionButton} />
                    </>
                    : null}
                </div>
              </div>
            ))}
          </div>
        </div>
        <br />

      </div>
      <Footer />
    </>
  );
}

export default Subscription;
