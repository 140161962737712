import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import './AccountProfiling.css';
// import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from "primereact/toast";
import { useLocation } from 'react-router-dom';

const Transactionv = (props) => {
    const [transactionData, setTransactionData] = useState([]);
    const location = useLocation();
    const toast = useRef(null);
    const [expandedRows, setExpandedRows] = useState([]);
    const accessToken = location?.state?.accessToken;

    const displayErrorToast = (message) => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    const accountIValue = location?.state?.rowID || sessionStorage?.rowID;

    useEffect(() => {
        const fetchData = async () => {

            if (accountIValue) {
                try {
                    const requestOptions = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    };

                    const uri = `/accounts/${accountIValue}/transactions`;
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_HOST}${uri}`,
                        requestOptions
                    );

                    const data = response.data;
                    if (response.data.length === 0) {
                        // displayWarningToast('No Transaction found.');
                    } else {
                        setTransactionData(data);
                    }
                } catch (error) {
                    if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                    else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    }
                }
            }
        };

        fetchData();

    }, [accountIValue, accessToken]);

    const TypeTemplate = (rowData) => {
        const status = rowData.category;

        let color = '';

        if (status === 'Medium Risk') {
            color = '#f7af00';
        } else if (status === 'High Risk') {
            color = '#f42616';
        } else if (status === 'Low risk' || 'LowRisk') {
            color = ' rgb(69 160 75)';
        }

        return (
            <div
                style={{
                    color: color,
                    fontWeight: '500',
                }}
            >
                {status || '-'}
            </div>
        );
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="w-full flex text-justify Acount-profile-expander-txt" style={{ fontSize: '14px', padding: '20px 40px' }}>
                <div className='f-Dd'>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Transaction ID</div>     <div className='w-3'>:</div> <div className='w-11'>{rowData.transactionId ? rowData.transactionId : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Account ID</div>         <div className='w-3'>:</div> <div className='w-11'>{rowData.accountId ? rowData.accountId : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Transaction Amount</div> <div className='w-3'>:</div> <div className='w-11'>{rowData.amount ? rowData.amount : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Status</div>             <div className='w-3'>:</div> <div className='w-11'>{rowData.status ? rowData.status : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Currency</div>           <div className='w-3'>:</div> <div className='w-11'>{rowData.currency ? rowData.currency : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Beneficiary ID</div>     <div className='w-3'>:</div> <div className='w-11'>{rowData.beneficiaryId ? rowData.beneficiaryId : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Beneficiary</div>        <div className='w-3'>:</div> <div className='w-11'>{rowData.beneficiaryName ? rowData.beneficiaryName : '-'}</div></div>
                </div>
                <div className='w-full f-Dd'>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Mode Of Payment</div> <div className='w-3'>:</div> <div className='w-11'>{rowData.modeOfPayment ? rowData.modeOfPayment : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Purpose</div>         <div className='w-3'>:</div> <div className='w-11'>{rowData.purpose ? rowData.purpose : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Transfer Status</div> <div className='w-3'>:</div> <div className='w-11'>{rowData.transferStatus ? rowData.transferStatus : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Created User</div>    <div className='w-3'>:</div> <div className='w-11'>{rowData.createdUser ? rowData.createdUser : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Created Date</div>    <div className='w-3'>:</div> <div className='w-11'>{rowData.createdDate ? rowData.createdDate : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Update User</div>     <div className='w-3'>:</div> <div className='w-11'>{rowData.modifiedBy ? rowData.modifiedBy : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-12rem font-semibold'>Update Date</div>     <div className='w-3'>:</div> <div className='w-11'>{rowData.modifiedDate ? rowData.modifiedDate : '-'}</div></div>
                </div>
            </div>
        )
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    const rendertransactionIdCell = (transactionId) => {
        return transactionId ? transactionId : '-';
    };

    const renderBeneficiaryNameCell = (beneficiaryName) => {
        return beneficiaryName ? beneficiaryName : '-';
    };

    const renderAmountCell = (amount) => {
        return amount ? amount : '-';
    };

    return (
        <div style={{ padding: '20px 0' }} >
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
            <DataTable value={transactionData} scrollable='true'
                paginator
                rowExpansionTemplate={rowExpansionTemplate}
                onRowToggle={onRowToggle}
                expandedRows={expandedRows}
                style={{ whiteSpace: 'nowrap' }}
                rows={5} rowsPerPageOptions={[5, 10, 15, 20]} id='trans-tab'>
                <Column expander style={{ width: '3rem' }} />
                <Column field="transactionId" header="Transaction Id" body={(rowData) => rendertransactionIdCell(rowData.transactionId)}></Column>
                <Column field="amount" header="Amount" body={(rowData) => renderAmountCell(rowData.amount)}></Column>
                <Column field="category" header="Category" body={TypeTemplate} ></Column>
                <Column field="beneficiaryName" header="Beneficiary Name" body={(rowData) => renderBeneficiaryNameCell(rowData.beneficiaryName)}></Column>
                <Column field="createdUser" header="Created User"></Column>
            </DataTable>
        </div>
    );
};
export default Transactionv;
